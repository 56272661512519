import React, {useState, useCallback} from 'react';
import formatMessage from 'format-message';
import Dialog from '../../../components/dialog';
import Button from '../../../components/button';
import {NotificationTypeSelectFormField} from '../../../components/form/form-fields';
import {TextField} from '../../../components/form/fields';
import {NOTIFICATION_TITLE_MAX_LENGTH} from '../../../assets/constants';

const NotificationEditDialog = ({value, onChange}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleCancel = useCallback(() => {
    setInputValue(value);
    setOpen(false);
  }, [value]);

  const handleUpdate = useCallback(() => {
    onChange(inputValue);
    setOpen(false);
  }, [onChange, inputValue]);

  return (
    <>
      <Button variant='outlined' icon={'edit'} onClick={() => setOpen(true)}>
        {formatMessage('Edit')}
      </Button>
      <Dialog
        open={open}
        title={formatMessage('Edit notification')}
        onClose={handleCancel}
        button={formatMessage('Update')}
        action={handleUpdate}
        disabled={!inputValue}
      >
        <NotificationTypeSelectFormField disabled />
        <TextField
          label={formatMessage('Title')}
          value={inputValue}
          handleChange={e => setInputValue(e.currentTarget.value)}
          showError={!inputValue}
          error={formatMessage('Please fill in a title name')}
          isWarning
          maxLength={NOTIFICATION_TITLE_MAX_LENGTH}
        />
      </Dialog>
    </>
  );
};

export default NotificationEditDialog;
