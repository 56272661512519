import theme from '../../../assets/theme';

export default {
  wrapper: {
    '-ms-grid-row': 1,
    '-ms-grid-column': 3,
    gridArea: 'extra',
  },
  container: {
    top: '0',
    right: '0',
    zIndex: '9999',
    display: 'flex',
    padding: [['6px', theme.spacing(10), '6px', '0']],
    alignItems: 'center',
  },
  iconContainer: {
    borderRadius: '100px',
    boxSizing: 'content-box',
    height: '24px',
    width: '24px',
    margin: '0 6px',
    padding: '6px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
  icons: {
    height: '24px',
    width: '24px',
  },
  menuItems: {
    width: 140,
  },
};
