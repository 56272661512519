export const getDashboardDetail = (dashboards, dashboardId, key) => {
  let activeDashboard = dashboards.find(d => d[key] === dashboardId);
  if (!activeDashboard) {
    for (let dashboard of dashboards) {
      const selectedChild = dashboard.foundChildren?.find(children => children[key] === dashboardId);
      if (selectedChild) {
        return selectedChild;
      } else {

      }
    }
  }
  return activeDashboard;
};
