import React, {useEffect, useRef} from 'react';
import {withStyles} from '@material-ui/core';
import theme from '../../../assets/theme';
import texts from '../../../assets/texts';
import logo from '../../../assets/images/seabury-logo.png';

const isIE11 =
  '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

const styles = {
  container: {
    position: 'relative',
    '-ms-grid-row': 1,
    '-ms-grid-column': 1,
    gridArea: 'logo',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
    zIndex: '4',
    background: theme.palette.background.paper,
  },
  relative: {
    display: 'block',
    left: '0',
    padding: [['6px', theme.spacing(2), '6px', theme.spacing(10)]],
    width: 'auto',
    height: '48px',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
  },
  fixed: {
    opacity: '0',
    position: 'fixed',
    top: '0',
    left: '0',
    display: 'block',
    padding: [['6px', theme.spacing(2), '6px', theme.spacing(10)]],
    width: 'auto',
    height: '48px',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
  },
};

const Logo = ({classes, sticky}) => {
  const relative = useRef();
  const fixed = useRef();

  useEffect(() => {
    const makeSticky = () => {
      if (relative && fixed) {
        if (relative.current && fixed.current) {
          window.requestAnimationFrame(function() {
            const position = relative.current.getBoundingClientRect().top;
            if (position >= 0) {
              fixed.current.style.opacity = '0';
              relative.current.style.opacity = '1';
            } else {
              fixed.current.style.opacity = '1';
              relative.current.style.opacity = '0';
            }
          });
        }
      }
    };

    if (sticky) {
      if (!isIE11) window.addEventListener('scroll', makeSticky);

      return () => window.removeEventListener('scroll', makeSticky);
    }
  }, [sticky]);

  return (
    <div className={classes.container}>
      <img ref={relative} src={logo} alt={texts.company} className={classes.relative} />
      <img ref={fixed} src={logo} alt={texts.company} className={classes.fixed} />
    </div>
  );
};

export default withStyles(styles)(Logo);
