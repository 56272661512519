const EDITOR_MIN_HEIGHT = 300;

export default {
  messageContainer: {
    '& #editor': {
      overflow: 'hidden',
    },
    '& .ql-editor': {
      minHeight: EDITOR_MIN_HEIGHT,
      maxHeight: '85vh',
    },
  },
};
