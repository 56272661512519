import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from './card-header-styles';

const CardHeader = ({classes, title, subtitle, extra, rightButton}) => {
  return (
    <div className={classes.header}>
      <div className={classes.titles}>
        <h2 className={classes.mainTitle}>{title}</h2>
        <div className={classes.subTitles}>
          <h3 className={classes.subTitle}>{subtitle}</h3>
          {extra && <h3 className={classes.period}>{extra}</h3>}
        </div>
      </div>
      {rightButton && <div className={classes.actions}>{rightButton}</div>}
    </div>
  );
};

export default withStyles(styles)(CardHeader);
