import theme from '../../../../assets/theme';

export default {
  titleContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  title: {
    lineHeight: '30px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 28,
    margin: 0,
    wordBreak: "break-word",
    marginRight: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: [[theme.spacing(1), 0, 0]],
  },
  closeIcon: {
    marginLeft: theme.spacing(-8),
    position: 'absolute',
    cursor: 'pointer',
    padding: theme.spacing(1),
  },
  statusTag: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    borderRadius: '20px',

    '&> img': {
      marginRight: theme.spacing(1),
    },
  },
  statusTagWarning: {
    color: theme.palette.grey.mid,
    border: `1px solid ${theme.palette.warning.main}`,
  },
  infoIcon: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
};
