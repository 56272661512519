import React from 'react';
import propTypes from 'prop-types';
import formatMessage from 'format-message';
import {Grid, withStyles, OutlinedInput, InputAdornment} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SearchIcon from '@material-ui/icons/Search';
import styles from './notification-viewer-header-styles';
import {UserTypeOptions} from '../../../../state/notifications/constants';

const NotificationViewerHeader = ({classes, filters, onTypeClick, disabled, onSearchChange, selectedOption}) => (
  <Grid container className={classes.container} justify='space-between'>
    <Grid item className={classes.item}>
      <ToggleButtonGroup
        size='small'
        variant='contained'
        color='primary'
        value={selectedOption}
        onChange={onTypeClick}
        disabled={disabled}
        exclusive
      >
        {UserTypeOptions.map((userTypeOption,index) => {
          return (
            <ToggleButton
              key={index}
              className={classes.outline}
              classes={{
                root: classes.root,
                selected: classes.selected,
                disabled: classes.disabled,
                textColorInherit: classes.textColorInherit,
              }}
              color='primary'
              variant='contained'
              value={userTypeOption.value}
            >
              {userTypeOption.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Grid>
    <Grid item className={classes.item}>
      <OutlinedInput
        defaultValue={filters.query}
        className={classes.input}
        onChange={onSearchChange}
        placeholder={formatMessage('Search')}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        inputProps={{
          maxLength: 40,
        }}
      />
    </Grid>
  </Grid>
);

NotificationViewerHeader.propTypes = {
  classes: propTypes.object.isRequired,
  onTypeClick: propTypes.func.isRequired,
  onSearchChange: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

NotificationViewerHeader.defaultProps = {
  disabled: false,
};
export default withStyles(styles)(NotificationViewerHeader);
