import theme from '../../../assets/theme';

export default {
  welcomeTitle: {
    color: theme.palette.black,
    fontSize: 48,
    fontWeight: 'bold',
    letterSpacing: -2
  },

  card: {
    width: 500,
    display: 'flex',
    flexFlow: 'column  nowrap',
    '&.MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    [theme.breakpoints.down('lg')]: {
      width: 465,
    },
    [theme.breakpoints.down('md')]: {
      width: 385,
    },
    [theme.breakpoints.up('xl')]: {
      width: 640,
    },
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(5),
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  field: {
    height: theme.spacing(7),
    margin: 0,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(15),
      fontSize: '2rem'
    }
  },
  underline: {
    "&:before": {
      borderBottomColor: theme.palette.grey.midLight,
      borderWidth: "1px"
    }
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    }
  },
  errorContainer: {
    position: 'relative',
    margin: 0,
  },
  error: {
    position: 'absolute',
    top: -theme.spacing(6),
    left: 0,
    padding: [[theme.spacing(1), 0]],
    margin: 0,
    color: theme.palette.grey.mid,
    fontSize: 12,
    lineHeight: '16px',
  },
  buttonSection: {
    display: 'flex',
    '&.MuiButton-root': {
      width: 170,
      borderRadius: 'none'
    }
  },
  button: {
    height: theme.spacing(6),
    width: theme.spacing(12)+2,
    marginBottom: theme.spacing(3),
    textTransform: 'none',
    color: 'white',
    '&$disabled': {
      color: 'white',
    },
    fontSize: 16,
    borderRadius: 'none'
  },
  disabled: {},
  alternative: {
    display: 'block',
    textDecoration: 'none',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    height: theme.spacing(2)-3,
    width: theme.spacing(12)+2,
    color: theme.palette.black,
    fontSize: theme.spacing(2)-3,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center'
  },
  help: {
    textAlign: 'start',
    color: theme.palette.grey.midLight,
    fontSize: 13,
    lineHeight: '24px',
    marginTop: theme.spacing(8),
  },
};
