import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {Link, Tabs, Tab, MenuList, MenuItem, Divider, RootRef, withStyles} from '@material-ui/core';
import {useStore} from 'outstated';

import {numberOfDashboardTabs, COGNOS_URL} from '../../../assets/constants';
import Menu from '../../menu';
import LinkIcon from '../../../assets/icons/icon_24_link_blue.svg';
import ChevronDownIcon from '../../../assets/icons/icon_24_chevron_down_blue.svg';
import ChevronUpIcon from '../../../assets/icons/icon_24_chevron_up_blue.svg';
import styles from './navigation-dashboards-styles';
import DashboardStore from "../../../state/dashboard";

const NavigationDashboard = props => {
  const {classes, onClick, tabs, newDashboardTabs, linkName = "Datasets", setLinkName, active} = props;

  const [moreMenu, setMoreMenu] = useState();
  const moreMenuRef = useRef();
  const tabMenuRef = useRef([]);
  const tabSubMenuRef = useRef([]);
  const [activeMenu, setActiveMenu] = useState();
  const [activeSubMenu, setActiveSubMenu] = useState();
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);

  const history = useHistory();

  useEffect(() => {
    if (props.tabs) {
      tabMenuRef.current = tabMenuRef.current.slice(0, props.tabs.length);
    }
  }, [props.tabs]);

  const openNewWindow = childId => {
    const url = `${process.env.REACT_APP_HTTP_HOST}/?fullScreenView=true&id=${childId}`;
    const screen = window.screen;

    return window.open(url, '_blank', `toolbar=0,location=0,menubar=0,width=${screen.width},height=${screen.height}`);
  };

  const getName = child => {
    const name = child.dropDownName?.length ? child.dropDownName : child.name;
    if (!child.targetWindow) {
      return <span>{name}</span>;
    }
    return (
      <div style={{display: 'flex'}}>
        <span style={{paddingRight: 10}}>{name}</span>
        <img src={LinkIcon} alt={child.name} />
      </div>
    );
  };

  const getItemName = child => {
    const name = child.name;
    if (!child.targetWindow) {
      return <span>{name}</span>;
    }
    return (
      <div style={{display: 'flex'}}>
        <span style={{paddingRight: 10}}>{name}</span>
        <img src={LinkIcon} alt={child.name} />
      </div>
    );
  };

  const handleMenuOnclick = child => {
    if (child.targetWindow) {
      openNewWindow(child.id);
    } else {
      onClick(child.id);
    }
  };

  const handleButtonOnClick = () => {
    const currentPath = history.location.pathname;
    if (currentPath.includes('notifications') || currentPath.includes('help')) {
      DashboardMutations.setInitialized(false);
      history.push('/airworld');
    } else {
      if (linkName === 'Dashboards') {
        setLinkName('Datasets');
      } else {
        setLinkName('Dashboards');
      }
    }
  }

  return (
    <Tabs
      classes={{
        root: classes.container,
        flexContainer: classes.flexContainer,
        fixed: classes.fixed,
        indicator: classes.hiddenIndicator,
      }}
      textColor='primary'
      value={active}
      onMouseLeave={() => {
        setMoreMenu(false);
        setActiveMenu(null);
      }}
    >
      {tabs && tabs.map((item, index) => {
        let beforeActive = tabs[index + 1] && tabs[index + 1].id === active;
        if (!beforeActive && index === numberOfDashboardTabs - 1) beforeActive = true;

        if (index > numberOfDashboardTabs) {
          return null;
        } else if (index === numberOfDashboardTabs) {
          return (
            <RootRef key={index} rootRef={moreMenuRef}>
              <Tab
                key={index}
                selected={tabs.slice(numberOfDashboardTabs).find(e => e.id === active) || moreMenu}
                label={
                  <span>
                    More
                    <img src={moreMenu ? ChevronUpIcon : ChevronDownIcon} alt='' />
                  </span>
                }
                value={item.id}
                onClick={() => {
                  setMoreMenu(true);
                  setActiveMenu(null);
                }}
                onMouseEnter={() => {
                  setMoreMenu(true);
                  setActiveMenu(null);
                }}
                TabsListProps={{ onMouseLeave: () => setMoreMenu(false) }}
                className={classes.moreTab}
                classes={{
                  root: classes.dashboard,
                  selected: classes.selected,
                  disabled: classes.disabled,
                  textColorInherit: classes.textColorInherit,
                }}
              />
            </RootRef>
          );
        } else if (item.foundChildren?.length) {
          return (
            <div>
              <RootRef key={index} rootRef={el => (tabMenuRef.current[index] = el)}>
                <Tab
                  key={index}
                  selected={activeMenu === index}
                  label={getName(item)}
                  value={item.id}
                  onMouseEnter={() => {
                    setMoreMenu(null);
                    setActiveMenu(index);
                  }}
                  onClick={() => handleMenuOnclick(item)}
                  className={classes.moreTab}
                  classes={{
                    root: classes.dashboard,
                    selected: classes.selected,
                    disabled: classes.disabled,
                    textColorInherit: classes.textColorInherit,
                  }}
                />
              </RootRef>
              <Menu
                anchorEl={tabMenuRef.current[index]}
                open={activeMenu === index}
                onClickAway={() => setActiveMenu(null)}
                className={classes.moreMenu}
              >
                <MenuList className='menu-items'>
                  {item.foundChildren.map((child, chidIndex) => {
                    if (item.foundChildren.length === 1 && !child.selfChild) {
                      return (
                        <MenuItem
                          key={chidIndex}
                          selected={child.id === active}
                          className='menu-item'
                          onClick={() => {
                            handleMenuOnclick(child);
                            setActiveMenu(null);
                          }}
                        >
                          {getItemName(child)}
                          <Divider key={chidIndex} className='divider' />
                        </MenuItem>
                      );
                    } else if (item.foundChildren.length > 1) {
                      return (
                        <MenuItem
                          key={chidIndex}
                          selected={child.id === active}
                          className='menu-item'
                          onClick={() => {
                            handleMenuOnclick(child);
                            setActiveMenu(null);
                          }}
                        >
                          {getItemName(child)}
                          <Divider key={chidIndex} className='divider' />
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </Menu>
            </div>
          );
        } else {
          return (
            <Tab
              className={beforeActive ? classes.beforeActive : ''}
              classes={{
                root: classes.dashboard,
                selected: classes.selected,
              }}
              onMouseEnter={() => setActiveMenu(null)}
              key={index}
              label={item.name}
              value={item.id}
              onClick={() => onClick(item.id)}
            />
          );
        }
      })}
      {/* <Tab
        className={classes.externalTab}
        disableRipple
        label={
          <span>
            <Link href={COGNOS_URL} target={'_blank'} underline={'none'}>
              Cognos
            </Link>
          </span>
        }
        icon={<img src={LinkIcon} alt={'Cognos'} />}
      />
      <Tab disabled className={classes.lastRoundedTab} /> */}
      <Tab
        className={classes.externalTab}
        disableRipple
        label={
          <span>
            <Link onClick={() =>{
              handleButtonOnClick(linkName)
              onClick(newDashboardTabs[0].id)
            } } underline={'none'}
            classes={{
              root: classes.link
            }}>
              {linkName}
            </Link>
          </span>
        }
        icon={<img src={LinkIcon} alt={'Cognos'} />}
      />
      <Tab disabled className={classes.lastRoundedTab} />
      <Menu
        open={Boolean(moreMenu)}
        anchorEl={moreMenuRef.current}
        onClickAway={() => setMoreMenu(false)}
        className={classes.moreMenu}
      >
        <MenuList className='menu-items'>
          {tabs && tabs.slice(numberOfDashboardTabs).map((item, index) => {
            if (item.foundChildren?.length) {
              return (
                <div>
                  <RootRef key={index} rootRef={el => (tabSubMenuRef.current[index] = el)}>
                    <MenuItem
                      key={item.id}
                      selected={item.id === active}
                      className='menu-item'
                      onMouseEnter={() => setActiveSubMenu(index)}
                      onClick={() => {
                        onClick(item.id);
                        setMoreMenu(null);
                      }}
                    >
                      {item.name}
                      <Divider  key={item.id} className='divider' />
                    </MenuItem>
                  </RootRef>
                  <Menu
                    anchorEl={tabSubMenuRef.current[index]}
                    open={activeSubMenu === index}
                    onClickAway={() => setActiveSubMenu(null)}
                    className={classes.moreMenu}
                    placement='right-start'
                  >
                    <MenuList className='menu-items'>
                      {item.foundChildren.map((child, chidIndex) => {
                        if (!child.selfChild) {
                          return (
                            <MenuItem
                              key={chidIndex}
                              selected={child.id === active}
                              className='menu-item'
                              onClick={() => {
                                handleMenuOnclick(child);
                                setActiveMenu(null);
                              }}
                            >
                              {getItemName(child)}
                              <Divider key={chidIndex} className='divider' />
                            </MenuItem>
                          );
                        }
                      })}
                    </MenuList>
                  </Menu>
                </div>
              );
            } else {
              return (
                <MenuItem
                  key={item.id}
                  selected={item.id === active}
                  className='menu-item'
                  onMouseEnter={() => setActiveSubMenu(index)}
                  onClick={() => {
                    handleMenuOnclick(item);
                    setMoreMenu(null);
                  }}
                >
                  {getItemName(item)}
                  <Divider className='divider' />
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Menu>
    </Tabs>
  );
};

export default withStyles(styles)(NavigationDashboard);
