import theme from '../../assets/theme';

export default {
  closeIcon: {
    cursor: 'pointer'
  },
  helpPinTo: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    borderRadius: 100
  },
  popoverSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  root: {
    top: 83,
    left: 1013
  },
  pintomessagebox: {
    color: theme.palette.primary.main,
    fontWeight: 'unset',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 3px 5px -1px #666, 0px 6px 10px 0px rgb(0 0 0 / 2%)',
    display: 'flex',
    padding: '6px',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
    borderRadius: 4,
    letterDpacing: '0.01071em',
    minWidth: 220,
    width: 'auto',
    height: 'fit-content',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  title: {
    paddingLeft: 'inherit',
    whiteSpace: 'nowrap',
    maxWidth: '230px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listWrap: {
    height: 'auto',
    maxHeight: 265,
    overflow: 'auto'
  },

  container: {
    width: 500,
    padding: [[theme.spacing(1), theme.spacing(2)]],
    '& .quill > div': {
      border: '0',
      maxHeight: '200px',
      overflowY: 'auto',
    },
  },
  listContainer: {
    width: 300,
    padding: [[theme.spacing(1), theme.spacing(2)]],
    '& .quill > div': {
      border: '0',
      maxHeight: '200px',
      overflowY: 'auto',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    '& .MuiTypography-displayBlock': {
      wordBreak: 'break-word'
    }
  },
  menu: {
    zIndex: '999 !important',
    marginTop: theme.spacing(-1),
    opacity: 0.98,
  },

  content: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [[theme.spacing(2.5), theme.spacing(2)]],
  },
  editor: {
    '&>div': {
      border: '0 !important',
      marginTop: theme.spacing(1),
      maxHeight: '500px',
      width: '450px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    maxHeight: '500px',
    width: '450px',
  },
  articleIconActive: {
    fill: theme.palette.primary.main,
  },
  shortTitle: {
      paddingLeft : [theme.spacing(2)]
  },
  info: {
      textAlign: 'end'
  },
};
