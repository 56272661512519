import {useMemo, useState} from 'react';
import {produce} from 'immer';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga';
import {DATE_RANGE_FILTER_TYPE} from '../assets/constants';
import DashboardsService from '../services/dashboards-service';
import logger from '../utils/logger';
import {Routes} from '../Routes';
import {getDashboardDetail} from '../sections/Helper';
import cloneDeep from 'lodash/cloneDeep';

const initialDashboardState = {
  initialized: false,
  dashboards: [],
  fullScreenView: false,
  dashboardId: null,
  current: {
    hasAccess: null,
    activeDashboard: false,
    activePage: false,
    filters: {
      values: [],
      isFilterV2: false,
    },
    period: '',
    loadedVisuals: 0,
    loadingVisuals: 0,
    pintoStatus: {
      pageHeader: false,
      pageFooter: false,
    },
    popperIndex: '',
    popperNotificationIndex: '',
    reportTooltipIndex: '',
    filterLevels: {}
  },
};

const getFiltersFromPage = page => {
  const filters = {values: [], isFilterV2: false};
  if (page && page.reports && page.reports.length > 0) {
    const legacyFilterValues = page.reports[0].filters.map(filter => ({
      ...filter,
      active: filter.active || null,
    }));

    const isFilterV2 = page.reports[0].exportDataFilters.length;

    filters.values = isFilterV2 ? page.reports[0].exportDataFilters : legacyFilterValues;
    filters.isFilterV2 = isFilterV2;
  }
  let defaultValueData = {};
  let filterDetails = cloneDeep(filters);
  filterDetails.values.forEach(element => {
    if(element.defaultFilterValues && element.defaultFilterValues.length) {
      element.filterValues && element.filterValues.map((filterValues) => {
        let endValue2 = {};
          let endValue3 = {};
    
          filterValues.value2.map((v2) => {
            if (v2.default) {
              endValue2 = v2;
            }
          });
          filterValues.value2 &&
          filterValues.value2.forEach((v2) => {
            v2.value3 &&
              v2.value3.forEach((v3) => {
                if (v3.default) {
                  endValue3 = v3;
                }
              });
          });

          if (filterValues.default) {
            defaultValueData = filterValues;

          } else if (endValue2.default) {
            defaultValueData = endValue2;

          } else if (endValue3.default) {
            defaultValueData = endValue3;
          }
      });
    }
    if(defaultValueData.length) {
      if(element.pbi && element.pbi.target && element.pbi.target.column ) {
        element.pbi.target.column = defaultValueData.type
      }
    }
  });

  return filterDetails;
};

const DashboardState = dashboardState => {
  const [state, setState] = useState(dashboardState || initialDashboardState);

  const mutations = useMemo(
    () => ({
      initDashboardData: payload =>
        setState(baseState =>
          produce(baseState, draft => {
            draft.dashboards = payload.dashboards;
            draft.initialized = true;
            draft.helppopoverStatus = false;
            draft.popperIndex = '';
            draft.popperNotificationIndex = '';
            draft.reportTooltipIndex = '';
            draft.current = {
              ...baseState,
              activeDashboard: payload.dashboardID,
              activePage: payload.pageID,
              filters: payload.filters,
              period: '',
              loadedVisuals: 0,
              loadingVisuals: 0,
              filterLevels: payload.filterLevels,
            };
            draft.pintoStatus = {
              pageHeader: false,
              pageFooter: false,
            };
          })
        ),

      setDashboardData: dashboards =>
        setState(
          produce(draft => {
            draft.dashboards = dashboards;
          })
        ),
      setFullScreenView: (fullScreenView, dashboardId) =>
        setState(
          produce(draft => {
            draft.fullScreenView = fullScreenView;
            draft.dashboardId = dashboardId;
          })
        ),
      setActiveDashboard: dashboardId =>
        setState(baseState =>
          produce(baseState, draft => {
            if (!dashboardId) {
              draft.current.activeDashboard = false;
              return;
            }
            draft.current.activeDashboard = dashboardId;
            const activeDashboard = getDashboardDetail(baseState.dashboards, dashboardId, 'id');
            if (activeDashboard && activeDashboard.pages.length) {
              const activePage = activeDashboard.pages.find(p => p.id === baseState.current.activePage);
              draft.current.activePage = activePage ? activePage.id : activeDashboard.pages[0].id;
              draft.current.filters = getFiltersFromPage(activePage || activeDashboard.pages[0]);
            }
          })
        ),

      setActivePage: pageID =>
        setState(baseState =>
          produce(baseState, draft => {
            const activeDashboard = getDashboardDetail(baseState.dashboards, baseState.current.activeDashboard, 'id');
            const activePage = activeDashboard.pages.find(p => p.id === pageID);
            if (activePage) {
              draft.current.activePage = pageID;
            }
            draft.current.filters = getFiltersFromPage(activePage);
          })
        ),

      clearActiveDashboardAndPage: () =>
        setState(
          produce(draft => {
            draft.current.activePage = false;
            draft.current.activeDashboard = false;
            draft.current.hasAccess = null;
          })
        ),

      setFilters: filters =>
        setState(
          produce(draft => {
            draft.current.filters = filters;
          })
        ),
      
      setFilterLevels: filterLevels =>
        setState(
          produce(draft => {
            draft.current.filterLevels = filterLevels.selection;
          })
        ),

      clearDashboards: () =>
        setState(
          produce(draft => {
            draft = initialDashboardState;
          })
        ),

      setAccess: hasAccess =>
        setState(
          produce(draft => {
            draft.current.hasAccess = hasAccess;
          })
        ),

      increaseLoadingVisuals: () => {
        setState(
          produce(draft => {
            draft.current.loadingVisuals += 1;
          })
        );
      },

      decreaseLoadingVisuals: () => {
        setState(
          produce(draft => {
            draft.current.loadingVisuals -= 1;
            draft.current.loadedVisuals += 1;
          })
        );
      },
      setPeriod: period =>
        setState(
          produce(draft => {
            draft.current.period = period;
          })
        ),
      setInitialized: value =>
        setState(
          produce(draft => {
            draft.initialized = value;
          })
        ),

      setPintoOpenStatus: values =>
        setState(
          produce(draft => {
            draft.pintoStatus.pageHeader = values.pageHeader;
            draft.pintoStatus.pageFooter = values.pageFooter;
          })
        ),
      setPopoverIndex: values =>
        setState(
          produce(draft => {
            draft.popperIndex = values;
          })
        ),
      setPopoverNotificationIndex: values =>
        setState(
          produce(draft => {
            draft.popperNotificationIndex = values;
          })
        ),
      setReportTooltip: values =>
        setState(
          produce(draft => {
            draft.reportTooltipIndex = values;
          })
        ),
    }),
    []
  );

  const effects = useMemo(
    () => ({
      initDashboards: async location => {
        try {
          const dashboards = await DashboardsService.getDashboards();
          const payload = {
            dashboards,
            dashboardID: false,
            pageID: false,
            filters: {values: [], isFilterV2: false},
          };

          const paths = location.pathname.split('/').slice(-2);
          if (paths.length === 2 && paths[1] !== '' && location.pathname !== Routes.MS_RETURN_TOKEN) {
            if (paths[0] === '') {
              const dashboard = getDashboardDetail(dashboards, paths[1], 'url');
              if (dashboard) {
                payload.dashboardID = dashboard.id;
                const page = dashboard.pages.length ? dashboard.pages[0] : null;
                if (page) {
                  payload.pageID = page.id;
                  payload.filters = getFiltersFromPage(page);
                }
              }
            } else {
              const dashboard = getDashboardDetail(dashboards, paths[0], 'url');
              if (dashboard) {
                payload.dashboardID = dashboard.id;
                const page = dashboard.pages.find(p => p.url === `${paths[0]}/${paths[1]}`);
                if (page) {
                  payload.pageID = page.id;
                  payload.filters = getFiltersFromPage(page);
                }
              } else {
                if (state.fullScreenView) {
                  const activeDashboard = getDashboardDetail(dashboards, state.dashboardId, 'id');
                  if (activeDashboard && activeDashboard.pages.length) {
                    payload.dashboardID = activeDashboard.id;
                    payload.pageID = activeDashboard.pages[0].id;
                    payload.filters = getFiltersFromPage(activeDashboard.pages[0]);
                  }
                } else {
                  if (payload.dashboards.length > 0) {
                    payload.dashboardID = payload.dashboards[0].id;
                    if (payload.dashboards[0].pages.length > 0) {
                      payload.pageID = payload.dashboards[0].pages[0].id;
                      payload.filters = getFiltersFromPage(payload.dashboards[0].pages[0]);
                    }
                  }
                }
              }
            }
          } else if (payload.dashboards.length > 0) {
            if (state.fullScreenView) {
              const activeDashboard = getDashboardDetail(dashboards, state.dashboardId, 'id');
              if (activeDashboard && activeDashboard.pages.length) {
                payload.dashboardID = activeDashboard.id;
                payload.pageID = activeDashboard.pages[0].id;
                payload.filters = getFiltersFromPage(activeDashboard.pages[0]);
              }
            } else {
              payload.dashboardID = payload.dashboards[0].id;
              if (payload.dashboards[0].pages.length > 0) {
                payload.pageID = payload.dashboards[0].pages[0].id;
                payload.filters = getFiltersFromPage(payload.dashboards[0].pages[0]);
              }
            }
          }

          return mutations.initDashboardData(payload);
        } catch (err) {
          // To clear the local storage if there is any error and then it will redirect back to login
          mutations.clearDashboards();
          localStorage.clear();
          
          logger.warn('[dashboard] error initting dashbaords', err);
          return err;
        }
      },

      fetchDashboards: async () => {
        const dashboards = await DashboardsService.getDashboards();
        return mutations.setDashboardData(dashboards);
      },

      checkAccessToDashboard: token => {
        if (!token) return;
        if (
          jwtDecode(token).exp < Math.floor(new Date().getTime() / 1000) ||
          !state.dashboards ||
          !getDashboardDetail(state.dashboards, state.current.activeDashboard, 'id')
        ) {
          mutations.setAccess(false);
        } else {
          mutations.setAccess(true);
          if (process.env.NODE_ENV === 'production') {
            try {
              ReactGA.set({userId: jwtDecode(token).userInfo.id});
              ReactGA.set({dimension1: jwtDecode(token).userInfo.id});
            } catch (err) {
              logger.warn('[dashboard] error setting token info', err);
              return null;
            }
          }
        }
      },
      updateWindowView: (fullScreenView, dashboardId) => {
        return mutations.setFullScreenView(fullScreenView, dashboardId);
      },
      computePeriodLabel: () => {

        if (state.current.filters.isFilterV2) {
          const dateFilter = state.current.filters.values.find(f => f.values.find(r => r.period));
          if (dateFilter) {
            const periodToSet = dateFilter.values.find(r => {
              if (dateFilter.pbi.active) {
                return r.value === dateFilter.pbi.active;
              }
              if (dateFilter.defaultValues && dateFilter.defaultValues.length) {
                return r.value === dateFilter.defaultValues[0];
              }
            });
            if (periodToSet && state.current.period !== periodToSet.period) {
              mutations.setPeriod(periodToSet.period);
            }
          }
        } else {
          const dateFilter = state.current.filters.values.find(f => f.type === DATE_RANGE_FILTER_TYPE);
          if (
            dateFilter &&
            dateFilter.active &&
            dateFilter.active
              .split('/')
              .slice(-1)
              .toString()
              .toLowerCase() !== state.current.period.toLowerCase()
          ) {
            mutations.setPeriod(
              dateFilter.active
                .split('/')
                .slice(-1)
                .toString()
            );
          }
        }
      },
    }),
    [state, mutations]
  );

  return {state, mutations, effects};
};

export default DashboardState;
