import theme from '../../../assets/theme';

export default {
  container: {
    '-ms-grid-row': 1,
    '-ms-grid-column': 2,
    gridArea: 'dashboards',
    backgroundColor: theme.palette.background.paper,
    margin: '0',
    marginRight: 'auto',
    overflow: 'visible',
    '& $flexContainer': {
      height: '100%',
    },
    '& $fixed': {
      overflow: 'visible',
    },
  },
  flexContainer: {},
  fixed: {},
  selected: {},
  disabled: {},
  dashboard: {
    position: 'relative',
    minWidth: 'unset',
    borderRadius: theme.spacing(1),
    overflow: 'visible',
    backgroundColor: theme.palette.background.paper,
    zIndex: '4',
    '&$textColorInherit': {
      opacity: 1,
    },
    '& img': {
      width: 'auto',
    },
    '&:before': {
      display: 'none',
      content: '""',
      position: 'absolute',
      left: '-16px',
      bottom: '0',
      width: '32px',
      height: '16px',
      zIndex: '-1',
    },
    '&:after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      right: '-16px',
      bottom: '0',
      width: '32px',
      height: '16px',
      zIndex: '-1',
    },
    '&:hover': {
      zIndex: '1',
      backgroundColor: theme.palette.grey.light,
      cursor: 'pointer',
      '&:before, &:after': {
        display: 'block',
        backgroundColor: theme.palette.grey.light,
      },
    },
    '&$selected': {
      zIndex: '2',
      backgroundColor: theme.palette.background.default,
      borderRadius: [[theme.spacing(1), theme.spacing(1), 0, 0]],
      '&:before, &:hover:before, &:after, &:hover:after': {
        display: 'block',
        backgroundColor: theme.palette.background.default,
      },
    },
    '&$selected + &:hover': {
      zIndex: '3',
      '&:before': {
        display: 'none',
      },
    },
  },
  beforeActive: {
    '&:hover': {
      zIndex: '3',
      '&:after': {
        display: 'none',
      },
    },
  },
  moreTab: {
    color: theme.palette.primary.main,
    '& img': {
      paddingLeft: theme.spacing(1),
    },
  },
  lastRoundedTab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: [[0, 0, 0, theme.spacing(1)]],
    zIndex: '4',
    width: '16px',
  },
  externalTab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: [[0, 0, 0, theme.spacing(1)]],
    zIndex: '4',
  },
  textColorInherit: {},
  hiddenIndicator: {
    opacity: 0,
  },
  moreMenu: {
    marginTop: '-8px',
  },
  targetWindowStyle: {
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
  },
  link: {
    fontWeight: 'bold',
  },
};
