export const checkValidVisual = ({isInsightType, value, dashboards}) => {
  if (isInsightType && value && value.location && dashboards && dashboards.length) {
    const pinParts = value.location.split('/');
    // check dashboard
    const dashboardName = pinParts[0];
    const dashboard = dashboards.filter(dashboard => dashboard.name === dashboardName);
    if (!dashboard || !dashboard.length) return false;
    // check page
    const pageName = pinParts[1];
    const page = dashboard[0].pages.filter(page => page.name === pageName);
    if (!page || !page.length || !page[0].reports || !page[0].reports.length) return false;
    // check visual
    const visualName = pinParts[2];
    // NOTE: just 1 report per page for now, so it;s save to use the first item
    const visual = page[0].reports[0].charts.filter(chart => chart.title === visualName);
    if (!visual || !visual.length) return false;
  }
  return true;
};
