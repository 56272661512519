import theme from '../../../../assets/theme';

export default {
  header: {
    flex: '0 0 auto',
    display: 'flex',
  },
  titles: {
    flex: '1 1 auto',
    padding: [[theme.spacing(2), 0, theme.spacing(2), theme.spacing(2)]],
  },
  mainTitle: {
    fontSize: 18,
    margin: 0,
  },
  subTitles: {
    color: theme.palette.grey.mid,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    marginTop: 2,
    fontWeight: 300,
  },
  period: {
    fontSize: 14,
    margin: [[0, theme.spacing(2)]],
    marginTop: 2,
    fontWeight: 300,
  },
  actions: {
    margin: [['auto', 0, 'auto', theme.spacing(1)]],
    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    height: 32,
    padding: [[0, theme.spacing(2)]],
  },
  divider: {
    width: 1,
    margin: [[0, theme.spacing(2)]],
    height: '32px',
  },
  tooltip: {
    fontSize: 12,
    color: '#3B4045',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.grey.light,
    padding: [['6px']],
  },
  helpButton: {
    padding: [['6px']],
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
  popoverSection: {
    paddingTop: 0
  }
};
