import React, {useState, useRef, useMemo} from 'react';
import classNames from 'classnames';
import {withStyles, MenuList} from '@material-ui/core';
import styles from './navigation-menu-styles';
import Account from '../../../assets/icons/icon_24_account_blue.svg';
import Menu from '../../menu';
import NavigationIconHelp from '../../../components/navigation/icons/help';
import NavigationIconMailbox from '../../../components/navigation/icons/mailbox';
import {hasTempAdminPermission} from '../../../utils/access-token';

const NavigationMenu = ({classes, children}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const nav = useRef(null);
  const hasPermission = useMemo(() => hasTempAdminPermission(), []);

  return (
    <div className={classes.wrapper}>
      <div ref={nav} className={classes.container}>
        {/* TODO: Remove the hasPermission check, when content ready -  or simply make TEMP_HELP_PERMISSION in as false */}
        {hasPermission && <NavigationIconHelp />}
        <NavigationIconMailbox />
        <div
          className={classes.iconContainer}
          onClick={e => (anchorEl ? null : setAnchorEl(e.target))}
          style={{pointerEvents: anchorEl ? 'none' : ''}}
        >
          <img src={Account} alt='Account' className={classes.icons} title='Account' aria-label='Account' />
        </div>
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClickAway={() => setAnchorEl(null)}>
          <MenuList className={classNames(classes.menuItems, 'menu-items')}>{children}</MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default withStyles(styles)(NavigationMenu);
