export default {
  container: {
    position: 'relative',
    minHeight: '54px',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    visibility: 'hidden',
  },
};
