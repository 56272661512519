import theme from '../../assets/theme';

export default {
  main: {
    flex: '1 1 auto',
    marginBottom: theme.spacing(2),
  },
  grid: {
    maxWidth: '100%',
    padding: [[0, theme.spacing(10)]],
  },
  gridSpace: {
    padding: [[0, theme.spacing(1)]],
  },
  filterHeader: {
    display:'flex'
  },
  pinToBox: {
    paddingRight: theme.spacing(12)+1,
    marginBottom: 10,
  },
  visualPinTo: {
    padding: [[0, theme.spacing(12), theme.spacing(2),theme.spacing(10)]],
    display: 'flex'
   }
};
