import theme from '../../assets/theme';

export default {
  button: {
    width: '150px',
    padding: [[theme.spacing(1.75), theme.spacing(1) / 2]],
    borderRadius: theme.spacing(1),
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
