import theme from '../../assets/theme';

export default {
  container: {
    minWidth: '500px',
    maxWidth: '500px',
  },
  containerNarrow: {
    minWidth: '330px',
    maxWidth: '330px',
  },
  button: {
    width: '150px',
    padding: [[theme.spacing(1.75), theme.spacing(1) / 2]],
    borderRadius: theme.spacing(1),
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
  },
  content: {
    paddingBottom: 0,
  },
  noActions: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    maxWidth: '95%',
  },
  break: {
    margin: 0,
    border: 'none',
    borderTop: [[1, theme.palette.grey.light, 'solid']],
  },
};

