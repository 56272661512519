import {NOTIFICATION_STATUS} from '../../assets/constants';
import EyeOff from '../../assets/icons/icon_24_eye_off_grey.svg';
import formatMessage from 'format-message';
import EyeOn from '../../assets/icons/icon_24_eye_on_blue.svg';

export const getPublishDialogContent = type => ({
  newStatus: NOTIFICATION_STATUS.PUBLISHED,
  icon: EyeOn,
  title: formatMessage('Publish article?'),
  // Announcement and Insights
  message: type === 'VISUAL'
    ? formatMessage(
        'Do you want to publish this article? This will replace any article currently pinned to the same location.'
      )
    : formatMessage('Do you want to publish this article?'),
});

export const getUnpublishDialogContent = type => ({
  newStatus: NOTIFICATION_STATUS.UNPUBLISHED,
  icon: EyeOff,
  title: formatMessage('Unpublish article?'),
  message: formatMessage('Do you want to unpublish this article?'),
});

export const getDeleteDialogContent = status => ({
  title: formatMessage('Delete article?'),
  message:
    status === NOTIFICATION_STATUS.PUBLISHED
      ? formatMessage(
          'Are you sure you want to delete this article? Any published article will be unpublished and deleted.'
        )
      : formatMessage('Are you sure you want to delete this article?'),
});
