import React, {useEffect, useState} from 'react';
import {useStore} from 'outstated';
import {Redirect} from 'react-router';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import AppStore from '../../../state/app';
import LoadingSpinner from '../../../components/loading-spinner';
import {Routes} from '../../../Routes';
import styles from './terms-condition-styles';
import CommonHeader from '../../../components/common-header';
import Footer from '../../../components/footer';

const TermsAndConditions = props => {
  // From Styles
  const {classes, location} = props;
  const redirectUrl = (location && location.state && location.state.pathname) || Routes.ROOT;
  const {state: AppState, mutations: AppMutations} = useStore(AppStore);

  return (
    <>
      {!AppState.initialized && <LoadingSpinner />}
      {AppState.initialized && AppState.token !== null && <Redirect to={redirectUrl} />}
      {AppState.initialized && AppState.token === null && (
        <div className={classes.wrapper}>
          <CommonHeader nonLanding='true' />
          <section className={classes.termsContainer}>
            <Container maxWidth={'md'}>
              <h1 className={classes.title}>Terms and conditions</h1>
              <p className={classes.content}>
                Access to and use of this Database is provided solely under the Terms and Conditions as agreed between
                Accenture and Licensee. Accenture reserves the right to pursue legal recourse for any unauthorised
                access and use.
                <br />
                <br />
                Accenture will perform patch management for software used in delivery of the Databases, evaluating and
                deploying patches released by vendors on a monthly basis according to a planned patch schedule (updates,
                hotfixes and security) during defined scheduled maintenance windows, details of which are available on
                request.
                <br />
                <br /> Usage of this website is logged for analytics purposes.
              </p>
            </Container>
          </section>
          <Footer landingPages={true} />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(TermsAndConditions);
