import React, {useEffect, useMemo, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import {useStore} from 'outstated';
import {Button, useMediaQuery, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

import history from '../../../utils/history';
import Logo from '../../../components/navigation/logo';
import Dashboards from '../../../components/navigation/dashboards';
import Pages from '../../../components/navigation/pages';
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner-view';
import DashboardStore from '../../../state/dashboard';
import {NotificationsMailboxStore} from '../../../state/notifications/store';
import {PinArticleStore} from '../../../state/help/store';
import AppStore from '../../../state/app';
import styles from './dashboard-navigation-styles';
import DashboardNavigationIcons from './dashboard-navigation-icons';
import {HIDE_NOTIFICATION_SECTION, FILTER_STICKY_MAX_HEIGHT} from '../../../assets/constants';
import logo from '../../../assets/images/seabury-logo.png';
import {getDashboardDetail} from '../../Helper';
import {PINTO_TYPES} from '../../../state/help/constant';

const NavigationDashboard = ({classes, tabsEnabled, ...props}) => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);
  const {effects: MailboxEffects} = useStore(NotificationsMailboxStore);
  const {effects: PinArticleEffects} = useStore(PinArticleStore);

  const {state: AppState} = useStore(AppStore);

  const isSmallScreen = useMediaQuery(`(max-height:${FILTER_STICKY_MAX_HEIGHT}px)`, {noSsr: true});

  // Effects
  useEffect(() => { // To call notification api on each dashboard change
    if (
      DashboardState.current.activeDashboard &&
      DashboardState.current.activePage &&
      AppState.token &&
      !HIDE_NOTIFICATION_SECTION
    ) {
      MailboxEffects.getNotifications();
      MailboxEffects.getInsights();

      PinArticleEffects.getVisualArticles(PINTO_TYPES[0]);
      PinArticleEffects.getArticles();
      DashboardMutations.setFilterLevels({});

    }
  }, [DashboardState.current.activeDashboard, DashboardState.current.activePage]);
  
  useEffect(() => {
    if (DashboardState.initialized) {
      const unlisten = history.listen((location, action) => {
        if (action === 'POP') {
          DashboardMutations.setActiveDashboard(location.state.activeDashboardTab);

          if (location.state.activePageTab) {
            DashboardMutations.setActivePage(location.state.activePageTab);
          }
        }
        // if (AppState.dashboardRoutes.includes(location.pathname) && AppState.token && !HIDE_NOTIFICATION_SECTION) {
        //   MailboxEffects.getNotifications();
        //   MailboxEffects.getInsights();
        // }
      });

      return function unlistenHistory() {
        unlisten();
      };
    }
  }, [DashboardState.initialized, DashboardMutations, AppState.dashboardRoutes, AppState.token, MailboxEffects]);

  useEffect(() => {
    const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');
    if (dashboard) {
      const page = dashboard.pages.find(p => p.id === DashboardState.current.activePage);
      if (page) {
        history.replace(`/${page.url}`, {
          activeDashboardTab: DashboardState.current.activeDashboard,
          activePageTab: DashboardState.current.activePage,
        });
      } else {
        DashboardMutations.setActiveDashboard(false);
        DashboardMutations.setActivePage(false);
      }
    }
  }, [
    DashboardState.dashboards,
    DashboardState.current.activeDashboard,
    DashboardState.current.activePage,
    DashboardState,
    DashboardMutations,
  ]);

  const pageTabs = useMemo(() => {
    const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');
    if (dashboard) {
      return dashboard.pages;
    }
    return [];
  }, [DashboardState]);

  const closeWindow = () => {
    return window.close();
  };

  const [linkName, setLinkName] = useState('Datasets');

  const dashboardTabs = useMemo(() => {
    const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id')
    const dashboards = (linkName === "Datasets") ? true : false;
      if (dashboard.resourceType === "Dataset") {
        setLinkName('Dashboards')
        return DashboardState.dashboards.filter(tab => tab.resourceType === 'Dataset')
      } else if (dashboards) {
        return DashboardState.dashboards.filter(tab => tab.resourceType === 'Dashboard')
      } else {
        return DashboardState.dashboards.filter(tab => tab.resourceType === 'Dataset')
      }

   }, [DashboardState]);

   const newDashboardTabs = useMemo(() => {
    const newDashboards = (linkName === "Datasets") ? true : false;
     if (newDashboards) {
       return DashboardState.dashboards.filter(tab => tab.resourceType === 'Dataset')
     } else {
       return DashboardState.dashboards.filter(tab => tab.resourceType === 'Dashboard')
     }
    }, [DashboardState]);

  return (
    <>
      {!DashboardState.initialized && <LoadingSpinner />}
      {DashboardState.initialized && (
        <>
          {!DashboardState.fullScreenView ? (
            <AppBar
              position={`${!isSmallScreen ? 'sticky' : 'initial'}`}
              color='default'
              className={classNames(classes.appBarTopMargin, classes.appBar)}
            >
              <Logo color={'primary'} sticky={!isSmallScreen} />
              <Dashboards
                tabs={dashboardTabs}
                active={DashboardState.current.activeDashboard}
                onClick={DashboardMutations.setActiveDashboard}
                linkName={linkName}
                setLinkName={setLinkName}
                newDashboardTabs={newDashboardTabs}
                DashboardState={DashboardState}
              />
              <DashboardNavigationIcons 
                linkName={linkName}
                setLinkName={setLinkName}
                newDashboardTabs={newDashboardTabs}
                active={DashboardState.current.activeDashboard}
                onClick={DashboardMutations.setActiveDashboard} 
                sticky={!isSmallScreen} 
              />

              <Pages
                tabs={pageTabs}
                active={DashboardState.current.activePage}
                onClick={DashboardMutations.setActivePage}
                tabsEnabled={tabsEnabled}
                sticky={!isSmallScreen}
              />
            </AppBar>
          ) : (
            <AppBar position='sticky' color='default' className={classes.appBar}>
              <img src={logo} alt={'Seabury'} className={classes.relative} />
              <Pages
                tabs={pageTabs}
                active={DashboardState.current.activePage}
                onClick={DashboardMutations.setActivePage}
                tabsEnabled={tabsEnabled}
                sticky={!isSmallScreen}
                isFullScreen={DashboardState.fullScreenView}
              />
              <Grid item className={classes.buttonContainer}>
                <Button className={classes.button} onClick={closeWindow} color='primary' variant='contained' autoFocus>
                  CLOSE
                </Button>
              </Grid>
            </AppBar>
          )}
        </>
      )}
    </>
  );
};

NavigationDashboard.defaultProps = {
  tabsEnabled: false,
};

export default withStyles(styles)(NavigationDashboard);
