import React, {useEffect, useState} from 'react';
import {useStore} from 'outstated';
import {Redirect} from 'react-router';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';

import AppStore from '../../../state/app';
import LoadingSpinner from '../../../components/loading-spinner';
import {Routes} from '../../../Routes';
import styles from './announcements-styles';
import CommonHeader from '../../../components/common-header';
import {NotificationsMailboxStore} from '../../../state/notifications/store';
import RichTextEditorViewer from '../../../components/rich-text-editor/viewer';
import Footer from '../../../components/footer';
import bannerImage from '../../../assets/images/announcement.png';
import texts from '../../../assets/texts';

const AnnouncementsPage = props => {
  // From Styles
  const {classes, location} = props;
  const redirectUrl = (location && location.state && location.state.pathname) || Routes.ROOT;
  const {state: AppState, mutations: AppMutations} = useStore(AppStore);

  const {state: MailboxState, effects: MailboxEffects} = useStore(NotificationsMailboxStore);

  const {announcement} = MailboxState;

  useEffect(() => {
    MailboxEffects.getAnnouncement();
  }, []);

  if (!announcement) return null;

  return (
    <>
      {!AppState.initialized && <LoadingSpinner />}
      {AppState.initialized && AppState.token !== null && <Redirect to={redirectUrl} />}
      {AppState.initialized && AppState.token === null && (
        <div className={classes.wrapper}>
          <CommonHeader nonLanding='true' />
          {Object.keys(announcement).length ? (
            <section className={classes.termsContainer}>
              <Container maxWidth={'md'}>
                <img src={bannerImage} alt={texts.company} className={classes.bannerImage} />
                <h6 className={classes.news}>— News</h6>
                <h5 className={classes.date}>{announcement.datePublished}</h5>
                <h1 className={classes.title}>{announcement.title}</h1>
                <div className='announcement-page'>
                  <RichTextEditorViewer value={announcement.message} />
                </div>
              </Container>
            </section>
          ) : (
            <Container maxWidth={'md'}>
              <section className={classes.termsContainer}>
                <h1 className={classes.title} style={{textAlign: 'center'}}>
                  No announcements found!
                </h1>
              </section>
            </Container>
          )}

            <div className={classes.footer}>
              <Footer landingPages={true} />
            </div>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(AnnouncementsPage);
