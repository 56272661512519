import theme from '../../../../assets/theme';

export default {
  mailboxContainer: {
    width: 500,
    padding: [[theme.spacing(1), theme.spacing(2)]],
  },
  closeIcon: {
    display: 'flex',
    cursor: 'pointer',
  },
  wideBadge: {
    width: '100%',
  },
  divider: {
    bottom: 'inherit !important',
  },

  // Mailbox list
  listContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [[theme.spacing(2.5), theme.spacing(2)]],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  listInfo: {
    marginRight: theme.spacing(1.5),
  },
  listTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  listSubtitle: {
    color: theme.palette.grey.mid,
    fontSize: '14px',
  },
  chevronIcon: {
    minWidth: '8px',
  },

  // Bottom Action
  bottomContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(3),

    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },

  '@media screen and (max-height: 768px)': {
    listContent: {
      padding: [[theme.spacing(1.5), theme.spacing(2)]],
    },
    bottomContainer: {
      padding: theme.spacing(2),
    },
  },
};
