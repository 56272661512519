import theme from '../../../assets/theme';

export default {
  container: {
    paddingBottom: theme.spacing(1) / 2,
    '&:hover': {
      background: theme.palette.grey.veryLight,
      margin: -theme.spacing(1),
      width: `calc(100% + ${theme.spacing(2)}px)`,
      padding: [[theme.spacing(1), theme.spacing(1), theme.spacing(1.5)]],
      borderRadius: theme.spacing(1) / 2,
    },
    '&:hover $actions': {
      visibility: 'visible',
    },
  },
  title: {
    position: 'relative',
    color: theme.palette.primary.main,
    fontSize: 28,
    lineHeight: '30px',
    margin: 0,
  },
  link: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: [[theme.spacing(1), 0, 0]],
  },
  actions: {
    visibility: 'hidden',
  },
  button: {
    minWidth: 'unset',
    height: theme.spacing(4.5),
    background: 'white',
    border: [[1, 'solid', theme.palette.grey.light]],
    padding: [[theme.spacing(1) / 2, theme.spacing(1.5)]],
    marginLeft: theme.spacing(1),
    '& $icon': {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1.5),
  },
  icon: {
    display: 'block',
    height: 14,
    width: 14,
  },
};
