import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {SelectField} from '../fields';
import {NOTIFICATION_TYPE_ADMIN_LABELS} from '../../../assets/constants';

const options = [
  {
    value: NOTIFICATION_TYPE_ADMIN_LABELS.DATA_UPDATE,
    text: formatMessage(NOTIFICATION_TYPE_ADMIN_LABELS.DATA_UPDATE),
  },
  {
    value: NOTIFICATION_TYPE_ADMIN_LABELS.PRIORITY_DATA_UPDATE,
    text: formatMessage(NOTIFICATION_TYPE_ADMIN_LABELS.PRIORITY_DATA_UPDATE),
  },
  {value: NOTIFICATION_TYPE_ADMIN_LABELS.NEWS, text: formatMessage(NOTIFICATION_TYPE_ADMIN_LABELS.NEWS)},
  {value: NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT, text: formatMessage(NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT)},
  {
    value: NOTIFICATION_TYPE_ADMIN_LABELS.ANNOUNCEMENT,
    text: formatMessage(NOTIFICATION_TYPE_ADMIN_LABELS.ANNOUNCEMENT),
  },
];

const NotificationTypeSelectFormField = ({disabled}) => {
  return (
    <Field
      validate={value => (value ? undefined : formatMessage('required'))}
      name='type'
      render={({input, meta}) => (
        <SelectField
          label={formatMessage('Type')}
          value={input.value}
          handleChange={input.onChange}
          extra={meta.error}
          options={options}
          disabled={disabled}
        />
      )}
    />
  );
};

NotificationTypeSelectFormField.propTypes = {
  disabled: PropTypes.bool,
};

NotificationTypeSelectFormField.defaultProps = {
  disabled: false,
};

export default NotificationTypeSelectFormField;
