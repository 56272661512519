import theme from '../../../assets/theme';

export default {
  category: {
    marginTop: theme.spacing(2),
  },
  select: {
    margin: [[0, 0, theme.spacing(4)]],
  },
  selectInputFilled: {
    border: `1px ${theme.palette.grey.mid} solid !important`,
  },
  radio: {
    padding: [[theme.spacing(1), theme.spacing(2)]],
  },
};
