import React, {useMemo} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import formatMessage from 'format-message';
import CustomToolbar from './toolbar';
import {getLinksForDelta, mediaHandler} from '../../utils/editor-utils';
import CustomVideoBlot from './custom-blocks/CustomVideoBlot';
import CustomImageBlot from './custom-blocks/CustomImageBlot';
import CustomImageResizeSpec from './custom-blocks/CustomImageResizeSpec';
import CustomVideoResizeSpec from './custom-blocks/CustomVideoResizeSpec';

const Font = Quill.import('formats/font');
const Size = Quill.import('formats/size');

Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida', 'roboto'];
Size.whitelist = ['extra-small', 'small', 'normal', 'large', 'huge'];

Quill.register(Font, true);
Quill.register(Size, true);
Quill.register(CustomVideoBlot);
Quill.register(CustomImageBlot);
Quill.register('modules/blotFormatter', BlotFormatter);

const clipboardMatcher = opts => (node, delta) => {
  delta.ops = delta.ops.reduce((acc, op) => {
    acc = op.insert ? [...acc, ...getLinksForDelta(op, opts)] : [...acc, op];
    return acc;
  }, []);
  return delta;
};

/**
 * Rich text editor
 *
 * @param value
 * @param onChange
 * @param placeholder
 * @param disableLinks: disables ALL links (included img and video)
 * @param disableVideo: disables only video (img and hyperlinks should be allowed)
 * @param disableImages: disables only img (video and hyperlinks should be allowed)
 * NOTE: base64 data img are already disabled by default, this prop does not change that behaviour
 * @returns {*}
 * @constructor
 */
const RichTextEditor = ({value, onChange, placeholder, disableLinks, disableVideo, disableImages}) => {
  const modules = useMemo(
    () => ({
      toolbar: {
        container: '#toolbar',
        handlers: {
          image: function() {
            mediaHandler(this.quill, 'image');
          },
          video: function() {
            mediaHandler(this.quill, 'video');
          },
          link: function() {
            mediaHandler(this.quill, 'link');
          },
        },
      },
      clipboard: {
        matchers: [
          ['V', clipboardMatcher({disableLinks, disableVideo, disableImages})],
          [Node.ELEMENT_NODE, clipboardMatcher({disableLinks, disableVideo, disableImages})],
          [Node.TEXT_NODE, clipboardMatcher({disableLinks, disableVideo, disableImages})],
        ],
      },
      history: {
        userOnly: true,
      },
      blotFormatter: {
        specs: [CustomImageResizeSpec, CustomVideoResizeSpec],
      },
    }),
    [disableLinks, disableVideo, disableImages]
  );

  return (
    <>
      <CustomToolbar disableLinks={disableLinks} disableVideo={disableVideo} disableImages={disableImages} />
      <ReactQuill
        id='editor'
        bounds='#editor'
        theme='snow'
        modules={modules}
        value={value}
        onChange={(content, delta, source) => {
          if (source === Quill.sources.USER) {
            onChange(content);
          }
        }}
        placeholder={placeholder}
      />
    </>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableLinks: PropTypes.bool,
  disableVideo: PropTypes.bool,
  disableImages: PropTypes.bool,
  placeholder: PropTypes.string,
};

RichTextEditor.defaultProps = {
  placeholder: formatMessage('Message'),
  disableLinks: false,
  disableVideo: false,
  disableImages: false,
};

export default RichTextEditor;
