import theme from '../../../assets/theme';

export default {
  wrapper: {
    marginTop: '9rem',
  },
  mainSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '7.5rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '7.5rem',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: 'fit-content',
      marginBottom: '7.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  secondMainSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '7.5rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '7.5rem',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: 'fit-content',
      marginBottom: '7.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  productImageSection: {
    [theme.breakpoints.only('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem',
    },
  },
  productSecondImageSection: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
    [theme.breakpoints.only('md')]: {
      display: 'flex',
    },
  },
  productSecondContent: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  productImg: {
    width: '100%',
    height: '100%',

    [theme.breakpoints.only('md')]: {
      height: '100%',

      margin: 'auto',
      marginTop: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  productSecondImg: {
    width: '100%',
    height: '100%',

    [theme.breakpoints.only('md')]: {
      height: '100%',

      margin: 'auto',
      marginTop: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },

  endTextContent: {
    margin: '4.25rem 0 4.25rem 0',
    [theme.breakpoints.only('md')]: {
      margin: 0,
    },
  },
  title: {
    color: theme.palette.black,
    fontSize: 48,
    fontWeight: 'bold',
    letterSpacing: -2,
    lineHeight: '60px',
    [theme.breakpoints.only('md')]: {
      width: 310,
    },
    [theme.breakpoints.up('md')]: {
      width: 319,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      letterSpacing: -1,
      lineHeight: '32px',
    },
  },
  subTitle: {
    color: theme.palette.black,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '20px',
  },
  productContent: {
    width: 360,
    color: theme.palette.black,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: {
      width: 338,
    },
  },
  productDescription: {
    [theme.breakpoints.up('xl')]: {
      marginTop: '20%',
    },
  },

  endImageSection: {
    width: '60%',
    height: '31.25rem',
  },
  startcontentSection: {
    width: '40%',
    height: 500,
  },
  startTextContent: {
    margin: '4.25rem 4.25rem 4.25rem 0',
    [theme.breakpoints.only('md')]: {
      margin: 0,
    },
  },
};
