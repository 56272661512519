import theme from '../../assets/theme';

export default {
  fullScreenContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 9999,
    backgroundColor: theme.palette.background.default,
  },
};
