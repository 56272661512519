import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

const transformFilters = dashboardFilters =>
  dashboardFilters.map(filter => ({
    column: filter.pbi.target.column,
    table: filter.pbi.target.table,
    label: filter.label,
    order: filter.order,
    values: filter.values,
    filterValues: filter.filterValues,
    fullColumnArray: filter.fullColumnArray,
    resetLabel: filter.resetLabel,
    pbi: {
      schemaUrl: filter.pbi['$schema'],
      filterType: filter.pbi.filterType,
      operator: filter.pbi.operator,
    },
  }));

export default {
  getReports: async workspaceId => {
    try {
      const {data} = await SeaburyAxios.get(`/api/powerbi/${workspaceId}/reports`);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  getReportVisuals: async (workspaceId, reportId) => {
    try {
      const {data} = await SeaburyAxios.get(`/api/powerbi/${workspaceId}/report/${reportId}`);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  createReport: async body => {
    try {
      const {data} = await SeaburyAxios.post('/api/admin/report', body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  /*
   * Report charts
   */

  addReportChart: async (report, chartId) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${report.id}/charts`, {
        charts: report.charts.map(chart => chart.id).concat(chartId),
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  setReportCharts: async (report, charts) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${report.id}/charts`, {charts});
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  /*
   * Report filters
   */

  addReportFilter: async (report, filter) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${report.id}/filters`, {
        filters: report.filters.concat(filter),
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  addReportFilterV2: async (report, filter) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/v2/admin/report/${report.id}/filters`, {
        filters: [...transformFilters(report.exportDataFilters), filter],
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  setReportFilters: async (report, filters) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${report.id}/filters`, {filters});
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  setReportFiltersV2: async (report, filters) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/v2/admin/report/${report.id}/filters`, {
        filters: transformFilters(filters),
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  removeReportFilter: async (report, filterIndex) => {
    try {
      const filters = report.filters.filter((f, i) => i !== filterIndex);
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${report.id}/filters`, {filters});
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  removeReportFilterV2: async (report, filterIndex) => {
    try {
      const filters = report.exportDataFilters.filter((f, i) => i !== filterIndex);
      const {data} = await SeaburyAxios.patch(`/api/v2/admin/report/${report.id}/filters`, {
        filters: transformFilters(filters),
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
