import React, {useCallback, useState} from 'react';
import _isObject from 'lodash/fp/isObject';
import {ClickAwayListener, Divider, Fab, MenuItem, MenuList, withStyles} from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import Menu from '../menu';
import styles from './dropdown-styles';
import propTypes from 'prop-types';

const Dropdown = ({classes, value, name, options, onItemClick, label, disabled}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorWidth, setAnchorWidth] = useState(null);

  const selectedValue = _isObject(value)
    ? options.find(opt => opt.id === value.id)
    : options.find(opt => opt.value === value);

  const openMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
    setAnchorWidth(event.currentTarget.offsetWidth);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = Boolean(anchorEl);
  return (
    <Fab
      key={name}
      aria-owns={anchorEl ? 'simple-menu' : undefined}
      aria-haspopup='true'
      onClick={event => (!isOpen ? openMenu(event) : closeMenu())}
      variant='extended'
      disableRipple
      className={classes.button}
      disabled={disabled}
    >
      {selectedValue ? selectedValue.label : label}
      {isOpen && <KeyboardArrowUp className={classes.arrow} />}
      {!isOpen && <KeyboardArrowDown className={classes.arrow} />}
      <Menu open={isOpen} anchorEl={anchorEl} style={{minWidth: anchorWidth}}>
        <ClickAwayListener onClickAway={closeMenu}>
          <MenuList className='menu-items'>
            {options.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onItemClick(item);
                  }}
                  className='menu-item'
                >
                  {item.label}
                  <Divider key={index} className='divider' />
                </MenuItem>
              );
            })}
          </MenuList>
        </ClickAwayListener>
      </Menu>
    </Fab>
  );
};

Dropdown.propTypes = {
  classes: propTypes.object.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.object,
  options: propTypes.array.isRequired,
  onItemClick: propTypes.func.isRequired,
  label: propTypes.string.isRequired,
  disabled: propTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
  value: null,
};
export default withStyles(styles)(Dropdown);
