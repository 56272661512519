import React, {useEffect, useState, useMemo} from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import {useStore} from 'outstated';

import {apiURL, ROW_LEVEL_SECURITY_LIST, TARGET_WINDOW_LIST} from '../../../assets/constants';
import {axiosHeader} from '../../../utils/axios';
import texts from '../../../assets/texts';

import {Card, CardContent, MenuList, MenuItem, Divider, withStyles} from '@material-ui/core';

import CustomCard from '../../../components/card';
import Category from '../../../components/category';
import Header from '../dashboard-details-header';
import Menu from '../../../components/menu';
import WithLoader from '../../../components/with-loader';
import AdminStore from '../../../state/admin';
import styles from './admin-dashboard-details-styles';

import Account from '../../../assets/icons/icon_24_account_darkGrey.svg';
import Page from '../../../assets/icons/icon_24_page_darkGrey.svg';

const DashboardDetails = props => {
  const [rowLevelSecurity, setRowLevelSecurity] = useState(ROW_LEVEL_SECURITY_LIST[0].value);
  const [targetWindow, setTargetWindow] = useState(TARGET_WINDOW_LIST[0].value);

  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);

  const dashboard = useMemo(() => props.dashboards[AdminState.activeIndex], [
    props.dashboards,
    AdminState.activeIndex,
  ]);

  // update state based on dashboard value
  useEffect(() => {
    setRowLevelSecurity(dashboard?.rowLevelSecurity?.toString() || ROW_LEVEL_SECURITY_LIST[0].value);
    setTargetWindow(dashboard?.targetWindow?.toString() || TARGET_WINDOW_LIST[0].value);
  }, [dashboard]);

  // From Styles
  const {classes} = props;

  // From Admin-container
  const {editDashboard, deleteDashboard, addPage, editPage} = props;

  // State
  const [groups, setGroups] = useState(null);
  const [groupsMenuAnchorEl, setGroupsMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    (async function getGroups() {
      setLoading(true);
      try {
        const url = `${apiURL}/admin/securityGroups`;
        const {data} = await axios.get(url, axiosHeader(200));
        const sortedGroups = data.groups
          .map(g => g)
          .sort((a, b) => {
            if (b.name.toLowerCase() < a.name.toLowerCase()) {
              return 1;
            } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
        setGroups(sortedGroups);
      } catch (e) {}

      setLoading(false);
    })();
  }, []);

  // Actions
  const addGroup = async groupName => {
    setLoading(true);
    setGroupsMenuAnchorEl(null);

    try {
      const groups = [...dashboard.groups, groupName].sort();
      const url = `${apiURL}/admin/dashboard/${dashboard.id}/securitygroups`;
      await axios.patch(url, {security_groups: groups}, axiosHeader(200));
    } catch (e) {}

    await AdminEffects.getDashboards();
    setLoading(false);
  };

  const removeGroup = async groupName => {
    setLoading(true);
    setGroupsMenuAnchorEl(null);

    const groups = dashboard.groups.filter(group => group !== groupName);

    try {
      const url = `${apiURL}/admin/dashboard/${dashboard.id}/securitygroups`;

      if (groups.length > 0) {
        await axios.patch(url, {security_groups: groups}, axiosHeader(200));
      } else {
        await axios.delete(url, axiosHeader(200));
      }
    } catch (e) {}

    await AdminEffects.getDashboards();
    setLoading(false);
  };

  const onChangeRowSecurity = async event => {
    const selectedOption = event.currentTarget.value;
    let body = {
      name: dashboard.name,
      url: dashboard.url,
      published: dashboard.published,
      rowLevelSecurity: +selectedOption,
    };
    const response = await AdminEffects.updateRowLevelSecurity(dashboard.id, body);
    if (response) {
      await AdminEffects.getDashboards();
      setRowLevelSecurity(selectedOption);
    } else {
      setRowLevelSecurity(rowLevelSecurity);
    }

    return;
  };

  const onChangeTargetwindow = async event => {
    const selectedOption = event.currentTarget.value;
    let body = {
      name: dashboard.name,
      url: dashboard.url,
      published: dashboard.published,
      targetWindow: +selectedOption,
    };
    const response = await AdminEffects.updateTargetWindow(dashboard.id, body);
    if (response) {
      await AdminEffects.getDashboards();
      setTargetWindow(selectedOption);
    } else {
      setTargetWindow(targetWindow);
    }
    return;
  };

  const renderRowSecurityView = classes => {
    return (
      <>
        {ROW_LEVEL_SECURITY_LIST.map((option, index) => (
          <>
            <input
              key={index}
              className={classes.radioSpace}
              checked={rowLevelSecurity === option.value}
              type='radio'
              value={option.value}
              name='rowLevelSecurity'
              onChange={onChangeRowSecurity}
            />
            <label className={classes.radioLabelSpace}>{option.label}</label>
          </>
        ))}
      </>
    );
  };

  const renderTargetWindow = classes => {
    return (
      <>
        {TARGET_WINDOW_LIST.map((option,index) => (
          <>
            <input
              key={index}
              className={classes.radioSpace}
              checked={targetWindow === option.value}
              type='radio'
              value={option.value}
              name='targetWindow'
              onChange={onChangeTargetwindow}
            />
            <label className={classes.radioLabelSpace}>{option.label}</label>
          </>
        ))}
      </>
    );
  };

  // Render
  if (dashboard)
    return (
      <Card className={classes.wrapper}>
        <CardContent className={classes.container}>
          <Header
            dashboardID={dashboard.id}
            title={dashboard.name}
            url={dashboard.url}
            editDashboard={editDashboard}
            deleteDashboard={deleteDashboard}
          />

          <Category
            icon={Account}
            title={'User groups'}
            button={'Add user group'}
            action={e => setGroupsMenuAnchorEl(e.target)}
            loading={loading}
            disabled={Boolean(groupsMenuAnchorEl)}
          />
          {groups && (
            <Menu
              open={Boolean(groupsMenuAnchorEl)}
              anchorEl={groupsMenuAnchorEl}
              onClickAway={() => setGroupsMenuAnchorEl(null)}
              placement='bottom-end'
            >
              <MenuList className='menu-items'>
                {groups
                  .filter(group => !dashboard.groups.includes(group.name))
                  .map(group => (
                    <MenuItem className='menu-item' key={group.uuid} onClick={() => addGroup(group.name)}>
                      {group.name}
                      <Divider className='divider' />
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}

          <WithLoader loading={Boolean(loading && groups)}>
            {dashboard.groups.map((group, id) => (
              <CustomCard key={id} title={group} onButtonClick={() => removeGroup(group)} button='trash' />
            ))}
          </WithLoader>
          <Category title={'Row level security'} />
          <CustomCard key={'rowSecurity'} children={renderRowSecurityView(classes)} applyHover={false} />
          <Category title={'Target Window'} />
          <CustomCard key={'targetWindow'} children={renderTargetWindow(classes)} applyHover={false} />

          <Category icon={Page} title={'Pages'} button={'Create a new page'} action={addPage} />

          {dashboard.pages.map((page, id) => (
            <CustomCard
              key={id}
              title={page.name}
              link={`${texts.admin.dashboardsURL}${dashboard.url}/${page.url
                .split('/')
                .slice(-1)
                .toString()}`}
              onCardClick={() => editPage(page.id)}
            />
          ))}
        </CardContent>
      </Card>
    );
  return null;
};

DashboardDetails.propTypes = {
  dashboard: propTypes.object,
  editDashboard: propTypes.func,
  deleteDashboard: propTypes.func,
  addPage: propTypes.func,
  editPage: propTypes.func,
};

export default withStyles(styles)(DashboardDetails);
