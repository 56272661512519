import React, {useEffect, useMemo} from 'react';
import formatMessage from 'format-message';
import _remove from 'lodash/remove';
import {withStyles, Checkbox} from '@material-ui/core';
import classNames from 'classnames';
import AccountIcon from '../../../../assets/icons/icon_24_account_darkGrey.svg';
import Button from '../../../../components/button';
import Category from '../../../../components/category';
import Autocomplete from '../../../../components/autocomplete';
import Card from '../../../../components/card';
import SectionCard from '../../../../components/card/section-card';
import styles from './help-audience-styles';
import {Field} from 'react-final-form';
import {useStore} from 'outstated';
import AdminStore from '../../../../state/admin';
import {NOTIFICATION_TYPE_ADMIN_LABELS} from '../../../../assets/constants';

const renderOption = (option, value) => {
  return (
    <div>
      <Checkbox color='primary' checked={value.includes(option)} />
      {option}
    </div>
  );
};

const HelpAudicienceContent = ({classes, input, audiences, isLoadingAudiences}) => {
  return (
    <div className={classes.contentContainer}>
      {input.value.map((audience, index) => {
        const isValid = isLoadingAudiences || audiences.includes(audience);
        return (
          <Card
            key={index}
            className={classNames(classes.card, {[classes.invalidCard]: !isValid})}
            title={audience}
            button={isValid ? 'cross' : 'warningCross'}
            onButtonClick={() => {
              const values = [...input.value];
              _remove(values, item => item === audience);
              input.onChange(values);
            }}
          />
        );
      })}
      {input.value && input.value.length !== 0 ? (
        <div className={classes.removeAllButton}>
          <Button variant='outlined' onClick={() => input.onChange([])}>
            {formatMessage('Remove all user groups')}
          </Button>
        </div>
      ) : (
        <p className={classes.emptyMessage}>{formatMessage('No Audience defined yet')}</p>
      )}
    </div>
  );
};

const HelpAudicienceStaticContent = ({classes, input, type}) => {
  const computedTitle = useMemo(() => {
    switch (type) {
      case NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT:
        return input.value[0];
      case NOTIFICATION_TYPE_ADMIN_LABELS.ANNOUNCEMENT:
        return formatMessage('Customers Login Screen');
      default:
        return formatMessage('Not defined type');
    }
  }, [type, input.value]);

  return (
    <div className={classes.contentContainer}>
      <Card title={computedTitle} />
    </div>
  );
};

const HelpAudienceSection = ({classes, type}) => {
  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);

  const {audiences, isLoadingAudiences} = AdminState;

  const requiresSelectSection = true

  useEffect(() => {
    if (requiresSelectSection) AdminEffects.getAudiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresSelectSection]);
  return (
    <Field
      name='audience'
      validate={value => (value.length || !requiresSelectSection ? undefined : formatMessage('required'))}
      render={({input}) => {
        return (
          <>
            <Category icon={AccountIcon} title={formatMessage('Audience')} />
            <SectionCard
              title={formatMessage('The audience are the User groups the article is going to be shared with')}
              subtitle={formatMessage('Add User Group here:')}
              button={
                requiresSelectSection && (
                  <Autocomplete
                    buttonLabel={formatMessage('Add user group')}
                    multiple
                    value={input.value}
                    onChange={input.onChange}
                    getOptionSelected={(option, value) => option === value}
                    renderOption={options => renderOption(options, input.value)}
                    getOptionLabel={option => option}
                    disableCloseOnSelect={true}
                    noOptionsText={formatMessage('No audience defined yet')}
                    options={audiences}
                  />
                )
              }
            >
              {requiresSelectSection ? (
                <HelpAudicienceContent
                  classes={classes}
                  input={input}
                  audiences={audiences}
                  isLoadingAudiences={isLoadingAudiences}
                />
              ) : (
                <HelpAudicienceStaticContent classes={classes} input={input} type={type} />
              )}
            </SectionCard>
          </>
        );
      }}
    />
  );
};

export default withStyles(styles)(HelpAudienceSection);
