import querystring from 'querystring';
import {Routes} from '../Routes';

const hostURL = process.env.REACT_APP_HTTP_HOST;
const tenant = process.env.REACT_APP_MICROSOFT_TENANT;
const clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const nonce = process.env.REACT_APP_TOKEN_NONCE;

export default ({redirectUrl}) => {
  const state = encodeURI(JSON.stringify({tokenState: process.env.REACT_APP_TOKEN_STATE, redirectUrl}));
  return `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?
    ${querystring.encode({
      client_id: clientId,
      response_type: 'id_token',
      redirect_uri: encodeURI(`${hostURL}${Routes.MS_RETURN_TOKEN}`),
      scope: 'profile offline_access https://graph.microsoft.com/mail.read openid',
      response_mode: 'fragment',
      state,
      nonce,
    })}`;
};

export const decodeTokenState = tokenState => JSON.parse(decodeURI(tokenState));
