import React from 'react';
import ProptTypes from 'prop-types';
import classNames from 'classnames';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {withStyles, Grid} from '@material-ui/core';

import CheckIcon from '../../../../assets/icons/icon_24_check_blue.svg';
import CloseIcon from '../../../../assets/icons/icon_24_close_blue.svg';
import styles from './help-edit-header-styles';
import {HelpEditDialog} from '../../create-dialog';

const HelpEditHeader = ({classes, article, isPublished, formState, onClose, onChange}) => {
  return (
    <Field
      name='fullTitle'
      render={({input}) => (
        <>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <div className={classes.titleContainer}>
                <div>
                  <img src={CloseIcon} alt='Close' className={classes.closeIcon} onClick={onClose} />
                  <h1 className={classes.title}>{input.value}</h1>
                </div>
                <HelpEditDialog value={input.value} onChange={onChange}
                article={article} formState={formState}/>
              </div>
            </Grid>
            <Grid item>
              <div className={classNames(classes.statusTag, {[classes.statusTagWarning]: !isPublished})}>
                {isPublished ? (
                  <>
                    <img src={CheckIcon} alt='Close' />
                    {formatMessage('Published')}
                  </>
                ) : (
                  formatMessage('Unpublished')
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>              
              <p className={classes.subtitle}>
                {formatMessage('Author is identified as: {author}', {
                  author: article.author.name || article.author.email,
                })}
              </p>
            </Grid>            
          </Grid>
        </>
      )}
    />
  );
};

HelpEditHeader.proptTypes = {
  classes: ProptTypes.object.isRequired,
  article: ProptTypes.object.isRequired,
};

export default withStyles(styles)(HelpEditHeader);
