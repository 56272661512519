import React, {useMemo, useEffect} from 'react';
import formatMessage from 'format-message';
import PropTypes from 'prop-types';
import {withStyles, Radio} from '@material-ui/core';
import SectionCard from '../../../../components/card/section-card';
import CustomCard from '../../../../components/card';
import Autocomplete from '../../../../components/autocomplete';
import ContentIcon from '../../../../assets/icons/icon_24_content_darkGrey.svg';
import Category from '../../../../components/category';
import styles from './notification-pin-styles';
import {useStore} from 'outstated';
import AdminStore from '../../../../state/admin';
import {NOTIFICATION_TYPE_ADMIN_LABELS} from '../../../../assets/constants';
import {Field} from 'react-final-form';
import classNames from 'classnames';
import {checkValidVisual} from '../../../../utils/notifications';

const renderOption = (options, selectedValue) => {
  const optToDisplay = options.location
    .split('/')
    .slice(1)
    .join(' - ');
  return (
    <div>
      <Radio color='primary' checked={options.chartId === selectedValue.chartId} />
      {optToDisplay}
    </div>
  );
};

const RadioItem = ({type}) => {
  const label = useMemo(() => {
    switch (type) {
      case NOTIFICATION_TYPE_ADMIN_LABELS.PRIORITY_DATA_UPDATE:
      case NOTIFICATION_TYPE_ADMIN_LABELS.DATA_UPDATE:
      case NOTIFICATION_TYPE_ADMIN_LABELS.NEWS:
        return formatMessage('Mailbox');
      case NOTIFICATION_TYPE_ADMIN_LABELS.ANNOUNCEMENT:
        return formatMessage('Login');
      default:
        return '';
    }
  }, [type]);

  return (
    <>
      <Radio color='primary' checked={true} />
      {label}
    </>
  );
};

const EditableItem = ({classes, insightInfo, onClick, isValid}) => {
  if (!insightInfo)
    return (
      <p className={classes.emptyMessage}>{formatMessage('This Notification is not pinned to a Dashboard yet')}</p>
    );

  const texts = insightInfo.location.split('/');
  return (
    <CustomCard
      className={classNames(classes.card, {[classes.invalidCard]: !isValid})}
      key={insightInfo.location}
      title={`${texts[0]} / ${texts[1]}`}
      link={texts[2]}
      onButtonClick={onClick}
      button={isValid ? 'cross' : 'warningCross'}
    />
  );
};

const NotificationPinSection = ({classes, type}) => {
  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);

  useEffect(() => {
    if (type === NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT) {
      AdminEffects.getDashboardsTilesInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const isInsightType = type === NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT;

  const options = useMemo(() => {
    const optionArr = [];

    AdminState.dashboards.forEach(dashboard => {
      dashboard.pages.forEach(page => {
        if (page.reports.length) {
          page.reports[0].charts.forEach(chart => {
            optionArr.push({
              chartId: chart.id,
              location: `${dashboard.name}/${page.name}/${chart.title}`,
            });
          });
        }
      });
    });

    return optionArr;
  }, [AdminState.dashboards]);

  return (
    <Field
      name='insightInfo'
      validate={value => (value || !isInsightType ? undefined : formatMessage('required'))}
      render={({input}) => {
        const isValid = checkValidVisual({isInsightType, value: input.value, dashboards: AdminState.dashboards});
        return (
          <>
            <Category icon={ContentIcon} title={formatMessage('Pin to')} />
            <SectionCard
              title={formatMessage('The page where it will be pinned to')}
              subtitle={formatMessage('Select page and visual here:')}
              button={
                isInsightType && (
                  <Autocomplete
                    buttonLabel={formatMessage('Add page/visual')}
                    value={input.value}
                    onChange={input.onChange}
                    getOptionSelected={(option, value) => option.chartId === value.chartId}
                    renderOption={({...options}) => renderOption(options, input.value)}
                    groupBy={option => option.location.split('/')[0]}
                    getOptionLabel={option => (typeof option === 'string' ? option : option.location)}
                    disableCloseOnSelect={true}
                    noOptionsText={formatMessage('No pages/visuals defined yet')}
                    options={options}
                    placeholder={formatMessage('Search dashboard/page/tile...')}
                  />
                )
              }
            >
              <div className={classes.contentContainer}>
                {!isInsightType ? (
                  <RadioItem type={type} />
                ) : (
                  <EditableItem
                    classes={classes}
                    insightInfo={input.value}
                    onClick={() => input.onChange(null)}
                    isValid={isValid}
                  />
                )}
              </div>
            </SectionCard>
          </>
        );
      }}
    />
  );
};

NotificationPinSection.propTypes = {
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(NotificationPinSection);
