import {useMemo, useState} from 'react';
import NotificationsService from '../../../services/notifications-service';
import logger from '../../../utils/logger';
import {useStore} from 'outstated';
import DialogStore from '../../dialog';
import formatMessage from 'format-message';
import {NOTIFICATION_STATUS} from '../../../assets/constants';
// eslint-disable-next-line import/named
import NotificationsMutations, {initialNotificationsState, resetPagination} from '../mutations';
import NotificationEffects from '../effects';
import produce from 'immer';
import {VALID_STATUSES} from '../constants';

const Admin = notificationState => {
  const initialState = {
    ...initialNotificationsState,
    filters: {
      ...initialNotificationsState.filters,
      status: null,
    },
  };
  const [state, setState] = useState(notificationState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...NotificationsMutations(setState),
      setFiltersStatus: status =>
        setState(
          produce(draft => {
            if (
              status === draft.filters.status ||
              (status === 'ALL' && draft.filters.status === null) ||
              !VALID_STATUSES.includes(status)
            ) {
              draft.loading = false;
            } else {
              draft.loading = true;
              draft.filters.status = status === 'ALL' ? null : status;
              resetPagination(draft);
            }
          })
        ),
    }),
    []
  );
  const effects = useMemo(
    () => ({
      ...NotificationEffects(state, mutations, DialogMutations),
      createOrUpdateNotification: async notification => {
        try {
          let data;
          if (notification._id) {
            data = await NotificationsService.updateNotification(notification._id, notification);
          } else {
            data = await NotificationsService.createNotification(notification);
          }
          mutations.setCurrentNotification(data);
        } catch (err) {
          if (err && err.response && err.response.status === 413) {
            DialogMutations.showError({
              title: formatMessage('Notification too large'),
              message: formatMessage('This notification can not be saved because it exceeds the maximum size (50KB).'),
            });
          } else {
            DialogMutations.showError({
              title: formatMessage('Unknown error'),
              message: formatMessage(
                'Due to an unknown error we could not update the notification. Please try again or try again later.'
              ),
            });
          }
          logger.warn(err);
          return null;
        }
      },

      deleteNotification: async id => {
        try {
          if (id) {
            mutations.setLoading(true);
            await NotificationsService.deleteNotification(id);
            if (state.current && state.current._id === id) {
              mutations.clearCurrent();
            }
            DialogMutations.showInfo({
              title: formatMessage('Notification deleted.'),
              message: formatMessage('The notification has been successfully deleted.'),
            });
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          DialogMutations.showError({
            title: formatMessage('Deletion error.'),
            message: formatMessage('The notification could not be deleted. Please try again later.'),
          });
          mutations.setLoading(false);
          return null;
        }
      },

      createOrUpdateNotificationPublication: async (notification, requestData = false) => {
        try {
          const {_id, ...body} = notification;
          let result = {};
          mutations.setLoading(true);
          if (_id) {
            let data = {};
            if (requestData) {
              data = await NotificationsService.getNotificationById(_id);
            }
            result = await NotificationsService.updateNotification(_id, {...data, ...body});
          } else {
            result = await NotificationsService.createNotification(body);
          }
          switch (notification.status) {
            case NOTIFICATION_STATUS.PUBLISHED:
              DialogMutations.showInfo({
                title: formatMessage('Notification published!'),
                message: formatMessage('The notification has been successfully published.'),
              });
              break;
            case NOTIFICATION_STATUS.UNPUBLISHED:
              DialogMutations.showInfo({
                title: formatMessage('Notification unpublished!'),
                message: formatMessage('The notification has been successfully unpublished.'),
              });
              break;
            // invalid
            default:
              break;
          }
          return result;
        } catch (err) {
          if (err && err.response && err.response.status === 413) {
            DialogMutations.showError({
              title: formatMessage('Notification too large'),
              message: formatMessage('This notification can not be saved because it exceeds the maximum size (50KB).'),
            });
          } else {
            switch (notification.status) {
              case NOTIFICATION_STATUS.PUBLISHED:
                DialogMutations.showError({
                  title: formatMessage('Error publishing'),
                  message: formatMessage(
                    'Due to an unknown error we could not update the notification. Please try again later.'
                  ),
                });
                break;
              case NOTIFICATION_STATUS.UNPUBLISHED:
                DialogMutations.showError({
                  title: formatMessage('Error unpublishing'),
                  message: formatMessage(
                    'Due to an unknown error we could not update the notification. Please try again later.'
                  ),
                });
                break;
              // invalid
              default:
                break;
            }
          }
          logger.warn(err);
          mutations.setLoading(false);
          return null;
        }
      },
    }),
    [mutations, DialogMutations, state]
  );
  return {state, mutations, effects};
};
export default Admin;
