import theme from '../../../assets/theme';

export default {
    wrapper: {
        display: 'block',
        fontFamily: theme.landingFontFamily.karla,
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh',
        position: 'relative'
    },
    termsContainer: {
        height: '100%',
        paddingTop: '9.5rem',
    },
    news: {
        color: theme.palette.black,
        fontSize: 15,
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '24px',
    },
    date: {
        color: theme.palette.grey.border,
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: '28px',
    },
    title: {
        color: theme.palette.black,
        fontSize: 36,
        fontWeight: 'bold',
        letterSpacing: -1.6,
        lineHeight: '52px',
        [theme.breakpoints.down('md')]: {
            fontSize: 36,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        }
    },

    footer: {
        paddingBottom: '9.5rem',
    }

};