import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {withStyles, Popper, InputBase} from '@material-ui/core';
import Button from '../button';
import {Autocomplete as MuiAutocomplete} from '@material-ui/lab';
import styles from './autocomplete-styles';
import formatMessage from 'format-message';

const Autocomplete = ({
  classes,
  className,
  buttonLabel,
  multiple,
  onChange,
  value,
  renderOption,
  disableCloseOnSelect,
  getOptionLabel,
  getOptionSelected,
  options,
  noOptionsText,
  groupBy,
  placeholder,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Button icon='add' variant='outlined' onClick={handleOpen}>
        {buttonLabel}
      </Button>
      <Popper id={buttonLabel} open={open} anchorEl={anchorEl} placement='bottom-end' className={classes.popper}>
        <MuiAutocomplete
          className={className}
          open
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            groupLabel: classes.groupLabel,
          }}
          onClose={handleClose}
          multiple={multiple}
          disableCloseOnSelect={disableCloseOnSelect}
          renderTags={() => null}
          options={options}
          value={value}
          onChange={(source, newValue, g) => {
            // Only allow to modify values through menu-options
            if (source.currentTarget.getAttribute('role') === 'option') onChange(newValue);
          }}
          disablePortal
          groupBy={groupBy}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          inputValue={inputValue}
          renderOption={renderOption}
          noOptionsText={noOptionsText}
          renderInput={params => {
            return (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                placeholder={placeholder}
                onChange={e => {
                  setInputValue(e.currentTarget.value);
                }}
                className={classes.inputBase}
              />
            );
          }}
        />
      </Popper>
    </>
  );
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  renderOption: PropTypes.func.isRequired,
  disableCloseOnSelect: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func.isRequired,
  options: PropTypes.array,
  noOptionsText: PropTypes.string,
  groupBy: PropTypes.func,
  placeholder: PropTypes.string,
};

Autocomplete.defaultProps = {
  multiple: false,
  disableCloseOnSelect: true,
  options: [],
  noOptionsText: formatMessage('No options'),
  placeholder: formatMessage('Search...'),
};

export default withStyles(styles)(Autocomplete);
