import SeaburyAxios, {AXIOS_REQUEST_CANCELED} from './SeaburyAxios';
import _pick from 'lodash/pick';
import {
  notificationsUrl,
  NOTIFICATION_STATUS,
  serverURL,
  NOTIFICATION_TYPES,
  NOTIFICATION_TYPE_ADMIN_LABELS,
  apiURL
} from '../assets/constants';
import qs from 'query-string';
import Axios from 'axios';

const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: 'short', day: '2-digit'});

let cancelToken;

const transformNotifications = items => {
  return items.map(notification => transformNotification(notification));
};

const transformNotification = notification => ({
  ...notification,
  dateCreated: dateTimeFormat.format(new Date(notification.createdAt)),
  datePublished: dateTimeFormat.format(new Date(notification.publishedAt)),
  status: NOTIFICATION_STATUS[notification.status],
  type: NOTIFICATION_TYPES[notification.type],
  typeAdminLabel: NOTIFICATION_TYPE_ADMIN_LABELS[notification.type],
});

const paramsSerializer = params => {
  const finalParams = Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
  return qs.stringify(finalParams);
};

const transformNotificationsToSend = notification => {
  const notificationToSend = _pick(notification, ['title', 'type', 'status', 'audience', 'message', 'insightInfo']);
  return {
    ...notificationToSend,
    type: Object.keys(NOTIFICATION_TYPE_ADMIN_LABELS).find(
      key => NOTIFICATION_TYPE_ADMIN_LABELS[key] === (notification.typeAdminLabel || notification.type)
    ),
    status: Object.keys(NOTIFICATION_STATUS).find(key => NOTIFICATION_STATUS[key] === notificationToSend.status),
  };
};

const NotificationService = {
  getNotifications: async params => {
    const {data} = await SeaburyAxios.get(notificationsUrl, {
      params,
      paramsSerializer,
    });
    return {
      ...data,
      docs: transformNotifications(data.docs),
    };
  },
  searchNotifications: async params => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = Axios.CancelToken.source();

      const {data} = await SeaburyAxios.get(notificationsUrl, {
        params,
        cancelToken: cancelToken.token,
        paramsSerializer,
      });
      return {
        ...data,
        docs: transformNotifications(data.docs),
      };
    } catch (error) {
      if (Axios.isCancel(error)) {
        const errorToThrow = {key: AXIOS_REQUEST_CANCELED};
        throw errorToThrow;
      } else {
        throw error;
      }
    }
  },
  createNotification: async body => {
    const notification = transformNotificationsToSend(body);

    const {data} = await SeaburyAxios.post(notificationsUrl, notification);

    return transformNotification(data);
  },
  updateNotification: async (notificationId, body) => {
    const notification = transformNotificationsToSend(body);

    const {data} = await SeaburyAxios.put(`${notificationsUrl}/${notificationId}`, notification);

    return transformNotification(data);
  },
  getNotificationById: async id => {
    const {data} = await SeaburyAxios.get(`${notificationsUrl}/${id}`);

    return transformNotification(data);
  },
  deleteNotification: async notificationId => {
    const {data} = await SeaburyAxios.delete(`${notificationsUrl}/${notificationId}`);
    return data;
  },
  getAnnouncement: async () => {
    const {data} = await Axios.get(`${serverURL}${notificationsUrl}/announcement`);
    return data ? transformNotification(data) : null;
  },
  getNotificationStorageFiles: async () => {
    const {data} = await SeaburyAxios.get(`${apiURL}/azureblob/notifications`);
    return data;
  },
};

export default NotificationService;
