import React, {useEffect,useMemo} from 'react';
import {Grid, Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useStore} from 'outstated';

import Navigation from '../../components/navigation/';
import Footer from '../../components/footer';
import helpIcon from '../../assets/icons/icon_80_notExisting_midGrey.svg';
import DashboardStore from '../../state/dashboard';
import styles from './404-styles';
import {Routes} from '../../Routes';
import {getAuthenticadUserInfo} from '../../utils/access-token';

const NotFoundErrorPage = ({classes}) => {
  const {state: DashboardState, effects: DashboardEffects} = useStore(DashboardStore);
  const userInfo = useMemo(() => getAuthenticadUserInfo(), []);
  useEffect(() => {
    if  (userInfo) {
      DashboardEffects.fetchDashboards();
    }
  }, []);

  return (
    <>
      <Navigation dashboardTabs={DashboardState.dashbaords} />
      <main className={classes.wrapper}>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justify={'center'}
          direction={'column'}
          className={classes.container}
        >
          <img className={classes.icon} src={helpIcon} alt={''} />
          <div className={classes.description}>
            Oops, the page you are looking for
            <br />
            does not exist.
          </div>
          <Button
            variant='contained'
            color='primary'
            component={'button'}
            classes={{root: classes.button, disabled: classes.disabled}}
            href={Routes.ROOT}
          >
            Back to Home
          </Button>
        </Grid>
      </main>
      <Footer />
    </>
  );
};

export default withStyles(styles)(NotFoundErrorPage);
