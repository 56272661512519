import {useMemo, useState} from 'react';
import produce from 'immer';
import history from '../utils/history';
import AppService from '../services/app-service';
import {Routes} from '../Routes';

const initialAppState = {
  initialized: false,
  token: localStorage.getItem('accessToken') || null,
  tokenExpire: localStorage.getItem('accessTokenExpiration') || null,
  dashboardRoutes: [],
};

const AppState = appState => {
  const [state, setState] = useState(appState || initialAppState);

  const mutations = useMemo(
    () => ({
      setDashboardRoutes: routes =>
        setState(
          produce(draft => {
            draft.dashboardRoutes = routes;
            draft.initialized = true;
          })
        ),
      setDashboardToken: (token, expire) =>
        setState(
          produce(draft => {
            if (token === null) {
              localStorage.clear();
              history.push(Routes.LOGIN);
            } else {
              localStorage.setItem('accessToken', token);
              localStorage.setItem('accessTokenExpiration', expire);
            }
            draft.token = token;
            draft.tokenExpire = expire;
          })
        ),
      clearApp: () =>
        setState(
          produce(draft => {
            localStorage.clear();
            draft.initialized = false;
            draft.tokenExpire = null;
            draft.token = null;
            draft.dashboardRoutes = [];
          })
        ),
    }),
    []
  );

  const effects = useMemo(
    () => ({
      authenticate: async token => {
        const data = await AppService.authenticate(token);
        if (data && data.access_token) {
          return mutations.setDashboardToken(data.access_token, parseInt(data.expires_in, 10));
        }
        return null;
      },
      fetchConfiguration: async () => {
        const data = await AppService.getConfiguration();
        if (data && data.dashboard && data.dashboard.routes) {
          return mutations.setDashboardRoutes(data.dashboard.routes);
        }
        return null;
      },
    }),
    [mutations]
  );

  return {state, mutations, effects};
};

export default AppState;
