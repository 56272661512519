import theme from '../../assets/theme';

export default {
  button: {
    fontSize: 16,
    padding: [[2, 12]],
    marginLeft: theme.spacing(2.5),
  },
  buttonOutlined: {
    background: theme.palette.background.paper,
  },
  buttonDisabled: {},
  label: {
    pointerEvents: 'none',
  },
  icon: {
    display: 'block',
    marginRight: theme.spacing(1.5),
    height: 14,
    width: 14,
  },
};
