import React, {useCallback, useState, useRef} from 'react';
import {withStyles, IconButton} from '@material-ui/core';
import {useStore} from 'outstated';

import Help from '../../../../../assets/icons/icon_24_help_midGrey.svg';
import formatMessage from 'format-message';
import styles from './visual-article-styles';
import Menu from '../../../../../components/menu';
import CardHeader from '../../../../../components/card/header';
import CloseIcon from '../../../../../assets/icons/icon_24_close_darkGrey.svg';
import ArticleDetailsDialog from '../../../../help/viewer/article-dialog/article-detail-view';
import {HelpAdminStore} from '../../../../../state/help/store';
import DashboardStore from '../../../../../state/dashboard';

const ArticlePinHeader = ({classes, onClose, fullTitle}) => {
  return (
    <CardHeader
      title={formatMessage(fullTitle)}
      rightButton={
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={CloseIcon}
            alt={formatMessage('Close')}
            title={formatMessage('Close')}
            aria-label={formatMessage('Close')}
          />
        </div>
      }
    />
  );
};

const ArticlePin = ({classes, pintoArticle, anchorEl, visualID}) => {
  const {state: ArticleState, mutations: ArticlesMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const {isOpen, article, articleList, isLoadMore, backButton, fromPinTo} = ArticleState;
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);

  const [open, setOpen] = useState(false);
  const iconRef = useRef();
  const [isClicked, setClickState] = useState(false);

  const handleClose = useCallback(() => {
    DashboardMutations.setPopoverIndex('');
  }, []);

  const handleOpen = useCallback(e => {
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPopoverIndex(visualID);
  }, []);

  const handleClick = async() => {
    const result = await ArticleEffects.getArticleDetails(pintoArticle._id);
    if (result) {
      ArticlesMutations.setArticleDialogValue({
        isOpen: true,
        article: result,
        isLoadMore: false,
        backButton: false,
        fromPinTo: true,
      });
    }
    setClickState(true);
    DashboardMutations.setPopoverIndex('');
   
  };

  return (
    <>
      <IconButton ref={iconRef} className={classes.articleIcon} onMouseEnter={event => handleOpen(event)}>
        <img src={Help} alt='Help' width={24} height={24} />
      </IconButton>

      <Menu
        className={classes.menu}
        open={DashboardState.popperIndex === visualID}
        placement='bottom-end'
        anchorEl={anchorEl}
        onClickAway={handleClose}
        onMouseLeave={handleClose}
      >
        <div className={classes.container}>
          <ArticlePinHeader classes={classes} onClose={handleClose} fullTitle={pintoArticle.fullTitle} />
          <p className={classes.shortTitle}>{pintoArticle.hoverTitle}</p>
          <p className={classes.info}>
            <a
              className={classes.furtherDetails}
              onClick={() => {
                handleClick();
              }}
            >
              Further information
            </a>
          </p>
        </div>
      </Menu>

      {isClicked && <ArticleDetailsDialog
        open={isOpen}
        isOpen={isOpen}
        article={article}
        isLoadMore={isLoadMore}
        backButton={backButton}
        articleList={articleList}
        fromPinTo={fromPinTo}
      /> }
    </>
  );
};

export default withStyles(styles)(ArticlePin);
