import theme from '../../../../assets/theme';

export default {
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    margin: 0,
    marginBottom: 10
  },
  emptyMessage: {
    color: theme.palette.grey.mid,
    fontSize: 14,
  },
  invalidCard: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
};
