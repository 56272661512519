import React, {useEffect, useCallback, useMemo} from 'react';
import {withStyles} from '@material-ui/core';
import _isEmpty from 'lodash/fp/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import {useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';
import {useStore} from 'outstated';
import formatMessage from 'format-message';

import styles from './help-edit-styles';
import {HelpAdminStore} from '../../../state/help/store';
import DialogStore from '../../../state/dialog';
import HelpPinSection from './pin-section';
import HelpAudienceSection from './audience-section';
import HelpMessageSection from './message-section';
import {FullScreenLoader} from '../../../components/with-loader';
import Form from '../../../components/form';
import {useParams, Redirect} from 'react-router-dom';
import {NOTIFICATION_STATUS, NOTIFICATION_TYPES} from '../../../assets/constants';
import {Routes} from '../../../Routes';
import HelpEditContainer from './help-edit-container';
import LoadingSpinner from '../../../components/loading-spinner';
import {getPublishDialogContent, getUnpublishDialogContent} from './../../help/help-dialog-content';
import AdminStore from '../../../state/admin';
import {NOTIFICATION_WITH_AUDIENCE_LABELS} from '../../../state/notifications/constants';
import {checkValidVisual} from '../../../utils/notifications';

const HelpEdit = ({classes}) => {
  let {id} = useParams();
  const history = useHistory();
  const {state: AdminState} = useStore(AdminStore);
  const {audiences, isLoadingAudiences} = AdminState;
  const {state: ArticlesState, mutations: ArticleMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {current: article} = ArticlesState;
  const {pintTo: pinToValue} = ArticlesState;
  const isPublished = article.status === NOTIFICATION_STATUS.PUBLISHED;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
    ArticleEffects.getArticle(id);
    return () => ArticleMutations.clearCurrent();
  }, []);

  // dialogs
  const showCheckingAudience = () => {
    DialogMutations.showInfo({
      title: formatMessage('Checking Article.'),
      message: formatMessage('We are checking that article audiences are valid. Please try again in a few seconds.'),
    });
  };
  const showInvalidAudience = () => {
    DialogMutations.showError({
      title: formatMessage('Invalid audience.'),
      message: formatMessage('We have detected an invalid audience. Please remove it and try again.'),
    });
  };
  const showInvalidVisual = () => {
    DialogMutations.showError({
      title: formatMessage('Invalid visual.'),
      message: formatMessage('We have detected an invalid visual pin. Please select a new one and try again.'),
    });
  };

  const isInsightType = article.section === NOTIFICATION_TYPES.INSIGHT;
  const isAudienceType = NOTIFICATION_WITH_AUDIENCE_LABELS.includes(article.section);

  const handleSubmit = useCallback(
    async values => {
      const pintTo = pinToValue.pintTo;
      const updatedData = cloneDeep(values);
      updatedData.pintTo = pinToValue.pintTo;
      if (updatedData.pintTo === 'NOT_PINNED' || updatedData.pintTo === 'SITE_FOOTER') {
        delete updatedData.pinToInfo;
      } else if (updatedData.pinToInfo) {
        if (updatedData.pintTo === 'VISUAL') {
          updatedData.pinToInfo = updatedData.pinToInfo.filter(obj => obj.hasOwnProperty('chartId'));
        } else {
          updatedData.pinToInfo = updatedData.pinToInfo.filter(obj => obj.hasOwnProperty('pageId'))
        }
        updatedData.pinToInfo.forEach(element => {
          delete element.selected
        });
      }
      
      const data = {
        ...updatedData,
        pintTo,
      };
      if (isAudienceType && isLoadingAudiences) {
        showCheckingAudience();
      } else if (isAudienceType && !data.audience.every(audience => audiences.includes(audience))) {
        showInvalidAudience();
      } 
      else if (!checkValidVisual({isInsightType, value: data.pinToInfo, dashboards: AdminState.dashboards})) {
        showInvalidVisual();
      } 
      else {    
        const result = await ArticleEffects.createOrUpdateArticle(data);
        if (result) {
          ArticleMutations.setUpdateArticle({
            isUpdated: false,
          });
          ArticleMutations.setValidPinTo({
            isValidPinLocation: true,
          });
          history.push(Routes.ADMIN.HELP);
        }
      }
    },
    [article, pinToValue, isAudienceType, isLoadingAudiences, isInsightType, AdminState.dashboards]
  );

  const handleTogglePublish = useCallback(
    values => {
      const {newStatus, icon, title, message} = !isPublished
        ? getPublishDialogContent(pinToValue.pintTo)
        : getUnpublishDialogContent();
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (isAudienceType && isLoadingAudiences && newStatus === NOTIFICATION_STATUS.PUBLISHED) {
            showCheckingAudience();
          } else if (
            isAudienceType &&
            !values.audience.every(audience => audiences.includes(audience)) &&
            newStatus === NOTIFICATION_STATUS.PUBLISHED
          ) {
            showInvalidAudience();
          } 
          else if (
            !checkValidVisual({isInsightType, value: values.pinToInfo, dashboards: AdminState.dashboards}) &&
            newStatus === NOTIFICATION_STATUS.PUBLISHED
          ) {
            showInvalidVisual();
          } 
          else {
            const updatedData = cloneDeep(values);
            updatedData.pintTo = pinToValue.pintTo;
            if (updatedData.pintTo === 'NOT_PINNED' || updatedData.pintTo === 'SITE_FOOTER') {
              delete updatedData.pinToInfo;
            }
            if (updatedData.pinToInfo) {
              if (updatedData.pintTo === 'VISUAL') {
                updatedData.pinToInfo = updatedData.pinToInfo.filter(obj => obj.hasOwnProperty('chartId'))
              } else {
                updatedData.pinToInfo = updatedData.pinToInfo.filter(obj => obj.hasOwnProperty('pageId'))
              }
              
              updatedData.pinToInfo.forEach(element => {
                delete element.selected
              });
            }
            const result = await ArticleEffects.createOrUpdateArticlePublication({
              ...updatedData,
              status: newStatus,
            });
            if (result) {
              ArticleMutations.setCurrentArticle(result);
              ArticleMutations.setUpdateArticle({
                isUpdated: false,
              });
              ArticleMutations.setValidPinTo({
                isValidPinLocation: true,
              });
              history.push(Routes.ADMIN.HELP);
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [
      article,
      pinToValue,
      isPublished,
      DialogMutations,
      ArticleMutations,
      audiences,
      isLoadingAudiences,
      isAudienceType,
      AdminState.dashboards,
      isInsightType,
    ]
  );

  const initialValues = useMemo(
    () => ({
      audience: isInsightType ? ['W-Insight'] : [],
      ...article,
    }),
    [ArticleEffects]
  );
  if (article.error) {
    return <Redirect to={Routes.ADMIN.HELP} />;
  }

  return (
    <FullScreenLoader
      loading={_isEmpty(article)}
      render={() => (
        <>
          <div className={classes.wrapper}>
            <div className={classes.container}>
              <Form initialValues={initialValues} onSubmit={handleSubmit}>
                {({...formState}) => (
                  <>
                    {formState.submitting && <LoadingSpinner overlay />}
                    <HelpEditContainer
                      article={article}
                      isPublished={isPublished}
                      formState={formState}
                      onTogglePublish={() => handleTogglePublish(formState.values)}
                    >
                      <HelpPinSection type={article.section} formState={formState} />

                      <HelpAudienceSection type={article.section} />

                      <HelpMessageSection type={article.section} formState={formState}/>
                    </HelpEditContainer>
                  </>
                )}
              </Form>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default withStyles(styles)(HelpEdit);
