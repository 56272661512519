import React, {useMemo, useEffect, useState} from 'react';
import formatMessage from 'format-message';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles, Checkbox} from '@material-ui/core';
import _remove from 'lodash/remove';
import {useStore} from 'outstated';
import {Field} from 'react-final-form';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import SectionCard from '../../../../components/card/section-card';
import CustomCard from '../../../../components/card';
import Autocomplete from '../../../../components/autocomplete';
import ContentIcon from '../../../../assets/icons/icon_24_content_darkGrey.svg';
import Category from '../../../../components/category';
import styles from './help-pin-styles';
import AdminStore from '../../../../state/admin';
import {checkValidVisual} from '../../../../utils/notifications';
import {HelpAdminStore} from '../../../../state/help/store';

const HelpPinSection = ({classes, type, formState}) => {
  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);
  const {state: ArticlesState} = useStore(HelpAdminStore);
  const {pintTo: pinToValue} = ArticlesState;
  const {current: article} = ArticlesState;
  const {mutations: ArticlesMutations} = useStore(HelpAdminStore);

  const [selectedInput, setInputValue] = useState(article.pinToInfo ? article.pinToInfo : []);

  useEffect(() => {
    AdminEffects.getDashboardsTilesInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const isInsightType = true;

  const options = useMemo(() => {
    const optionArr = [];

    AdminState.dashboards.forEach(dashboard => {
      dashboard.pages.forEach(page => {
        if (page.reports.length) {
          page.reports[0].charts.forEach(chart => {
            let isSelected = false;
            if (selectedInput && selectedInput.some(person => person.chartId === chart.id)) {
              isSelected = true;
            }
            optionArr.push({
              selected: isSelected,
              chartId: chart.id,
              location: `${dashboard.name}/${page.name}/${chart.title}`,
            });
          });
        }
      });
    });
    return optionArr;
  }, [AdminState.dashboards, selectedInput]);

  const pageOptions = useMemo(() => {
    const pageOptionArr = [];

    AdminState.dashboards.forEach(dashboard => {
      dashboard.pages.forEach(pages => {
        if (pages.reports.length) {
          pages.reports[0].reportPages.forEach(page => {
            let isSelected = false;
            if (selectedInput && selectedInput.some(person => person.pageId === page._id)) {
              isSelected = true;
            }
            pageOptionArr.push({
              selected: isSelected,
              pageId: page._id,
              location: `${dashboard.name}/${pages.name}/${page.displayName}`,
            });
          });
        }
      });
    });
    return pageOptionArr;
  }, [AdminState.dashboards]);

  const renderOption = (options, selected, selectedValue) => {
    const optToDisplay = options.location
      .split('/')
      .slice(1)
      .join(' - ');
    return (
      <div>
        <Checkbox color='primary' checked={options.selected} />
        {optToDisplay}
      </div>
    );
  };

  const renderVisualOption = (options, selectedValue) => {
    const optToDisplay = options.location
      .split('/')
      .slice(1)
      .join(' - ');
    return (
      <div>
        <Radio color='primary' checked={options.selected} />
        {optToDisplay}
      </div>
    );
  };

  const handleOnChange = e => {
    let arr = cloneDeep(e);
    arr.map(val => {
      val.selected = true;
    });
    _remove(arr, item => item.hasOwnProperty('chartId'));

    setInputValue(arr);
    pageOptions.forEach(element => {
      if (arr && arr.some(person => person.pageId === element.pageId)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    if (arr) {
      options.forEach(element => {
        element.selected = false;
      });
    }

    ArticlesMutations.setUpdateArticle({
      isUpdated: true,
    });
    ArticlesMutations.setCurrentArticle({
      ...article,
      message: formState.values.message,
      audience: formState.values.audience,
      pinToInfo: arr,
    });
  };

  const handleVisualOnChange = e => {
    let arr = cloneDeep(e);
    const visualArray = [];
    visualArray.push(arr);
    visualArray.map(val => {
      val.selected = true;
    });

    setInputValue(visualArray);
    if (visualArray) {
      pageOptions.forEach(element => {
        element.selected = false;
      });
    }

    ArticlesMutations.setUpdateArticle({
      isUpdated: true,
    });
    ArticlesMutations.setCurrentArticle({
      ...article,
      message: formState.values.message,
      audience: formState.values.audience,
      pinToInfo: visualArray,
    });
  };

  const [pintTo, setValue] = React.useState(pinToValue.pintTo);
  const [isValidPinLocation, setValidValue] = React.useState(true);

  const isPintoVisible = pintTo !== 'NOT_PINNED' && pintTo !== 'SITE_FOOTER' ? true : false;
  const RadioItem = ({type}) => {
    const checkValidPinLocation = value => {
      if (value === 'NOT_PINNED' || value === 'SITE_FOOTER') {
        setValidValue(true);
        ArticlesMutations.setValidPinTo({
          isValidPinLocation: true,
        });
        ArticlesMutations.setUpdateArticle({
          isUpdated: true,
        });
      } else {
        if (!formState.values.pinToInfo) {
          setValidValue(false);
          ArticlesMutations.setValidPinTo({
            isValidPinLocation: false,
          });
          ArticlesMutations.setUpdateArticle({
            isUpdated: true,
          });
        } else {
          let isValueSelected;
          if (value === 'VISUAL') {
            isValueSelected = formState.values.pinToInfo.filter(x => x.hasOwnProperty('chartId'));
          }
          if (value === 'PAGE_HEADER' || value === 'PAGE_FOOTER') {
            isValueSelected = formState.values.pinToInfo.filter(x => x.hasOwnProperty('pageId'));
          }
          if (isValueSelected.length) {
            setValidValue(true);
            ArticlesMutations.setValidPinTo({
              isValidPinLocation: true,
            });
            ArticlesMutations.setUpdateArticle({
              isUpdated: true,
            });
          } else {
            setValidValue(false);
            ArticlesMutations.setValidPinTo({
              isValidPinLocation: false,
            });
            ArticlesMutations.setUpdateArticle({
              isUpdated: true,
            });
          }
        }
      }
    };
    const handleChange = event => {
      setValue(event.target.value);
      ArticlesMutations.setArticlePinTo({
        pintTo: event.target.value,
      });
      checkValidPinLocation(event.target.value);
      ArticlesMutations.setCurrentArticle({
        ...article,
        message: formState.values.message,
        audience: formState.values.audience,
        pinToInfo: formState.values.pinToInfo,
      });
    };

    return (
      <RadioGroup row aria-label='pintTo' name='pintTo' value={pintTo} onChange={handleChange}>
        <FormControlLabel value='NOT_PINNED' control={<Radio color='primary' />} label='Not pinned' />
        <FormControlLabel value='VISUAL' control={<Radio color='primary' />} label='Visual' />
        <FormControlLabel value='PAGE_FOOTER' control={<Radio color='primary' />} label='Page footer' />
        <FormControlLabel value='PAGE_HEADER' control={<Radio color='primary' />} label='Page header' />
        <FormControlLabel value='SITE_FOOTER' control={<Radio color='primary' />} label='Site footer' />
      </RadioGroup>
    );
  };

  const EditableItem = ({classes, pinToInfo, onClick, isValid}) => {
    if (!pinToInfo || pintTo === 'NOT_PINNED' || pintTo === 'SITE_FOOTER') {
      return <p className={classes.emptyMessage}>{formatMessage('This Article is not pinned to a Dashboard yet')}</p>;
    }

    return (
      <>
        {pinToInfo
          .filter(x => x.hasOwnProperty(pintTo === 'VISUAL' ? 'chartId' : 'pageId'))
          .map(val => {
            const texts = val.location.split('/');
            return (
              <CustomCard
                className={classNames(classes.card, {[classes.invalidCard]: !isValid})}
                key={val.location}
                title={`${texts[0]} / ${texts[1]}`}
                link={texts[2]}
                onButtonClick={() => {
                  const values = [...pinToInfo];
                  _remove(values, item => item.location === val.location);
                  setInputValue(values);
                  if (pintTo === 'VISUAL') {
                    options.forEach(element => {
                      if (values && values.some(person => person.chartId === element.chartId)) {
                        element.selected = true;
                      } else {
                        element.selected = false;
                      }
                    });
                    if (values) {
                      pageOptions.forEach(element => {
                        element.selected = false;
                      });
                    }
                  } else {
                    pageOptions.forEach(element => {
                      if (values && values.some(person => person.pageId === element.pageId)) {
                        element.selected = true;
                      } else {
                        element.selected = false;
                      }
                    });
                    if (values) {
                      options.forEach(element => {
                        element.selected = false;
                      });
                    }
                  }
                  if(values) {
                    ArticlesMutations.setUpdateArticle({
                      isUpdated: true,
                    });
                  }

                  ArticlesMutations.setCurrentArticle({
                    ...article,
                    message: formState.values.message,
                    audience: formState.values.audience,
                    pinToInfo: values.length ? values : [],
                  });
                }}
                button={isValid ? 'cross' : 'warningCross'}
              />
            );
          })}
      </>
    );
  };

  return (
    <Field
      name='pinToInfo'
      validate={value => (value || !isPintoVisible ? undefined : formatMessage('required'))}
      render={({input}) => {
        const isValid = checkValidVisual({isPintoVisible, value: input.value, dashboards: AdminState.dashboards});
        return (
          <>
            <Category icon={ContentIcon} title={formatMessage('Pin to')} />
            {isPintoVisible && (
              <SectionCard
                title={formatMessage('The page where it will be pinned to')}
                subtitle={formatMessage('Select page and visual here:')}
                button={
                  isPintoVisible && pintTo === 'VISUAL' ? (
                    <Autocomplete
                      buttonLabel={formatMessage('Add page/visual')}
                      value={selectedInput}
                      onChange={e => handleVisualOnChange(e)}
                      getOptionSelected={(option, value) => option.chartId === value.chartId}
                      renderOption={({...options}) => renderVisualOption(options, selectedInput)}
                      groupBy={option => option.location.split('/')[0]}
                      getOptionLabel={option => (typeof option === 'string' ? option : option.location)}
                      disableCloseOnSelect={false}
                      noOptionsText={formatMessage('No pages/visuals defined yet')}
                      options={options}
                      placeholder={formatMessage('Search dashboard/page/tile...')}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      buttonLabel={formatMessage('Add page/visual')}
                      value={selectedInput}
                      onChange={e => handleOnChange(e)}
                      getOptionSelected={(option, value) => option.pageId === value.pageId}
                      renderOption={({...pageOptions}, {selected}) => renderOption(pageOptions, selected, input.value)}
                      groupBy={option => option.location.split('/')[0]}
                      getOptionLabel={option => (typeof option === 'string' ? option : option.location)}
                      disableCloseOnSelect={true}
                      noOptionsText={formatMessage('No pages/visuals defined yet')}
                      options={pageOptions}
                      placeholder={formatMessage('Search dashboard/page/tile...')}
                    />
                  )
                }
              >
                <div className={classes.contentContainer}>
                  <RadioItem type={type} />
                </div>
                <div>
                  <EditableItem classes={classes} pinToInfo={selectedInput} isValid={isValid} />
                </div>
              </SectionCard>
            )}

            {!isPintoVisible && (
              <SectionCard
                title={formatMessage('The page where it will be pinned to')}
                subtitle={formatMessage('Select page and visual here:')}
              >
                <div className={classes.contentContainer}>
                  <RadioItem type={type} />
                </div>
                <div>
                  <EditableItem classes={classes} pinToInfo={selectedInput} isValid={isValid} />
                </div>
              </SectionCard>
            )}
          </>
        );
      }}
    />
  );
};

HelpPinSection.propTypes = {
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(HelpPinSection);
