import theme from '../../assets/theme';

export default {
  container: {
    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: theme.spacing(8),
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
    cursor: 'default',
    marginTop: theme.spacing(5),
  },
  item: {
    textAlign: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    minWidth: theme.spacing(2),
    maxWidth: '42px',
  },
  itemHidden: {
    visibility: 'hidden',
  },
  info: {
    cursor: 'default',
    textAlign: 'center',
    margin: theme.spacing(1.2),
    padding: theme.spacing(1.2),
    textDecoration: 'none',
    fontSize: 18,
    color: theme.palette.primary.light,
    minWidth: theme.spacing(20),
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '3px',
    fontWeight: 'bold',
  },
  doubleChevron: {
    '& svg:first-child': {
      marginRight: theme.spacing(-1),
    },
    '& svg:last-child': {
      marginLeft: theme.spacing(-1),
    },
  },
};
