import React from 'react';
import {withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import formatMessage from 'format-message';
import classNames from 'classnames';
import styles from './pagination-styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '../../components/button';

const addPrevButton = (classes, {currentPage}, onClick) => (
  <Button
    className={classNames(classes.item, {[classes.itemHidden]: currentPage < 2})}
    variant='outlined'
    onClick={onClick}
  >
    <KeyboardArrowLeftIcon />
  </Button>
);

const addNextButton = (classes, {currentPage, pages}, onClick) => (
  <Button
    className={classNames(classes.item, {[classes.itemHidden]: currentPage >= pages})}
    variant='outlined'
    onClick={onClick}
  >
    <KeyboardArrowRightIcon />
  </Button>
);

const addFirstButton = (classes, {pages, currentPage}, onClick) => (
  <Button
    className={classNames(classes.item, classes.doubleChevron, {
      [classes.itemHidden]: pages <= 2 || currentPage === 1,
    })}
    variant='outlined'
    onClick={onClick}
  >
    <KeyboardArrowLeftIcon />
    <KeyboardArrowLeftIcon />
  </Button>
);

const addLastButton = (classes, {pages, currentPage}, onClick) => (
  <Button
    className={classNames(classes.item, classes.doubleChevron, {
      [classes.itemHidden]: pages <= 2 || currentPage >= pages,
    })}
    variant='outlined'
    onClick={onClick}
  >
    <KeyboardArrowRightIcon />
    <KeyboardArrowRightIcon />
  </Button>
);

const Pagination = ({className, classes, pages, currentPage, onPageChanged}) => {
  const pageObject = {pages, currentPage, onPageChanged};

  if (pages > 1) {
    return (
      <div className={classNames(classes.container, className)}>
        {addFirstButton(classes, pageObject, () => onPageChanged(1))}
        {addPrevButton(classes, pageObject, () => onPageChanged(currentPage - 1))}
        <span className={classes.info}>{formatMessage('Page {currentPage} of {pages}', {currentPage, pages})}</span>
        {addNextButton(classes, pageObject, () => onPageChanged(currentPage + 1))}
        {addLastButton(classes, pageObject, () => onPageChanged(pages))}
      </div>
    );
  }
  return null;
};

Pagination.propTypes = {
  classes: propTypes.object.isRequired,
  pages: propTypes.number,
  currentPage: propTypes.number,
  onPageChanged: propTypes.func,
};

Pagination.defaultProps = {
  pages: 1,
  currentPage: 1,
  onPageChanged: () => {},
};

export default withStyles(styles)(Pagination);
