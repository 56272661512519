import theme from '../../assets/theme';

export default {
  wrapper: {
    height: 'calc(100vh - 146px)',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  container: {
    textAlign: 'center',
    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  appBar: {
    flex: '0 0 auto',
    height: '56px',
    paddingTop: theme.spacing(1),
    display: 'grid',
    '-ms-grid-columns': 'auto 1fr auto',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: '"logo dashboards extra" "fixedLogoBackground pagination pagination "',
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
  },
  button: {
    height: theme.spacing(7),
    textTransform: 'none',
    color: 'white',
    fontSize: 16,
  },
  icon: {
    width: '80px',
    height: '80px',
  },
  description: {
    margin: [[theme.spacing(2), 0]],
  },
};
