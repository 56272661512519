import React, {useCallback} from 'react';
import formatMessage from 'format-message';
import {useHistory} from 'react-router-dom';
import HelpEditHeader from './header';
import Button from '../../../components/button';
import InfoIcon from '../../../assets/icons/icon_24_error_blue.svg';
import {withStyles} from '@material-ui/core';
import styles from './help-edit-styles';
import {useStore} from 'outstated';
import DialogStore from '../../../state/dialog';
import {Routes} from '../../../Routes';
import {HelpAdminStore} from '../../../state/help/store';

const HelpEditContainer = ({classes, article, isPublished, formState, onTogglePublish, children}) => {
  
  const history = useHistory();
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {state: ArticlesState, mutations: ArticlesMutations} = useStore(HelpAdminStore);
  const {isUpdated, isValidPinLocation} = ArticlesState;
  const {pintTo: pinToValue} = ArticlesState;

  let isValueSelected = [];
  if (formState.values.pinToInfo && pinToValue.pintTo === 'VISUAL') {
    isValueSelected = formState.values.pinToInfo.filter(x => x.hasOwnProperty('chartId'));
    if (!isValueSelected.length) {
      ArticlesMutations.setUpdateArticle({
        isUpdated: true,
      });
      ArticlesMutations.setValidPinTo({
        isValidPinLocation: false,
      });
    } else {
      ArticlesMutations.setValidPinTo({
        isValidPinLocation: true,
      });
    }
  } else if (formState.values.pinToInfo && (pinToValue.pintTo === 'PAGE_HEADER' || pinToValue.pintTo === 'PAGE_FOOTER')) {
    isValueSelected = formState.values.pinToInfo.filter(x => x.hasOwnProperty('pageId'));
    if (!isValueSelected.length) {
      ArticlesMutations.setUpdateArticle({
        isUpdated: true,
      });
      ArticlesMutations.setValidPinTo({
        isValidPinLocation: false,
      });
    } else {
      ArticlesMutations.setValidPinTo({
        isValidPinLocation: true,
      });
    }
  } else {
    isValueSelected = [];
    ArticlesMutations.setUpdateArticle({
      isUpdated: true,
    });
    ArticlesMutations.setValidPinTo({
      isValidPinLocation: true,
    });
  }

  const handleCancel = useCallback(
    (isPristine, isUpdated) => {
      if (isPristine && !isUpdated) {
        return history.push(Routes.ADMIN.HELP);
      } else {
        DialogMutations.showConfirm({
          onActionConfirmed: () => {
            DialogMutations.close();
            history.push(Routes.ADMIN.HELP);
            ArticlesMutations.setUpdateArticle({
              isUpdated: false,
            });
            ArticlesMutations.setValidPinTo({
              isValidPinLocation: true,
            });
          },
          icon: InfoIcon,
          title: formatMessage('Cancel editing'),
          message: formatMessage(
            'Are you sure you want to cancel editing? If you continue, your adjustments will not be saved.'
          ),
        });
      }

     
      
    },
    [history]
  );

  const handleChange = useCallback(
    isPristine => {
      ArticlesMutations.setCurrentArticle({
        ...article,
        message : formState.values.message,
        audience : formState.values.audience,
        pinToInfo : formState.values.pinToInfo
      });
    }
  );

  return (
    <>
      <HelpEditHeader
      article={article}
      isPublished={isPublished}
      formState={formState}
      onClose={() => handleCancel(formState.pristine, isUpdated)}
      onChange={() => handleChange(formState)}
      />
      {children}
      <div className={classes.buttonContainer}>
        <Button variant='outlined' onClick={() => handleCancel(formState.pristine, isUpdated)}>
          {formatMessage('Cancel')}
        </Button>
        <Button
          // disabled={formState.pristine || !formState.valid}
          disabled={isUpdated ? (!isValidPinLocation ? (!formState.valid || !isValueSelected.length) : !formState.valid) : formState.pristine || !formState.valid}
          variant={isPublished ? 'contained' : 'outlined'}
          type='submit'
        >
          {isPublished ? formatMessage('Update') : formatMessage('Save')} 
        </Button>
        <Button
          disabled={!isValidPinLocation ? (!formState.valid || !isValueSelected.length) : !formState.valid}
          variant={isPublished ? 'outlined' : 'contained'}
          onClick={() => onTogglePublish(formState.form.submit)}
        >
          {isPublished ? formatMessage('Unpublish') : formatMessage('Publish')}
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(HelpEditContainer);
