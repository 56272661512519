import {useMemo, useState} from 'react';
import produce from 'immer';
import {useStore} from 'outstated';
import formatMessage from 'format-message';

import {NOTIFICATION_STATUS} from '../../../assets/constants';
import logger from '../../../utils/logger';
import HelpArticleService from '../../../services/help-service';
import DialogStore from '../../dialog';
import articlesMutations, {initialArticleState, resetPagination} from '../mutations';
import ArticleEffects from '../effets';
import {VALID_STATUSES} from '../constant';

const Help = articleState => {
  const initialState = {
    ...initialArticleState,
    filters: {
      ...initialArticleState.filters,
      status: null,
    },
  };
  const [state, setState] = useState(articleState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...articlesMutations(setState),
      setFiltersStatus: status =>
        setState(
          produce(draft => {
            if (
              status === draft.filters.status ||
              (status === 'ALL' && draft.filters.status === null) ||
              !VALID_STATUSES.includes(status)
            ) {
              draft.loading = false;
            } else {
              draft.loading = true;
              draft.filters.status = status === 'ALL' ? null : status;
              resetPagination(draft);
            }
          })
        ),
      setSection: sections =>
        setState(
          produce(draft => {
            draft.sections = sections;
          })
        ),
      setSectionFilterArray: sections =>
        setState(
          produce(draft => {
            draft.sectionFilterArray = sections;
          })
        ),
      setLoadingSection: value =>
        setState(
          produce(draft => {
            draft.isLoadingSection = value;
          })
        ),
    }),
    []
  );

  const effects = useMemo(
    () => ({
      ...ArticleEffects(state, mutations, DialogMutations),
      createOrUpdateArticle: async article => {
        try {
          let data;
          if (article._id) {
            data = await HelpArticleService.updateArticle(article._id, article);
          } else {
            data = await HelpArticleService.createArticle(article);
          }
          mutations.setCurrentArticle(data);
          return data;
        } catch (err) {
          if (err && err.response && err.response.status === 413) {
            DialogMutations.showError({
              title: formatMessage('Article too large'),
              message: formatMessage('This article can not be saved because it exceeds the maximum size (50KB).'),
            });
          } else {
            if (err.response.status === 409) {
              DialogMutations.showError({
                title: formatMessage('Duplicate article'),
                message: formatMessage('Duplicate article cannot be added'),
              });
            } else {
              DialogMutations.showError({
                title: formatMessage('Unknown error'),
                message: formatMessage(
                  'Due to an unknown error we could not save the article. Please try again or try again later.'
                ),
              });
            }
          }
          logger.warn(err);
          return null;
        }
      },

      createOrUpdateArticlePublication: async (article, requestData = false) => {
        mutations.setLoading(true);
        try {
          const {_id, ...body} = article;
          let result = {};
          mutations.setLoading(true);
          if (_id) {
            let data = {};
            if (requestData) {
              data = await HelpArticleService.getArticleById(_id);
            }
            result = await HelpArticleService.updateArticle(_id, {...data, ...body});
          } else {
            result = await HelpArticleService.createArticle(body);
          }
          switch (article.status) {
            case NOTIFICATION_STATUS.PUBLISHED:
              DialogMutations.showInfo({
                title: formatMessage('Article published!'),
                message: formatMessage('The article has been successfully published.'),
              });
              break;
            case NOTIFICATION_STATUS.UNPUBLISHED:
              DialogMutations.showInfo({
                title: formatMessage('Article unpublished!'),
                message: formatMessage('The article has been successfully unpublished.'),
              });
              break;
            // invalid
            default:
              break;
          }
          return result;
        } catch (err) {
          if (err && err.response && err.response.status === 413) {
            DialogMutations.showError({
              title: formatMessage('Article too large'),
              message: formatMessage('This article can not be saved because it exceeds the maximum size (50KB).'),
            });
          } else {
            switch (article.status) {
              case NOTIFICATION_STATUS.PUBLISHED:
                if (err.response.status === 409) {
                  DialogMutations.showError({
                    title: formatMessage('Duplicate article'),
                    message: formatMessage('Duplicate article cannot be added'),
                  });
                } else {
                  DialogMutations.showError({
                    title: formatMessage('Error publishing'),
                    message: formatMessage(
                      'Due to an unknown error we could not update the article. Please try again later.'
                    ),
                  });
                }
                break;
              case NOTIFICATION_STATUS.UNPUBLISHED:
                if (err.response.status === 409) {
                  DialogMutations.showError({
                    title: formatMessage('Duplicate article'),
                    message: formatMessage('Duplicate article cannot be added'),
                  });
                } else {
                  DialogMutations.showError({
                    title: formatMessage('Error unpublishing'),
                    message: formatMessage(
                      'Due to an unknown error we could not update the article. Please try again later.'
                    ),
                  });
                }
                break;
              // invalid
              default:
                break;
            }
          }
          logger.warn(err);
          mutations.setLoading(false);
          return null;
        }
      },
      deleteArticle: async id => {
        try {
          if (id) {
            mutations.setLoading(true);
            await HelpArticleService.deleteArticle(id);
            if (state.current && state.current._id === id) {
              mutations.clearCurrent();
            }
            DialogMutations.showInfo({
              title: formatMessage('Article deleted.'),
              message: formatMessage('The article has been successfully deleted.'),
            });
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          DialogMutations.showError({
            title: formatMessage('Deletion error.'),
            message: formatMessage('The article could not be deleted. Please try again later.'),
          });
          mutations.setLoading(false);
          return null;
        }
      },

      getSection: async () => {
        mutations.setLoadingSection(true);
        try {
          const sections = await HelpArticleService.getSectionList();
          mutations.setSection(sections.sectionList);
          mutations.setSectionFilterArray(sections.sectionFilterList);
        } catch (error) {
          DialogMutations.showError({
            title: formatMessage('Data can not be retrieved'),
            message: formatMessage(
              'The Section data cannot be retrieved from the Seabury backend. Please try again or try again later.'
            ),
          });
        } finally {
          mutations.setLoadingSection(false);
        }
      },
      deleteSection: async id => {
        try {
          if (id) {
            mutations.setLoading(true);
            await HelpArticleService.deleteSection(id);
            if (state.current && state.current._id === id) {
            }
            DialogMutations.showInfo({
              title: formatMessage('Section deleted.'),
              message: formatMessage('The section has been successfully deleted.'),
            });
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          DialogMutations.showError({
            title: formatMessage('Deletion error.'),
            message: formatMessage('The section could not be deleted. Please try again later.'),
          });
          mutations.setLoading(false);
          return null;
        }
      },
    }),
    [mutations, DialogMutations, state]
  );

  return {state, mutations, effects};
};

export default Help;
