import React, {useEffect, useCallback, useMemo} from 'react';
import {withStyles} from '@material-ui/core';
import _isEmpty from 'lodash/fp/isEmpty';
import ReactGA from 'react-ga';
import {useStore} from 'outstated';

import styles from './notification-edit-styles';
import {NotificationsAdminStore} from '../../../state/notifications/store';
import DialogStore from '../../../state/dialog';
import NotificationPinSection from './pin-section';
import NotificationAudienceSection from './audience-section';
import NotificationMessageSection from './message-section';
import {FullScreenLoader} from '../../../components/with-loader';
import Form from '../../../components/form';
import {useParams, Redirect} from 'react-router-dom';
import {NOTIFICATION_STATUS, NOTIFICATION_TYPES} from '../../../assets/constants';
import {Routes} from '../../../Routes';
import NotificationEditContainer from './notification-edit-container';
import LoadingSpinner from '../../../components/loading-spinner';
import {getPublishDialogContent, getUnpublishDialogContent} from '../notification-dialog-content';
import AdminStore from '../../../state/admin';
import formatMessage from 'format-message';
import {NOTIFICATION_WITH_AUDIENCE_LABELS} from '../../../state/notifications/constants';
import {checkValidVisual} from '../../../utils/notifications';

const NotificationEdit = ({classes}) => {
  let {id} = useParams();
  const {state: AdminState} = useStore(AdminStore);
  const {audiences, isLoadingAudiences} = AdminState;
  const {state: NotificationsState, mutations: NotificationMutations, effects: NotificationEffects} = useStore(
    NotificationsAdminStore
  );
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {current: notification} = NotificationsState;
  const isPublished = notification.status === NOTIFICATION_STATUS.PUBLISHED;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }

    NotificationEffects.getNotification(id);
    return () => NotificationMutations.clearCurrent();
  }, []);

  // dialogs
  const showCheckingAudience = () => {
    DialogMutations.showInfo({
      title: formatMessage('Checking notification.'),
      message: formatMessage(
        'We are checking that notification audiences are valid. Please try again in a few seconds.'
      ),
    });
  };
  const showInvalidAudience = () => {
    DialogMutations.showError({
      title: formatMessage('Invalid audience.'),
      message: formatMessage('We have detected an invalid audience. Please remove it and try again.'),
    });
  };
  const showInvalidVisual = () => {
    DialogMutations.showError({
      title: formatMessage('Invalid visual.'),
      message: formatMessage('We have detected an invalid visual pin. Please select a new one and try again.'),
    });
  };

  const isInsightType = notification.type === NOTIFICATION_TYPES.INSIGHT;
  const isAudienceType = NOTIFICATION_WITH_AUDIENCE_LABELS.includes(notification.type);

  const handleSubmit = useCallback(
    async values => {
      const data = {
        ...notification,
        ...values,
      };
      if (isAudienceType && isLoadingAudiences) {
        showCheckingAudience();
      } else if (isAudienceType && !data.audience.every(audience => audiences.includes(audience))) {
        showInvalidAudience();
      } else if (!checkValidVisual({isInsightType, value: data.insightInfo, dashboards: AdminState.dashboards})) {
        showInvalidVisual();
      } else {
        await NotificationEffects.createOrUpdateNotification(data);
      }
    },
    [notification, isAudienceType, isLoadingAudiences, isInsightType, AdminState.dashboards]
  );

  const handleTogglePublish = useCallback(
    values => {
      const {newStatus, icon, title, message} = !isPublished
        ? getPublishDialogContent(notification.type)
        : getUnpublishDialogContent();
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (isAudienceType && isLoadingAudiences && newStatus === NOTIFICATION_STATUS.PUBLISHED) {
            showCheckingAudience();
          } else if (
            isAudienceType &&
            !values.audience.every(audience => audiences.includes(audience)) &&
            newStatus === NOTIFICATION_STATUS.PUBLISHED
          ) {
            showInvalidAudience();
          } else if (
            !checkValidVisual({isInsightType, value: values.insightInfo, dashboards: AdminState.dashboards}) &&
            newStatus === NOTIFICATION_STATUS.PUBLISHED
          ) {
            showInvalidVisual();
          } else {
            const result = await NotificationEffects.createOrUpdateNotificationPublication({
              ...values,
              status: newStatus,
            });
            if (result) {
              NotificationMutations.setCurrentNotification(result);
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [
      notification,
      isPublished,
      DialogMutations,
      NotificationMutations,
      audiences,
      isLoadingAudiences,
      isAudienceType,
      AdminState.dashboards,
      isInsightType,
    ]
  );

  const initialValues = useMemo(
    () => ({
      audience: isInsightType ? ['W-Insight'] : [],
      ...notification,
    }),
    [notification]
  );

  if (notification.error) {
    return <Redirect to={Routes.ADMIN.NOTIFICATIONS} />;
  }

  return (
    <FullScreenLoader
      loading={_isEmpty(notification)}
      render={() => (
        <>
          <div className={classes.wrapper}>
            <div className={classes.container}>
              <Form initialValues={initialValues} onSubmit={handleSubmit}>
                {({...formState}) => (
                  <>
                    {formState.submitting && <LoadingSpinner overlay />}
                    <NotificationEditContainer
                      notification={notification}
                      isPublished={isPublished}
                      formState={formState}
                      onTogglePublish={() => handleTogglePublish(formState.values)}
                    >
                      <NotificationPinSection type={notification.type} />

                      <NotificationAudienceSection type={notification.type} />

                      <NotificationMessageSection type={notification.type} formState={formState} />
                    </NotificationEditContainer>
                  </>
                )}
              </Form>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default withStyles(styles)(NotificationEdit);
