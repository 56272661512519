import React from 'react';
import classNames from 'classnames';
import {TextField as MUiTextField, withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import styles from './text-field-styles';

const TextField = props => {
  // From Styles
  const {classes} = props;

  // From Parent
  const {label, value, autoFocus, handleChange, extra, isWarning, showError, error, maxLength} = props;

  const className = classNames(classes.field, {[classes.cssOutlinedInputWarning]: showError && isWarning});

  // Render
  return (
    <>
      <MUiTextField
        label={label}
        type='text'
        name={label.toLowerCase()}
        margin='normal'
        variant='outlined'
        autoFocus={autoFocus}
        fullWidth
        value={value}
        onChange={e => handleChange(e)}
        className={className}
        error={showError && !isWarning}
        helperText={showError ? error : undefined}
        InputLabelProps={{
          classes: {
            root: value.length > 0 ? classes.inputLabel : null,
            focused: classes.inputFocused,
          },
        }}
        inputProps={{
          maxLength: maxLength
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.inputFocused,
            notchedOutline: value.length > 0 ? classes.notchedOutline : null,
          },
        }}
      />
      {extra && <p className={classes.extra}>{extra}</p>}
    </>
  );
};

TextField.propTypes = {
  label: propTypes.string,
  value: propTypes.any,
  autoFocus: propTypes.bool,
  handleChange: propTypes.func,
};

export default withStyles(styles)(TextField);
