import produce from 'immer';
import {VALID_TYPES} from './constants';

export const initialNotificationsState = {
  notifications: null,
  totalCount: 0,
  loading: true,
  currentLoading: false,
  filters: {
    query: null,
    type: null,
  },
  pagination: {
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 1,
  },
  queryPagination: {
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 1,
  },
  reload: false,
  error: null,
  current: {},
  notificationStorageFiles: null,
  isUpdated: false
};

export const resetPagination = (draft, initialPagination = {}, paginationKey = 'pagination') => {
  draft[paginationKey].limit = initialPagination.limit || 10;
  draft[paginationKey].offset = initialPagination.offset || 0;
  draft[paginationKey].totalPages = initialPagination.totalPages || 1;
  draft[paginationKey].currentPage = initialPagination.currentPage || 1;
};

export const setPage = (draft, page, paginationKey = 'pagination') => {
  if (page < 1) {
    draft[paginationKey].currentPage = 1;
    draft[paginationKey].offset = 0;
  } else if (page > draft[paginationKey].totalPages) {
    draft[paginationKey].currentPage = draft[paginationKey].totalPages;
    draft[paginationKey].offset = draft[paginationKey].limit * (draft[paginationKey].totalPages - 1);
  } else {
    draft[paginationKey].currentPage = page;
    draft[paginationKey].offset = draft[paginationKey].limit * (page - 1);
  }
};

export const updatePagination = (draft, data, paginationKey = 'pagination') => {
  if (data.limit !== draft[paginationKey].limit) {
    draft[paginationKey].limit = data.limit || 10;
  }
  if (data.offset !== draft[paginationKey].offset) {
    draft[paginationKey].offset = data.offset || 0;
  }
  if (data.page !== draft[paginationKey].currentPage) {
    draft[paginationKey].currentPage = data.page || 1;
  }
  if (data.totalPages !== draft[paginationKey].totalPages) {
    draft[paginationKey].totalPages = data.totalPages || 1;
  }
};

const hasQuery = state => state.filters.query && state.filters.query.length;

export default (setState, initialUserPagination) => ({
  setNotificationsData: data =>
    setState(
      produce(draft => {
        draft.notifications = data.docs;
        draft.totalCount = data.totalDocs;
        updatePagination(draft, data, hasQuery(draft) ? 'queryPagination' : 'pagination');
        draft.error = null;
        draft.loading = false;
      })
    ),
  setPage: page =>
    setState(
      produce(draft => {
        draft.loading = true;
        setPage(draft, page, hasQuery(draft) ? 'queryPagination' : 'pagination');
      })
    ),
  setFiltersQuery: query =>
    setState(
      produce(draft => {
        if (query === draft.filters.query || (query === 'ALL' && draft.filters.query === null)) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.filters.query = typeof query === 'string' ? query : null;
          resetPagination(draft, initialUserPagination, 'queryPagination');
        }
      })
    ),
  setFiltersType: type =>
    setState(
      produce(draft => {
        if (
          type === draft.filters.type ||
          (type === 'ALL' && draft.filters.type === null) ||
          !VALID_TYPES.includes(type)
        ) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.filters.type = type === 'ALL' ? null : type;
          resetPagination(draft, initialUserPagination);
        }
      })
    ),
  setLoading: value =>
    setState(
      produce(draft => {
        draft.loading = value;
      })
    ),
  setCurrentLoading: value =>
    setState(
      produce(draft => {
        draft.currentLoading = value;
      })
    ),
  setNotificationsError: value =>
    setState(
      produce(draft => {
        draft.error = value;
        draft.notifications = [];
        draft.loading = false;
        resetPagination(draft, initialUserPagination);
        resetPagination(draft, initialUserPagination, 'queryPagination');
      })
    ),
  setCurrentNotification: values => {
    setState(
      produce(draft => {
        draft.current = values;
        draft.currentLoading = false;
      })
    );
  },
  clearCurrent: () => {
    setState(
      produce(draft => {
        draft.current = {};
        draft.currentLoading = false;
      })
    );
  },
  reload: () => {
    setState(baseState =>
      produce(baseState, draft => {
        draft.reload = !baseState.reload;
      })
    );
  },

  setNotificationStorageFileList: values => {
    setState(
      produce(draft => {
        draft.notificationStorageFiles = values;
        draft.currentLoading = false;
      })
    );
  },

  setUpdateNotification: values => {
    setState(
      produce(draft => {
        draft.isUpdated = values.isUpdated;
        draft.currentLoading = false;
      })
    );
  },
});
