import theme from '../../../../assets/theme';

export default {
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  container: {
    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  messageWrapper: {
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
  },
  message: {
    display: 'flex',
    padding: '5px',
    margin: '10px',
    textAlign: 'center',
  },
  card: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1.5),
    minHeight: theme.spacing(9),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    borderColor: theme.palette.grey.light,
  },
  cardActive: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    borderColor: theme.palette.primary.main,
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(1.5),
    },
    '&> div:last-child': {
      marginTop: theme.spacing(1),
    },
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'start',
    whiteSpace: 'nowrap',
    maxWidth: '80%',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  cardLink: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: 0,
  },
  item: {
    width: '100%',
    display: 'flex',
    flexWrap: 'column nowrap',
    justifyContent: 'space-between',
  },
  itemRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'row nowrap',
  },
  itemNewText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '10px',
    margin: 0,
    color: theme.palette.warning.main,
  },
  itemIcon: {
    display: 'block',
    marginRight: theme.spacing(1),
    height: 20,
    color: theme.palette.grey.dark,
  },
  itemType: {
    fontSize: 14,
    lineHeight: '18px',
    margin: 0,
    color: theme.palette.grey.mid,
  },
  itemTypeContainer: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
  },
  itemPublicationDate: {
    flex: '0 1 auto',
    textAlign: 'right',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    margin: 0,
    color: theme.palette.grey.mid,
  },
};
