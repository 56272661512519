import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {apiURL} from '../assets/constants';
import {TEMP_HELP_PERMISSION} from '../assets/constants'
export async function isAccessTokenValid() {
  const token = localStorage.getItem('accessToken');

  if (token) {
    const expiration = localStorage.getItem('accessTokenExpiration');
    const currentUnixTime = Math.floor(new Date().getTime() / 1000);
    const isExpired = expiration - currentUnixTime <= 0;

    if (!isExpired) {
      return true;
    }
  }

  return false;
}

export async function setAccessToken(microsoftToken) {
  try {
    const url = `${apiURL}/auth`;
    const {data} = await axios.post(url, null, {
      headers: {Authorization: microsoftToken},
      timeout: 10000,
      validateStatus: status => status === 200,
    });

    storeAccessToken(data);
    return true;
  } catch (e) {}
  return false;
}

export function storeAccessToken(data) {
  localStorage.setItem('accessToken', data.access_token);
  localStorage.setItem('accessTokenExpiration', data.expires_in);
}

export function isAdmin() {
  const token = localStorage.getItem('accessToken');
  return !!(token && jwtDecode(token).userRoles.isAdmin);
}

export function getAuthenticadUserInfo() {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return jwtDecode(token).userInfo;
  }
  return null;
}

export function getAdminPermissions() {
  const token = localStorage.getItem('accessToken');
  let adminPermissions = {
    isDashAdmin: false,
    isNotifAdmin: false,
    isHelpAdmin:  false,
  }
  if (token) {
    const userToken = jwtDecode(token).userRoles;
    adminPermissions = {
      isDashAdmin: userToken.isDashAdmin ? userToken.isDashAdmin : false,
      isNotifAdmin: userToken.isNotifAdmin ? userToken.isNotifAdmin : false,
      isHelpAdmin: userToken.isHelpAdmin ? userToken.isHelpAdmin : false,
    };
  }

  return adminPermissions;
}

//TODO: Remove or make TEMP_HELP_PERMISSION false always, when content ready - temporary code for help prod deployment  - this is checked in other pages also
export function hasTempAdminPermission() {
  const token = localStorage.getItem('accessToken');
  let hasPermission = true;
  if (token && TEMP_HELP_PERMISSION) {
    const userToken = jwtDecode(token).userRoles; 
    hasPermission = userToken.isHelpAdmin ? true : false;
  }
  return hasPermission;
}
