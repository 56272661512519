import theme from '../../../../assets/theme';

export default {
  field: {
    margin: [[0, 0, theme.spacing(4)]],
    '& ~ $extra': {
      margin: [[-theme.spacing(4), 0, theme.spacing(4), theme.spacing(2)]],
      color: theme.palette.grey.mid,
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  extra: {},
  inputLabel: {
    '&:not($inputFocused):not($inputError)': {
      color: theme.palette.grey.mid,
    },
  },
  cssOutlinedInput: {
    '&:not($inputFocused):not($inputError) $notchedOutline': {
      borderColor: theme.palette.grey.mid,
    },
  },
  cssOutlinedInputWarning: {
    '& label.Mui-focused': {
      color: theme.palette.grey.mid,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.warning.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.warning.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.warning.main,
      },
    },
  },
  notchedOutline: {},
  inputFocused: {},
  inputError: {},
  errorContainer: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    top: theme.spacing(-4),
    left: theme.spacing(2),
    margin: 0,
    color: theme.palette.grey.mid,
    fontSize: 12,
    lineHeight: '16px',
  },
};
