import theme from '../../../assets/theme';

export default {
  fixedLogoBackground: {
    '-ms-grid-row': 2,
    '-ms-grid-column': 1,
    gridArea: 'fixedLogoBackground',
    backgroundColor: theme.palette.background.default,
  },
  pagination: {
    '-ms-grid-row': 2,
    '-ms-grid-column': 2,
    '-ms-grid-column-span': 2,
    gridArea: 'pagination',
    backgroundColor: theme.palette.background.default,
  },
};
