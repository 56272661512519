import React from 'react';
import propTypes from 'prop-types';
import {Button, Dialog, withStyles} from '@material-ui/core';
import Warning from '../../../assets/icons/icon_80_alert_orange.svg';
import Close from '../../../assets/icons/icon_24_close_blue.svg';
import styles from './admin-delete-dashboard-styles';

const DeleteDialog = ({classes, open, name, onClose, deleteDashboard}) => (
  <Dialog open={open} onClose={onClose} classes={{paper: classes.container}}>
    <div className={classes.header}>
      <h1 className={classes.title}>Delete dashboard?</h1>
      <img alt='Close' src={Close} className={classes.cross} onClick={onClose} />
    </div>
    <div className={classes.content}>
      <img src={Warning} aria-hidden alt='' className={classes.icon} />
      <p className={classes.text}>Are you sure you want to delete {name} dashboard? This cannot be undone.</p>
    </div>
    <div className={classes.actions}>
      <Button variant='outlined' onClick={deleteDashboard} color='primary' className={classes.button}>
        Delete dashboard
      </Button>
      <Button variant='contained' onClick={onClose} color='primary' autoFocus className={classes.button}>
        Cancel
      </Button>
    </div>
  </Dialog>
);

DeleteDialog.propTypes = {
  open: propTypes.bool,
  name: propTypes.string,
  onClose: propTypes.func,
  deleteDashboard: propTypes.func,
};

export default withStyles(styles)(DeleteDialog);
