import theme from '../../../assets/theme';

export default {
  loaderChildren: {
    position: 'relative',
  },
  wrapper: {
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
  },
  container: {
    padding: [[theme.spacing(2), theme.spacing(2), 0]],
    '&:last-child': {
      padding: [[theme.spacing(2), theme.spacing(2), 0]],
    },
  },
  radioLabelSpace: {
    padding: [[0, theme.spacing(2), 0, theme.spacing(0.5)]],
  },
  radioSpace: {
    padding: [[theme.spacing(1), theme.spacing(1), 0]],
  },
};
