import React from 'react';
import {Grid, Button, withStyles} from '@material-ui/core';

import Navigation from '../../components/navigation';
import Footer from '../../components/footer/footer-view';
import helpIcon from '../../assets/icons/icon_80_noAccess_midGrey.svg';
import styles from './403-styles';
import {Routes} from '../../Routes';

const ForbiddenErrorPage = ({classes}) => {
  return (
    <>
      <Navigation />
      <main className={classes.wrapper}>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justify={'center'}
          direction={'column'}
          className={classes.container}
        >
          <img className={classes.icon} src={helpIcon} alt={''} />
          <div className={classes.description}>
            Oops, it seems like you don't have
            <br />
            access to this page.
          </div>
          <Button
            variant='contained'
            color='primary'
            component={'button'}
            classes={{root: classes.button, disabled: classes.disabled}}
            href={Routes.ROOT}
          >
            Back to Home
          </Button>
        </Grid>
      </main>
      <Footer />
    </>
  );
};

export default withStyles(styles)(ForbiddenErrorPage);
