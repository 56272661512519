import './i18n/setup';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from './assets/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import BuildVersion from './components/build-version';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'outstated';
import ReactGA from 'react-ga';
import * as pbi from 'powerbi-client';
import {getQueryParam} from './utils/location';
import DashboardState from './state/dashboard';
import AppState from './state/app';
import AdminState from './state/admin';
import AdminPageEditState from './state/admin-page-edit';
import {NotificationsAdminStore, NotificationsUserStore, NotificationsMailboxStore} from './state/notifications/store';
import {HelpAdminStore, PinArticleStore} from './state/help/store';
import {SectionAdminStore} from './state/section/store';
import {Routes} from './Routes';
import './assets/rich-text-styles.css';
import {SimpleDialog} from './components/dialog';
import DialogStore from './state/dialog';

// block right click
if (process.env.REACT_APP_IS_LOCAL !== 'true') {
  document.addEventListener(
    'contextmenu',
    function(ev) {
      ev.preventDefault();
      // eslint-disable-next-line no-console
      console.log('right click blocked');
      return false;
    },
    true
  );
}

function renderApp() {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BuildVersion />
      <Provider
        stores={[
          DashboardState,
          AdminPageEditState,
          AdminState,
          NotificationsAdminStore,
          NotificationsUserStore,
          NotificationsMailboxStore,
          HelpAdminStore,
          PinArticleStore,
          SectionAdminStore,
          AppState,
          DialogStore,
        ]}
      >
        <>
          <SimpleDialog />
          <App />
        </>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  );
}

if ([Routes.ROOT, '', Routes.LOGIN].includes(document.location.pathname)) {
  const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );
  const config = {
    type: 'visual',
    embedUrl: '/reportEmbed',
  };
  powerbi.preload(config);
  /*element.addEventListener("load", function() {
    renderApp();
  });*/
} else {
  //renderApp();
}
renderApp();
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-141766705-1');
}
const isTesting = getQueryParam(window.location, 'test') === 'true';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (isTesting && 'serviceWorker' in navigator && localStorage.getItem('disableServiceWorker') !== 'true') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
