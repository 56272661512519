import React from 'react';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {TextField} from '../fields';
import {NOTIFICATION_TITLE_MAX_LENGTH} from '../../../assets/constants';

const NotificationTitleFormField = () => {
  return (
    <Field
      name='title'
      validate={value => (value ? undefined : formatMessage('required'))}
      render={({input, meta}) => {
        return (
          <TextField
            label={formatMessage('Title')}
            value={input.value}
            handleChange={input.onChange}
            showError={meta.modified && !input.value}
            error={formatMessage('Please fill in a title name')}
            isWarning
            maxLength={NOTIFICATION_TITLE_MAX_LENGTH}
          />
        );
      }}
    />
  );
};

export default NotificationTitleFormField;
