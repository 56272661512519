import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import {withStyles, Link} from '@material-ui/core';

import NavigationIcon from '../navigation-icons-view';
import Help from '../../../../assets/icons/icon_24_help_blue.svg';
import styles from './navigation-help-styles';

const NavigationIconHelp = ({classes, children}) => {
  return (
    <NavigationIcon 
      IconComponent={
        <img src={Help} alt={formatMessage('Help')} title={formatMessage('Help')} aria-label={formatMessage('Help')} />
      }
      help={true}
    />
  );
};

NavigationIconHelp.defaultProps = {
  children: PropTypes.node,
};

export default withStyles(styles)(NavigationIconHelp);
