import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import {useStore} from 'outstated';

import Category from '../../../../components/category';
import SectionCard from '../../../../components/card/section-card';
import PageIcon from '../../../../assets/icons/icon_24_page_darkGrey.svg';
import RichTextEditor from '../../../../components/rich-text-editor';
import {Field} from 'react-final-form';
import {withStyles} from '@material-ui/core';
import styles from './message-section-styles';
import {removeHtmlTags} from '../../../../utils/helpers';
import {NOTIFICATION_TYPES} from '../../../../assets/constants';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {HelpAdminStore} from '../../../../state/help/store';

const MessageSection = ({classes, type, formState}) => {
  const {state: ArticlesState, mutations: ArticlesMutations, effects: ArticlesEffects} = useStore(HelpAdminStore);
  const {current: article, helpStorageFiles: helpStorageFileList} = ArticlesState;
  const [messageContent, setMessageContent] = useState(article.message ? article.message : '');
  
  useEffect(() => {
    ArticlesEffects.getHelpStorageFiles();
  }, []);

  const onClickFun = async (option) => {
    const fileType = option.contentType.split('/').shift();
    const filename = option.name.split('.').shift();
    let link = '';
    if (fileType === 'image')  {
      link = `<img class=\"ql-custom-image\" src="${option.blobURI}">`;
    } else if (fileType === 'video') {
      link = `<video controls="" class="ql-custom-video"><source src="${option.blobURI}" type="video/mp4"></video>`
    } else {
      link = `<h4>Title</h4><a href="${option.blobURI}" rel="noopener noreferrer" target="_blank">${filename}</a>`;
    }

    const newMessage = messageContent.concat(link);
    setMessageContent(newMessage);
    handleTextEditorOnChange(newMessage);
    ArticlesMutations.setUpdateArticle({
      isUpdated: true,
    });
    ArticlesMutations.setCurrentArticle({
      ...article,
      audience: formState.values.audience,
      message: newMessage,
    });
  }
  
  const handleTextEditorOnChange = (e) => {
    setMessageContent(e);
    ArticlesMutations.setUpdateArticle({
      isUpdated: true,
    });
    ArticlesMutations.setCurrentArticle({
      ...article,
      audience: formState.values.audience,
      message: e,
    });
  }

  return (
    <Field
      name='message'
      validate={value => {
        const text = removeHtmlTags(value);
        return !text || text.length === 0 || !text.trim().length? formatMessage('Required') : undefined;
      }}
      render={({input}) => (
        <>
          <Category icon={PageIcon} title={formatMessage('Article')} />
          <SectionCard
            // title={formatMessage('The message will be displayed in the article for the selected audience')}
            // subtitle={formatMessage('Type your message here:')}
            button={
              <Autocomplete
              id="combo-box-demo"
              options={helpStorageFileList}
              onChange={(source, newValue, g) => {
                // Only allow to modify values through menu-options
                if (source.currentTarget.getAttribute('role') === 'option') onClickFun(newValue);
              }}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Add files or images" variant="outlined" />}
            />  
            }
          >
            <div className={classes.messageContainer}>
              <RichTextEditor
                value={messageContent}
                onChange={(e) => handleTextEditorOnChange(e)}
                type={type}
                disableVideo={type === NOTIFICATION_TYPES.INSIGHT}
                disableImages={type === NOTIFICATION_TYPES.INSIGHT}
              />
            </div>
          </SectionCard>
        </>
      )}
    />
  );
};

MessageSection.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default withStyles(styles)(MessageSection);
