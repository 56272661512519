import theme from '../../../../assets/theme';

export default {

  listItem: {
    cursor: 'pointer',
    paddingLeft: 0
  },
  newstyle: {
    height: '100%',
    border: "1px solid green",
    margin: 6,
    padding: 8
  },
};
