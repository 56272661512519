import React, {useEffect, useMemo} from 'react';

import AppBar from '../../../../components/navigation/appbar';
import {AdminTabs} from '../../../../components/navigation/tabs';
import {useStore} from 'outstated';
import DashboardStore from '../../../../state/dashboard';
import Icons from '../../../../components/navigation/icons';
import NavigationIconAccount from '../../../../components/navigation/icons/account';
import {Divider, withStyles} from '@material-ui/core';
import {
  LogoutMenuOption,
  UserAccountOption,
  AdminLeaveOption,
} from '../../../../components/navigation/menu/navigation-menu-options';
import {getAuthenticadUserInfo} from '../../../../utils/access-token';
import formatMessage from 'format-message';
import styles from '../../../../components/navigation/icons/navigation-icons-styles';

const NotificationNavigation = ({classes}) => {
  const {mutations: DashboardMutations} = useStore(DashboardStore);
  const userInfo = useMemo(() => getAuthenticadUserInfo(), []);

  useEffect(() => {
    DashboardMutations.clearActiveDashboardAndPage();
  }, [DashboardMutations]);

  return (
    <>
      <AppBar>
        <AdminTabs />
        <Icons className={classes.titleContainer}>
          <span className={classes.title}>{formatMessage('ADMIN AREA')}</span>
          <NavigationIconAccount>
            <UserAccountOption isAdmin username={userInfo && userInfo.displayName} />
            <Divider />
            <AdminLeaveOption />
            <Divider />
            <LogoutMenuOption />
          </NavigationIconAccount>
        </Icons>
      </AppBar>
    </>
  );
};

export default withStyles(styles)(NotificationNavigation);
