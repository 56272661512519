import React, {useCallback, useState} from 'react';
import {MenuItem, Switch, withStyles} from '@material-ui/core';
import formatMessage from 'format-message';
import AccountIcon from '../../../assets/icons/icon_24_account_darkGrey.svg';
import texts from '../../../assets/texts';
import {useStore} from 'outstated';
import DashboardStore from '../../../state/dashboard';
import AppStore from '../../../state/app';
import {NotificationsAdminStore} from '../../../state/notifications/store';
import logger from '../../../utils/logger';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../Routes';
import accountStyles from './navigation-account-menu-styles';

export const LogoutMenuOption = () => {
  const {mutations: DashboardMutations} = useStore(DashboardStore);
  const {mutations: AppMutations} = useStore(AppStore);

  const {mutations: NotificationMutations} = useStore(
    NotificationsAdminStore
  );
  const logout = useCallback(async () => {
    DashboardMutations.clearDashboards();
    NotificationMutations.clearCurrent()
    DashboardMutations.setInitialized(false);
    AppMutations.clearApp();
  }, [DashboardMutations, AppMutations]);

  return (
    <MenuItem onClick={logout} className='menu-item'>
      {texts.menu.logout}
    </MenuItem>
  );
};

function isServiceWorkerEnabled() {
  return localStorage.getItem('disableServiceWorker') === 'true';
}

function toggleServiceWorker() {
  const disableServiceWorker = localStorage.getItem('disableServiceWorker');

  if (disableServiceWorker === 'true') {
    localStorage.setItem('disableServiceWorker', 'false');
    return false;
  } else {
    localStorage.setItem('disableServiceWorker', 'true');
    return true;
  }
}

export const EnableServiceWorkerOption = () => {
  const [checked, setChecked] = useState(isServiceWorkerEnabled());

  const toggleServiceWorkerCallback = useCallback(() => {
    setChecked(toggleServiceWorker());
  }, [checked]);

  return (
    <MenuItem onClick={toggleServiceWorkerCallback} className='menu-item'>
      {texts.menu.serviceWorker}
      <Switch checked={isServiceWorkerEnabled()} />
    </MenuItem>
  );
};

async function cleanRemoteCache() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/varnish/purge`, {
      method: 'PURGEALL',
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'X-Purge-Pass': 'aCcHuyBexVqhum9BVn5f',
      },
    });

    logger.info('PURGEALL response: ', response);
  } catch (error) {
    logger.error('Error while trying to clean remote cache.', error);
  }
}

export const CleanRemoteCacheOption = () => {
  return (
    <MenuItem onClick={cleanRemoteCache} className='menu-item'>
      {texts.menu.cleanRemoteCache}
    </MenuItem>
  );
};

export const NotificationMailboxOption = () => {
  const history = useHistory();
  const {mutations: DashboardMutations} = useStore(DashboardStore);

  const navigateToMailbox = () => {
    DashboardMutations.setActiveDashboard(null);
    history.push(Routes.NOTIFICATIONS);
  };

  return (
    <MenuItem onClick={navigateToMailbox} className='menu-item'>
      {formatMessage('Notifications mailbox')}
    </MenuItem>
  );
};

export const UserAccountOption = withStyles(accountStyles)(({classes, username, isAdmin}) => {
  return (
    <MenuItem disabled className={classes.accountMenuItemContainer}>
      <div className={classes.accountMenuItem}>
        <div className={classes.accountUser}>
          <img className={classes.icon} src={AccountIcon} alt={''} />
          <span className={classes.accountTitle}>{username}</span>
        </div>
        <span className={classes.accountSubtitle}>{isAdmin && formatMessage('Admin user')}</span>
      </div>
    </MenuItem>
  );
});

export const AdminEnterOption = ({route}) => {
  const enterAdmin = () => {
    //history.push(route || Routes.ADMIN.ROOT);
    const newTab = window.open(route || Routes.ADMIN.ROOT, '_blank');
    newTab.focus();
  };

  return (
    <MenuItem onClick={enterAdmin} className='menu-item'>
      {formatMessage('Open Admin Area')}
    </MenuItem>
  );
};

export const AdminLeaveOption = ({route}) => {
  const history = useHistory();
  const {mutations: DashboardMutations} = useStore(DashboardStore);

  const leaveAdmin = () => {
    DashboardMutations.setInitialized(false);
    history.push(route || Routes.ROOT);
  };

  return (
    <MenuItem onClick={leaveAdmin} className='menu-item'>
      {formatMessage('Back to Dashboards')}
    </MenuItem>
  );
};

export const DataDashLinkOption = ({linkName, setLinkName, newDashboardTabs, onClick}) => {
  const changeName = (linkName) => {
    if (linkName === 'Dashboards') {
      setLinkName('Datasets');
    } else {
      setLinkName('Dashboards');
    }
  }

  return (
    <MenuItem className='menu-item' onClick={() =>{
      changeName(linkName)
      onClick(newDashboardTabs[0].id)
    } }>
      {linkName}
    </MenuItem>
  )
}
