import NotificationsService from '../../services/notifications-service';
import logger from '../../utils/logger';
import formatMessage from 'format-message';
import {AXIOS_REQUEST_CANCELED} from '../../services/SeaburyAxios';

export default (state, mutations, dialog) => ({
  getNotifications: async ({isSearch = false} = {}) => {
    mutations.setLoading(true);
    try {
      const params =
        state.filters.query && state.filters.query.length
          ? {
              ...state.filters,
              limit: state.queryPagination.limit,
              from: state.queryPagination.offset,
            }
          : {
              ...state.filters,
              limit: state.pagination.limit,
              from: state.pagination.offset,
            };
      const data = isSearch
        ? await NotificationsService.searchNotifications(params)
        : await NotificationsService.getNotifications(params);
      mutations.setNotificationsData(data);
      return data;
    } catch (err) {
      logger.warn(err);

      if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

      const errorMessage = formatMessage(
        'Due to a technical issue, the notification data is currently not available. Please try again later.'
      );
      mutations.setNotificationsError(errorMessage);
      dialog.showError({
        title: formatMessage('Data cannot be retrieved.'),
        message: errorMessage,
      });
      return null;
    }
  },

  getNotification: async id => {
    mutations.setCurrentLoading(true);
    try {
      if (id) {
        const data = await NotificationsService.getNotificationById(id);
        mutations.setCurrentNotification(data);
      }
      mutations.setCurrentLoading(false);
      return true;
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The Notification data cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },

  getNotificationStorageFiles: async () => {
    mutations.setCurrentLoading(true);
    try {
        const data = await NotificationsService.getNotificationStorageFiles();
        mutations.setNotificationStorageFileList(data);
        return data;
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The file list cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },

});
