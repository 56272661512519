import React from 'react';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import styles from './about-styles';
import AirCargo from '../../../assets/images/air-cargo.png';

const About = props => {
  const {classes} = props;

  return (
    <section className={classes.aboutWrapper} id="about">
        <Container maxWidth={'xl'}>
            <div className={classes.aboutSection}>
                <Box display="flex" className={classes.firstColumn}>
                    <p className={classes.menuTitle}>— About</p>
                    <p className={classes.menuDetails}>Global air and ocean trade expertise.</p>
                </Box>
                <Box display="flex" className={classes.secondColumn}>
                    <h1 className={classes.contentTitle}>Accenture Cargo is the global leader in consulting and market intelligence for the cargo industry.</h1>
                    <p className={classes.contentDetails}>Passionate about helping our client improving their business performance, we at Accenture Cargo combine industry expertise with advanced analytics to deliver better commercial decisions.</p>
                    <p className={classes.contentDetails}>We provide strategy consulting, real-time performance improvement, and industry-leading market databases and optimization tools to clients across all segments of the cargo industry.</p>                
                </Box>
                <Box display="flex" className={classes.thirdColumn}>
                    <div className={classes.aboutImage}>
                        <img src={AirCargo} alt={'AirCargo'} className={classes.airCargoImg} />
                    </div>
                </Box>

                {/* Mobile view */}
                <Box display="flex" className={classes.mobileDescriptionView}>
                    <p className={classes.mobContentDetails}>We are passionate about helping our clients improve their business performance and combine industry expertise with advanced analytics to deliver better commercial decisions.</p>
                    <p className={classes.mobContentDetails}>We provide strategy consulting, real-time performance improvement, and industry-leading market databases and optimization tools to clients across all segments of the cargo industry.</p>                
                </Box>

            </div>
        </Container>
    </section>
  );
};

export default withStyles(styles)(About);
