import React, {useRef,useEffect} from 'react';
import {Card, CardContent, Grid, withStyles} from '@material-ui/core';

import Heading from './heading';
import LoadingSpinner from '../../../components/loading-spinner';
import LoadingError from '../../../components/loading-error';
import styles from './report-styles';

import usePowerBi from '../../../utils/usePowerBi';
import {useStore} from 'outstated';
import {NotificationsMailboxStore} from '../../../state/notifications/store';
import {PinArticleStore} from '../../../state/help/store';
import DashboardStore from '../../../state/dashboard';
const Report = ({
  size = 12,
  visualData,
  title = 'Title',
  subtitle = 'Subtitle',
  period,
  description,
  classes,
  reportID,
  page,
  visual,
  visualID,
  bootstraping = false,
  onIncreaseLoadingVisuals,
  onDecreaseLoadingVisuals,
  isChangingPage,
  dashboardName,
  pageName,
  visualMongoID,
  isChartLayout,
}) => {
  const report = useRef();
  const insightAnchorRef = useRef();
  const {state: MailboxState} = useStore(NotificationsMailboxStore);
  const {state: ArticlaState} = useStore(PinArticleStore);
  const {mutations: DashboardMutations} = useStore(DashboardStore);

  const {status, handleReload} = usePowerBi({
    report,
    visual,
    visualMongoID,
    reportID,
    page,
    bootstraping,
    isChangingPage,
    onIncreaseLoadingVisuals,
    onDecreaseLoadingVisuals,
    visualID,
  });

  const handleClose = () => {
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
  }

  return (
    <Grid item xs={size} className={bootstraping ? classes.hidden : ''} onMouseLeave={handleClose}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isChartLayout && (
            <Heading
              title={title}
              subtitle={subtitle}
              period={period}
              description={description}
              notification={MailboxState.insights[`${dashboardName}/${pageName}/${title}`]}
              anchorRef={insightAnchorRef.current}
              visualID={visualID}
              pintoArticle={ArticlaState.insights[`${dashboardName}/${pageName}/${title}`]}
            />
          )}
          <hr ref={insightAnchorRef} className={classes.break} />
          <div className={classes.content} style={{position: 'relative'}}>
            {!status.showVisual && status.loaded === true && <LoadingSpinner />}
            {visualData.id === visualMongoID && <div
              id={`visual-${visualID}`}
              ref={report}
              className={`${classes.report} ${!isChartLayout ? classes.twoColumnFullHeight : classes.twoColumn}`}
              style={{visibility: !bootstraping ? 'visible' : 'hidden'}}
            />}
            {!bootstraping && <div className={classes.placeHolder} />}
            {!bootstraping && visual && status.loaded === null && (
              <div className={classes.loadingContainer}>
                <LoadingSpinner />
              </div>
            )}
            {!bootstraping && visual && status.loaded === false && (
              <div className={classes.loadingContainer}>
                <LoadingError action={handleReload} />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default withStyles(styles)(Report);
