import theme from '../../../assets/theme';

export default {
  wrapper: {
    width: '100%',
    minHeight: '100vh',
    background: theme.palette.background.paper,
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  container: {
    width: '1280px',
    height: '100%',
    margin: 'auto',
    background: theme.palette.background.paper,
    padding: [[theme.spacing(6), theme.spacing(8)]],
    flex: '1 1 auto',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(6),
    justifyContent: 'flex-end',

    '& > button': {
      padding: [[theme.spacing(1), theme.spacing(4)]],
    },
    '& > button:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  cancelEditModalContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};
