import React from 'react';
import HelpTableActions from './help-table-row-actions';
import formatMessage from 'format-message';

export default ({onDeleteClick, onEditClick, onPublishClick, onUnpublishClick}) => [
  {
    id: 'dateCreated',
    Header: formatMessage('Date created'),
    accessor: 'dateCreated',
  },
  {
    id: 'section',
    Header: formatMessage('Section'),
    accessor: 'section',
  },
  {
    id: 'fullTitle',
    Header: formatMessage('Full title'),
    accessor: 'fullTitle',
  },
  {
    id: 'pinLocation',
    Header: formatMessage('Pin location'),
    accessor: 'pinToInfo.location',
    Cell: cellInfo => {
      if (cellInfo && cellInfo.row && cellInfo.row.original) {
        const {pintTo, pinToInfo} = cellInfo.row.original;

        switch (pintTo) {
          case 'NOT_PINNED' :
            return formatMessage('Not pinned');
          case 'SITE_FOOTER':
            return formatMessage('Site footer');
          case 'VISUAL':
          case 'PAGE_FOOTER':
          case 'PAGE_HEADER':
            let locationArray = '';
            if(pinToInfo.length === 1) {
              locationArray = pinToInfo && pinToInfo[0].location;
            }
            else {
              locationArray = 'Multiple locations'
            }
            return locationArray 
          default:
            return '';
        }
      } 
      return '';
    },
  },
  {
    id: 'author',
    Header: formatMessage('Author'),
    accessor: 'author.name',
  },
  {
    id: 'status',
    Header: formatMessage('Status'),
    accessor: 'status',
  },
  {
    accessor: 'actions',
    Cell: cellInfo => {
      const article = cellInfo.row.original;
      return (
        <HelpTableActions
          notificationId={article._id}
          status={article.status}
          onPublishClick={() => onPublishClick(article)}
          onUnpublishClick={() => onUnpublishClick(article)}
          onEditClick={() => onEditClick(article._id)}
          onDeleteClick={() => onDeleteClick(article._id, article.status)}
        />
      );
    },
  },
];
