import theme from '../../../../assets/theme';

export default {
  container: {
    width: 500,
    padding: [[theme.spacing(1), theme.spacing(2)]],
    '& .quill > div': {
      border: '0',
      maxHeight: '200px',
      overflowY: 'auto',
    },
  },
  menu: {
    zIndex: '999 !important',
    marginTop: theme.spacing(-2),
    opacity: 0.98,
  },
  notificationIcon: {
    padding: '6px',
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [[theme.spacing(2.5), theme.spacing(2)]],
  },
  editor: {
    '&>div': {
      border: '0 !important',
      marginTop: theme.spacing(1),
      maxHeight: '500px',
      width: '450px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    maxHeight: '500px',
    width: '450px',
  },
  notificationIconActive: {
    fill: theme.palette.primary.main,
  },
};
