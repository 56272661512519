import React, {useMemo} from 'react';
import {Tabs, Tab, withStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import formatMessage from 'format-message';
import {Routes} from '../../../Routes';
import styles from './navigation-styles';
import {getAdminPermissions} from '../../../utils/access-token';
import AdminStore from '../../../state/admin';
import {useStore} from 'outstated';

const AdminTabs = ({classes}) => {
  const history = useHistory();
  const {state: AdminState, mutations: AdminMutations} = useStore(AdminStore);
  const adminPermissions = useMemo(() => getAdminPermissions(), []);

  const path = history.location.pathname;

  return (
    <Tabs
      classes={{
        root: classes.adminContainer,
        flexContainer: classes.flexContainer,
        fixed: classes.fixed,
        indicator: classes.hiddenIndicator,
      }}
      textColor='primary'
      indicatorColor='primary'
      value={
        path.includes(Routes.ADMIN.SECTIONS) ? 
        4 : path.includes(Routes.ADMIN.HELP) ? 
        3 : path.includes(Routes.ADMIN.NOTIFICATIONS) ? 
        2 : path.includes(Routes.ADMIN.ROOT) ? 
        AdminState.tabTitle === "Datasets" ? 
        1 : 0 : 0
      }
    >
      {adminPermissions && adminPermissions.isDashAdmin && <Tab
        key={0}
        label={formatMessage('Dashboards')}
        onClick={() => {
          if (history.location.pathname !== Routes.ADMIN.ROOT) {
            history.replace(Routes.ADMIN.ROOT);
          }
          AdminMutations.setTabTitle('Dashboards');
        }}
      /> }
      {adminPermissions && adminPermissions.isDashAdmin && <Tab
        key={AdminState.tabTitle === "Datasets" ? 1 : 0}
        label={formatMessage('Datasets')}
        onClick={() => {
          if (history.location.pathname !== Routes.ADMIN.ROOT) {
            history.replace(Routes.ADMIN.ROOT);
          }
          AdminMutations.setTabTitle('Datasets');
        }}
      /> }
      {adminPermissions && adminPermissions.isNotifAdmin && <Tab
        key={adminPermissions.isDashAdmin ? 2 : 0}
        label={formatMessage('Notifications')}
        onClick={() => {
          if (!history.location.pathname.includes(Routes.ADMIN.NOTIFICATIONS)) {
            history.replace(Routes.ADMIN.NOTIFICATIONS);
          }
        }}
      /> }
      {adminPermissions && adminPermissions.isHelpAdmin && <Tab
        key={adminPermissions.isDashAdmin ? (adminPermissions.isNotifAdmin? 3 : 2) : (adminPermissions.isNotifAdmin? 2 : 0)}
        label={formatMessage('Help')}
        onClick={() => {
          if (!history.location.pathname.includes(Routes.ADMIN.HELP)) {
            history.replace(Routes.ADMIN.HELP);
          }
        }}
      /> }
      {adminPermissions && adminPermissions.isHelpAdmin && <Tab
        key={adminPermissions.isDashAdmin ? (adminPermissions.isNotifAdmin? 4 : 3) : (adminPermissions.isNotifAdmin? 3 : 2)}
        label={formatMessage('Sections')}
        onClick={() => {
          if (!history.location.pathname.includes(Routes.ADMIN.SECTIONS)) {
            history.replace(Routes.ADMIN.SECTIONS);
          }
        }}
      /> }
    </Tabs>
  );
};

export default withStyles(styles)(AdminTabs);
