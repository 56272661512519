import React, {useEffect, useState, useMemo} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import {useStore} from 'outstated';
import {decodeTokenState} from '../../utils/sso-uri';
import history from '../../utils/history';
import LoadingSpinner from '../../components/loading-spinner';
import DashboardStore from '../../state/dashboard';
import AppStore from '../../state/app';
import {Routes} from '../../Routes';

const Authenticate = ({component, isAdmin = false}) => {
  const location = useLocation();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const {state: DashboardState, effects: DashboardEffects, mutations: DashboardMutations} = useStore(DashboardStore);
  const {state: AppState, effects: AppEffects} = useStore(AppStore);

  let hash;
  let state = {};
  if (history.location.pathname === Routes.MS_RETURN_TOKEN && history.location.hash) {
    hash = queryString.parse(history.location.hash);
    state = decodeTokenState(hash.state);
  }

  useEffect(() => {
    if (history.location.pathname === Routes.MS_RETURN_TOKEN && history.location.hash && hash.id_token) {
      const microsoftToken = hash.id_token;
      const tokenInfo = jwtDecode(microsoftToken);
      if (
        state.tokenState === process.env.REACT_APP_TOKEN_STATE &&
        tokenInfo.nonce === process.env.REACT_APP_TOKEN_NONCE
      ) {
        AppEffects.authenticate(microsoftToken);
      }
    }

    setRedirectToLogin(false);
  }, [state]);

  useEffect(() => {
    if (AppState.token === null && history.location.pathname !== Routes.MS_RETURN_TOKEN) {
      setRedirectToLogin(true);
    } else if (AppState.token && !DashboardState.initialized) {
      DashboardEffects.initDashboards(state.redirectUrl ? {pathname: state.redirectUrl} : history.location);
    }
  }, [AppState.token, state]);

  if (redirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: Routes.LANDING,
          state: {pathname: location.pathname !== Routes.MS_RETURN_TOKEN ? location.pathname : null},
        }}
      />
    );
  }
  if (!DashboardState.initialized) return <LoadingSpinner />;
  const tokenData = AppState.token ? jwtDecode(AppState.token) : null;

  // TO CHECK ADMIN ROUTE PERMISIONS
  let adminPermissions = tokenData.userRoles;

  let adminTabAccess = true;
  let hasTabAccess = true;
  const path = location.pathname
    .split('/')
    .slice(0, -1)
    .join('/');
    if (
    tokenData.userRoles.isAdmin &&
    !adminPermissions.isNotifAdmin &&
    !adminPermissions.isHelpAdmin &&
    !adminPermissions.isDashAdmin
  ) {
    hasTabAccess = false;
    
  }
  
  if (path === Routes.ADMIN.NOTIFICATIONS) {
    if (!adminPermissions.isNotifAdmin) {
      adminTabAccess = false;
    } else {
      adminTabAccess = true;
    }
  }
  if (path === Routes.ADMIN.HELP) {
    if (!adminPermissions.isHelpAdmin) {
      adminTabAccess = false;
    } else {
      adminTabAccess = true;
    }
  }
  switch (location.pathname) {
    case Routes.ADMIN.NOTIFICATIONS:
    case Routes.ADMIN.NOTIFICATIONS_NEW:
      if (!adminPermissions.isNotifAdmin) {
        adminTabAccess = false;
      } else {
        adminTabAccess = true;
      }
      break;

    case Routes.ADMIN.HELP:
    case Routes.ADMIN.HELP_NEW:
    case Routes.ADMIN.SECTIONS:
      if (!adminPermissions.isHelpAdmin) {
        adminTabAccess = false;
      } else {
        adminTabAccess = true;
      }
      break;
    case Routes.ADMIN.ROOT:
      if (!adminPermissions.isDashAdmin) {
        adminTabAccess = false;
      } else {
        adminTabAccess = true;
      }
      break;
  }
  return (
    <>
      {/* {tokenData &&
        (!isAdmin || (isAdmin && tokenData && tokenData.userRoles && tokenData.userRoles.isAdmin && !hasTabAccess)) && (
          <Redirect push to={Routes.ROOT} />
        )} */}

      {tokenData &&
      (!isAdmin ||
        (isAdmin &&
          tokenData &&
          tokenData.userRoles &&
          tokenData.userRoles.isAdmin &&
          hasTabAccess &&
          adminTabAccess)) ? (
        component
      ) : (
        <Redirect
          to={
            adminPermissions.isDashAdmin
              ? Routes.ADMIN.ROOT
              : adminPermissions.isNotifAdmin
              ? Routes.ADMIN.NOTIFICATIONS
              : adminPermissions.isHelpAdmin
              ? Routes.ADMIN.HELP
              : Routes.ROOT
          }
        />
      )}
      {isAdmin && tokenData && tokenData.userRoles && !tokenData.userRoles.isAdmin && <Redirect to={Routes.ROOT} />}
    </>
  );
};

export default Authenticate;
