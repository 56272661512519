import theme from '../../../assets/theme';

export default {
    wrapper: {
        paddingTop: '9rem',
    },
    title: {
        width: '22.563rem',
        color: theme.palette.black,
        fontSize: 48,
        fontWeight: 'bold',
        letterSpacing: -2,
        lineHeight: '60px',
        marginTop: 0
    },
    subTitle: {
        width: '22.563rem',
        color: theme.palette.black,
        fontSize: 15,
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '24px'
    },
    description: {
        width: 361,
        color: '#979EA6',
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: '28px',
    },
    firstColumn: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
    iconSection: {
        marginTop: '4rem'
    },
    iconItem: {
        marginBottom: '2rem',
        textAlign: 'center'
    },
    iconName: {
        color: theme.palette.black,
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: -0.8,
        lineHeight: '36px',
    },
    clientText: {
        justifyContent: 'center',
        marginBottom: '10rem'
    },
    aboutClient: {
        color: theme.palette.black,
        fontSize: 48,
        fontWeight: 'bold',
        letterSpacing: -2,
        lineHeight: '60px',
        textAlign: 'center',
    }
}