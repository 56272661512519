import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import propTypes from 'prop-types';
import {Card, CardContent, withStyles} from '@material-ui/core';
import classNames from 'classnames';
import styles from './notification-viewer-list-styles';
import {NOTIFICATION_TYPES} from '../../../../assets/constants';
import DataUpdateIcon from '../../../../assets/icons/icon_24_data_update_blue.svg';
import NewsIcon from '../../../../assets/icons/icon_24_page_darkGrey.svg';
import {useStore} from 'outstated';
import {NotificationsUserStore} from '../../../../state/notifications/store';
import {useHistory} from 'react-router-dom';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

const NotificationViewerListItem = ({classes, item}) => {
  const {state} = useStore(NotificationsUserStore);
  const history = useHistory();
  const isActive = state.current && state.current._id === item._id;
  let subtitle;
  switch (item.type) {
    case NOTIFICATION_TYPES.NEWS:
      subtitle = (
        <span className={classes.itemTypeContainer}>
          <img src={NewsIcon} className={classes.itemIcon} alt='icon' />
          <span className={classes.itemType}>{NOTIFICATION_TYPES.NEWS}</span>
        </span>
      );
      break;
    case NOTIFICATION_TYPES.DATA_UPDATE:
      subtitle = (
        <span className={classes.itemTypeContainer}>
          <img src={DataUpdateIcon} className={classes.itemIcon} alt='icon' />
          <span className={classes.itemType}>{NOTIFICATION_TYPES.DATA_UPDATE}</span>
        </span>
      );
      break;
    case NOTIFICATION_TYPES.INSIGHT:
    case NOTIFICATION_TYPES.ANNOUNCEMENT:
    default:
      break;
  }

  return (
    <Card
      className={classNames(classes.card, {[classes.cardActive]: isActive})}
      onClick={() => {
        history.push(`/notifications/${item._id}`);
      }}
    >
      <CardContent className={classNames(classes.cardContent)}>
        <div className={classes.item}>
          <div className={classes.cardTitle}>
            <span>{item.title}</span>
          </div>
          <span className={classes.itemNewText}>{timeAgo.format(new Date(item.publishedAt))}</span>
        </div>
        <div className={classes.item}>
          {subtitle}
          <span className={classes.itemPublicationDate}>{item.datePublished}</span>
        </div>
      </CardContent>
    </Card>
  );
};

NotificationViewerListItem.propTypes = {
  classes: propTypes.object.isRequired,
  item: propTypes.object.isRequired,
};

export default withStyles(styles)(NotificationViewerListItem);
