import React from 'react';
import classNames from 'classnames';
import formatMessage from 'format-message';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Button, Dialog, DialogActions, DialogTitle, DialogContent, withStyles} from '@material-ui/core';
import Close from '../../assets/icons/icon_24_close_blue.svg';
import styles from './dialog-article-view-styles';

const CustomArticleDialog = ({
  className,
  classes,
  children,
  open,
  title,
  onClose,
  onBack,
  button,
  cancelButtonComponent,
  action,
  disabled,
  narrow,
  hideActions,
  isLoadMore,
  subTitle,
  backButton,
  fromPinTo
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    onBack={onBack}
    maxWidth='md'
    classes={{paperWidthMd: classNames(classes.container, {[classes.containerNarrow]: narrow}, className)}}
    BackdropProps={{
      style: {
        backgroundColor: 'rgb(0 0 0 / 14%)'
      },
    }}
  >
    <DialogTitle disableTypography>
      <h4 className={classes.title}>{title}</h4>
      <img alt='Close' src={Close} onClick={onClose} />
    </DialogTitle>
    {!fromPinTo && (
      <div className={classes.subTitles}>
        <h3 className={classes.subTitle}>{!isLoadMore ? subTitle : 'Article list'}</h3>
      </div>
    )}
    <hr className={classes.break} />

    <DialogContent className={classNames(classes.content, {[classes.noActions]: hideActions})}>
      {children}
    </DialogContent>
    {!hideActions && backButton && (
      <DialogActions className={classes.dialogAction}>
        {cancelButtonComponent ? (
          cancelButtonComponent
        ) : (
          <>
           {backButton && <Button
              onClick={onBack}
              variant='outlined'
              color='primary'
              startIcon={<ArrowBackIosIcon color='primary' />}
            >
              {formatMessage('Back')}
            </Button>}
          </>
        )}
      </DialogActions>
    )}
  </Dialog>
);

export default withStyles(styles)(CustomArticleDialog);
