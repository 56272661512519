import theme from '../../assets/theme';

export default {
  info: {
    backgroundColor: theme.palette.warning.main,
  },
  badge: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    minWidth: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
};
