import React, {useState, useEffect, useMemo} from 'react';
import Dialog from './admin-delete-dashboard-view';
import axios from 'axios';
import {useStore} from 'outstated';

import {apiURL} from '../../../assets/constants';
import AdminStore from '../../../state/admin';

const AdminDeleteDashboard = props => {
  const [name, setName] = useState(null);

  const {state: AdminState, mutations: AdminMutations, effects: AdminEffects} = useStore(AdminStore);

  const dashboard = useMemo(() => props.dashboards[AdminState.activeIndex], [AdminState]);

  const {open, onClose} = props;

  useEffect(() => {
    if (!name && open && dashboard) setName(dashboard.name);
  }, [name, open, dashboard]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setName(null);
    }, 500);
  };

  const deleteDashboard = async () => {
    const jwt = localStorage.getItem('accessToken');
    axios
      .delete(`${apiURL}/admin/dashboard/${dashboard.id}`, {
        headers: {authorization: `bearer ${jwt}`},
        validateStatus: status => [204, 401, 403, 404].includes(status),
      })
      .then(async ({status}) => {
        if (status === 204) {
          AdminEffects.getDashboards();
          AdminMutations.setActive(0);
          handleClose();
        } else if (status === 401 || status === 403) {
          //
        } else if (status === 404) {
          //
        }
      })
      .catch(err => null);
    handleClose();
  };

  if (dashboard) {
    return <Dialog {...props} name={name} deleteDashboard={deleteDashboard} onClose={handleClose} />;
  }
  return null;
};

export default AdminDeleteDashboard;
