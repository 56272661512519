import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import {useStore} from 'outstated';

import Dialog from './admin-add-page-view';
import {axiosHeader} from '../../../utils/axios';
import {apiURL, LAYOUT_OPTIONS} from '../../../assets/constants';
import AdminStore from '../../../state/admin';
import AppStore from '../../../state/app';

export default props => {
  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);
  const {effects: AppEffects} = useStore(AppStore);

  const [pages, dashboard] = useMemo(() => {
    if (props.dashboards[AdminState.activeIndex]) {
      const dashboard = props.dashboards[AdminState.activeIndex];
      const pages = dashboard.pages;
      return [pages, dashboard];
    }
    return [];
  }, [AdminState.activeIndex, props.dashboards]);

  // From Page-edit
  const {edit, pageId, pageGroup} = props;

  // State
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [filled, setFilled] = useState(false);
  const [error, setError] = useState(false);

  // Effects

  //// Handle Changes
  useEffect(() => {
    if (url.length > 0 && name.length > 0 && !error) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [url, name, error]);

  //// Edit Mode
  useEffect(() => {
    if (edit)
      if (edit.status) {
        setName(edit.page.name);
        setUrl(
          edit.page.url
            .split('/')
            .slice(-1)
            .toString()
        );
      }
  }, [edit]);

  // Actions
  const onClose = () => {
    props.onClose();
    setTimeout(() => {
      setName('');
      setUrl('');
    }, 500);
  };

  const handleChange = e => {
    const field = e.target.name;

    if (field === 'name') setName(e.target.value);

    if (field === 'url') {
      const value = e.target.value.split('/');
      if (value[2]) {
        const url = value[2];
        if (/^([a-zA-Z0-9-])*$/.test(url)) {
          setUrl(url);
        }

        if (edit) {
          if (pages.filter(p => p.id !== edit.page.id).find(p => p.url === `${dashboard.url}/${url}`)) {
            return setError(true);
          }
        } else {
          if (
            pages
              .map(p =>
                p.url
                  .split('/')
                  .slice(-1)
                  .toString()
              )
              .includes(url)
          ) {
            return setError(true);
          }
        }

        return setError(false);
      } else {
        setUrl('');
      }
    }
  };

  const handleSave = async () => {
    try {
      let axiosURL;

      axiosURL = `${apiURL}/admin/page`;
      const layout = LAYOUT_OPTIONS[0].value;
      const res = await axios.post(axiosURL, {name, url: `${dashboard.url}/${url}`, layout}, axiosHeader(200));

      const pagesID = dashboard.pages.map(page => page.id);

      axiosURL = `${apiURL}/admin/dashboard/${dashboard.id}/pages`;
      await axios.patch(axiosURL, {pages: [...pagesID, res.data.id]}, axiosHeader(200));
    } catch (e) {}

    AppEffects.fetchConfiguration();
    AdminEffects.getDashboards();
    onClose();
  };

  const handleEdit = async () => {
    try {
      const axiosURL = `${apiURL}/admin/page/${edit.page.id}`;
      await axios.patch(
        axiosURL,
        {
          name,
          url: `${dashboard.url}/${url}`,
          reports: edit.page.reports.map(r => r.id),
          layout: edit.page.layout,
        },
        axiosHeader(200)
      );
    } catch (e) {}

    AppEffects.fetchConfiguration();
    AdminEffects.getDashboards();
    onClose();
  };

  if (dashboard) {
    return (
      <Dialog
        {...props}
        name={name}
        pageUrl={url}
        prefix={dashboard.url}
        error={error}
        filled={filled}
        change={handleChange}
        save={edit ? handleEdit : handleSave}
        onClose={onClose}
      />
    );
  }
  return null;
};
