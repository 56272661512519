import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import styles from './landing-footer-styles';
import history from '../../utils/history';
import {Routes} from '../../Routes';
import {SEABURY_EMAIL, SEABURY_LINK} from '../../assets/constants';

const LandingFooter = props => {
  // From Styles
  const {classes, location} = props;

  const handleTermsClick = () => {
    history.push(Routes.TERMS);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth', duration: 1000});
  };

  return (
    <section className={classes.wrapper} id='contact'>
      <Container maxWidth={'xl'}>
        <Grid container direction='row' spacing={0} className={classes.contactSection}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.leftContainer}>
            <h1 className={classes.title}>Get in touch with us to find out more about Accenture Cargo.</h1>
            <a className={classes.email} key="Email" href={`mailto:${SEABURY_EMAIL}`}>{SEABURY_EMAIL}</a>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <div className={classes.centerLine} />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.contactUs}>
            <Grid container direction='row' spacing={0}>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.officeSection}>
                <h3 className={classes.contactType}>OFFICE</h3>
                <p className={classes.contactAddress}>
                  Accenture Cargo<br />Gustav Mahlerplein 90<br />1082MA Amsterdam<br />The Netherlands 
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.contactSection}>
                <h3 className={classes.contactType}>CONTACTS</h3>
                <p className={classes.contactAddress}>
                <a className={classes.contactLink} key="Email" href={`mailto:${SEABURY_EMAIL}`}>{SEABURY_EMAIL}</a><br />
                <a className={classes.contactLink} href={`//${SEABURY_LINK}`} target="_blank">{SEABURY_LINK}</a>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className={classes.footerSection}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <span className={classes.item}>Accenture {new Date().getFullYear()}</span>
            <span className={classes.terms} onClick={handleTermsClick}> — Terms & conditions</span>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(LandingFooter);
