import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  createChart: async body => {
    try {
      const {data} = await SeaburyAxios.post(`/api/admin/chart`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  updateChart: async (chartId, body) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/chart/${chartId}`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  deleteChart: async chartId => {
    try {
      await SeaburyAxios.delete(`/api/admin/chart/${chartId}`);
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
