import React from 'react';
import propTypes from 'prop-types';
import NotificationViewerListItem from './notification-viewer-list-item-view';

const NotificationViewerList = ({items}) => {
  return items.map(item => <NotificationViewerListItem key={item._id} item={item} />);
};

NotificationViewerList.propTypes = {
  items: propTypes.array.isRequired,
};

export default NotificationViewerList;
