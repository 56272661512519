import theme from '../../assets/theme';

export default {
  appBarTransparent: {
    height: '5.5rem',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0)'
  },
  appBarSolid: {
    height: '5.5rem',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.96)'
  },
  headerContent: {
    padding: 0
  },
  appBarTextWhite: {
    color: theme.palette.primary.contrastText,
    width: 43,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    textAlign: 'center',
    textDecoration: 'none',
    marginRight: theme.spacing(2),
    '&:hover': {
      color: theme.palette.background.signinButton,
    },
  },
  appBarTextBlack: {
    color: theme.palette.black,
    width: 43,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    textAlign: 'center',
    textDecoration: 'none',
    marginRight: theme.spacing(2),
    '&:hover': {
      color: theme.palette.background.signinButton,
    },
  },
  headerLogo: {
    float: 'left',
    flex: 1,
    paddingTop: 12,
  },
  logo: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '2.5rem',
      width: '6.761rem'
    },
  },
  signinButton: {
    height: 32,
    borderRadius: 2,
    backgroundColor: theme.palette.background.signinButton,
    color: theme.palette.primary.contrastText,
  },


  mobileMenu: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.background.paper,
  },
  menuIcon: {
    cursor: 'pointer',
    padding: [[theme.spacing(1)+1, theme.spacing(1), theme.spacing(1)+1, theme.spacing(1)]],
  },
  drawerContainer: {
    boxSizing: 'border-box',
    height: '100%',
    width: 387,
    border: `1px solid ${theme.palette.grey.border}`,
    borderRadius: 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 48px 0 rgba(0,0,0,0.08)',
  },
  mobMenuList: {
    color: theme.palette.black,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -0.8,
    lineHeight: '36px',
    padding: [[0, 0, theme.spacing(5), theme.spacing(5)]],
    '&:hover': {
      color: theme.palette.background.signinButton,
    },
  },
  closeMenu: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.grey.line,
    borderRadius: '50%'
  },
  closeMenuSection: {
    direction: 'rtl',
    padding: '1.781rem'
  },
  closeIcon: {
    cursor: 'pointer',
    padding: [[theme.spacing(1)+2, theme.spacing(1)+2, theme.spacing(1)+2, theme.spacing(1)+2]],
  },
  mobSigninButton: {
    height: 32,
    borderRadius: 2,
    backgroundColor: theme.palette.background.signinButton,
    color: theme.palette.primary.contrastText,
    margin: [[0, 0, theme.spacing(5), theme.spacing(5)]],
  }
};
