import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import {withStyles, Divider} from '@material-ui/core';
import {useStore} from 'outstated';

import CardHeader from '../../../../components/card/header';
import styles from './report-heading-styles';
import NotificationPin from './notification-pin-view';
import ArticlePin from '../heading/visual-article';
import ReportPoppover from '../heading/report-poppover/report-poppover';
import DashboardStore from '../../../../state/dashboard';

const Heading = ({classes, title, subtitle, period, description, notification, anchorRef, pintoArticle, visualID}) => {
  const {mutations: DashboardMutations} = useStore(DashboardStore);

  const handleClose = () => {
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
  }
  return (
    <CardHeader
      onMouseLeave={handleClose}
      title={title}
      subtitle={subtitle}
      extra={period}
      notification={notification}
      pintoArticle={pintoArticle}
      rightButton={
        notification || description || pintoArticle ? (
          <>
            {notification && (
              <>
                <NotificationPin message={notification.message} anchorEl={anchorRef} visualID={visualID} />
                <Divider className={classes.divider} />
              </>
            )}

            {pintoArticle ? (
              <>
                <ArticlePin pintoArticle={pintoArticle} anchorEl={anchorRef} visualID={visualID} />
              </>
            ) : (
              <>
                {description && (
                  <>
                    <ReportPoppover description={description} anchorEl={anchorRef} visualID={visualID}/>
                  </>
                )}
              </>
            )}
          </>
        ) : null
      }
    />
  );
};

Heading.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  period: propTypes.string,
  description: propTypes.string,
  notification: propTypes.object,
  anchorRef: propTypes.node,
};

export default withStyles(styles)(Heading);
