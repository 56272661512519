import React, {useMemo} from 'react';
import Icons from '../../../components/navigation/icons';
import NavigationIconAccount from '../../../components/navigation/icons/account';
import NavigationIconHelp from '../../../components/navigation/icons/help';
import NavigationIconMailbox from '../../../components/navigation/icons/mailbox';
import {Divider} from '@material-ui/core';
import {
  AdminEnterOption,
  LogoutMenuOption,
  NotificationMailboxOption,
  UserAccountOption,
  DataDashLinkOption
} from '../../../components/navigation/menu/navigation-menu-options';
import {getAuthenticadUserInfo, isAdmin, getAdminPermissions, hasTempAdminPermission} from '../../../utils/access-token';

const DashboardNavigationIcons = ({sticky, linkName, setLinkName, onClick, newDashboardTabs}) => {
  const showAdminLinks = useMemo(() => isAdmin(), []);
  const userInfo = useMemo(() => getAuthenticadUserInfo(), []);
  const adminPermissions = useMemo(() => getAdminPermissions(), []);
  const hasPermission = useMemo(() => hasTempAdminPermission(), []);

  let hasTabAccess = true;
  if (!adminPermissions.isNotifAdmin && !adminPermissions.isHelpAdmin && !adminPermissions.isDashAdmin) {
    hasTabAccess = false;
  }
  return (
    <Icons sticky={sticky}>
      {/* TODO: Remove the hasPermission check, when content ready - or simply make TEMP_HELP_PERMISSION in as false */}
      {hasPermission && <NavigationIconHelp />}
      <NavigationIconMailbox />
      <NavigationIconAccount>
        <UserAccountOption isAdmin={showAdminLinks} username={userInfo && userInfo.displayName} />
        <Divider />
        {showAdminLinks && hasTabAccess && (
          <>
            <AdminEnterOption />
            <Divider />
          </>
        )}
        
        <NotificationMailboxOption />
        <Divider />
        <DataDashLinkOption 
          linkName={linkName} 
          setLinkName={setLinkName}
          onClick={onClick}
          newDashboardTabs={newDashboardTabs}
        />
        <Divider />
        <LogoutMenuOption />
      </NavigationIconAccount>
    </Icons>
  );
};

export default DashboardNavigationIcons;
