import React, {useEffect, useCallback, useMemo} from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import _debounce from 'lodash/fp/debounce';
import {Card, CardContent, Grid, withStyles} from '@material-ui/core';
import {useStore} from 'outstated';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import LoadingSpinner from '../../../components/loading-spinner';
import DialogStore from '../../../state/dialog';
import HelpTable from './help-table';
import HelpTableColumns from './help-table-columns';
import HelpTableHeader from './help-table-header-view';
import {HelpCreateDialog} from '../create-dialog';
import Pagination from '../../../components/pagination';
import HelpAdminNavigation from '../navigation/admin';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../Routes';
import {HelpAdminStore} from '../../../state/help/store';
import styles from './help-list-styles';
import {NOTIFICATION_STATUS} from '../../../assets/constants';
import {getDeleteDialogContent, getPublishDialogContent, getUnpublishDialogContent} from '../help-dialog-content';

const showMessage = (classes, title, subtitle) => {
  return (
    <div className={classes.messageWrapper}>
      <p className={classes.message}>{title}</p>
      <p className={classes.message}>{subtitle}</p>
    </div>
  );
};

const HelpList = ({classes}) => {
  const history = useHistory();
  const {state: ArticlesState, mutations: ArticlesMutations, effects: ArticlesEffects} = useStore(HelpAdminStore);
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {
    filters,
    sections,
    sectionFilterArray,
    pagination,
    queryPagination,
    articles,
    loading,
    error,
    reload,
  } = ArticlesState;

  const hasQuery = filters.query && filters.query.length;
  const currentPagination = hasQuery ? queryPagination : pagination;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    ArticlesEffects.getArticles({isSearch: true});
  }, [filters, pagination.currentPage, queryPagination.currentPage, reload]);

  useEffect(() => {
    if (currentPagination.currentPage > currentPagination.totalPages) {
      ArticlesMutations.setPage(currentPagination.totalPages);
    }
  }, [currentPagination, ArticlesMutations]);

  const handlePagination = useCallback(
    page => {
      ArticlesMutations.setPage(page);
    },
    [ArticlesMutations]
  );

  const handleStatusChange = useCallback(
    status => {
      ArticlesMutations.setFiltersStatus(status.value);
    },
    [ArticlesMutations]
  );
  const handleTypeChange = useCallback(
    type => {
      ArticlesMutations.setFiltersType(type.value);
    },
    [ArticlesMutations]
  );
  const handleSectionChange = useCallback(
    type => {
      ArticlesMutations.setFiltersSection(type.value);
    },
    [ArticlesMutations]
  );

  const setQuery = useCallback(_debounce(300)(ArticlesMutations.setFiltersQuery), []);
  const handleSearch = useCallback(
    event => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const hasFilters =
    (filters.query && filters.query.length) ||
    (filters.status && filters.status.length) ||
    (filters.pintTo && filters.pintTo.length) ||
    (filters.section && filters.section.length);
  const hasArticles = articles && articles.length;
  const hasError = error && error.length;

  const errorMessage = showMessage(
    classes,
    null,
    formatMessage('Due to technical issues, article data is currently not available. Please try again later.')
  );
  const noResultsMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No results found.'),
        formatMessage('Modify your filters and search criteria.')
      );
  const noArticlesMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No article present yet.'),
        formatMessage("Click on the '+ New article' button to get started")
      );

  const handleRowClick = useCallback(
    row => {
      ArticlesMutations.setValidPinTo({
        isValidPinLocation: true,
      });
      history.push(`${Routes.ADMIN.HELP}/${row.original._id}`);
    },
    [history]
  );

  const handleOpenDeleteArticleConfirmation = useCallback(
    (id, status) => {
      const {title, message} = getDeleteDialogContent(status);
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (id && id.length) {
            const result = await ArticlesEffects.deleteArticle(id);
            if (result) {
              ArticlesMutations.reload();
            }
          }
        },
        title,
        message,
      });
    },
    [DialogMutations, ArticlesMutations]
  );

  const handleEditClick = id => {
    ArticlesMutations.setValidPinTo({
      isValidPinLocation: true,
    });
    history.push(`${Routes.ADMIN.HELP}/${id}`);
  };

  const handlePublishClick = useCallback(
    article => {
      const {icon, title, message} = getPublishDialogContent(article.pintTo);
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (article && article.status !== NOTIFICATION_STATUS.PUBLISHED) {
            const result = await ArticlesEffects.createOrUpdateArticlePublication(
              {
                ...article,
                status: NOTIFICATION_STATUS.PUBLISHED,
              },
              true
            );
            if (result) {
              ArticlesMutations.reload();
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [DialogMutations, ArticlesMutations]
  );

  const handleUnpublishClick = useCallback(
    article => {
      const {icon, title, message} = getUnpublishDialogContent();
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (article && article.status !== NOTIFICATION_STATUS.UNPUBLISHED) {
            const result = await ArticlesEffects.createOrUpdateArticlePublication(
              {
                ...article,
                status: NOTIFICATION_STATUS.UNPUBLISHED,
              },
              true
            );
            if (result) {
              ArticlesMutations.reload();
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [DialogMutations, ArticlesMutations]
  );

  const columns = useMemo(
    () =>
      HelpTableColumns({
        onDeleteClick: handleOpenDeleteArticleConfirmation,
        onEditClick: handleEditClick,
        onPublishClick: handlePublishClick,
        onUnpublishClick: handleUnpublishClick,
      }),
    [
      filters,
      currentPagination.currentPage,
      handleOpenDeleteArticleConfirmation,
      handleEditClick,
      handlePublishClick,
      handleUnpublishClick,
    ]
  );

  return (
    <>
      <HelpAdminNavigation />
      <div className={classes.container}>
        <Grid container alignItems='center' justify='space-between' className={classes.header}>
          <Grid item className={classes.title}>
            {formatMessage('Help admin area')}
          </Grid>
          <Grid item>
            <HelpCreateDialog />
          </Grid>
        </Grid>

        {hasArticles || hasFilters ? (
          <>
            <HelpTableHeader
              filters={filters}
              sections={sectionFilterArray}
              disabled={loading}
              onStatusClick={handleStatusChange}
              onSectionClick={handleSectionChange}
              onTypeClick={handleTypeChange}
              onSearchChange={handleSearch}
            />
            <Card className={classNames(classes.card)}>
              <CardContent className={classes.cardContent}>
                {loading && <LoadingSpinner />}
                {!loading &&
                  (hasArticles ? (
                    <>
                      <HelpTable columns={columns} data={articles} onRowClick={handleRowClick} />
                      <Pagination
                        pages={currentPagination.totalPages}
                        currentPage={currentPagination.currentPage}
                        onPageChanged={handlePagination}
                      />
                    </>
                  ) : (
                    noResultsMessage
                  ))}
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            {loading && <LoadingSpinner />}
            {!loading && noArticlesMessage}
          </>
        )}
      </div>
    </>
  );
};

HelpList.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HelpList);
