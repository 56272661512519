import {useMemo, useState} from 'react';
import {useStore} from 'outstated';
import DialogStore from '../../dialog';
import NotificationsMutations, {initialNotificationsState, resetPagination} from '../mutations';
import NotificationEffects from '../effects';
import produce from 'immer';
import {VALID_TYPES, VALID_STATUSES, USER_LIST_TYPES} from '../constants';

const initialUserPagination = {
  limit: 8,
  offset: 0,
  currentPage: 1,
  totalPages: 1,
};

const User = notificationState => {
  const initialState = {
    ...initialNotificationsState,
    filters: {
      status: VALID_STATUSES[1], // only published
      sortBy: 'publishedAt',
      type: USER_LIST_TYPES[0],
      query: null,
      selectedFilterType: USER_LIST_TYPES[0],
    },
    pagination: {
      ...initialUserPagination,
    },
  };
  const [state, setState] = useState(notificationState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...NotificationsMutations(setState, initialUserPagination),
      // filter override, different behaviour than admin page
      selectedFilterType: type =>
        setState(
          produce(draft => {
            draft.filters.selectedFilterType = type;
          })
        ),
      setFiltersType: type =>
        setState(
          produce(draft => {
            if (
              type === draft.filters.type ||
              (type === 'ALL' && draft.filters.type === USER_LIST_TYPES) ||
              !VALID_TYPES.includes(type)
            ) {
              draft.loading = false;
            } else {
              draft.loading = true;
              switch (type) {
                case USER_LIST_TYPES[0]:
                  draft.filters.type = type;
                  break;
                // data updates use both types always for users
                case USER_LIST_TYPES[1]:
                case USER_LIST_TYPES[2]:
                  draft.filters.type = [USER_LIST_TYPES[1], USER_LIST_TYPES[2]];
                  break;
                case 'ALL':
                default:
                  draft.filters.type = USER_LIST_TYPES;
                  break;
              }
              resetPagination(draft, initialUserPagination);
            }
          })
        ),
    }),
    []
  );
  const effects = useMemo(() => NotificationEffects(state, mutations, DialogMutations), [
    mutations,
    DialogMutations,
    state,
  ]);
  return {state, mutations, effects};
};
export default User;
