import theme from '../../../assets/theme';

export default {
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  menu: {
    padding: [[theme.spacing(1)]],
    borderRadius: '16px',
    width: 32,
    height: 32,
    visibility: 'hidden',
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
  menuActive: {
    backgroundColor: theme.palette.grey.veryLight,
    visibility: 'visible',
  },
  menuUnfocus: {
    transition: 'all 0s ease-out 50ms',
  },

  card: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(9),
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
    '&:hover': {
      cursor: 'pointer',
      '& $menu': {
        visibility: 'visible',
      },
    },
  },
  activeCard: {
    border: [[1, 'solid', theme.palette.primary.main]],
    '& $menu': {
      visibility: 'visible',
    },
    '&:hover': {
      cursor: 'initial',
    },
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(2),
    },
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
  },
  cardLink: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: 0,
  },
};
