import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  getAudiences: async () => {
    try {
      const {data} = await SeaburyAxios.get(`/api/notifications/audienceGroups`);
      return data.map(d => d.displayName);
    } catch (err) {
      logger.warn(err);
      throw err;
    }
  },
};
