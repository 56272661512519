import theme from '../../assets/theme';
import loginBackground from '../../assets/images/login-side-background.png';

export default {

  wrapper: {
    display: 'block',
    fontFamily: theme.landingFontFamily.karla,
    backgroundColor: theme.palette.background.paper,
  },
  loginSection: {
    height: '100%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  signup: {
    textAlign: 'right',
    paddingRight: [theme.spacing(3)]
  },
  loginForm: {
    paddingTop: [[theme.spacing(4)]],
    height: '100%'
  },
  signupText: {
    color: theme.palette.grey.midLight,
    fontSize: 13,
    lineHeight: '24px',
    marginTop: 0,
    paddingTop: [theme.spacing(2)]
  },
  noAccounntYet: {
    color: theme.palette.black,
    fontSize: 13,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  notificationSection: {
    display: 'flex'
  },
  notification: {
    color: theme.palette.grey.midLight,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: 20,
    paddingLeft : [theme.spacing(2)],
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.up('xl')]: {
      fontSize: 18
    },
  },

  wrapperOld: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100vh',
    padding: [[theme.spacing(9), 0, 0]],
  },
  header: {
    paddingTop: [[theme.spacing(2)]],
    flex: '0 0 auto',
    position: 'absolute'
  },
  logo: {
    height: theme.spacing(8),
    cursor: 'pointer',
  },
  main: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  content: {
    flex: '1 1 auto',
    alignContent: 'flex-start',
    maxWidth: '70%',
    margin: [[theme.spacing(-8), 0, 0]],
  },
  contentTitle: {
    color: theme.palette.primary.main,
    fontSize: 38,
    margin: [[theme.spacing(2.5), 0]],
  },
  contentTxt: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(2),
    lineHeight: '22px',
  },
  form: {
    flex: '0 1 auto',
  },
  lottie: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '70%',
    height: '100%',
  },


  leftContainer: {
    width: '100%',
    height: '100%'
  },
  loginFormArea: {
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  firstHalf: {
    height: '50%',
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative'
  },
  secondHalf: {
    height: '50%',
    backgroundColor: theme.palette.background.blackbg,
  },
  rightSection: {
    display: 'flex',
    height: '75%',
    marginLeft: '6rem'
  },

  items: {
    color: theme.palette.grey.border,
    fontFamily: theme.landingFontFamily.lucida,
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'right',
    marginTop: 0,
  },
  item: {
    textDecoration: 'none',
    color: theme.palette.grey.border,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
      '&:before': {
        content: '"-"',
        marginRight: theme.spacing(1),
      },
    }
  },

  loginHeader: {
    height: '10%'
  },
  loginContent: {
    height: '80%'
  },

  mobileView: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      bottom: '50%',
      position: 'absolute'
    },
  },
  mobTitle: {
    color: theme.palette.black,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -0.8,
    lineHeight: '36px',
    width: '100%'
  },
  mobContent: {
    width: 335,
    color: theme.palette.black,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '32px',
    margin: '0 auto',
    marginLeft: 0
  },
  mobHomeButton: {
    backgroundColor: theme.palette.background.signinButton,
    color: theme.palette.primary.contrastText,
    height: 32,
    width: 164,
    borderRadius: 2,
    marginTop: theme.spacing(2)
  },
  mobText: {
    width: 162.99,
    color: theme.palette.black,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '20px',
    textAlign: 'center',
  },
  getInTouch: {
    cursor: 'pointer',
    color: theme.palette.grey.midLight,
    fontSize: 13,
    lineHeight: '24px',
    letterSpacing: 0,
    textDecoration: 'none',
  }
};
