import React, {useRef, useEffect, useState, useMemo} from 'react';
import {MenuList, MenuItem, Card, Grid, Divider, withStyles} from '@material-ui/core';

import Button from '../../../components/button';
import Category from '../../../components/category';
import ChartDialog from '../add-edit-chart';
import EditPageDetails from '../add-page';
import Header from '../dashboard-details-header';
import Menu from '../../../components/menu';
import WithLoader from '../../../components/with-loader';
import CustomCard from '../../../components/card';
import ChartsIcon from '../../../assets/icons/icon_24_chart_darkGrey.svg';
import CloseIcon from '../../../assets/icons/icon_24_close_blue.svg';
import ContentIcon from '../../../assets/icons/icon_24_content_darkGrey.svg';
import FiltersIcon from '../../../assets/icons/icon_24_filters_darkGrey.svg';
import {useStore} from 'outstated';
import AdminStore from '../../../state/admin';
import AppStore from '../../../state/app';
import styles from './admin-page-edit-styles';
import AdminPageEditStore from '../../../state/admin-page-edit';
import powerBiUtil from '../../../utils/powerBi';
import {LAYOUT_OPTIONS} from '../../../assets/constants';

const AdminPageEdit = props => {
  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);
  const {effects: AppEffects} = useStore(AppStore);
  const [layoutOption, setLayoutOption] = useState(LAYOUT_OPTIONS[0].value);
  const [showChartView, setShowChartView] = useState(true);

  useEffect(() => {}, [showChartView]);

  const dashboard = useMemo(() => props.dashboards[AdminState.activeIndex], [
    props.dashboards,
    AdminState.activeIndex,
  ]);

  const {state: AdminPageEditState, mutations: AdminPageEditMutations, effects: AdminPageEditEffects} = useStore(
    AdminPageEditStore
  );

  // From Admin-container
  const {id} = props;
  const page = dashboard.pages.find(page => page.id === id);
  const reports = (page && page.reports) || [];

  // From Styles
  const {classes} = props;
  const hiddenReport = useRef();

  // Effects
  useEffect(() => {
    setShowChartView(layoutOption === LAYOUT_OPTIONS[0].value);
  }, [layoutOption]);

  // Initial effect
  useEffect(() => {
    AdminPageEditMutations.setChartsList(null);
    AdminPageEditMutations.setFiltersList(null);
    AdminPageEditMutations.setEditPage({status: false, page});
    AdminPageEditEffects.getReports();
    if (page) {
      AdminPageEditMutations.setIsFilterV2(page.reports[0] && page.reports[0].exportDataFilters.length !== 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //// Get list of visuals and filters
  useEffect(() => {
    if (page && reports[0] && (AdminPageEditState.charts.list === null && AdminPageEditState.filters.list === null)) {
      AdminPageEditEffects.getVisualsAndFilters({
        hiddenReport,
        reportID: reports[0].reportID,
        dashboardId: dashboard.id,
      });
    }
    if (page) {
      setLayoutOption(page?.layout?.toString() || LAYOUT_OPTIONS[0].value);
    }
  }, [page, AdminPageEditState.filters.list, AdminPageEditState.charts.list, reports, AdminPageEditEffects, dashboard]);

  // TOOD: Remove isFilterV2
  const dashboardFilters = useMemo(() => {
    if (!page || !reports[0]) return [];
    return AdminPageEditState.filters.isFilterV2 ? reports[0].exportDataFilters : reports[0].filters;
  }, [page, reports, AdminPageEditState.filters.isFilterV2]);

  // Actions
  const onClose = () => {
    if (hiddenReport && hiddenReport.current) {
      powerBiUtil.reset(hiddenReport.current);
    }
    props.onClose();
  };

  const handleDeletePage = async () => {
    try {
      await AdminPageEditEffects.deletePage(page);
      await AppEffects.fetchConfiguration();
      await AdminEffects.getDashboards();
      onClose();
    } catch (e) {}
  };

  const handleAddReport = async reportID => {
    await AdminPageEditEffects.addReport(reportID, page);
  };

  const handleDeleteChart = async chartId => {
    await AdminPageEditEffects.deleteChart(chartId);
  };
  const handleDeleteReportPage = async chartId => {
    await AdminPageEditEffects.deleteReportPage(chartId);
  };
  const handleAddFiltersV1 = async filter => {
    await AdminPageEditEffects.addFiltersV1(page, filter);
  };

  const handleAddFiltersV2 = async filter => {
    await AdminPageEditEffects.addFiltersV2(page, filter);
  };

  const handleDeleteFiltersV1 = async filterIndex => {
    await AdminPageEditEffects.deleteFiltersV1(page, filterIndex);
  };

  const handleDeleteFiltersV2 = async filterIndex => {
    await AdminPageEditEffects.deleteFiltersV2(page, filterIndex);
  };

  const handleAddReportPage = async (reportId, reportPage) => {
    AdminPageEditMutations.setReportPagesMenuElement(null);

    const body = {
      reportPages: [
        {
          name: reportPage.name,
          displayName: reportPage.displayName,
        },
      ],
    };

    await AdminPageEditEffects.updateReportPage(reportId, body);
  };

  const onChangeLayoutOption = async event => {
    const selectedOption = event.currentTarget.value;
    let body = {
      name: page.name,
      url: page.url,
      reports: page.reports.map(r => r.id),
      layout: +selectedOption,
    };
    const response = await AdminEffects.updateLayoutOption(page.id, body);
    if (response) {
      setLayoutOption(`${selectedOption}`);
      await AdminEffects.getDashboards();
    } else {
      setLayoutOption(layoutOption);
    }
    await AdminEffects.getDashboards();

    return;
  };

  const renderLayoutOptionView = classes => {
    return (
      <>
        {LAYOUT_OPTIONS.map((option,index) => (
          <>
            <input
              key={index}
              className={classes.radioSpace}
              checked={layoutOption === option.value}
              type='radio'
              value={option.value}
              name='layoutOption'
              onChange={onChangeLayoutOption}
            />
            <label className={classes.radioLabelSpace}>{option.label}</label>
          </>
        ))}
      </>
    );
  };
  const renderChartView = () => {
    return (
      <>
        <Category
          icon={ChartsIcon}
          title={'Charts'}
          button={'Add chart from report'}
          action={AdminPageEditMutations.openChartDialog}
          loading={
            AdminPageEditState.charts.isLoading || !AdminPageEditState.charts.list || !AdminPageEditState.filters.list
          }
        />
        <ChartDialog
          open={AdminPageEditState.charts.dialog.open}
          onClose={AdminPageEditMutations.closeEditChartDialog}
          visuals={AdminPageEditState.charts.list}
          reportID={reports[0].id}
          pageCharts={reports[0].charts}
          edit={AdminPageEditState.charts.dialog}
        />
        <WithLoader loading={AdminPageEditState.reports.isLoading.saveReports}>
          {reports[0].charts.map(chart => {
            return (
              <CustomCard
                key={chart.id}
                title={chart.title}
                link={chart.subtitle}
                onCardClick={() => AdminPageEditMutations.openEditChartDialog(chart)}
                onButtonClick={() => handleDeleteChart(chart.id)}
                button={'trash'}
              />
            );
          })}
        </WithLoader>
      </>
    );
  };

  const renderPageView = report => {
    const {reportPages} = report;
    const hasReportPage = reportPages.length > 0 ? true : false;

    return (
      <>
        <Category
          icon={ChartsIcon}
          title={'Pages'}
          button={'Add Page from report'}
          disabled={hasReportPage}
          action={e => AdminPageEditMutations.setReportPagesMenuElement(e.target)}
          loading={
            AdminPageEditState.reportPages.isLoading ||
            !AdminPageEditState.reportPages.list ||
            !AdminPageEditState.filters.list
          }
        />
        {!hasReportPage && AdminPageEditState.reportPages?.list?.length ? (
          <Menu
            open={Boolean(AdminPageEditState.reportPages.menuElement)}
            anchorEl={AdminPageEditState.reportPages.menuElement}
            onClickAway={() => AdminPageEditMutations.setReportPagesMenuElement(null)}
            placement={'bottom-end'}
          >
            <MenuList>
              { AdminPageEditState.reportPages?.list?.length && AdminPageEditState.reportPages.list.map((reportPage, index) => {
                return (
                  <MenuItem key={index} onClick={() => handleAddReportPage(report.id, reportPage)}>
                    {reportPage.displayName}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        ) : null }
        {hasReportPage && !AdminPageEditState.reportPages.isLoading && (
          <WithLoader loading={AdminPageEditState.reports.isLoading.saveReports}>
            <CustomCard
              key={reportPages[0].name}
              title={reportPages[0].displayName}
              onButtonClick={() => handleDeleteReportPage(report.id)}
              button={'trash'}
            />
          </WithLoader>
        )}
      </>
    );
  };
  //  Render
  if (dashboard) {
    if (page) {
      return (
        <div className={classes.wrapper}>
          <div ref={hiddenReport} className={classes.hiddenReport} />

          <img src={CloseIcon} alt='Close' className={classes.closeButton} onClick={onClose} />

          <Header
            dashboardID={dashboard.id}
            title={page.name}
            url={`${dashboard.url}/${page.url
              .split('/')
              .slice(-1)
              .toString()}`}
            deleteDashboard={handleDeletePage}
            editDashboard={() => AdminPageEditMutations.setEditPage({status: true, page})}
          />
          <EditPageDetails
            open={AdminPageEditState.editPage.status}
            onClose={() => AdminPageEditMutations.setEditPage({status: false, page})}
            edit={AdminPageEditState.editPage}
            pageId={page.id}
            pageGroup={page.group}
            dashboards={props.dashboards}
          />

          <Category icon={ContentIcon} title={'Content from Power BI'} />

          <Card className={classes.card}>
            <WithLoader loading={AdminPageEditState.reports.isLoading.saveReports}>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                  <p className={classes.heading}>Power BI report for this page</p>
                  <p className={classes.title}>
                    {reports?.length && reports[0] ? reports[0].name : 'No report selected yet'}
                  </p>
                </Grid>
                <Grid item>
                  <Button
                    variant='outlined'
                    loading={AdminPageEditState.reports.isLoading.getReports}
                    disabled={Boolean(AdminPageEditState.reports.menuElement)}
                    onClick={e => AdminPageEditMutations.setReportsMenuElement(e.target)}
                    icon={reports?.length && reports[0] ? 'edit' : 'add'}
                  >
                    {reports?.length && reports[0] ? 'Edit' : 'Add report from PowerBI'}
                  </Button>
                  {AdminPageEditState.reports?.list?.length > 0 && (
                    <Menu
                      open={Boolean(AdminPageEditState.reports.menuElement)}
                      anchorEl={AdminPageEditState.reports.menuElement}
                      onClickAway={() => AdminPageEditMutations.setReportsMenuElement(null)}
                      placement={'bottom-end'} 
                    >
                      <MenuList className='menu-items'>
                        {AdminPageEditState.reports.list.map(report => (
                          <MenuItem className='menu-item' key={report.id} onClick={() => handleAddReport(report.id)}>
                            {report.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}
                </Grid>
              </Grid>
            </WithLoader>
            <Divider className={classes.divider} />
            <Category title={'Layout'} />
            <CustomCard key={'layoutOption'} children={renderLayoutOptionView(classes)} applyHover={false} />
            {reports[0] && (
              <>
                <Category
                  icon={FiltersIcon}
                  title={'Filters'}
                  button={
                    AdminPageEditState.filters.list
                      ? AdminPageEditState.filters.list.length > 0
                        ? 'Add filter from report'
                        : null
                      : ' '
                  }
                  action={e => AdminPageEditMutations.setFiltersMenu(e.target)}
                  disabled={Boolean(AdminPageEditState.filters.menuElement)}
                  loading={
                    AdminPageEditState.charts.isLoading ||
                    AdminPageEditState.filters.isLoading ||
                    !AdminPageEditState.filters.list ||
                    !AdminPageEditState.charts.list
                  }
                />
                {AdminPageEditState.filters.list && AdminPageEditState.filters.list.length > 0 && (
                  <Menu
                    open={Boolean(AdminPageEditState.filters.menuElement)}
                    anchorEl={AdminPageEditState.filters.menuElement}
                    onClickAway={() => AdminPageEditMutations.setFiltersMenu(null)}
                    placement={'bottom-end'}
                  >
                    <MenuList>
                      {/* Remove selected filters from filters selector */}
                      {AdminPageEditState.filters.list.map((filter, index) => {
                        if (
                          dashboardFilters.find(f => {
                            if (f.pbi) {
                              return f.pbi.target.table === filter.table && f.pbi.target.column === filter.column;
                            }
                            return f.target.table === filter.table && f.target.column === filter.column;
                          }) ||
                          (!AdminPageEditState.filters.isFilterV2 &&
                            reports[0].filters.find(f => {
                              return f.target.table === filter.target.table && f.target.column === filter.target.column;
                            }))
                        ) {
                          return null;
                        } else {
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => {
                                AdminPageEditState.filters.isFilterV2
                                  ? handleAddFiltersV2(filter)
                                  : handleAddFiltersV1(filter);
                              }}
                            >
                              {AdminPageEditState.filters.isFilterV2
                                ? `${filter.order}-${filter.label}-${filter.table}${filter.fullColumnArray ? "[" + filter.fullColumnArray.join("][") + "]" : "[" + filter.column + "]"}`
                                : `${filter.target.table}: ${filter.target.column}`}
                            </MenuItem>
                          );
                        }
                      })}
                    </MenuList>
                  </Menu>
                )}
                <WithLoader
                  loading={AdminPageEditState.reports.isLoading.saveReports || AdminPageEditState.filters.isLoading}
                >
                  {/* Display old filters with [Not supported] label if report uses v2 */}
                  {AdminPageEditState.filters.isFilterV2 &&
                    reports[0].filters.length !== 0 &&
                    reports[0].filters.map((filter, index) => (
                      <CustomCard
                        key={index}
                        title={`${filter.target.table}: ${filter.target.column} [Not supported - should be removed]`}
                        onButtonClick={() => handleDeleteFiltersV1(index)}
                        button={'trash'}
                      />
                    ))}
                  {/* Display filters cards (v1 or v2) */}
                  {dashboardFilters?.map((filter, index) => (
                    <CustomCard
                      key={index}
                      title={
                        filter.pbi
                          ? `${filter.order}-${filter.label}-${filter.pbi.target.table}${filter.pbi.target.fullColumnArray ? "[" + filter.pbi.target.fullColumnArray.join("][") + "]" : "[" + filter.pbi.target.column + "]"}`
                          : `${filter.target.table}: ${filter.target.column}`
                      }
                      onButtonClick={() =>
                        AdminPageEditState.filters.isFilterV2
                          ? handleDeleteFiltersV2(index)
                          : handleDeleteFiltersV1(index)
                      }
                      button={'trash'}
                    />
                  ))}
                </WithLoader>
                {showChartView ? renderChartView() : renderPageView(reports[0])}
              </>
            )}
          </Card>
        </div>
      );
    } else {
      props.onClose();
    }
  }

  return null;
};

export default withStyles(styles)(AdminPageEdit);
