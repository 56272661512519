import theme from '../../assets/theme';

export default {
  container: {
    minWidth: '500px',
    boxShadow: 'none',
  },
  containerNarrow: {
    minWidth: '330px',
    maxWidth: '330px',
  },
  button: {
    width: '150px',
    padding: [[theme.spacing(1.75), theme.spacing(1) / 2]],
    borderRadius: theme.spacing(1),
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  noActions: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    maxWidth: '95%',
    wordBreak: 'break-all'
  },
  break: {
    margin: 0,
    border: 'none',
    borderTop: [[1, theme.palette.grey.light, 'solid']],
  },

  titles: {
    flex: '1 1 auto',
    padding: [[theme.spacing(2), 0, theme.spacing(2), theme.spacing(2)]],
  },
  mainTitle: {
    fontSize: 18,
    margin: 0,
  },
  subTitles: {
    color: theme.palette.grey.mid,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    margin: '0 16px 6px',
    fontSize: '14px',
    marginTop: '2px',
    fontWeight: 300
  },
  dialogTitle: {
    flex: '0 0 auto',
    margin: '0 16px -15px',
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogAction: {
    marginRight: 'auto'
  }
};

