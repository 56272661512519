import React from 'react';
import {Grid, withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import Button from '../button';
import styles from './admin-category-styles';

const Category = ({classes, icon, title, button, action, loading, disabled, isShowLabel, className, ...props}) => (
  <Grid
    {...props}
    container
    alignItems='center'
    justify='space-between'
    className={className ? className : classes.container}
  >
    {isShowLabel && (
      <Grid item>
        <Grid container alignItems='center'>
          <Grid item className={classes.icon}>
            <img alt='' aria-hidden src={icon} className={classes.iconImg} />
          </Grid>
          <Grid className={classes.title}>{title}</Grid>
        </Grid>
      </Grid>
    )}

    <Grid item>
      {button && (
        <Button variant='outlined' onClick={action} loading={loading} disabled={disabled} icon='add'>
          {button}
        </Button>
      )}
    </Grid>
  </Grid>
);

Category.propTypes = {
  icon: propTypes.string,
  title: propTypes.string,
  button: propTypes.string,
  action: propTypes.func,
  loading: propTypes.bool,
  disabled: propTypes.bool,
  isShowLabel: propTypes.bool,
};
Category.defaultProps = {
  isShowLabel: true,
};
export default withStyles(styles)(Category);
