import React, {useCallback, useState, useRef} from 'react';
import {withStyles, IconButton} from '@material-ui/core';
import {useStore} from 'outstated';

import Help from '../../../../../assets/icons/icon_24_help_midGrey.svg';
import formatMessage from 'format-message';
import styles from './report-poppover.styles';
import Menu from '../../../../../components/menu';
import CardHeader from '../../../../../components/card/header';
import CloseIcon from '../../../../../assets/icons/icon_24_close_darkGrey.svg';
import DashboardStore from '../../../../../state/dashboard';

const ReportPinHeader = ({classes, onClose, fullTitle}) => {
  return (
    <CardHeader
      title={formatMessage('Chart description')}
      rightButton={
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={CloseIcon}
            alt={formatMessage('Close')}
            title={formatMessage('Close')}
            aria-label={formatMessage('Close')}
          />
        </div>
      }
    />
  );
};

const ReportPoppover = ({classes, description, anchorEl, visualID}) => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);

  const iconRef = useRef();

  const handleClose = useCallback(() => {
    DashboardMutations.setReportTooltip('');
  }, []);

  const handleOpen = useCallback(e => {
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setReportTooltip(visualID);
  }, []);

  return (
    <>
      <IconButton ref={iconRef} className={classes.articleIcon} onMouseEnter={event => handleOpen(event)}>
        <img src={Help} alt='Help' width={24} height={24} />
      </IconButton>
      <Menu
        className={classes.menu}
        open={DashboardState.reportTooltipIndex === visualID}
        placement='bottom-end'
        anchorEl={anchorEl}
        onClickAway={handleClose}
        onMouseLeave={handleClose}
      >
        <div className={classes.container}>
          <ReportPinHeader classes={classes} onClose={handleClose} />
          <p className={classes.info}>
            {description}
          </p>
        </div>
      </Menu>
    </>
  );
};

export default withStyles(styles)(ReportPoppover);
