import theme from '../../../assets/theme';

export default {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100vh',
    padding: '8% 12%',
    zIndex: 1101,
    background: theme.palette.background.paper,
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    top: '4%',
    left: '6%',
    padding: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  divider: {
    margin: [[theme.spacing(1.5), 0]],
  },
  hiddenReport: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
    width: '1px',
    height: '1px',
    opacity: '0',
  },
  card: {
    boxShadow: 'unset',
    border: [[1, 'solid', theme.palette.grey.light]],
    padding: theme.spacing(2),
  },
  heading: {
    color: theme.palette.grey.mid,
    fontSize: 14,
    margin: theme.spacing(1) / 2,
  },
  title: {
    color: theme.palette.grey.dark,
    fontSize: 14,
    margin: theme.spacing(1) / 2,
    fontWeight: 'bold',
  },
  dialog: {
    zInbdex: 9999,
  },
  cardContainer: {
    border: [[1, 'solid', theme.palette.grey.light]],
    boxShadow: 'none',
    margin: [[theme.spacing(2), 0]],
    '&:hover': {
      background: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
    '&:hover $cardDelete': {
      visibility: 'visible',
    },
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
  },
  cardSubtitle: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: 0,
  },
  cardText: {
    flex: '1 0 auto',
    padding: theme.spacing(2),
  },
  cardIcon: {
    padding: theme.spacing(2),
  },
  cardDelete: {
    visibility: 'hidden',
    padding: [[0, theme.spacing(1)]],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  radioLabelSpace: {
    padding: [[0, theme.spacing(2), 0, theme.spacing(0.5)]],
  },
  radioSpace: {
    padding: [[theme.spacing(1), theme.spacing(1), 0]],
  }
};
