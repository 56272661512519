import React from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import {Button, MenuItem, Divider, withStyles} from '@material-ui/core';
import Menu from '../../../components/menu';
import {ReactComponent as MoreIcon} from '../../../assets/icons/icon_24_more_blue.svg';
import styles from './help-table-row-actions-styles';
import {NOTIFICATION_STATUS} from '../../../assets/constants';

const addPublishMenuItem = (status, handlePublish, handleUnpublish) => {
  switch (status) {
    case NOTIFICATION_STATUS.PUBLISHED:
      return <MenuItem onClick={handleUnpublish}>{formatMessage('Unpublish')}</MenuItem>;
    case NOTIFICATION_STATUS.UNPUBLISHED:
      return <MenuItem onClick={handlePublish}>{formatMessage('Publish')}</MenuItem>;
    // invalid
    default:
      return null;
  }
};

const HelpTableActions = ({classes, status, onPublishClick, onUnpublishClick, onEditClick, onDeleteClick}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (event) event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
  };

  const handlePublish = event => {
    onPublishClick();
    handleClose(event);
  };

  const handleUnpublish = event => {
    onUnpublishClick();
    handleClose(event);
  };

  const handleEdit = event => {
    onEditClick();
    handleClose(event);
  };

  const handleDelete = event => {
    onDeleteClick();
    handleClose(event);
  };

  return (
    <>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} placement='bottom-end' open={Boolean(anchorEl)} onClickAway={handleClose}>
        <MenuItem onClick={handleEdit}>{formatMessage('Edit')}</MenuItem>
        <Divider className={classes.divider} />
        <MenuItem onClick={handleDelete}>{formatMessage('Delete')}</MenuItem>
        <Divider className={classes.divider} />
        {addPublishMenuItem(status, handlePublish, handleUnpublish)}
      </Menu>
    </>
  );
};

HelpTableActions.propTypes = {
  classes: propTypes.object.isRequired,
  status: propTypes.string.isRequired,
  onPublishClick: propTypes.func.isRequired,
  onUnpublishClick: propTypes.func.isRequired,
  onEditClick: propTypes.func.isRequired,
  onDeleteClick: propTypes.func.isRequired,
};

export default withStyles(styles)(HelpTableActions);
