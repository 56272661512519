import React from 'react';
import {Redirect} from 'react-router';
import {useStore} from 'outstated';
import {withStyles} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import texts from '../../assets/texts';
import LoadingSpinner from '../../components/loading-spinner';
import LoggingForm from './form';

import logo from '../../assets/images/seabury-logo-dark.svg';
import notificationIcon from '../../assets/icons/ic-notifications.svg';
import AppStore from '../../state/app';
import {NotificationsMailboxStore} from '../../state/notifications/store';

import styles from './login-styles';
import {Routes} from '../../Routes';
import history from '../../utils/history';
import LoginPinNotification from './pin-notification';

const Login = props => {
  // From Styles
  const {classes, location} = props;
  const redirectUrl = (location && location.state && location.state.pathname) || Routes.ROOT;
  const {state: AppState, mutations: AppMutations} = useStore(AppStore);
  const {state: MailboxState} = useStore(NotificationsMailboxStore);
  const {announcement} = MailboxState;

  const handleHomeClick = () => {
    history.push(Routes.LANDING);
  };
  const handleTermsClick = () => {
    history.push(Routes.TERMS);
  };
  

  // Render
  return (
    <>
      {!AppState.initialized && <LoadingSpinner />}
      {AppState.initialized && AppState.token !== null && <Redirect to={redirectUrl} />}
      {AppState.initialized && AppState.token === null && (
        <div className={classes.wrapper}>
          <div maxWidth={'xl'} className={classes.loginSection}>
            <Grid container direction='row' spacing={0} style={{height: '100vh'}}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className={classes.leftContainer}>
                  <div className={classes.firstHalf}>
                    <Container maxWidth='xl'>
                      <header className={classes.header}>
                        <img src={logo} alt={texts.company} className={classes.logo} onClick={handleHomeClick} />
                      </header>
                    </Container>
                  </div>
                  <div className={classes.secondHalf}>
                    <Container maxWidth='xl'>
                      <div className={classes.notificationSection}>
                        {announcement && (
                          <img src={notificationIcon} alt={'Notificationns'} className={classes.notificationIcon} />
                        )}
                        {announcement && <p className={classes.notification}>NOTIFICATION</p>}
                      </div>
                      <div className={classes.content}>
                        <LoginPinNotification />
                      </div>
                    </Container>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className={classes.leftContainer}>
                  <div className={classes.loginFormArea}>
                    {/* <Container maxWidth='xl' className={classes.loginForm}> */}
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className={classes.loginHeader}>
                      <div className={classes.signup}>
                        <p className={classes.signupText}>
                          <span className={classes.noAccounntYet}>No account yet?</span>
                          <a className={classes.getInTouch} href="/home#contact"> Get in touch</a>
                        </p>
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className={classes.loginContent}>
                      <section className={classes.rightSection}>
                        <div className={classes.form}>
                          <LoggingForm authenticate={AppMutations.setDashboardToken} redirectUrl={redirectUrl} />
                        </div>
                      </section>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={classes.signup}>
                        <p className={classes.items}>
                          <span className={classes.item}>Accenture {new Date().getFullYear()}</span>
                          <span className={classes.item} onClick={handleTermsClick}>Terms & conditions</span>
                        </p>
                      </div>
                    </Grid>
                    {/* </Container> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* Mobile View */}
          <section className={classes.mobileView}>
            <Container maxWidth='xs'>
              <h6 className={classes.mobTitle}>Login not available on mobile</h6>
              <p className={classes.mobContent}>
                The Cargo portal is not optimised for mobile devices. Please use a desktop or laptop computer to login
                to the Cargo portal.
              </p>
              <Button color='inherit' className={classes.mobHomeButton} onClick={handleHomeClick}>
                Ok got it
              </Button>
              <p className={classes.mobText}>BACK TO HOME</p>
            </Container>
          </section>
          {/* Mobile View */}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Login);
