import React, {useEffect, useState} from 'react';
import Dialog from './admin-add-dashboard-view';
import {useStore} from 'outstated';
import AdminStore from '../../../state/admin';
import {useMemo} from 'react';

const AdminAddDashboard = ({edit, onClose, filteredDashboards, ...props}) => {
  const {state: AdminState, mutations: AdminMutations, effects: AdminEffects} = useStore(AdminStore);

  const {
    currentDashboard: {name, url, weightage, selfChild, dropDownName, error, filled},
  } = AdminState;

  const [dashboardsURL, activeDashboard] = useMemo(() => {
    return [filteredDashboards.map(d => d.url), filteredDashboards[AdminState.activeIndex]];
  }, [filteredDashboards, AdminState.activeIndex]);

  const [groupsMenuAnchorEl, setGroupsMenuAnchorEl] = useState(null);
  const [dashboardChildren, setDashboardChildren] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [resourceType, setResourceType] = useState('');

  let weightList = []; // weightage should be total dashboard count plus one
  if (filteredDashboards.length) {
    for (let index = 1; index <= filteredDashboards.length+50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  } else {
    for (let index = 1; index <= 50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  }


  useEffect(() => {
    if (AdminState.tabTitle === 'Dashboards') {
      setResourceType('Dashboard');
    } else if (AdminState.tabTitle === 'Datasets') {
      setResourceType('Dataset');
    }
    
    const dashboards = filteredDashboards.filter(
      dashboard => activeDashboard?.id !== dashboard.id && !dashboard.children.length
    );

    setDashboards(dashboards);
    if (edit && activeDashboard) {
      AdminMutations.setCurrentDashboard({
        name: activeDashboard.name,
        url: activeDashboard.url,
        weightage: activeDashboard.weightage,
        selfChild: activeDashboard.selfChild,
        dropDownName: activeDashboard.dropDownName,
      });
      const dashboardChildren = activeDashboard.children.filter(dashboardId => dashboardId !== activeDashboard.id);
      const children = dashboards.filter(dashboard => {
        const dasboard = dashboardChildren.find(dashboardId => dashboard.id === dashboardId);
        if (dasboard) {
          return {
            id: dasboard.id,
            name: dasboard.name,
          };
        }
        return false;
      });

      setDashboardChildren(children);
    } else {
      AdminMutations.setCurrentDashboard({
        name: '',
        url: '',
        weightage: null,
        selfChild: false,
        dropDownName: '',
      });
      setDashboardChildren([]);
    }
  }, [activeDashboard, edit, AdminMutations, AdminState.dashboards, AdminState.tabTitle, filteredDashboards]);

  const addDashboard = async dashboard => {
    setGroupsMenuAnchorEl(null);
    const children = [
      ...dashboardChildren,
      {
        id: dashboard.id,
        name: dashboard.name,
      },
    ];
    setDashboards(dashboards.filter(item => item.id !== dashboard.id));
    setDashboardChildren(children);
  };

  const removeDashboard = async selectedId => {
    const dashboard = filteredDashboards?.find(dashboard => dashboard.id === selectedId);
    setDashboards([dashboard]);
    setDashboardChildren(dashboardChildren.filter(dashboard => dashboard.id !== selectedId));
  };
  const onCloseClick = () => {
    AdminMutations.setCurrentDashboard({
      name: '',
      url: '',
      weightage: null,
      selfChild: false,
      dropDownName: '',
    });
    setDashboardChildren([]);
    onClose();
  };
  const change = e => {
    const field = e.target.name;

    if (field === 'name') AdminMutations.setCurrentDashboard({name: e.target.value});
    if (field === 'selfChild') AdminMutations.setCurrentDashboard({selfChild: e.target.checked});
    if (field === 'dropDownName') AdminMutations.setCurrentDashboard({dropDownName: e.target.value});
    if(field === undefined && e.target.value) AdminMutations.setCurrentDashboard({weightage: e.target.value});


    if (field === 'url') {
      const value = e.target.value.split('/');
      if (value[1]) {
        const url = value[1];
        if (/^([a-zA-Z0-9-])*$/.test(url)) {
          AdminMutations.setCurrentDashboard({url});
        }
        if (dashboardsURL.includes(url) && !(edit && url === activeDashboard.url)) {
          AdminMutations.setCurrentDashboard({error: true});
        } else {
          AdminMutations.setCurrentDashboard({error: false});
        }
      } else {
        AdminMutations.setCurrentDashboard({url: ''});
      }
    }
  };

  useEffect(() => {
    if (url.length > 0 && name.length > 0 && weightage && !error) {
      AdminMutations.setCurrentDashboard({filled: true});
    } else {
      AdminMutations.setCurrentDashboard({filled: false});
    }
  }, [url, name, weightage, dropDownName, error, AdminMutations]);

  const save = async () => {
    const children = dashboardChildren.map(children => children.id);
    await AdminEffects.createDashboard({name, url,weightage, children, selfChild, dropDownName, resourceType});
    await AdminEffects.getDashboards();
    AdminMutations.setActiveIndex(dashboardsURL.length);
    onClose();
  };

  const patch = async () => {
    const children = dashboardChildren.map(children => children.id);
    await AdminEffects.editDashboard({activeDashboard, name, url,weightage, children, selfChild, dropDownName});
    await AdminEffects.getDashboards();
    onClose();
  };

  return (
    <Dialog
      {...props}
      name={name}
      url={url}
      weightage={weightage}
      selfChild={selfChild}
      dropDownName={dropDownName}
      error={error}
      filled={filled}
      edit={edit}
      change={change}
      save={edit && activeDashboard ? patch : save}
      onCloseClick={onCloseClick}
      groupsMenuAnchorEl={groupsMenuAnchorEl}
      setGroupsMenuAnchorEl={setGroupsMenuAnchorEl}
      dashboards={dashboards}
      addDashboard={addDashboard}
      removeDashboard={removeDashboard}
      dashboardChildren={dashboardChildren}
      weightList={weightList}
      tabTitle={AdminState.tabTitle}
    />
  );
};

export default AdminAddDashboard;
