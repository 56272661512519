import React from 'react';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core';

import Category from '../../../components/category';
import Dialog from '../../../components/dialog';
import TextField from '../../../components/form/fields/text-field';
import styles from './admin-add-edit-chart-styles';

import LayoutIcon from '../../../assets/icons/icon_24_chartLayout_darkGrey.svg';

import {
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import texts from '../../../assets/texts';

const ChartDialog = props => {
  // From Styles
  const {classes} = props;

  // State from Container
  const {open, edit, visuals, visual, title, subtitle, tooltips, layout, filled} = props;

  // Actions from Container
  const {onClose, onSave, handleChange} = props;

  return (
    <Dialog
      open={open}
      title={'Add chart from report'}
      onClose={onClose}
      button={edit.status ? texts.editing.updateButton : texts.editing.createButton}
      action={onSave}
      disabled={!filled}
    >
      <FormControl className={classes.select} fullWidth variant='outlined' margin='normal'>
        <InputLabel>Chart</InputLabel>
        <Select
          value={visual ? visual.name : ''}
          onChange={e => handleChange(e)}
          input={<OutlinedInput classes={{notchedOutline: classes.selectInputFilled}} name='visual' labelWidth={40} />}
        >
          {visuals &&
            visuals.map(visual => (
              <MenuItem key={visual.name} value={visual.name}>
                {visual.title ? visual.title : visual.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField label='Title' value={title} handleChange={handleChange} />

      <TextField label='Subtitle' value={subtitle} handleChange={handleChange} />

      <TextField
        label='Extra info'
        value={tooltips}
        handleChange={handleChange}
        extra={'Add info to help the user understand the chart'}
      />

      <Category title='Layout' icon={LayoutIcon} style={{marginTop: 0}} />

      <RadioGroup defaultValue={layout ? '1' : ''} name='layout' onChange={handleChange}>
        <FormControlLabel value={''} control={<Radio className={classes.radio} color='primary' />} label='One column' />
        <FormControlLabel
          value={'1'}
          control={<Radio className={classes.radio} color='primary' />}
          label='Two columns'
        />
      </RadioGroup>
    </Dialog>
  );
};

ChartDialog.propTypes = {
  open: propTypes.bool,
  edit: propTypes.object,
  visuals: propTypes.array,
  visual: propTypes.object,
  title: propTypes.string,
  subtitle: propTypes.string,
  layout: propTypes.bool,
  filled: propTypes.bool,
};

export default withStyles(styles)(ChartDialog);
