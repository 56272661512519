import React from 'react';
import HelpTableActions from './help-section-table-row-actions';
import formatMessage from 'format-message';

export default ({onDeleteClick, onEditClick}) => [
  {
    id: 'dateCreated',
    Header: formatMessage('Date created'),
    accessor: 'dateCreated',
  },
  {
    id: 'section',
    Header: formatMessage('Section'),
    accessor: 'section',
  },
  {
    id: 'author',
    Header: formatMessage('Author'),
    accessor: 'author.name',
  },
  {
    accessor: 'actions',
    Cell: cellInfo => {
      const section = cellInfo.row.original;
      return (
        <HelpTableActions
          notificationId={section._id}
          onEditClick={() => onEditClick(section)}
          onDeleteClick={() => onDeleteClick(section._id, section.status)}
        />
      );
    },
  },
];
