import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {NotificationRoute} from './navigation';
import NotificationEdit from './edit';
import NotificationList from './list';
import {Routes} from '../../Routes';
import {withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import styles from './notifications-styles';
import Footer from '../../components/footer/footer-view';

const NotificationContainer = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Switch>
        <NotificationRoute exact path={Routes.ADMIN.NOTIFICATIONS} Component={NotificationList} />
        <NotificationRoute exact path={Routes.ADMIN.NOTIFICATIONS_NEW} Component={NotificationEdit} />
        <NotificationRoute exact path={Routes.ADMIN.NOTIFICATIONS_EDIT} Component={NotificationEdit} />
        <Redirect to={Routes.ADMIN.NOTIFICATIONS} />
      </Switch>
      <Footer />
    </div>
  );
};

NotificationContainer.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(NotificationContainer);
