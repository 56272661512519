import theme from '../../assets/theme';

export default {
  menu: {
    marginTop: theme.spacing(1),
    border: [[1, 'solid', theme.palette.grey.light]],
  },
  filterMenu: {
    border: [[1, 'solid', theme.palette.grey.light]],
  },
  menuItems: {
    padding: 0,
  },
  menuItem: {
    position: 'relative',
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.grey.veryLight,
    },
    '&:first-of-type': {
      borderRadius: [[theme.spacing(1) / 2, theme.spacing(1) / 2, 0, 0]],
    },
    '&:last-of-type': {
      borderRadius: [[0, 0, theme.spacing(1) / 2, theme.spacing(1) / 2]],
    },
    '&:only-child': {
      borderRadius: theme.spacing(1) / 2,
    },
    '&:last-child hr': {
      display: 'none',
    },

    '&.MuiSvgIcon-root': {
      display: 'none'
    }
  },
};
