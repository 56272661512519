import React from 'react';
import propTypes from 'prop-types';

import LoadingSpinner from '../loading-spinner';
import {withStyles} from '@material-ui/core';
import styles from './with-loader-styles';

const WithLoader = ({classes, loading, children}) => {
  if (loading)
    return (
      <div className={classes.container}>
        <div className={classes.background} />
        <LoadingSpinner />
        <div className={classes.content}>{children}</div>
      </div>
    );

  return children;
};

WithLoader.propTypes = {
  loading: propTypes.bool.isRequired,
  children: propTypes.any,
};

export default withStyles(styles)(WithLoader);
