import React, {useState} from 'react';
import {withStyles, List, ListItem, ListItemIcon, ListItemText, Paper} from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import formatMessage from 'format-message';
import {useStore} from 'outstated';

import DashboardStore from '../../state/dashboard';
import Menu from '../../components/menu';
import CardHeader from '../../components/card/header';
import CloseIcon from '../../assets/icons/icon_24_close_darkGrey.svg';
import styles from './pinto-article-box-styles';
import Help from '../../assets/icons/icon_24_help_midGrey.svg';
import {PinArticleStore, HelpAdminStore} from '../../state/help/store';
import ArticleDetailsDialog from '../../sections/help/viewer/article-dialog/article-detail-view';
import {getDashboardDetail} from '../../sections/Helper';

const ArticlePinHeader = ({classes, onClose, title}) => {
  return (
    <CardHeader
      title={formatMessage(title)}
      rightButton={
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={CloseIcon}
            alt={formatMessage('Close')}
            title={formatMessage('Close')}
            aria-label={formatMessage('Close')}
          />
        </div>
      }
    />
  );
};

const PintoArticleBox = ({classes, type}) => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);
  const {state: PinArticlaState} = useStore(PinArticleStore);
  const {state: ArticleState, mutations: ArticlesMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const {isOpen, article, articleList, isLoadMore, backButton,fromPinTo} = ArticleState;

  
  const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');
  let dashboardName = '';
  let pageName = '';
  if (dashboard) {
    dashboardName = dashboard.name;
    const page = dashboard.pages.find(p => p.id === DashboardState.current.activePage);

    if (page && page.reports && page.reports.length > 0) {
      pageName = page.name;
    }
  }

  const {pintoStatus} = DashboardState;

  const [anchorEl, setAnchorEl] = React.useState(null);

  let open = Boolean(anchorEl);

  
  const handleMouseEnter = event => {    
    setAnchorEl(null);
    DashboardMutations.setPintoOpenStatus({
      pageHeader: type === 'pageHeader' ? true : false,
      pageFooter: type === 'pageFooter' ? true : false,
    });
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
    setAnchorEl(null);
  };
  const [isClicked, setClickState] = useState(false);

  const handleClick = async value => {
    setAnchorEl(null);
    const result = await ArticleEffects.getArticleDetails(value._id);
    if (result) {
      ArticlesMutations.setArticleDialogValue({
        isOpen: true,
        article: result,
        isLoadMore: false,
        backButton: false,
        fromPinTo: true
      });
    }
    setClickState(true);    
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
  };

  // Render
  return (
    <>
      <img
        className={classes.helpPinTo}
        src={Help}
        alt={formatMessage('Help article')}
        aria-label={formatMessage('Help article')}
        onMouseEnter={handleMouseEnter}
      />

      {/* Article List */}
      {PinArticlaState && PinArticlaState?.articleArray.length && (
        <Menu
          className={classes.menu}
          open={(type === 'pageHeader' && pintoStatus.pageHeader) || type === 'pageFooter' && pintoStatus.pageFooter}
          placement='bottom-end'
          anchorEl={anchorEl}
          onClickAway={handleClose}
          onMouseLeave={handleClose}
        >
          <div className={classes.listContainer}>
            <ArticlePinHeader classes={classes} onClose={handleClose} title={'Related help articles'} />
            <Paper className={classes.paper}>
              <div className={classes.listWrap}>
                <List component='nav' aria-label='main mailbox folders'>
                  {type === 'pageHeader' && PinArticlaState?.articleArray.filter(res => res.details.pintTo === 'PAGE_HEADER' && res.pinTo.location.split('/').slice(0, -1).join('/') === `${dashboardName}/${pageName}`).map((val, index) => (
                    <ListItem
                      key={index}
                      className={classes.listItem}
                      button
                      onClick={() => {
                        handleClick(val.details, false);
                      }}
                      title={val.details.hoverTitle}
                    >
                      <ListItemIcon>
                        <AssignmentOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={val.details.shortTitle} />
                    </ListItem>
                  ))}
                  {type === 'pageFooter' && PinArticlaState?.articleArray.filter(res => res.details.pintTo === 'PAGE_FOOTER' && res.pinTo.location.split('/').slice(0, -1).join('/') === `${dashboardName}/${pageName}`).map((val, index) => (
                    <ListItem
                      key={index}
                      className={classes.listItem}
                      button
                      onClick={() => {
                        handleClick(val.details, false);
                      }}
                      title={val.details.hoverTitle}
                    >
                      <ListItemIcon>
                        <AssignmentOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={val.details.shortTitle} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Paper>
          </div>
        </Menu>
      )}

      {isClicked && <ArticleDetailsDialog
        open={isOpen}
        isOpen={isOpen}
        article={article}
        isLoadMore={isLoadMore}
        backButton={backButton}
        articleList={articleList}
        fromPinTo={fromPinTo}
      /> }
    </>
  );
};

export default withStyles(styles)(PintoArticleBox);
