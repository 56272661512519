import React from 'react';
import formatMessage from 'format-message';
import {Button, Dialog, DialogActions, DialogTitle, DialogContent, withStyles} from '@material-ui/core';
import Close from '../../assets/icons/icon_24_close_blue.svg';
import Form from '../../components/form';
import styles from './dialog-styles';

const CustomFormDialog = ({
  classes,
  initialValues,
  children,
  open,
  title,
  onClose,
  button,
  onSubmit,
  computeDisabled,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth='sm' classes={{paperWidthSm: classes.container}}>
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({...formState}) => (
        <>
          <DialogTitle disableTypography>
            <h4>{title}</h4>
            <img alt='Close' src={Close} onClick={onClose} />
          </DialogTitle>
          <DialogContent className={classes.content}>{children}</DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={onClose} variant='outlined' color='primary'>
              {formatMessage('Cancel')}
            </Button>
            {button && (
              <Button
                className={classes.button}
                color='primary'
                type='submit'
                variant='contained'
                autoFocus
                disabled={computeDisabled(formState)}
              >
                {button}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Form>
  </Dialog>
);

export default withStyles(styles)(CustomFormDialog);
