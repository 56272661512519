import React, {useEffect} from 'react';
import {Redirect, Router, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import {withStyles} from '@material-ui/core';
import {useStore} from 'outstated';
import queryString from 'query-string';

import Authentication from './authentication';
import history from '../utils/history';
import LoggingPage from '../routes/login';
import LandingPage from '../routes/landing';
import TermsAndConditionsPage from '../routes/terms-conditions';
import AnnouncementsPage from '../routes/announcements';

import Admin from '../routes/admin';
import Dashboard from '../routes/dashboard';
import Error404 from '../routes/errors/404';
import LoadingSpinner from '../components/loading-spinner';
import AppStore from '../state/app';
import styles from './app-styles';
import NotificationContainer from '../sections/notification/notification-container';
import HelpContainer from '../sections/help/help-container';
import HelpSectionContainer from '../sections/help-sections/help-section-container';
import NotificationViewerContainer from '../sections/notification/viewer/notification-viewer-container';
import HelpViewerContainer from '../sections/help/viewer/help-viewer-container';
import {Routes} from '../Routes';
import {decodeTokenState} from '../utils/sso-uri';
import DashboardStore from '../state/dashboard';
import {HIDE_NOTIFICATION_SECTION} from '../assets/constants';

// @TODO: Move logic of checking if a user is valid and/or admin to here.
// @TODO: Move logic of fetching dashboard to here when a user is logged in, clear it when logging out.

axios.interceptors.request.use(
  function(config) {
    if (config.headers.authorization === 'bearer null') {
      return Promise.reject();
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response && error.response.status === 401 && !error.response.config.url.endsWith('/api/auth')) {
      localStorage.clear();
      window.location.href = Routes.LANDING;
    }

    return Promise.reject(error);
  }
);



const App = () => {
  const {state: AppState, effects: AppEffects} = useStore(AppStore);
  const {effects: DashboardEffects, state: DashboardState} = useStore(DashboardStore);
  
  useEffect(() => { 
      if (
        DashboardState.current.activeDashboard &&
        DashboardState.current.activePage &&
        AppState.token &&
        !HIDE_NOTIFICATION_SECTION
      ) {
        if (process.env.NODE_ENV === 'production') {
          const isAdminPages = window.location.pathname.split('/').slice(1,2).join('/');
          if (isAdminPages !== 'admin') {
            ReactGA.set({page: window.location.pathname});
            ReactGA.pageview(window.location.pathname);
          }
        }
      }
  }, [DashboardState.current.activeDashboard, DashboardState.current.activePage]);


  useEffect(() => {
    if (!AppState.initialized) {
      AppEffects.fetchConfiguration();
      const {search} = history.location;
      const searchString = sessionStorage.getItem('fullScreenView') || search;
      const fullScreenView = searchString?.includes('fullScreenView=true');

      if (fullScreenView) {
        sessionStorage.setItem('fullScreenView', searchString);
        const dashboardId = searchString.substr(searchString?.indexOf('id=') + 3);
        DashboardEffects.updateWindowView(fullScreenView, dashboardId);
      }
    }
  }, [AppState.initialized, AppEffects, DashboardEffects]);

  return (
    <>
      {!AppState.initialized && <LoadingSpinner />}
      {AppState.initialized && (
        <Router history={history}>
          <Switch>
            <Route exact path={Routes.LOGIN} component={LoggingPage} />
            <Route exact path={Routes.LANDING} component={LandingPage} />
            <Route exact path={Routes.TERMS} component={TermsAndConditionsPage} />
            <Route exact path={Routes.ANNOUNCEMENTS} component={AnnouncementsPage} />

            <Route
              exact
              path={Routes.MS_RETURN_TOKEN}
              render={() => {
                const hash = queryString.parse(history.location.hash);
                const {redirectUrl} = decodeTokenState(hash.state);
                return <Authentication component={<Redirect to={redirectUrl || Routes.ROOT} />} />;
              }}
            />
            <Route
              exact
              path={[Routes.NOTIFICATIONS, Routes.NOTIFICATIONS_DETAILS]}
              render={() => <Authentication component={<NotificationViewerContainer />} />}
            />
            <Route
              path={Routes.ADMIN.NOTIFICATIONS}
              render={() => <Authentication isAdmin component={<NotificationContainer />} />}
            />
            <Route path={[Routes.HELP, Routes.HELP_DETAILS]} render={() => <Authentication component={<HelpViewerContainer />} />} />

            <Route path={Routes.ADMIN.HELP} render={() => <Authentication isAdmin component={<HelpContainer />} />} />
            <Route
              path={Routes.ADMIN.SECTIONS}
              render={() => <Authentication isAdmin component={<HelpSectionContainer />} />}
            />
            <Route exact path={Routes.ADMIN.ROOT} render={() => <Authentication isAdmin component={<Admin />} />} />
            <Route exact path={AppState.dashboardRoutes} render={() => <Authentication component={<Dashboard />} />} />
            <Route exact path={Routes.ROOT} render={() => <Authentication component={<Dashboard />} />} />
            <Route component={Error404} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default withStyles(styles)(App);
