import formatMessage from 'format-message';

export const AllOption = {
  id: 'all',
  label: formatMessage('All'),
  value: 'ALL',
};

export const StatusOptions = [
  AllOption,
  {
    id: 'published',
    label: formatMessage('Published'),
    value: 'PUBLISHED',
  },
  {
    id: 'unpublished',
    label: formatMessage('Unpublished'),
    value: 'UNPUBLISHED',
  },
];
export const AdminTypeOptions = [
  AllOption,
  {
    id: 'new',
    label: formatMessage('News'),
    value: 'NEWS',
  },
  {
    id: 'data_update',
    label: formatMessage('Data update'),
    value: 'DATA_UPDATE',
  },
  {
    id: 'priority_data_update',
    label: formatMessage('Priority data update'),
    value: 'PRIORITY_DATA_UPDATE',
  },
  {
    id: 'insight',
    label: formatMessage('Insight'),
    value: 'INSIGHT',
  },
  {
    id: 'announcement',
    label: formatMessage('Announcement'),
    value: 'ANNOUNCEMENT',
  },
];

export const UserTypeOptions = [
  AllOption,
  {
    id: 'new',
    label: formatMessage('News'),
    value: 'NEWS',
  },
  {
    id: 'data_update',
    label: formatMessage('Data update'),
    value: 'DATA_UPDATE',
  },
];

export const VALID_TYPES = ['ALL', 'NEWS', 'ANNOUNCEMENT', 'DATA_UPDATE', 'INSIGHT', 'PRIORITY_DATA_UPDATE'];
export const VALID_STATUSES = ['ALL', 'PUBLISHED', 'UNPUBLISHED'];
export const PINTO_TYPES = ['VISUAL', 'PAGE_HEADER', 'PAGE_FOOTER', 'SITE_FOOTER'];

export const OLD_NOTIFICATION_AGE = 13; // 14 days
export const MAX_NOTIFICATIONS_MAILBOX = 7;
export const USER_LIST_TYPES = ['NEWS', 'DATA_UPDATE', 'PRIORITY_DATA_UPDATE'];
export const NOTIFICATION_WITH_AUDIENCE_LABELS = [
  formatMessage('Priority data update'),
  formatMessage('Data update'),
  formatMessage('News'),
];
