import theme from '../assets/theme';
import 'typeface-roboto';

export default {
  '@global': {
    '#root': {
      display: 'flex',
      flexFlow: 'column nowrap',
      minHeight: '100vh',
    },
    body: {
      fontFamily: ['Roboto', 'sans-serif'],
      color: theme.palette.text.primary,
    },
    img: {
      maxWidth: '100%',
    },
    '.disable': {
      pointerEvents: 'none',
    },
    '.divider': {
      position: 'absolute',
      bottom: '0',
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    '.menu-items': {
      padding: 0,
      minWidth: 200,
      maxHeight: '60vh',
      overflow: 'auto',
    },
    '.menu-item': {
      position: 'relative',
      padding: [[theme.spacing(1.5), theme.spacing(2)]],
      '&:hover': {
        background: theme.palette.grey.veryLight,
      },
      '&:first-of-type': {
        borderRadius: [[theme.spacing(1) / 2, theme.spacing(1) / 2, 0, 0]],
      },
      '&:last-of-type': {
        borderRadius: [[0, 0, theme.spacing(1) / 2, theme.spacing(1) / 2]],
      },
      '&:only-child': {
        borderRadius: theme.spacing(1) / 2,
      },
      '&:last-child hr': {
        display: 'none',
      },
    },
    

    '.announcement-page .ql-container.ql-snow': {
      border: 'none',
      paddingLeft: 0
    },
    '.announcement-page .ql-container.ql-snow .ql-editor': {
      paddingLeft: 0
    }

   
  },
};
