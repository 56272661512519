import SeaburyAxios, {AXIOS_REQUEST_CANCELED} from './SeaburyAxios';
import _pick from 'lodash/pick';
import qs from 'query-string';
import Axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import formatMessage from 'format-message';
import {
  helpArticleUrl,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE_ADMIN_LABELS,
  apiURL,
} from '../assets/constants';

import logger from '../utils/logger';

const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: 'short', day: '2-digit'});

let cancelToken;

const transformArticles = items => {
  return items.map(article => transformArticle(article));
};

const transformArticle = article => ({
  ...article,
  dateCreated: dateTimeFormat.format(new Date(article.createdAt)),
  datePublished: dateTimeFormat.format(new Date(article.publishedAt)),
  status: NOTIFICATION_STATUS[article.status],
  typeAdminLabel: NOTIFICATION_TYPE_ADMIN_LABELS[article.type],
});

const paramsSerializer = params => {
  const finalParams = Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
  return qs.stringify(finalParams);
};

const transformArticleToSend = article => {
  const articleToSend = _pick(article, ['fullTitle', 'shortTitle','hoverTitle', 'section', 'weightage', 'status', 'audience', 'message', 'pintTo', 'pinToInfo']);
  return {
    ...articleToSend,
    status: Object.keys(NOTIFICATION_STATUS).find(key => NOTIFICATION_STATUS[key] === articleToSend.status),
  };
};

const transformSectionList = sections => {
  let sectionList = [];
  let sectionFilterList = [];
  sections.docs.map(element => {
    sectionList.push({
      value : element.section,
      text : element.section,
      label:element.section,
      id:element.section
    })
  });

  const AllOption = {
    id: 'all',
    label: formatMessage('All'),
    value: 'ALL',
    text: formatMessage('All'),
  };

  sectionFilterList = cloneDeep(sectionList);
  sectionFilterList.unshift(AllOption);
  const sectionArray = {
    sectionFilterList: sectionFilterList,
    sectionList: sectionList
  }
  return sectionArray;
}

const transformSections = items => {
  return items.map(article => transformSection(article));
};

const transformSection = sction => ({
  ...sction,
  dateCreated: dateTimeFormat.format(new Date(sction.createdAt))
});

const HelpArticleService = {
  getArticles: async params => {
    const {data} = await SeaburyAxios.get(helpArticleUrl, {
      params,
      paramsSerializer,
    });
    return {
      ...data,
      docs: transformArticles(data.docs),
    };
  },
  searchArticles: async params => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = Axios.CancelToken.source();
      const {data} = await SeaburyAxios.get(helpArticleUrl, {
        params,
        cancelToken: cancelToken.token,
        paramsSerializer,
      });
      return {
        ...data,
        docs: transformArticles(data.docs),
      };
    } catch (error) {
      if (Axios.isCancel(error)) {
        const errorToThrow = {key: AXIOS_REQUEST_CANCELED};
        throw errorToThrow;
      } else {
        throw error;
      }
    }
  },
  createArticle: async body => {
    const article = transformArticleToSend(body);
    const {data} = await SeaburyAxios.post(helpArticleUrl, article);

    return transformArticle(data);
  },
  updateArticle: async (articleId, body) => {
    const article = transformArticleToSend(body);

    const {data} = await SeaburyAxios.put(`${helpArticleUrl}/${articleId}`, article);

    return transformArticle(data);
  },
  getArticleById: async id => {
    const {data} = await SeaburyAxios.get(`${helpArticleUrl}/${id}`);
    return transformArticle(data);
  },
  createSection: async body => {

    const {data} = await SeaburyAxios.post(`${helpArticleUrl}/section`, body);

    return transformSection(data);
  },

  updateSection: async (body, id) => {

    const {data} = await SeaburyAxios.put(`${helpArticleUrl}/section/${id}`, body);

    return transformSection(data);
  },

  deleteArticle: async articleId => {
    const {data} = await SeaburyAxios.delete(`${helpArticleUrl}/${articleId}`);
    return data;
  },

  getSectionList: async () => {
    try {
      const limit = 500; // Currently using 500 as the limit in databasae
      const {data} = await SeaburyAxios.get(`${helpArticleUrl}/section?limit=${limit}`);
      return transformSectionList(data);
    } catch (err) {
      logger.warn(err);
      throw err;
    }
  },
  getSections: async params => {
    const {data} = await SeaburyAxios.get(`${helpArticleUrl}/section`, {
      params,
      paramsSerializer,
    });
    return {
      ...data,
      docs: transformSections(data.docs),
    };
  },
  deleteSection: async sectionId => {
    const {data} = await SeaburyAxios.delete(`${helpArticleUrl}/section/${sectionId}`);
    return data;
  },

  getSectionArticles: async params => {
    const {data} = await SeaburyAxios.get(`${helpArticleUrl}/userHelpArticle`, {
      params,
      paramsSerializer,
    });
    return data;
  },
  searchSectionArticles: async params => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = Axios.CancelToken.source();
      const {data} = await SeaburyAxios.get(`${helpArticleUrl}/userHelpArticle`, {
        params,
        cancelToken: cancelToken.token,
        paramsSerializer,
      });
      return data;
    } catch (error) {
      if (Axios.isCancel(error)) {
        const errorToThrow = {key: AXIOS_REQUEST_CANCELED};
        throw errorToThrow;
      } else {
        throw error;
      }
    }
  },

  getSectionDetails: async section => {
    const {data} = await SeaburyAxios.get(`${helpArticleUrl}/userHelpArticle/${section}`);
    return data;
  },

  getHelpStorageFiles: async () => {
    const {data} = await SeaburyAxios.get(`${apiURL}/azureblob/help`);
    return data;
  },
};

export default HelpArticleService;
