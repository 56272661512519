import theme from '../../../assets/theme';

export default {
  card: {
    boxShadow: 'unset',
    border: [[1, 'solid', theme.palette.grey.light]],
    padding: theme.spacing(2),
  },
  heading: {
    color: theme.palette.grey.mid,
    fontSize: 14,
    margin: theme.spacing(1) / 2,
  },
  title: {
    color: theme.palette.grey.dark,
    fontSize: 14,
    margin: theme.spacing(1) / 2,
    fontWeight: 'bold',
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    padding: [[theme.spacing(2), 0]],
    borderTop: `1px solid ${theme.palette.grey.light}`,
  },
};
