import React, {useState, useMemo} from 'react';
import produce from 'immer';
import PowerBiService from './../services/powerbi-service';
import {workspaceID} from '../assets/constants';
import powerBiUtil, {POWERBI_CUSTOM_ERRORS} from '../utils/powerBi';
import AdminPagesService from '../services/admin-pages-service';
import AdminReportsService from '../services/admin-reports-service';
import AdminChartsService from '../services/admin-charts-service';
import AdminReportPagesService from '../services/admin-report-pages-service';
import {useStore} from 'outstated';
import AdminStore from './admin';
import DialogStore from './dialog';
import formatMessage from 'format-message';

const initialPageEditState = {
  reports: {
    list: [],
    menuElement: null,
    isLoading: {
      getReports: false,
      saveReports: false,
    },
  },
  charts: {
    list: null,
    isLoading: false,
    dialog: {open: false, status: false, item: null},
  },
  filters: {
    list: null,
    isFilterV2: null,
    isLoading: false,
    menuElement: null,
  },
  editPage: {
    status: false,
    page: null,
  },
  chartEdit: {
    visual: null,
    title: '',
    subtitle: '',
    tooltips: '',
    layout: false,
    filled: false,
  },
  reportPages: {
    list: null,
    menuElement: null,
    isLoading: false,
  },
};

const InvalidFilterFormatComponent = () => {
  return (
    <p>
      {formatMessage(
        "Some filter tables are not well formed. Please, review them and note 'value' column is mandatory. For further information, visit the "
      )}
      <a
        href='https://seaburyconsulting.visualstudio.com/Cargo%20Data%20Team/_wiki/wikis/Cargo%20Data%20Team.wiki/1477/Filter-table-creation'
        target='_blank'
        rel='noopener noreferrer'
      >
        wiki.
      </a>
    </p>
  );
};

const AdminPageEditState = initialState => {
  const [state, setState] = useState(initialState || initialPageEditState);

  const {effects: AdminEffects} = useStore(AdminStore);
  const {mutations: DialogMutations} = useStore(DialogStore);

  // Mutations

  const mutations = useMemo(
    () => ({
      setReportsList: reportsList => {
        setState(
          produce(draft => {
            draft.reports.list = reportsList;
            draft.reports.isLoading.getReports = false;
          })
        );
      },

      setGetReportsLoading: loading => {
        setState(
          produce(draft => {
            draft.reports.isLoading.getReports = loading;
          })
        );
      },

      setSaveReportsLoading: loading => {
        setState(
          produce(draft => {
            draft.reports.isLoading.saveReports = loading;
          })
        );
      },

      setReportsMenuElement: element => {
        setState(
          produce(draft => {
            draft.reports.menuElement = element;
          })
        );
      },
      setReportPagesMenuElement: element => {
        setState(
          produce(draft => {
            draft.reportPages.menuElement = element;
          })
        );
      },
      setChartsList: chartsList => {
        setState(
          produce(draft => {
            draft.charts.list = chartsList;
            draft.charts.isLoading = false;
          })
        );
      },

      openChartDialog: () => {
        setState(
          produce(draft => {
            draft.charts.dialog.open = true;
          })
        );
      },

      openEditChartDialog: chart => {
        setState(
          produce(draft => {
            draft.charts.dialog.open = true;
            draft.charts.dialog.status = true;
            draft.charts.dialog.item = chart;
          })
        );
      },

      closeEditChartDialog: () => {
        setState(
          produce(draft => {
            draft.charts.dialog.open = false;
            draft.charts.dialog.status = false;
            draft.charts.dialog.item = null;
          })
        );
      },

      setLayoutLoading: isLoading => {
        setState(
          produce(draft => {
            draft.charts.isLoading = isLoading;
            draft.reportPages.isLoading = isLoading;
          })
        );
      },

      setFiltersList: filterList => {
        setState(
          produce(draft => {
            draft.filters.list = filterList;
            draft.filters.isLoading = false;
          })
        );
      },

      setIsFilterV2: isFilterV2 => {
        setState(
          produce(draft => {
            draft.filters.isFilterV2 = isFilterV2;
          })
        );
      },

      setFiltersLoading: loading => {
        setState(
          produce(draft => {
            draft.filters.isLoading = loading;
          })
        );
      },

      setFiltersMenu: element => {
        setState(
          produce(draft => {
            draft.filters.menuElement = element;
          })
        );
      },

      setEditPage: ({status, page}) => {
        setState(
          produce(draft => {
            draft.editPage.page = page;
            draft.editPage.status = status;
          })
        );
      },
      setVisualsAndFilters: ({visuals, filters, isFilterV2, reportPages}) => {
        setState(baseState =>
          produce(baseState, draft => {
            draft.charts.list = visuals;
            draft.filters.list = filters;
            draft.charts.isLoading = false;
            draft.filters.isLoading = false;
            draft.reportPages.list = reportPages;
            draft.reportPages.isLoading = false;

            if (isFilterV2) draft.filters.isFilterV2 = isFilterV2;
          })
        );
      },
      setChartEditValues: ({...values}) => {
        setState(baseState =>
          produce(baseState, draft => {
            Object.keys(values).forEach(key => {
              draft.chartEdit[key] = values[key];
            });
          })
        );
      },
    }),
    []
  );

  //Effects

  const effects = useMemo(
    () => ({
      getReports: async () => {
        mutations.setGetReportsLoading(true);
        const data = await PowerBiService.getReports(workspaceID);
        mutations.setReportsList(data?.reports);
      },

      getVisualsAndFilters: async ({hiddenReport, reportID, dashboardId}) => {
        powerBiUtil.reset(hiddenReport.current);
        mutations.setLayoutLoading(true);
        mutations.setReportPagesMenuElement(null);

        try {
          const data = await powerBiUtil.getVisualsAndFilters({
            element: hiddenReport.current,
            reportID,
            dashboardId,
          });

          if (data) {
            const {visuals, filters, isFilterV2, reportPages} = data;
            mutations.setLayoutLoading(false);
            mutations.setVisualsAndFilters({visuals, filters, isFilterV2, reportPages});
          }
        } catch (error) {
          mutations.setLayoutLoading(false);
          mutations.setVisualsAndFilters({visuals: [], filters: [], reportPages: []});
          if (error && error.key === POWERBI_CUSTOM_ERRORS.INVALID_FILTER_FORMAT) {
            DialogMutations.showError({
              title: formatMessage('Invalid filter format'),
              message: <InvalidFilterFormatComponent />,
            });
          }

          if (error && error.key === POWERBI_CUSTOM_ERRORS.INVALID_FILTER_NAME) {
            DialogMutations.showError({
              title: formatMessage('Invalid filter name'),
              message: formatMessage('Filter name should be like "filter-order-label-table[column]-resetLabel"'),
            });
          }
        }
      },

      deletePage: async page => {
        await AdminPagesService.deletePage(page);
      },

      addReport: async (reportID, page) => {
        mutations.setSaveReportsLoading(true);

        mutations.setReportsMenuElement(null);
        mutations.setVisualsAndFilters({visuals: null, filters: null});

        const report = await AdminReportsService.createReport({report_id: reportID, group_id: workspaceID});
        await AdminPagesService.addPageReport(page, report.id);
        await AdminEffects.getDashboards();
        mutations.setSaveReportsLoading(false);
      },

      deleteChart: async chartID => {
        await AdminChartsService.deleteChart(chartID);
        await AdminEffects.getDashboards();
      },
      updateReportPage: async (reportID, body) => {
        await AdminReportPagesService.updateReportPages(reportID, body);
        await AdminEffects.getDashboards();
      },
      deleteReportPage: async reportID => {
        await AdminReportPagesService.deleteReportPage(reportID);
        await AdminEffects.getDashboards();
      },
      addFiltersV1: async (page, filter) => {
        mutations.setFiltersLoading(true);
        mutations.setFiltersMenu(null);

        if (page.reports[0]) {
          await AdminReportsService.addReportFilter(page.reports[0], filter);
          await AdminEffects.getDashboards();
        }
        mutations.setFiltersLoading(false);
      },

      addFiltersV2: async (page, filter) => {
        mutations.setFiltersLoading(true);
        mutations.setFiltersMenu(null);

        if (page.reports[0]) {
          await AdminReportsService.addReportFilterV2(page.reports[0], filter);
          await AdminEffects.getDashboards();
        }
        mutations.setFiltersLoading(false);
      },

      deleteFiltersV1: async (page, filterIndex) => {
        mutations.setFiltersLoading(true);
        await AdminReportsService.removeReportFilter(page.reports[0], filterIndex);
        await AdminEffects.getDashboards();

        mutations.setFiltersLoading(false);
      },

      deleteFiltersV2: async (page, filterIndex) => {
        mutations.setFiltersLoading(true);
        await AdminReportsService.removeReportFilterV2(page.reports[0], filterIndex);
        await AdminEffects.getDashboards();

        mutations.setFiltersLoading(false);
      },
    }),
    [AdminEffects, DialogMutations, mutations]
  );

  return {
    state,
    mutations,
    effects,
  };
};

export default AdminPageEditState;
