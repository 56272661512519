import React from 'react';
import classNames from 'classnames';
import {Button, Dialog, DialogActions, DialogTitle, DialogContent, withStyles} from '@material-ui/core';
import Close from '../../assets/icons/icon_24_close_blue.svg';
import texts from '../../assets/texts';
import styles from './dialog-styles';

const CustomDialog = ({
  className,
  classes,
  children,
  open,
  title,
  onClose,
  button,
  cancelButtonComponent,
  action,
  disabled,
  narrow,
  hideActions,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='sm'
    classes={{paperWidthSm: classNames(classes.container, {[classes.containerNarrow]: narrow}, className)}}
  >
    <DialogTitle disableTypography>
      <h4 className={classes.title}>{title}</h4>
      <img alt='Close' src={Close} onClick={onClose} />
    </DialogTitle>
    <DialogContent className={classNames(classes.content, {[classes.noActions]: hideActions})}>
      {children}
    </DialogContent>
    {!hideActions && (
      <DialogActions>
        {cancelButtonComponent ? (
          cancelButtonComponent
        ) : (
          <Button className={classes.button} onClick={onClose} variant='outlined' color='primary'>
            {texts.dialogs.cancel}
          </Button>
        )}
        {button && (
          <Button
            className={classes.button}
            onClick={action}
            color='primary'
            variant='contained'
            autoFocus
            disabled={disabled}
          >
            {button}
          </Button>
        )}
      </DialogActions>
    )}
  </Dialog>
);

export default withStyles(styles)(CustomDialog);
