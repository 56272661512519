export const serverURL = process.env.REACT_APP_SERVER_URL || 'http://localhost';
export const apiURL = `${serverURL}/api`;
export const notificationsUrl = `/api-notifications/v1/notifications`;
export const helpArticleUrl = `/api-helparticles/v1/help`;

export const workspaceID = process.env.REACT_APP_WORKSPACE_ID || `948bf687-77b0-4199-8057-f56c08729cff`;
export const defaultVisualTimout = 60;
export const numberOfDashboardTabs = 6;
export const defaultFilterValueIdentifier = '[x] ';

export const DEFAULT_FILTER_TYPE = 0;
export const DATE_RANGE_FILTER_TYPE = 1;

export const COGNOS_URL = process.env.REACT_APP_COGNOS_URL || '';
export const HIDE_NOTIFICATION_SECTION = process.env.REACT_APP_HIDE_NOTIFICATION_SECTION === 'true';


const COMMON_LABELS = {
  NEWS: 'News',
  INSIGHT: 'Insight',
  ANNOUNCEMENT: 'Announcement',
};
export const NOTIFICATION_TYPES = {
  DATA_UPDATE: 'Data update',
  PRIORITY_DATA_UPDATE: 'Data update',
  ...COMMON_LABELS,
};
export const NOTIFICATION_TYPE_ADMIN_LABELS = {
  DATA_UPDATE: 'Data update',
  PRIORITY_DATA_UPDATE: 'Priority data update',
  ...COMMON_LABELS,
};

export const NOTIFICATION_STATUS = {
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
};

export const NOTIFICATION_TITLE_MAX_LENGTH = 100;

export const TITLE_MIN_LENGTH = 1;


export const FILTER_STICKY_MAX_HEIGHT = 800;

export const NOTIFICATIONS_HELP_URL =
  'https://seaburyconsulting.visualstudio.com/Cargo%20Data%20Team/_wiki/wikis/Cargo%20Data%20Team.wiki/1498/Notifications-module';

export const ROW_LEVEL_SECURITY_LIST = [
  {
    label: 'Disabled',
    value: '0',
  },
  {
    label: 'Azure Analysis services',
    value: '1',
  },
  {
    label: 'Power BI',
    value: '2',
  },
];

export const TARGET_WINDOW_LIST = [
  {
    label: 'Same Window',
    value: '0',
  },
  {
    label: 'New Window',
    value: '1',
  },
];

export const LAYOUT_OPTIONS = [
  {
    value: '0',
    label: 'Visual(tiles)',
  },
  {
    value: '1',
    label: 'Page (view)',
  },
  {
    value: '2',
    label: 'Page (edit)',
  },
];

export const HELP_ARTICLE_PINTO = 'NOT_PINNED';

export const PINTO_TYPES = ['VISUAL', 'PAGE_HEADER', 'PAGE_FOOTER', 'SITE_FOOTER'];
export const OLD_NOTIFICATION_AGE = 13; // 14 days
export const VALID_STATUSES = ['ALL', 'PUBLISHED', 'UNPUBLISHED'];

export const TEMP_HELP_PERMISSION = false; ///TODO: Make it as false always, when content is ready. Temporary code for help prod deployment  - this is used to check conditions in other page



export const SEABURY_EMAIL = 'cargo.commercial@accenture.com';
export const SEABURY_LINK = '';
