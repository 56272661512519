import React from 'react';
import propTypes from 'prop-types';
import Dialog from '../../../components/dialog';
import {TextField, withStyles, Checkbox, FormControlLabel, MenuItem, Divider, MenuList} from '@material-ui/core';
import classNames from 'classnames';
import texts from '../../../assets/texts';
import styles from './admin-add-dashboard-styles';
import Category from '../../../components/category';
import Menu from '../../../components/menu';
import CustomCard from '../../../components/card';
import {SelectField} from '../../../components/form/fields';

const CreateDialog = ({
  classes,
  open,
  onCloseClick,
  name,
  url,
  weightage,
  selfChild,
  dropDownName,
  error,
  filled,
  edit,
  change,
  save,
  dashboards,
  addDashboard,
  removeDashboard,
  groupsMenuAnchorEl,
  setGroupsMenuAnchorEl,
  dashboardChildren,
  weightList,
  tabTitle
}) => (
  <Dialog
    open={open}
    title={
      edit ? 
      (tabTitle === 'Dashboards' ? texts.admin.editDashboard : texts.admin.editDataset) : 
      (tabTitle === 'Dashboards' ? texts.admin.addDashboard : texts.admin.addDataset)}
    onClose={onCloseClick}
    button={edit ? texts.editing.updateButton : texts.editing.createButton}
    action={save}
    disabled={!filled}
  >
    <TextField
      id='name-input'
      label='Name'
      type='text'
      name='name'
      margin='normal'
      variant='outlined'
      autoFocus
      fullWidth
      value={name}
      onChange={e => change(e)}
      className={classes.field}
      InputLabelProps={{
        classes: {
          root: name.length > 0 ? classes.inputLabel : null,
          focused: classes.inputFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.inputFocused,
          notchedOutline: name.length > 0 ? classes.notchedOutline : null,
        },
      }}
    />
    <TextField
      id='url-input'
      label='Url path'
      type='url'
      name='url'
      margin='normal'
      variant='outlined'
      fullWidth
      className={classes.field}
      InputLabelProps={{
        classes: {
          root: url.length > 0 ? classes.inputLabel : null,
          focused: classes.inputFocused,
          error: classes.inputError,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.inputFocused,
          notchedOutline: name.length > 0 ? classes.notchedOutline : null,
          error: classes.inputError,
        },
      }}
      value={`${texts.admin.dashboardsURL}${url}`}
      onChange={e => change(e)}
      error={error}
    />

    <SelectField
      id="weightage"
      name='weightage'
      label='Weight'
      value={weightage}
      handleChange={event => change(event)}
      options={weightList}
    />

    {dashboards?.length || dashboardChildren.length ? (
      <FormControlLabel
        className={classes.field}
        control={
          <Checkbox
            name='selfChild'
            color={'primary'}
            className={classes.checkBox}
            checked={selfChild}
            onChange={e => change(e)}
          />
        }
        label={tabTitle === 'Dashboards' ? texts.admin.addDashboardInSubmenu : texts.admin.addDatasetInSubmenu}
      />
    ) : null}
    {selfChild && (
      <TextField
        id='dropDownName-input'
        label='Drop Down Name'
        type='text'
        name='dropDownName'
        margin='normal'
        variant='outlined'
        autoFocus
        fullWidth
        value={dropDownName}
        onChange={e => change(e)}
        className={classes.field}
        InputLabelProps={{
          classes: {
            root: dropDownName?.length > 0 ? classes.inputLabel : null,
            focused: classes.inputFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.inputFocused,
            notchedOutline: dropDownName?.length > 0 ? classes.notchedOutline : null,
          },
        }}
      />
    )}
    {dashboards?.length || dashboardChildren.length ? (
      <>
        <Category
          isShowLabel={false}
          button={tabTitle === 'Dashboards' ? 'Add dashboard to dropdown' : 'Add dataset to dropdown'}
          className={classes.gridContainer}
          action={e => setGroupsMenuAnchorEl(e.target)}
        />
        <Menu
          open={Boolean(groupsMenuAnchorEl)}
          anchorEl={groupsMenuAnchorEl}
          onClickAway={() => setGroupsMenuAnchorEl(null)}
          placement='bottom-end'
        >
          <MenuList className='menu-items'>
            {dashboards.map(dashboard => (
              <MenuItem className='menu-item' key={dashboard.id} onClick={() => addDashboard(dashboard)}>
                {dashboard.name}
                <Divider className='divider' />
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </>
    ) : null}
    {dashboardChildren?.map((child, id) => (
      <CustomCard key={child?.id} title={child?.name} onButtonClick={() => removeDashboard(child?.id)} button='trash' />
    ))}
    <div className={classes.errorContainer}>
      <p className={classNames(classes.error, error ? null : classes.hidden)}>{texts.editing.duplicateURL}</p>
    </div>
  </Dialog>
);

CreateDialog.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  name: propTypes.string,
  url: propTypes.string,
  error: propTypes.bool,
  filled: propTypes.bool,
  edit: propTypes.bool,
  change: propTypes.func,
  save: propTypes.func,
  dropDownName: propTypes.string,
  selfChild: propTypes.bool,
};

export default withStyles(styles)(CreateDialog);
