import theme from '../../assets/theme';

export default {
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  container: {
    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
};
