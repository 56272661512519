import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  createPage: async (dashboard, name, url) => {
    try {
      const {data} = await SeaburyAxios.post(`/api/admin/page`, {name, url});
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  editPage: async (page, name, url) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/page/${page.id}`, {
        name,
        url,
        reports: page.reports.map(r => r.id),
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  deletePage: async page => {
    try {
      await SeaburyAxios.delete(`/api/admin/page/${page.id}`);
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  addPageReport: async (page, reportId) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/page/${page.id}/reports`, {
        reports: [reportId],
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
