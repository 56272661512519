import React, {useEffect, useMemo} from 'react';
import {Divider} from '@material-ui/core';

import AppBar from './appbar';
import {useStore} from 'outstated';
import DashboardStore from '../../state/dashboard';
import NavigationDashboard from '../../components/navigation/dashboards/navigation-dashboards-view';
import history from '../../utils/history';
import {getDashboardDetail} from '../../sections/Helper';
import {NotificationsMailboxStore} from '../../state/notifications/store';
import AppStore from '../../state/app';
import {HIDE_NOTIFICATION_SECTION} from '../../assets/constants';
import NavigationMenu from './menu/navigation-menu';
import {AdminEnterOption, LogoutMenuOption} from './menu/navigation-menu-options';
import {isAdmin, getAdminPermissions} from '../../utils/access-token';

const Navigation = () => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);
  const {effects: MailboxEffects} = useStore(NotificationsMailboxStore);
  const {state: AppState} = useStore(AppStore);
  const showAdminLinks = useMemo(() => isAdmin(), []);
  const adminPermissions = useMemo(() => getAdminPermissions(), []);
  let hasTabAccess = true;
  if (!adminPermissions.isNotifAdmin && !adminPermissions.isHelpAdmin && !adminPermissions.isDashAdmin) {
    hasTabAccess = false;
  }

  useEffect(() => {
    if (DashboardState.initialized) {
      const unlisten = history.listen((location, action) => {
        if (action === 'POP') {
          DashboardMutations.setActiveDashboard(location.state.activeDashboardTab);

          if (location.state.activePageTab) {
            DashboardMutations.setActivePage(location.state.activePageTab);
          }
        }
        if (AppState.dashboardRoutes.includes(location.pathname) && AppState.token && !HIDE_NOTIFICATION_SECTION) {
          MailboxEffects.getNotifications();
          MailboxEffects.getInsights();
        }
      });

      return function unlistenHistory() {
        unlisten();
      };
    }
  }, [DashboardState.initialized, DashboardMutations, AppState.dashboardRoutes, AppState.token, MailboxEffects]);

  useEffect(() => {
    if (DashboardState.dashboards) {
      const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');
    if (dashboard) {
      const page = dashboard.pages.find(p => p.id === DashboardState.current.activePage);
      if (page) {
        history.replace(`/${page.url}`, {
          activeDashboardTab: DashboardState.current.activeDashboard,
          activePageTab: DashboardState.current.activePage,
        });
      } else {
        DashboardMutations.setActiveDashboard(false);
        DashboardMutations.setActivePage(false);
      }
    }
    }
    
  }, [
    DashboardState.dashboards,
    DashboardState.current.activeDashboard,
    DashboardState.current.activePage,
    DashboardState,
    DashboardMutations,
  ]);
  return (
    <>
      <AppBar>
        <NavigationDashboard
          tabs={DashboardState.dashboards}
          active={DashboardState.current.activeDashboard}
          onClick={DashboardMutations.setActiveDashboard}
          sticky
        />
        <NavigationMenu>
          <Divider />
          {showAdminLinks && hasTabAccess && (
            <>
              <AdminEnterOption />
              <Divider />
            </>
          )}
          <LogoutMenuOption />
        </NavigationMenu>
      </AppBar>
    </>
  );
};

export default Navigation;
