import {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {useStore} from 'outstated';
import produce from 'immer';
import DashboardStore from '../state/dashboard';
import PowerbiService from '../services/powerbi-service';
import {workspaceID, defaultVisualTimout} from '../assets/constants';
import logger from './logger';
import {mergeFilters} from './filters';
import powerBiUtil, {POWERBI_ERRORS} from './powerBi';

const initialState = {
  status: {
    loaded: null,
    showVisual: false,
  },
  defaultFilters: null,
  expirationTime: undefined,
};

const resetEmbedReport = function(report, clean = false) {
  if (report && report.current && report.current.innerHTML !== '') {
    try {
      const el = powerBiUtil.get(report.current);
      if (el) {
        el.off('dataSelected');
        el.off('error');
        el.off('rendered');
        el.off('loaded');
      }
      if (clean) {
        powerBiUtil.reset(report.current);
      }
    } catch (error) {
      logger.error('PowerBI error:', error);
    }
  }
};

export default function usePowerBi({
  report,
  visual,
  reportID,
  page,
  bootstraping,
  isChangingPage,
  onIncreaseLoadingVisuals,
  onDecreaseLoadingVisuals,
  visualID,
  visualMongoID,
}) {
  const {state: DashboardState} = useStore(DashboardStore);
  const [state, setState] = useState(initialState);
  const timerRef = useRef();

  const setStatus = status =>
    setState(
      produce(draft => {
        draft.status = status;
      })
    );

  const setLoaded = loaded =>
    setState(
      produce(draft => {
        draft.status.loaded = loaded;
      })
    );

  const setDefaultFilters = defaultFilters =>
    setState(
      produce(draft => {
        draft.defaultFilters = defaultFilters;
      })
    );

  const setExpirationTime = expirationTime =>
    setState(
      produce(draft => {
        draft.expirationTime = expirationTime;
      })
    );

  useEffect(() => {
    setExpirationTime(Date.now() + 2 * 60000); // 2minutes
  }, [reportID, visual]);

  useEffect(() => {
    return () => {
      resetEmbedReport(report, true);
      setStatus({loaded: null, showVisual: false});
    };
  }, [reportID, visual]);

  useMemo(() => {
    if (reportID) {
      resetEmbedReport(report, true);
    }
  }, [reportID]);

  // Effect to apply filters
  useEffect(() => {
    if (report.current && report.current.innerHTML !== '' && !isChangingPage && state.defaultFilters) {
      try {
        const el = powerBiUtil.get(report.current);

        let newFilterList = mergeFilters(
          state.defaultFilters,
          DashboardState.current.filters.values,
          DashboardState.current.filters.isFilterV2,
          DashboardState.current.filterLevels
        );
        const filterLevels = DashboardState.current.filterLevels;
        let layerdFilterList = [];
        let initialFilterList = [];

        if (filterLevels) { // If any filter selected
          newFilterList.forEach(element => {
            if (
              (element.active === filterLevels.value2 || element.active === filterLevels.value3 || element.active === filterLevels.value1) &&
              (element.target.column === filterLevels.type2 || element.target.column === filterLevels.type3 || element.target.column === filterLevels.type1)
            ) {
              if ('value3' in filterLevels) {
                layerdFilterList.push(
                  {
                    ...element,
                    values: [filterLevels.value1],
                    target: {
                      ...element.target,
                      column: filterLevels.type1,
                    },
                  },
                  {
                    ...element,
                    values: [filterLevels.value2],
                    target: {
                      ...element.target,
                      column: filterLevels.type2,
                    },
                  },
                  element
                );
              } else if ('value2' in filterLevels) {
                layerdFilterList.push(
                  {
                    ...element,
                    values: [filterLevels.value1],
                    target: {
                      ...element.target,
                      column: filterLevels.type1,
                    },
                  },
                  element
                );
                if (filterLevels.subValue) { // TODO: if value3 conntains multiple values
                  // filterLevels.subValue.forEach(elem => {
                  //   layerdFilterList.push({
                  //     ...element,
                  //     values: [elem.value],
                  //     target: {
                  //       ...element.target,
                  //       column: elem.type,
                  //     },
                  //   });
                  // });
                  // layerdFilterList.push({
                  //   ...element,
                  //   values: ['fwd', '4wd'],
                  //   target: {
                  //     ...element.target,
                  //     column: filterLevels.subValue[0].type,
                  //   },
                  // });
                }
              } else {
                layerdFilterList.push(element);
              }
            } else {
              if (element.filters) {
                if ('value3' in element.filters) {
                  layerdFilterList.push(
                    {
                      ...element,
                      values: [element.filters.value1],
                      target: {
                        ...element.target,
                        column: element.filters.type1,
                      },
                    },
                    {
                      ...element,
                      values: [element.filters.value2],
                      target: {
                        ...element.target,
                        column: element.filters.type2,
                      },
                    },
                    element
                  );
                }
                else if ('value2' in element.filters) {
                  layerdFilterList.push(
                    {
                      ...element,
                      values: [element.filters.value1],
                      target: {
                        ...element.target,
                        column: element.filters.type1,
                      },
                    },
                    element
                  );
                } else {
                  layerdFilterList.push(element);
                }
              } else {
                let endValue = {};
              element.filterValues && element.filterValues.map((filterValues) => {
                filterValues.value2.length && filterValues.value2.map((v2) => {
                  if (v2.value === element.active) {
                    endValue = {
                      value : v2,
                    };
                  }
                });
                filterValues.value2.length &&
                filterValues.value2.forEach((v2) => {
                  v2.value3 &&
                    v2.value3.forEach((v3) => {
                      if (v3.value === element.active) {
                        endValue = {
                          value : v3,
                        };
                      }
                    });
                });
                if (filterValues.value === element.active) {
                  endValue = {
                    value : filterValues
                  };
                }
              });
              
              
              if (Object.keys(endValue).length) {
                layerdFilterList.push(
                  {
                    ...element,
                    target: {
                      ...element.target,
                      column: endValue.value.type,
                    },
                  });
              } else {
                layerdFilterList.push(element);
              }
              }
              
            }
          });
        } else { // On first load without any filter selection
          if (newFilterList) {
            newFilterList.forEach(element => {
              element.filterValues &&
                element.filterValues.map(filterValues => {
                  filterValues.value2.map(v2 => {
                    if (v2.default) {
                      initialFilterList.push(
                        {
                          ...element,
                          values: [filterValues.value],
                          target: {
                            ...element.target,
                            column: filterValues.type,
                          },
                        },
                        {
                          ...element,
                          values: [v2.value],
                          target: {
                            ...element.target,
                            column: v2.type,
                          },
                        },
                      );
                    }
                  });
                  filterValues.value2 &&
                    filterValues.value2.forEach(v2 => {
                      v2.value3 &&
                        v2.value3.forEach(v3 => {
                          if (v3.default) {
                            initialFilterList.push(
                              {
                                ...element,
                                values: [filterValues.value],
                                target: {
                                  ...element.target,
                                  column: filterValues.type,
                                },
                              },
                              {
                                ...element,
                                values: [v2.value],
                                target: {
                                  ...element.target,
                                  column: v2.type,
                                },
                              },
                              {
                                ...element,
                                values: [v3.value],
                                target: {
                                  ...element.target,
                                  column: v3.type,
                                },
                              },
                            );
                          }
                        });
                    });

                  if(!filterValues.value2.length) { // TODO: change API - default value is not set for default value added to first column
                    if (filterValues && filterValues.value === element.active) {
                      initialFilterList.push(
                        {
                          ...element,
                          values: [filterValues.value],
                          target: {
                            ...element.target,
                            column: filterValues.type,
                          },
                        }
                      );
                    }
                  }
                });
                if(!element.filterValues) {
                  initialFilterList.push(element)
                }
            });
          }
        }

        // Only apply filters to visible items
        if (el && el.element && el.element.style && el.element.style.visibility === 'visible') {
          el.setFilters(layerdFilterList.length ? layerdFilterList : (initialFilterList.length ? initialFilterList : newFilterList))
            .then(status => {})
            .catch(error => {
              if (process.env.REACT_APP_IS_LOCAL === 'true') {
                logger.error('PowerBI error setting filters:', error);
              }
            });
        }
      } catch (error) {
        logger.error('PowerBI error:', error);
      }
    }
  }, [DashboardState.current.filters.values, state.defaultFilters]);
  // Only trigger effect when updating state.defaultsFilters.
  // Dashboard.current.filter.values is needed to update effect values but it will not force a filter update because will be prevented by isChangingPage condition

  const loadVisual = useCallback(async () => {
    const dashboardId = DashboardState.current.activeDashboard;
    const pageId = DashboardState.current.activePage;
    if (visual && !bootstraping) {
      onIncreaseLoadingVisuals();
      setStatus({loaded: null, showVisual: false});
      const config = await PowerbiService.getVisualConfig({
        workspaceID,
        reportID,
        page,
        visual,
        dashboardId,
        pageId,
      });

      if (config && report.current) {
        if (config.expirationTime) {
          setExpirationTime(config.expirationTime * 1000 - 60000);
        }

        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setStatus({loaded: false, showVisual: state.status.showVisual});
          onDecreaseLoadingVisuals();
        }, defaultVisualTimout * 1000);

        const handleRendered = () => {
          clearTimeout(timerRef.current);
          setStatus({loaded: true, showVisual: true});
        };

        const handleLoaded = async element => {
          try {
            onDecreaseLoadingVisuals();
            let defaultFilters = [];

            // Set defaultFilters state when visual loads to trigger effect to apply the filters
            try {
              defaultFilters = await element.getFilters();
              setDefaultFilters(defaultFilters);
            } catch (getFiltersError) {

              logger.error('PowerBI on loaded getFiltersError error:', getFiltersError, element);
              setDefaultFilters([]);
            }

            try {
              element.iframe.contentWindow.powerbi.dataServices.modelHttpService.syncService.isEnabled = false;
            } catch (error) {
              // hack for disabling /refresh/subscribe
            }
          } catch (error) {
            clearTimeout(timerRef.current);
            setStatus({loaded: false, showVisual: false, errorOnLoad: true});
            logger.error('PowerBI on loaded error:', error);
            if (
              error &&
              (error.detailedMessage === 'Could not get filters' || error.detailedMessage === 'Could not set filters')
            ) {
              setStatus({loaded: false, showVisual: false, errorOnLoad: true});
              handleReload();
            } else {
              setStatus({loaded: false, showVisual: true, errorOnLoad: true});
            }
          }
        };

        const handleOnError = () => {
          setLoaded(false);
        };

        try {
          await powerBiUtil.embedVisual({
            element: report.current,
            visualConfig: config,
            callbacks: {onLoaded: handleLoaded, onRendered: handleRendered, onError: handleOnError},
          });
        } catch (error) {
          clearTimeout(timerRef.current);
          if (process.env.REACT_APP_IS_LOCAL === 'true') {
            logger.error('PowerBI embedding visual error: ', error);
          }
          switch (error.customKey) {
            case POWERBI_ERRORS.TOKEN_EXPIRED: {
              onDecreaseLoadingVisuals();
              setLoaded(false);
              handleReload();
              break;
            }
            case POWERBI_ERRORS.BROKEN_FILTERS: {
              setStatus({loaded: true, showVisual: true});
              break;
            }
            default: {
              onDecreaseLoadingVisuals();
              handleOnError();
            }
          }
        }
      }
    }
  }, [
    workspaceID,
    reportID,
    page,
    visual,
    DashboardState.current.filters.values,
    DashboardState.current.activeDashboard,
  ]);

  const handleReload = useCallback(() => {
    resetEmbedReport(report);
    setLoaded(null);
    loadVisual();
  }, [loadVisual]);

  useEffect(() => {
    let timer = null;
    if (!bootstraping && state.expirationTime) {
      timer = setTimeout(handleReload, state.expirationTime - Date.now());
    }
    return () => {
      if (timer) {
        return clearTimeout(timer);
      }
    };
  }, [state.expirationTime, handleReload]);

  useEffect(() => {
    if (visual) {
      loadVisual();
    }
  }, [reportID, visual, visualMongoID]);

  return {
    handleReload,
    status: state.status,
  };
}
