import React from 'react';
import propTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import {Card, CardContent, withStyles} from '@material-ui/core';
import classNames from 'classnames';
import styles from './notification-viewer-details-styles';
import RichTextEditorViewer from '../../../../components/rich-text-editor/viewer/rich-text-editor-viewer';

const NotificationViewerDetails = ({classes, data}) => {
  return (
    <Card className={classNames(classes.card)}>
      <CardContent className={classNames(classes.cardContent)}>
        <div className={classes.item}>
          <div className={classes.cardTitle}>
            <span>{data.title}</span>
          </div>
          <div className={classes.itemType}>
            <span>{data.type}</span>
          </div>
        </div>
        <RichTextEditorViewer className={classes.editor} value={data.message} />
      </CardContent>
    </Card>
  );
};

NotificationViewerDetails.propTypes = {
  classes: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
};

export default withStyles(styles)(NotificationViewerDetails);
