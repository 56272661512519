import _isObject from 'lodash/fp/isObject';
import _isString from 'lodash/fp/isString';
import {Quill} from 'react-quill';

const imgRegex = new RegExp(/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|svg))/, 'i');
const videoRegex = new RegExp(/(http)?s?:?(\/\/[^"']*\.(?:mp4))/, 'i');
const urlRegex = new RegExp(
  /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
  'i'
);
const dataImageRegex = new RegExp(/data:image\/([a-zA-Z]*);base64,([^\"]*)/, 'i');

// private helpers

/**
 * Builds image op from object source
 * @param op
 * @param disableLinks
 * @param disableImages
 * @returns {[*]|*[]|[{attributes: {link: *}}]}
 */
const getImageObjectOp = (op, disableLinks, disableImages) => {
  const img = op.insert && (op.insert.image || op.insert.customImage);
  if (img && (disableLinks || disableImages || dataImageRegex.test(img))) return [];
  return [op];
};

/**
 * Builds image or links op(s) from string source
 * @param op
 * @param disableLinks
 * @param disableVideo
 * @param disableImages
 * @returns {*}
 */
const getTransformedLinksFromStringOp = (op, disableLinks, disableVideo, disableImages) => {
  const parts = op.insert.split(' ');
  const finalParts = parts
    .map((str, i) => {
      // links disabled
      const isUrl = urlRegex.test(str);
      if (disableLinks && isUrl) {
        return null;
      }

      // check image to build correct object
      if (imgRegex.test(str)) {
        return disableImages ? null : {insert: {image: str}, attributes: {}};
      }

      // check video to build correct object
      if (videoRegex.test(str)) {
        return disableVideo ? null : {insert: {customVideo: str}, attributes: {}};
      }

      const whiteSpace = i === parts.length - 1 ? '' : ' ';
      return {
        insert: str + whiteSpace, // we need the space in case we have a link between 2 strings
        attributes: isUrl ? {link: str} : {},
      };
    })
    .filter(str => str);
  return finalParts.map(part => {
    return {
      insert: part.insert,
      attributes: part.attributes,
    };
  });
};

// END of private helpers

export const getLinksForDelta = (op, {disableLinks, disableVideo, disableImages} = {}) => {
  if (!op || !op.insert) return [];
  // no embedded images
  if (_isObject(op.insert)) {
    return getImageObjectOp(op, disableLinks, disableImages);
  }
  // handle links transformations
  if (_isString(op.insert) && (!op.attribute || !op.attribute.link) && urlRegex.test(op.insert)) {
    return getTransformedLinksFromStringOp(op, disableLinks, disableVideo, disableImages);
  }
  // no base64 data images (ALWAYS disabled)
  if (_isString(op.insert) && dataImageRegex.test(op.insert)) {
    return [];
  }
  // default result
  return [op];
};

export const isValidImageUrl = url => {
  return imgRegex.test(url);
};

export const isValidVideoUrl = url => {
  return videoRegex.test(url);
};

export const isValidUrl = url => {
  return urlRegex.test(url);
};

export const mediaHandler = (editor, mode) => {
  const tooltip = editor.theme.tooltip;
  const originalSave = tooltip.save;
  tooltip.save = function() {
    const range = editor.getSelection(true);
    const value = this.textbox.value;
    switch (this.root.getAttribute('data-mode')) {
      case 'image':
        if (value && isValidImageUrl(value)) {
          editor.insertEmbed(range.index, 'customImage', value, Quill.sources.USER);
        }
        break;
      case 'video':
        if (value && isValidVideoUrl(value)) {
          editor.insertEmbed(range.index, 'customVideo', value, Quill.sources.USER);
        }
        break;
      case 'link':
        if (value && isValidUrl(value)) {
          const text = range.length ? editor.getText(range.index, range.length) : null;
          const hasText = text && text.length;
          const delta = {
            ops: [
              range.index ? {retain: range.index} : null,
              hasText ? {delete: range.length} : null,
              {
                insert: hasText ? text : value,
                attributes: {
                  link: value,
                },
              },
            ].filter(op => op),
          };
          editor.updateContents(delta);
        }
        break;
      default:
        break;
    }

    tooltip.save = originalSave;
  };
  tooltip.edit(mode);
};
