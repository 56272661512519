import React, {useMemo, useCallback} from 'react';
import classNames from 'classnames';
import {withStyles, Divider, Link} from '@material-ui/core';
import styles from './navigation-mailbox-styles';
import NavigationIcon from '../navigation-icons-view';
import Badge, {BADGE_TYPES} from '../../../badge';
import CardHeader from '../../../card/header';
import MailIcon from '../../../../assets/icons/icon_24_mail_blue.svg';
import CloseIcon from '../../../../assets/icons/icon_24_close_darkGrey.svg';
import {ReactComponent as ChevronRight} from '../../../../assets/icons/icon_24_chevron_right_blue.svg';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {NotificationsMailboxStore} from '../../../../state/notifications/store';
import {MAX_NOTIFICATIONS_MAILBOX} from '../../../../state/notifications/constants';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../../Routes';
import DashboardStore from '../../../../state/dashboard';
import {HIDE_NOTIFICATION_SECTION} from '../../../../assets/constants';

const NavigationMailboxHeader = ({classes, onClose, numNotifications}) => {
  return (
    <CardHeader
      title={formatMessage('Notifications')}
      subtitle={formatMessage('{num} messages', {num: numNotifications})}
      rightButton={
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={CloseIcon}
            alt={formatMessage('Notifications')}
            title={formatMessage('Notifications')}
            aria-label={formatMessage('Notifications')}
          />
        </div>
      }
    />
  );
};

const NavigationMailboxList = ({classes, notifications, onClick}) => {
  return (
    <>
      {notifications && notifications.length && notifications.map(notification => (
        <div key={notification._id} className={classes.listContent} onClick={() => onClick(notification._id)}>
          <div className={classes.listInfo}>
            <div className={classes.listTitle}>{notification.title}</div>
            <div className={classes.listSubtitle}>{notification.datePublished}</div>
          </div>
          <ChevronRight className={classes.chevronIcon} />
        </div>
      ))}
    </>
  );
};

const NavigationMailboxBottomActions = ({classes, onClick}) => {
  return (
    <div key={'AllNotifications'} className={classes.bottomContainer}>
      <Link style={{cursor: 'pointer'}} onClick={onClick}>
        {formatMessage('See all notifications')}
      </Link>
    </div>
  );
};

const NavigationIconMailbox = ({classes}) => {
  const {state: MailboxState} = useStore(NotificationsMailboxStore);
  const history = useHistory();
  const {mutations: DashboardMutations} = useStore(DashboardStore);

  const handleMailboxItemClick = useCallback(
    ({id, onClose}) => {
      DashboardMutations.setActiveDashboard(null);
      if (id) {
        history.push(`${Routes.NOTIFICATIONS}/${id}`);
      } else {
        history.push(`${Routes.NOTIFICATIONS}`);
      }
      onClose();
    },
    [history]
  );

  const numNotifications = useMemo(
    () =>
      MailboxState.totalCount > MAX_NOTIFICATIONS_MAILBOX ? `${MAX_NOTIFICATIONS_MAILBOX}+` : MailboxState.totalCount,
    [MailboxState.totalCount]
  );

  if (HIDE_NOTIFICATION_SECTION) return null;

  return (
    <NavigationIcon
      placement='bottom-end'
      IconComponent={
        <Badge
          className={classNames({
            [classes.wideBadge]: MailboxState.totalCount > MAX_NOTIFICATIONS_MAILBOX,
          })}
          value={numNotifications}
          type={BADGE_TYPES.INFO}
        >
          <img
            src={MailIcon}
            alt={formatMessage('Notifications')}
            title={formatMessage('Notifications')}
            aria-label={formatMessage('Notifications')}
          />
        </Badge>
      }
      render={({onClose}) => (
        <div className={classes.mailboxContainer}>
          <NavigationMailboxHeader classes={classes} numNotifications={numNotifications} onClose={onClose} />
          <Divider className={classNames('divider', classes.divider)} />
          <NavigationMailboxList
            classes={classes}
            notifications={MailboxState.notifications}
            onClick={id => {
              handleMailboxItemClick({id, onClose});
            }}
          />
          <Divider className={classNames('divider', classes.divider)} />
          <NavigationMailboxBottomActions classes={classes} onClick={() => handleMailboxItemClick({onClose})} />
        </div>
      )}
    />
  );
};

export default withStyles(styles)(NavigationIconMailbox);
