import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import ReactGA from 'react-ga';
import {Grid, withStyles} from '@material-ui/core';
import {useStore} from 'outstated';

import AddDashboard from './add-dashboard';
import AddPage from './add-page';
import DeleteDashboard from './delete-dashboard';
import Details from './dashboard-details';
import EditPage from './page-edit';
import Footer from '../../components/footer';
import List from './dashboards-list';
import AdminNavigation from './navigation';

import {isAdmin} from '../../utils/access-token';
import AdminStore, {ADMIN_ACTIONS} from '../../state/admin';
import styles from './admin-styles';
import {Routes} from '../../Routes';
import LoadingSpinner from '../../components/loading-spinner';

const AdminContainer = ({classes}) => {
  const {state: AdminState, mutations: AdminMutations, effects: AdminEffects} = useStore(AdminStore);

  const [filteredDashboards, setFilteredDashboards] = useState([]);

  const {activeAction} = AdminState;
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
    AdminEffects.getDashboards();
    AdminEffects.getSiteFooterArticles();
  }, []);

  useEffect(() => {
    if (AdminState.tabTitle === 'Dashboards') {
      setFilteredDashboards(AdminState.dashboards.filter( d => d.resourceType === 'Dashboard'));
    } else {
      setFilteredDashboards(AdminState.dashboards.filter( d => d.resourceType === 'Dataset'));
    }
  }, [AdminState.tabTitle, AdminState.dashboards])

  // Actions
  const handleEditPage = id => {
    if (id) {
      AdminMutations.setEditPage(id);
      AdminMutations.setActiveAction(ADMIN_ACTIONS.EDIT_PAGE);
    }
  };

  // FIXME: we assume there will always be at least 1 dashboard, otherwise there will be a infinte loading
  // if (!AdminState.dashboards || !AdminState.dashboards.length) return <LoadingSpinner />;

  // Render
  return (
    <>
      {!isAdmin() && <Redirect to={Routes.LOGIN} />}

      <div className={classes.wrapper} style={{display: activeAction === ADMIN_ACTIONS.EDIT_PAGE ? 'none' : null}}>
        <AdminNavigation />
        <Grid container spacing={2} className={classes.container}>
          <Grid item sm={12} md={5}>
          <List dashboards={filteredDashboards} addDashboard={() => AdminMutations.setActiveAction(ADMIN_ACTIONS.ADD_DASHBOARD)} />
          </Grid>
          <Grid item sm={12} md={7}>
            <Details
              dashboards={filteredDashboards}
              editDashboard={() => AdminMutations.setActiveAction(ADMIN_ACTIONS.EDIT_DASHBOARD)}
              deleteDashboard={() => AdminMutations.setActiveAction(ADMIN_ACTIONS.DELETE_DASHBOARD)}
              addPage={() => AdminMutations.setActiveAction(ADMIN_ACTIONS.ADD_PAGE)}
              editPage={handleEditPage}
            />
          </Grid>
        </Grid>
        <Footer />
      </div>

      <AddDashboard
        open={activeAction === ADMIN_ACTIONS.ADD_DASHBOARD || activeAction === ADMIN_ACTIONS.EDIT_DASHBOARD}
        onClose={() => {
          AdminMutations.setActiveAction(false);
          AdminMutations.setActiveAction(false);
        }}
        edit={activeAction === ADMIN_ACTIONS.EDIT_DASHBOARD}
        filteredDashboards={filteredDashboards}
      />
      <DeleteDashboard
        open={activeAction === ADMIN_ACTIONS.DELETE_DASHBOARD}
        onClose={() => AdminMutations.setActiveAction(false)}
        dashboards={filteredDashboards}
      />

      <AddPage 
        open={activeAction === ADMIN_ACTIONS.ADD_PAGE} 
        onClose={() => AdminMutations.setActiveAction(false)} 
        dashboards={filteredDashboards} />
          {activeAction === ADMIN_ACTIONS.EDIT_PAGE && (
            <EditPage dashboards={filteredDashboards} id={AdminState.editPageId} onClose={() => AdminMutations.setActiveAction(false)} />
      )}
    </>
  );
};

export default withStyles(styles)(AdminContainer);
