import theme from '../../../assets/theme';

export default {
  container: {
    flex: 'flex',
    padding: [[theme.spacing(1), theme.spacing(10)]],
    margin: [[0, 0, theme.spacing(3)]],
    lineHeight: '40px',
    position: 'sticky',
    zIndex: 999,
    backgroundColor: theme.palette.background.default,
  },
  labelSpace: {
    padding: [[theme.spacing(1), theme.spacing(1)]],
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  button: {
    padding: [[0, theme.spacing(1.5), 0, theme.spacing(2)]],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    height: theme.spacing(4),
    textTransform: 'none',
    fontWeight: 'normal',
    boxShadow: theme.shadows[4],
    margin: [[0, theme.spacing(1) / 2]],
    '&:first-of-type': {
      marginLeft: '0',
    },
    '&:active': {
      background: theme.palette.grey.veryLight,
      boxShadow: theme.shadows[4],
      color: theme.palette.text.primary,
    },
    '&:hover, &:active:hover': {
      cursor: 'pointer',
      background: theme.palette.grey.veryLight,
    },
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  visualPinTo: {
    padding: [[theme.spacing(1), theme.spacing(10)]],
    display: 'flex',
    paddingRight: 25
   },
 

  customMenu: {
    padding: 0,
    minWidth: 200,
    maxHeight: '60vh',
    overflow: 'auto',
    marginTop: theme.spacing(1),
    border: [[1, 'solid', theme.palette.grey.light]],
    marginTop: '50px',

  },
  customMenItem: {
    '&.MuiSvgIcon-root': {
      display: 'none'
    }
  },
  customSubMenuItems: {
    minWidth: 200,
    maxHeight: '60vh',
    overflow: 'auto',
  },

  menuContainer: {
    marginTop: 100,
    border: [[1, 'solid', theme.palette.grey.light]],
  }
};
