import theme from '../../../assets/theme';

export default {
  moreTab: {
    opacity: 1,
    color: theme.palette.primary.main,
    '& img': {
      paddingLeft: theme.spacing(1),
    },
    '&$textColorInherit': {
      '&$disabled ': {
        opacity: '1',
      },
    },
  },
  textColorInherit: {},
  selected: {},
  disabled: {},
  moreMenu: {
    marginTop: '-2px',
  },
  relative: {
    display: 'block',
    left: '0',
    padding: [['6px', theme.spacing(2), '6px', theme.spacing(10)]],
    width: 'auto',
    height: '48px',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
    position: 'relative',
  },
  externalTab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: [[0, 0, 0, theme.spacing(1)]],
    zIndex: '4',
  },
  container: {
    top: '0',
    right: '0',
    zIndex: '9999',
    display: 'flex',
    padding: [['6px', theme.spacing(10), '6px', '0']],
    alignItems: 'center',
  },
  adminContainer: {
    top: '0',
    right: '0',
    zIndex: '9999',
    display: 'flex',
    padding: [['6px', theme.spacing(10), '0', '0']],
    alignItems: 'center',
  },
};
