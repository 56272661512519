import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {SelectField} from '../fields';

const SortSelectFormField = ({disabled, totalCount}) => {
  let weightList = [];
  if (totalCount) {
    for (let index = 1; index <= totalCount+50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  } else {
    for (let index = 1; index <= 50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  }

  return (
    <Field
      validate={value => (value ? undefined : formatMessage('required'))}
      name='weightage'
      render={({input, meta}) => (
        <SelectField
          label={formatMessage('Weight')}
          value={input.value}
          handleChange={input.onChange}
          extra={meta.error}
          options={weightList}
          disabled={disabled}
        />
      )}
    />
  );
};

SortSelectFormField.propTypes = {
  disabled: PropTypes.bool,
};

SortSelectFormField.defaultProps = {
  disabled: false,
};

export default SortSelectFormField;
