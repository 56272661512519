import theme from '../../../assets/theme';

export default {
  appBar: {
    top: '-56px',
    display: 'grid',
    '-ms-grid-columns': 'auto 1fr auto',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: '"logo dashboards extra" "fixedLogoBackground pagination pagination "',
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  relative: {
    display: 'block',
    left: '0',
    padding: [['6px', theme.spacing(2), '6px', theme.spacing(10)]],
    width: 'auto',
    height: '48px',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
    position: 'relative',
  },
};
