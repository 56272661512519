import theme from '../../../assets/theme';

export default {
  appBarTopMargin: {
    top: '-56px',
    paddingTop: theme.spacing(1),
  },
  appBar: {
    flex: '0 0 auto',
    display: 'grid',
    '-ms-grid-columns': 'auto 1fr auto',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: '"logo dashboards extra" "fixedLogoBackground pagination pagination "',
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    zIndex: 999,
  },
  relative: {
    display: 'block',
    left: '0',
    padding: [['6px', theme.spacing(2), '6px', theme.spacing(1)]],
    width: 'auto',
    height: '48px',
    borderRadius: [[0, 0, theme.spacing(1), 0]],
    position: 'relative',
  },
  button: {
    padding: [[theme.spacing(1), theme.spacing(1)]],
    fontSize: '12px',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  buttonContainer: {
    padding: [[theme.spacing(1), theme.spacing(1)]],
  },
};
