import axios from 'axios';
import axiosRetry from 'axios-retry';
import {serverURL} from '../assets/constants';
import {Routes} from '../Routes';

export const AXIOS_REQUEST_CANCELED = 'AXIOS_REQUEST_CANCELED';

axiosRetry(axios, {retries: 3, retryDelay: axiosRetry.exponentialDelay});

const SeaburyAxios = axios.create({
  baseURL: serverURL,
  headers: {
    'Content-Type': 'application/json',
    //'Cache-Control': 'no-cache'
  },
});

const ENDPOINT_WITHOUT_BEARER_TOKEN = ['/api/auth', '/api/configuration'];
const urlDoesNotRequireBearer = config => ENDPOINT_WITHOUT_BEARER_TOKEN.includes(config.url);

SeaburyAxios.interceptors.request.use(
  function(config) {
    if (urlDoesNotRequireBearer(config)) {
      return config;
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      return Promise.reject('Unauthorized [no token found]');
    }
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

SeaburyAxios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error && error.response && error.response.status === 401 && !error.response.config.url.endsWith('/api/auth')) {
      localStorage.clear();
      window.location.href = Routes.LANDING;
    }

    return Promise.reject(error);
  }
);
export default SeaburyAxios;
