import React, {useCallback, useState} from 'react';
import {withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useStore} from 'outstated';

import Menu from '../../menu';
import styles from './navigation-icons-styles';
import {Routes} from '../../../Routes';
import DashboardStore from '../../../state/dashboard';

const NavigationIcon = ({classes, IconComponent, placement, render, onClose, help}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {mutations: DashboardMutations} = useStore(DashboardStore);
  const handleClose = useCallback(() => {
    if (onClose) onClose();
    setAnchorEl(null);
  }, [onClose]);

  const history = useHistory();
  const handlehelpClick = useCallback(
    () => {
      DashboardMutations.setActiveDashboard(null);
     history.push(`${Routes.HELP}`);
    },
    [history]
  );

  return (
    <>
    {render && !help ? (
      <>
        <div
          className={classes.iconContainer}
          onClick={e => (anchorEl ? null : setAnchorEl(e.target))}
          style={{pointerEvents: anchorEl ? 'none' : ''}}
          >
          <div className={classes.icons}>{IconComponent}</div>
        </div>
        <Menu open={Boolean(anchorEl)} placement={placement} anchorEl={anchorEl} onClickAway={handleClose}>
          {render({onClose: handleClose})}
        </Menu>
      </>
      ) : (
        <>
        <div
          className={classes.iconContainer}
          onClick={handlehelpClick}
          style={{pointerEvents: anchorEl ? 'none' : ''}}
          >
          <div className={classes.icons}>{IconComponent}</div>
        </div>
      </>
      )}
    </>
  );
};

NavigationIcon.propTypes = {
  IconComponent: propTypes.node.isRequired,
  placement: propTypes.string,
  render: propTypes.func,
  onClose: propTypes.func,
};

export default withStyles(styles)(NavigationIcon);
