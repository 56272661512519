import React, {useState, useCallback} from 'react';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {useHistory} from 'react-router-dom';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {FormDialog} from '../../../components/dialog';
import Button from '../../../components/button';
import {Field} from 'react-final-form';
import {TextField} from '../../../components/form/fields';
import {NOTIFICATION_TITLE_MAX_LENGTH} from '../../../assets/constants';
import {SectionAdminStore} from '../../../state/section/store';
import {Routes} from '../../../Routes';
import {getAuthenticadUserInfo} from '../../../utils/access-token';
import {SelectField} from '../../../components/form/fields';

const HelpSectionCreate = ({classes, initialValues}) => {
  initialValues = {
    isShow: true
  }

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const {mutations: SectionMutations, effects: SectionEffects,state: SectionState} = useStore(SectionAdminStore);

  let weightList = [];
  if (SectionState.totalCount) {
    for (let index = 1; index <= SectionState.totalCount+50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  } else {
    for (let index = 1; index <= 50; index++) {
      weightList.push({
        value: index,
        text: index,
      });
    }
  }

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async values => {
      const userInfo = getAuthenticadUserInfo();
      if (values) {
        values.section = values.section.trimEnd();
       await SectionEffects.createSection(values);
      }
      setOpen(false);

      history.push(Routes.ADMIN.SECTIONS);
    },
    [SectionMutations, history]
  );

  return (
    <>
      <Button variant='outlined' icon={'add'} onClick={() => setOpen(true)}>
        {formatMessage('New section')}
      </Button>
      <FormDialog
        initialValues={initialValues}
        open={open}
        title={formatMessage('New help section')}
        onClose={handleOnClose}
        button={formatMessage('Create')}
        onSubmit={handleSubmit}
        computeDisabled={({...formData}) => !formData.valid || formData.pristine}
      >
        <Field
          name='section'
          validate={value => (value ? !value.match(/^[^\s].{2,100}$/) : formatMessage('required'))}
          render={({input, meta}) => {
            return (
              <>
                <TextField
                  label={formatMessage('Section name')}
                  value={input.value}
                  handleChange={input.onChange}
                  showError={(meta.modified && !input.value) || (meta.modified && !input.value.match(/^[^\s].{2,100}$/))}
                  error={formatMessage('Please fill in a section name with a minimum 3 character')}
                  isWarning
                  maxLength={NOTIFICATION_TITLE_MAX_LENGTH}
                />
              </>
            );
          }}
        />
        <Field
          name='weightage'
          validate={value => (value ? undefined : formatMessage('required'))}
          render={({input, meta}) => {
            return (
              <>
                <SelectField
                  label={formatMessage('Weight')}
                  value={input.value}
                  handleChange={input.onChange}
                  extra={meta.error}
                  options={weightList}
                />
              </>
            );
          }}
        />
         <Field
          name='isShow' 
          render={({input, meta}) => {
            return (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='isShow'
                      color={'primary'}
                      checked={input.value}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  }
                  label="Show on help page"
                />
              </>
            );
          }}
        />
      </FormDialog>
    </>
  );
};

export default HelpSectionCreate;
