import React from 'react';
import {Form as FinalForm} from 'react-final-form';

const Form = ({onSubmit, initialValues, children}) => {
  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
      {props => <form onSubmit={props.handleSubmit}>{children(props)}</form>}
    </FinalForm>
  );
};

export default Form;
