import React, {useEffect, useRef, useCallback} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useStore} from 'outstated';
import classNames from 'classnames';
import {scrollTop, isIE11} from '../utils';
import AppStore from '../../../state/app';
import {Routes} from '../../../Routes';
import {withStyles} from '@material-ui/core';
import styles from './navigation-icons-styles';

const Container = ({classes, sticky, children, className}) => {
  const location = useLocation();
  const nav = useRef(null);
  const {state: AppState} = useStore(AppStore);

  const makeSticky = useCallback(() => {
    if (nav && nav.current && sticky) {
      window.requestAnimationFrame(function() {
        nav.current.style.position = scrollTop() > 8 ? 'fixed' : '';
      });
    }
  }, [sticky]);

  useEffect(() => {
    if (!isIE11 && sticky) {
      window.addEventListener('scroll', makeSticky);
      return () => {
        window.removeEventListener('scroll', makeSticky);
      };
    }
  }, [sticky, makeSticky]);

  return (
    <>
      {AppState.token === null && (
        <Redirect
          to={{
            pathname: Routes.LOGIN,
            state: {pathname: location.pathname !== Routes.MS_RETURN_TOKEN ? location.pathname : null},
          }}
        />
      )}
      <div className={classes.wrapper}>
        <div ref={nav} className={classNames(classes.container, className)}>
          {children}
        </div>
      </div>
    </>
  );
};

Container.protoTypes = {
  sticky: PropTypes.bool,
};

Container.defaultProps = {
  sticky: false,
};

export default withStyles(styles)(Container);
