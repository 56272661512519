import React, {useCallback, useState, useLayoutEffect, useRef, useMemo} from 'react';
import {
  ClickAwayListener,
  Divider,
  Fab,
  MenuItem,
  MenuList,
  withStyles,
  useMediaQuery,
  Grid,
  RootRef,
  Typography,
} from '@material-ui/core';
import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import {useStore} from 'outstated';
import classNames from 'classnames';
import NestedMenuItem from 'material-ui-nested-menu-item';

import Menu from '../../../components/menu';
import {enableFilter} from '../../../utils/filters';
import {
  DATE_RANGE_FILTER_TYPE,
  defaultFilterValueIdentifier,
  FILTER_STICKY_MAX_HEIGHT,
} from '../../../assets/constants';
import styles from './filters-styles';
import DashboardStore from '../../../state/dashboard';
import {isIE11} from '../../../components/navigation/utils';
import PintoArticleBox from '../../../components/pinto-article-box';

const ResetFilterOption = ({isFilterV2, filter, selectFilter, filterID, resetLabel,label}) => {
  const MenuItemComponent = (
    <MenuItem onClick={() => selectFilter(filterID, '', label)} className='menu-item'>
      {resetLabel || 'Reset filter'}
    </MenuItem>
  );

  if (isFilterV2) {
    return resetLabel && MenuItemComponent;
  }

  return (
    !filter.values.find(v => typeof v === 'string' && v.startsWith(defaultFilterValueIdentifier)) && MenuItemComponent
  );
};

const Filters = props => {
  const matches = useMediaQuery(`(max-height:${FILTER_STICKY_MAX_HEIGHT}px)`);
  const {classes, filtersEnabled} = props;
  const filtersRef = useRef();
  const tabSubAreaRef = useRef([]);
  const tabSubCountryRef = useRef([]);
  const tabRef = useRef([]);

  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorWidth, setAnchorWidth] = useState(null);
  const [clickedFilter, setClickedFilter] = useState(null);

  const [menuPosition, setMenuPosition] = useState({top: 10, left: 10});
  const menuItemRef = useRef(null);

  const TOP_POSITION = useMemo(() => (matches ? 0 : 48), [matches]);
  const TOP_POSITION_FULL_SCREEN = useMemo(() => (matches ? 0 : 53), [matches]);

  useLayoutEffect(() => {
    const updateShadow = () => {
      if (filtersRef && filtersRef.current) {
        window.requestAnimationFrame(function() {
          const scrollTop = filtersRef.current.getBoundingClientRect().top;

          if (scrollTop <= 48 + 16) {
            const opacity = 1 - (scrollTop - TOP_POSITION) / 48;
            filtersRef.current.style.boxShadow = `0px 8px 10px -5px rgba(0,0,0,${opacity * 0.2})`;
          } else {
            filtersRef.current.style.boxShadow = `none`;
          }
        });
      }
    };

    if (!isIE11) window.addEventListener('scroll', updateShadow);

    return function removeScrollEventListener() {
      window.removeEventListener('scroll', updateShadow);
    };
  }, [TOP_POSITION]);

  const openMenu = useCallback((event, filterID) => {
    setAnchorEl(event.currentTarget);
    setClickedFilter(filterID);
    setAnchorWidth(event.currentTarget.offsetWidth);
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    setClickedFilter(null);
  }, []);

  const selectFilter = useCallback(
    (filterID, valueID, label, type, value, value2, value1) => {

      let selection  = {};
      if(value && value2 && value1) { // Selected value3
        selection = {
          value1: value1.value,
          type1: value1.type,
          value2: value2.value,
          type2: value2.type,
          value3: value.value,
          type3: value.type
        }
      }
      if(value && value2 && !value1) { // Selected value2
        selection = {
          value1: value2.value,
          type1: value2.type,
          value2: value.value,
          type2: value.type,
          subValue: value.value3 ? value.value3 : null
        }
      }
      if(value && !value2 && !value1) { // Selected value
        selection = {
          value1: value.value,
          type1: value.type
        }
      }

      if (Object.keys(selection).length) {
        DashboardMutations.setFilterLevels({
          ...DashboardState.current.filterLevels,
          selection
        });
      }
      

      const newArray = enableFilter(
        DashboardState.current.filters.values,
        filterID,
        valueID,
        DashboardState.current.filters.isFilterV2,
        type,
        value,
        label,
        selection
      );

      DashboardMutations.setFilters({values: newArray, isFilterV2: DashboardState.current.filters.isFilterV2});

      closeMenu();
    },
    [DashboardState, DashboardMutations, closeMenu]
  );

  const handleClose = () => {
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
  };

  return (
    <>
      <div
        onMouseLeave={handleClose}
        ref={filtersRef}
        className={classNames(classes.container, props.isFullScreenView && classes.labelSpace)}
        style={props.isFullScreenView ? {top: TOP_POSITION_FULL_SCREEN} : {top: TOP_POSITION}}
      >
        <Grid container>
          <Grid item sm={12} md={!props.isChartLayout && props.hasHeaderArticle?.length ? 10 : 12}>
            <span className={classes.title}>Filters</span>

            {DashboardState.current.filters.values.map((filterItem, filterID) => {
              // TODO: Remove isFilterV2

              const filter = DashboardState.current.filters.isFilterV2
                ? {...filterItem.pbi, defaultValues: filterItem.defaultFilterValues?.length ? filterItem.defaultFilterValues : filterItem.defaultValues}
                : filterItem;
              if (filter.hidden) return null;

              let activeFilterValueName = 'All';
              if (
                filter.active ||
                (DashboardState.current.filters.isFilterV2 &&
                  filter.active !== '' &&
                  filter.defaultValues &&
                  filter.defaultValues.length !== 0)
              ) {
                activeFilterValueName = filter.active || filter.defaultValues[0];

                if (filter.type === DATE_RANGE_FILTER_TYPE) {
                  activeFilterValueName = activeFilterValueName
                    .split('/')
                    .slice(0, 1)
                    .toString();
                }

                activeFilterValueName = activeFilterValueName.replace(defaultFilterValueIdentifier, '');
              }

              return (
                <>
                  <Fab
                    disabled={!filtersEnabled}
                    key={filterID}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup='true'
                    onClick={event => (clickedFilter !== filterID ? openMenu(event, filterID) : closeMenu())}
                    variant='extended'
                    disableRipple
                    className={classes.button}
                  >
                    {`${
                      DashboardState.current.filters.isFilterV2 ? filterItem.label : filter.target.column
                    }: ${activeFilterValueName}`}
                    {clickedFilter === filterID && <KeyboardArrowUp className={classes.arrow} />}
                    {clickedFilter !== filterID && <KeyboardArrowDown className={classes.arrow} />}
                    <>
                      <Menu
                        open={clickedFilter === filterID}
                        anchorEl={anchorEl}
                        type={'filter'}
                        style={{minWidth: anchorWidth, maxHeight:'60vh', overflow:'auto', marginTop:'8px'}}
                        onClickAway={closeMenu}
                        onMouseLeave={closeMenu}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <ResetFilterOption
                          isFilterV2={DashboardState.current.filters.isFilterV2}
                          filter={filter}
                          selectFilter={selectFilter}
                          filterID={filterID}
                          resetLabel={filterItem.resetLabel}
                          label={filterItem.label}
                        />

                        {(!DashboardState.current.filters.isFilterV2
                          ? filter?.values
                          : filter.filterValues && filter.target.table !== "DateRange"
                          ? filter?.filterValues
                          : filter?.values
                        ).map((value, valueID) => {
                          if (value === null) {
                            return null;
                          }

                          if (filter.type === DATE_RANGE_FILTER_TYPE) {
                            value = value.value
                              .split('/')
                              .slice(0, 1)
                              .toString();
                          }

                          return (
                            <>
                              {!DashboardState.current.filters.isFilterV2 && (
                                <>
                                  <MenuList className='menu-items'>
                                    <MenuItem
                                      key={valueID}
                                      onClick={() => selectFilter(filterID, valueID, filterItem.label)}
                                      className={classes.customMenItem}
                                    >
                                      {value.replace(defaultFilterValueIdentifier, '')}
                                    </MenuItem>
                                  </MenuList>
                                </>
                              )}

                              {DashboardState.current.filters.isFilterV2 && filter?.filterValues && filter.target.table !== "DateRange" ? (
                                <>
                                  <MenuList className='menu-items'>
                                    {!value?.value2?.length ? (
                                      <MenuItem
                                        key={valueID}
                                        onClick={() => selectFilter(filterID, valueID, filterItem.label, value.type, value)}
                                        className={classes.customMenItem}
                                      >
                                        {value.value.replace(defaultFilterValueIdentifier, '')}
                                      </MenuItem>
                                    ) : (
                                      <NestedMenuItem
                                        ref={menuItemRef}
                                        label={value.value.replace(defaultFilterValueIdentifier, '')}
                                        rightIcon={null}
                                        parentMenuOpen={!!menuPosition}
                                        onClick={() => selectFilter(filterID, valueID, filterItem.label, value.type, value)}
                                        className={classes.customMenItem}
                                      >
                                        {value?.value2?.length &&
                                          value?.value2.map((val, index) => {
                                            return (
                                              <MenuList className={classes.customSubMenuItems} style={{marginLeft:'15px'}}>
                                                {!val?.value3?.length && (
                                                  <MenuItem
                                                  onClick={() => selectFilter(filterID, index, filterItem.label, val.type, val,value)}
                                                  arrow={false}
                                                    className={classes.customMenItem}
                                                  >
                                                    {val.value.replace(defaultFilterValueIdentifier, '')}
                                                  </MenuItem>
                                                )}
                                                {val?.value3?.length && (
                                                  <NestedMenuItem
                                                    label={val.value.replace(defaultFilterValueIdentifier, '')}
                                                    rightIcon={null}
                                                    parentMenuOpen={!!menuPosition}
                                                    className={classes.customMenItem}
                                                    arrow={false}
                                                    onClick={() => selectFilter(filterID, index, filterItem.label, val.type, val,value)}
                                                  >
                                                    <MenuList className='menu-items'>
                                                      {val?.value3?.length &&
                                                        val?.value3.map((rel, i) => {
                                                          return (
                                                            <MenuItem
                                                              className={classes.customMenItem}
                                                              key={i}
                                                              onClick={() => selectFilter(filterID, i, filterItem.label, rel.type, rel, val,value)}
                                                            >
                                                              {rel.value.replace(defaultFilterValueIdentifier, '')}
                                                            </MenuItem>
                                                          );
                                                        })}
                                                    </MenuList>
                                                  </NestedMenuItem>
                                                )}
                                              </MenuList>
                                            );
                                          })}
                                      </NestedMenuItem>
                                    )}
                                  </MenuList>
                                </>
                              ) : (
                                <>
                                  <MenuList className='menu-items'>
                                    <MenuItem
                                      key={valueID}
                                      onClick={() => selectFilter(filterID, valueID, filterItem.label, )}
                                      className={classes.customMenItem}
                                    >
                                      {value.replace(defaultFilterValueIdentifier, '')}
                                    </MenuItem>
                                  </MenuList>
                                </>
                              )}
                            </>
                          );
                        })}
                      </Menu>
                    </>
                  </Fab>
                </>
              );
            })}
          </Grid>
          {!props.isChartLayout && props.hasHeaderArticle?.length ? (
            <Grid item sm={12} md={2} onMouseLeave={handleClose}>
              <div className={classes.visualPinTo}>
                <PintoArticleBox type={'pageHeader'} />
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(Filters);
