import theme from '../../../assets/theme';

export default {
  dialog: {
    maxHeight: `calc(100% - ${theme.spacing(20)}px)`,
    padding: [[theme.spacing(2), 0, 0]],
  },
  sm: {
    width: 350,
    maxWidth: 350,
  },
  md: {
    width: 760,
    maxWidth: 760,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: [[0, theme.spacing(2)]],
    margin: [[0, 0, theme.spacing(2)]],
  },
  h1: {
    fontSize: '18px',
    lineHeight: '20px',
    margin: [[0, 'auto', 0, 0]],
    color: theme.palette.text.primary,
  },
  close: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  content: {
    padding: 0,
    margin: 0,
    fontSize: '16px',
    color: theme.palette.text.primary,
    '&.scrolled': {
      borderTop: [[1, 'solid', theme.palette.grey.light]],
    },
  },
  text: {
    margin: 0,
    color: theme.palette.text.primary,
    '& p': {
      margin: 0,
    },
    '& > p': {
      padding: [[0, theme.spacing(2), theme.spacing(2)]],
    },
    '& table': {
      margin: 0,
      borderSpacing: 0,
    },
    '& tr': {
      padding: [[0, theme.spacing(2)]],
    },
    '& th': {
      fontSize: 14,
      color: theme.palette.grey.mid,
      whiteSpace: 'nowrap',
      fontWeight: 'normal',
    },
    '& th, & td': {
      borderBottom: [[1, 'solid', theme.palette.grey.light]],
      textAlign: 'left',
      verticalAlign: 'text-top',
      padding: theme.spacing(2),
    },
    '& td p ~ p': {
      marginTop: theme.spacing(2),
    },
  },
};
