import HelpArticleService from '../../services/help-service';
import logger from '../../utils/logger';
import formatMessage from 'format-message';
import {AXIOS_REQUEST_CANCELED} from '../../services/SeaburyAxios';

export default (state, mutations, dialog) => ({

  getSections: async ({isSearch = false} = {}) => {
    mutations.setLoading(true);
    try {
      const params =
        state.filters.query && state.filters.query.length
          ? {
              ...state.filters,
              limit: state.queryPagination.limit,
              from: state.queryPagination.offset,
            }
          : {
              ...state.filters,
              limit: state.pagination.limit,
              from: state.pagination.offset,
            };
      const data = isSearch
        ? await HelpArticleService.getSections(params)
        : await HelpArticleService.getSections(params);

      mutations.setSectionData(data);
      return data;
    } catch (err) {
      logger.warn(err);

      if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

      const errorMessage = formatMessage(
        'Due to a technical issue, the section data is currently not available. Please try again later.'
      );
      mutations.setArticleError(errorMessage);
      dialog.showError({
        title: formatMessage('Data cannot be retrieved.'),
        message: errorMessage,
      });
      return null;
    }
  },
});
