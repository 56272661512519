import {useMemo, useState} from 'react';
import {useStore} from 'outstated';
import produce from 'immer';
import DialogStore from '../../dialog';
import NotificationsMutations, {initialNotificationsState} from '../mutations';
import NotificationEffects from '../effects';
import {
  OLD_NOTIFICATION_AGE,
  VALID_STATUSES,
  VALID_TYPES,
  USER_LIST_TYPES,
  MAX_NOTIFICATIONS_MAILBOX,
  AdminTypeOptions,
} from '../constants';
import NotificationsService from '../../../services/notifications-service';
import logger from '../../../utils/logger';
import formatMessage from 'format-message';
import {AXIOS_REQUEST_CANCELED} from '../../../services/SeaburyAxios';

const getPublishedFromString = () => {
  const now = new Date();
  const publishedFromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - OLD_NOTIFICATION_AGE, 0, 0, 0);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
  const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(publishedFromDate);
  return `${year}-${month}-${day}`;
};

const getMailboxItems = notifications => {
  // NEWS and PRIORITY_DATA_UPDATE first
  const mailboxItems = notifications.filter(notification =>
    [AdminTypeOptions[1].label, AdminTypeOptions[3].label].includes(notification.typeAdminLabel)
  );
  if (mailboxItems.length >= MAX_NOTIFICATIONS_MAILBOX) {
    return mailboxItems.slice(0, MAX_NOTIFICATIONS_MAILBOX);
  }
  // DATA UPDATES last
  const dataUpdates = notifications.filter(notification =>
    [AdminTypeOptions[2].label].includes(notification.typeAdminLabel)
  );
  return [...mailboxItems, ...dataUpdates]
    .slice(0, MAX_NOTIFICATIONS_MAILBOX)
    .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());
};

const Mailbox = notificationState => {
  const initialState = {
    ...initialNotificationsState,
    filters: {
      status: VALID_STATUSES[1], // PUBLISHED
      publishedFrom: getPublishedFromString(),
      sortBy: 'publishedAt',
      type: USER_LIST_TYPES,
      withMessage: false,
    },
    insights: {},
    announcement: null,
    priorityDataUpdates: [],
  };
  const [state, setState] = useState(notificationState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...NotificationsMutations(setState),

      setInsights: insights =>
        setState(
          produce(draft => {
            draft.insights = insights;
          })
        ),
      setAnnouncement: notification =>
        setState(
          produce(draft => {
            draft.announcement = notification;
          })
        ),
    }),
    [state]
  );
  const effects = useMemo(
    () => ({
      ...NotificationEffects(state, mutations, DialogMutations),
      getInsights: async () => {
        try {
          const params = {
            type: VALID_TYPES[4],
            status: VALID_STATUSES[1],
            from: 0,
            limit: 500, // FIXME: find a better way to handle pagination
            withMessage: true,
          };
          const data = await NotificationsService.getNotifications(params);
          let insights = {};
          if (data && data.docs && data.docs.length) {
            insights = data.docs.reduce((acc, notification) => {
              acc[notification.insightInfo.location] = notification;
              return acc;
            }, {});
          }
          mutations.setInsights(insights);
          return insights;
        } catch (err) {
          logger.warn(err);
          const errorMessage = formatMessage(
            'Due to a technical issue, the notification data is currently not available. Please try again later.'
          );
          mutations.setNotificationsError(errorMessage);
          DialogMutations.showError({
            title: formatMessage('Data cannot be retrieved.'),
            message: errorMessage,
          });
          return null;
        }
      },
      getAnnouncement: async () => {
        const data = await NotificationsService.getAnnouncement();
        if (data) {
          mutations.setAnnouncement(data);
        }
      },
      // get notifications override to also get priority updates
      getNotifications: async () => {
        mutations.setLoading(true);
        try {
          const params = {
            ...state.filters,
            limit: 500, // FIXME: find a better way to handle pagination
            from: state.pagination.offset,
          };
          const data = await NotificationsService.getNotifications(params);
          const docs = getMailboxItems(data.docs);
          const finalData = {...data, docs};
          mutations.setNotificationsData(finalData);
          return finalData;
        } catch (err) {
          logger.warn(err);

          if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

          const errorMessage = formatMessage(
            'Due to a technical issue, the notification data is currently not available. Please try again later.'
          );
          mutations.setNotificationsError(errorMessage);
          DialogMutations.showError({
            title: formatMessage('Data cannot be retrieved.'),
            message: errorMessage,
          });
          return null;
        }
      },
    }),
    [mutations, DialogMutations, state]
  );
  return {state, mutations, effects};
};
export default Mailbox;
