import {Card as MuiCard, CardContent, Grid, withStyles} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import DeleteIcon from '../../assets/icons/icon_24_delete_blue.svg';
import CloseIcon from '../../assets/icons/icon_24_close_blue.svg';
import WarningCloseIcon from '../../assets/icons/icon_24_close_warning.svg';
import styles from './card-styles';

const Card = ({
  classes,
  className,
  title,
  link,
  onCardClick = null,
  onButtonClick = null,
  button = null,
  children = null,
  applyHover = true,
}) => {
  // Variables
  const icon = {
    trash: {src: DeleteIcon, alt: 'Delete'},
    cross: {src: CloseIcon, alt: 'Remove'},
    warningCross: {src: WarningCloseIcon, alt: 'Remove'},
  }[button];

  return (
    <MuiCard
      className={classNames(classes.container, className, applyHover && classes.containerHover)}
      onClick={onButtonClick ? null : onCardClick}
    >
      <CardContent className={classes.content}>
        {children}
        <Grid container alignItems='center' justify='space-between'>
          <Grid
            item
            className={classNames(classes.text, {[classes.croptext]: onButtonClick})}
            onClick={onButtonClick ? onCardClick : null}
          >
            {title && <h3 className={classes.title}>{title}</h3>}
            {link && <p className={classes.link}>{link}</p>}
          </Grid>
          {button && (
            <Grid item onClick={onButtonClick}>
              <img className={classes.icon} src={icon.src} alt={icon.alt} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </MuiCard>
  );
};

Card.propTypes = {
  title: propTypes.string,
  link: propTypes.string,
  onCardClick: propTypes.func,
  onButtonClick: propTypes.func,
  button: propTypes.string,
};

export default withStyles(styles)(Card);
