export default {
  company: 'Seabury',
  description: 'Some sort of text description to explain what it does and how it makes your life easier and better.',
  errors: {
    loading: 'Oops, something went wrong!<br />Please try again',
  },
  dialogs: {
    cancel: 'Cancel',
    ok: 'Ok'
  },
  menu: {
    logout: 'Log out',
    serviceWorker: 'ServiceWorker',
    cleanRemoteCache: 'Clean remote cache',
    admin: 'Admin Dashboard',
    notifications: 'Admin Notifications',
  },
  admin: {
    title: 'Dashboard admin area',
    datasetTitle: 'Dataset admin area',
    dashboardsURL: `${window.location.hostname}/`,
    addDashboard: 'New dashboard',
    addDataset: 'New dataset',
    editDashboard: 'Edit dashboard',
    editDataset: 'Edit dataset',
    addDashboardInSubmenu: 'Show this dashboard in dropdown',
    addDatasetInSubmenu: 'Show this dataset in dropdown',
  },
  editing: {
    createDialog: {
      title: 'New dashboard',
    },
    createPageDialog: {
      title: 'Create new page',
    },
    duplicateURL: 'This url already exists. Please choose a different one.',
    createButton: 'Create',
    updateButton: 'Save',
    saveButton: 'Done',
  },
  notifications: {
    new: 'New notification',
    type: 'Type',
    types: {
      dataUpdate: 'Data update',
      news: 'News',
      insight: 'Insight',
      announcement: 'Announcement',
    },
    errors: {
      emptyNameWarning: 'Please fill in a title name',
    },
  },
};
