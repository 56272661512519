import React from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import {Button, MenuItem, Divider, withStyles} from '@material-ui/core';
import Menu from '../../../components/menu';
import {ReactComponent as MoreIcon} from '../../../assets/icons/icon_24_more_blue.svg';
import styles from './help-section-table-row-actions-styles';


const HelpTableActions = ({classes, onEditClick, onDeleteClick}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (event) event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = event => {
    onEditClick();
    handleClose(event);
  };

  const handleDelete = event => {
    onDeleteClick();
    handleClose(event);
  };

  return (
    <>
      <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} placement='bottom-end' open={Boolean(anchorEl)} onClickAway={handleClose}>
        <MenuItem onClick={handleEdit}>{formatMessage('Edit')}</MenuItem>
        <Divider className={classes.divider} />
        <MenuItem onClick={handleDelete}>{formatMessage('Delete')}</MenuItem>
      </Menu>
    </>
  );
};

HelpTableActions.propTypes = {
  classes: propTypes.object.isRequired,
  onEditClick: propTypes.func.isRequired,
  onDeleteClick: propTypes.func.isRequired,
};

export default withStyles(styles)(HelpTableActions);
