import theme from '../../../../assets/theme';

export default {
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },

  card: {
    margin: 0,
    marginTop: theme.spacing(2),
    '& img': {
      padding: 0,
      marginLeft: theme.spacing(2),
    },
  },

  removeAllButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },

  emptyMessage: {
    color: theme.palette.grey.mid,
    fontSize: 14,
    margin: 0,
    marginTop: theme.spacing(1),
  },

  invalidCard: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
};
