import React from 'react';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import styles from './product-high-lights-styles';
import MarketIntelligence from '../../../assets/images/market-intelligence-databases.png';
import dynamicReport from '../../../assets/images/dynamic-reports.png';
import realTimeTracking from '../../../assets/images/real-time-capacity-tracking.png';

const ProductHighLights = props => {
  const {classes} = props;

  return (
    <section className={classes.wrapper}>
      {/* Market intelligence databases */}
      <div className={classes.mainSection}>
        <Grid container direction='row' spacing={0}>
          <Grid item xs={12} sm={12} md={7} className={classes.productImageSection}>
            <img src={MarketIntelligence} alt={'AirCargo'} className={classes.productImg} />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Container maxWidth='xs' className={classes.productDescription}>
              <div className={classes.endTextContent}>
                <h1 className={classes.title}>Market intelligence databases</h1>
                <h2 className={classes.subTitle}>GLOBAL COVERAGE</h2>
                <p className={classes.productContent}>
                Monthly updated global demand data covering close to 100% of international air and ocean freight per trade lane with visibility on ~2000 separate commodities. Over 20+ years of history available.
                </p>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
      {/* Market intelligence databases */}

      {/* Market dynamics reports */}
      <div className={classes.secondMainSection}>
        <Grid container direction='row' spacing={0}>
          <Grid item xs={12} sm={12} md={5} className={classes.productSecondContent}>
            <Container maxWidth='xs' className={classes.productDescription}>
              <div className={classes.startTextContent}>
                <h1 className={classes.title}>Market dynamics reports</h1>
                <h2 className={classes.subTitle}>INDUSTRY DEVELOPMENTS</h2>
                <p className={classes.productContent}>
                  Receive actionable analyses on relevant air and ocean freight trends via market reports, exclusively available to our clients. Translating trends into quantitative insight.
                </p>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={7} className={classes.productSecondImageSection}>
            <img src={dynamicReport} alt={'AirCargo'} className={classes.productSecondImg} />
          </Grid>
        </Grid>
      </div>
      {/* Market dynamics reports */}

      {/* Real-time insights */}
      <div className={classes.secondMainSection}>
        <Grid container direction='row' spacing={0}>
          <Grid item xs={12} sm={12} md={7} className={classes.productImageSection}>
            <img src={realTimeTracking} alt={'AirCargo'} className={classes.productImg} />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Container maxWidth='xs' className={classes.productDescription}>
              <div className={classes.endTextContent}>
                <h1 className={classes.title}>Real-time insights</h1>
                <h2 className={classes.subTitle}>TIMELY AND DETAILED DATA</h2>
                <p className={classes.productContent}>
                  Worldwide flown air cargo capacity of both passenger and full freighter flights per airline on an airport-to-airport basis; based on flight tracking data and updated weekly for real-time insights.
                </p>
              </div>
            </Container>
          </Grid>
        </Grid>
      </div>
      {/* Real-time insights */}
    </section>
  );
};
export default withStyles(styles)(ProductHighLights);
