import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Redirect, useParams, useHistory, useLocation} from 'react-router-dom';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import _debounce from 'lodash/fp/debounce';
import _isEmpty from 'lodash/fp/isEmpty';
import _isString from 'lodash/fp/isString';
import _isArray from 'lodash/fp/isArray';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Paper from '@material-ui/core/Paper';
import cloneDeep from 'lodash/cloneDeep';
import {
  Card,
  CardContent,
  Grid,
  withStyles,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
  InputAdornment,
  Divider,
  IconButton,
  OutlinedInput,
} from '@material-ui/core';
import {useStore} from 'outstated';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DirectionsIcon from '@material-ui/icons/Directions';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ReactGA from 'react-ga';

import {NotificationsMailboxStore} from '../../../state/notifications/store';
import LoadingSpinner from '../../../components/loading-spinner';
import styles from './help-viewer-styles';
import Footer from '../../../components/footer/footer-view';
import {Routes} from '../../../Routes';
import DashboardStore from '../../../state/dashboard';
import AppStore from '../../../state/app';
import HelpNavigation from '../navigation';
import {HelpAdminStore, PinArticleStore} from '../../../state/help/store';
import ArticleDetailsDialog from './article-dialog/article-detail-view';
import {PINTO_TYPES} from '../../../state/help/constant';

const showMessage = (classes, title, subtitle) => {
  return (
    <div className={classes.messageWrapper}>
      <p className={classes.message}>{title}</p>
      {subtitle && <p className={classes.message}>{subtitle}</p>}
    </div>
  );
};

const NotificationViewerEmpty = ({classes, message}) => (
  <div className={classes.wrapper}>
    {/* <span className={classNames(classes.title, classes.titleEmpty)}>{formatMessage('My notification mailbox')}</span> */}
    <Grid container spacing={2} alignItems={'center'} justify='space-between' className={classNames(classes.container)}>
      <Grid item sm={12}>
        {message}
      </Grid>
    </Grid>
  </div>
);
const HelpViewerContainer = ({classes}) => {
  const history = useHistory();
  let {id} = useParams();
  const location = useLocation();
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);
  const {state: AppState} = useStore(AppStore);

  // START
  const {state: ArticleState, mutations: ArticlesMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const {
    isOpen,
    article,
    articleList,
    isLoadMore,
    backButton,
    sectionArticles,
    sectionTotalCount,
    page,
    articleFilters,
    loading,
    error,
    currentLoading,
    sectionArticlePagination,
    sectionArticleQueryPagination,
    fromPinTo,
  } = ArticleState;

  const {effects: MailboxEffects} = useStore(NotificationsMailboxStore);
  const {effects: PinArticleEffects} = useStore(PinArticleStore);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
    const id = location.pathname
    .split('/')
    .slice(2,3)
    .join('/');

    const getArticleDetails = async id => {
      history.push(`${Routes.HELP}/${id}`);

      const result = await ArticleEffects.getArticleDetails(id);
      if (result) {
        ArticlesMutations.setArticleDialogValue({
          isOpen: true,
          article: result,
          isLoadMore: false,
          backButton: false,
          fromPinTo: false,
        });
      } else {
        history.push(`${Routes.HELP}`);
      }
    };

    if (id) {
     getArticleDetails(id);
    }

    return () =>  ArticlesMutations.clearCurrentSectionHelp(); // To clear the cached data
  }, [id]);

  useEffect(() => {
    MailboxEffects.getNotifications();
    ArticleEffects.getSectionArticles({isSearch: true});

    PinArticleEffects.getVisualArticles(PINTO_TYPES[0]);
    PinArticleEffects.getArticles();
  }, [articleFilters]);

  let dataArray;
  let sectionArray;
  if (sectionArticles) {
    sectionArray = cloneDeep(sectionArticles);
    dataArray = sectionArray.slice(NaN, 9);
  }

  const [sectionArticleArray, setstate] = React.useState(dataArray);
  const [pageNum, setpagestate] = useState(1);
  const hasQuery = articleFilters.query && articleFilters.query.length;
  const currentPagination = hasQuery ? sectionArticleQueryPagination : sectionArticlePagination;

  const fetchMoreData = useCallback(pages => {
    // next 9 more records append in 1.5 secs

    // if (dataArray >= sectionTotalCount) {
    //   return;
    // }
    const pageNumber = pageNum + 1;
    const newSectionArray = sectionArray.slice(NaN, pageNumber * 9);
    setpagestate(pageNumber);
    setTimeout(() => {
      setstate(newSectionArray);
    }, 1500);
  });

  
  const handleClick = async (value, isLoadMore) => {
    if (isLoadMore) {
      const getSectionDetails = async section => {
        const result = await ArticleEffects.getSectionDetails(section);
        if (result) {
          ArticlesMutations.setArticleDialogValue({
            isOpen: true,
            articleList: result,
            isLoadMore: true,
            backButton: false,
            fromPinTo: false,
          });
        }
      };
      getSectionDetails(value.section);
    } else {
      const result = await ArticleEffects.getArticleDetails(value._id);
      if (result) {
        ArticlesMutations.setArticleDialogValue({
          isOpen: true,
          article: result,
          isLoadMore: false,
          backButton: false,
          fromPinTo: false,
        });
        history.push(`${Routes.HELP}/${value._id}`);
      }else {
        history.push(`${Routes.HELP}`);
      }
    }
  };

  const setQuery = useCallback(_debounce(1000)(ArticlesMutations.setArticleFiltersQuery), []);
  const handleSearch = useCallback(
    event => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  // useEffect(() => {
  //   return () => ArticlesMutations.clearCurrent();
  // }, []);

  const hasFilters = articleFilters.query && articleFilters.query.length;
  const hasArticles = sectionArticles && sectionArticles.length;
  const hasError = error && error.length;

  // END

  const errorMessage = showMessage(
    classes,
    null,
    formatMessage('Due to technical issues, article data is currently not available. Please try again later.')
  );
  const noResultsMessage = hasError
    ? errorMessage
    : showMessage(
        {...classes, messageWrapper: classNames(classes.messageWrapper, classes.messageListWrapper)},
        formatMessage('No results found.'),
        formatMessage('Modify your search criteria.')
      );
  const noArticleMessage = hasError
    ? errorMessage
    : showMessage(classes, formatMessage('You have no help articles yet.'), null);

  return (
    <>
      {!DashboardState.initialized && <LoadingSpinner />}
      {DashboardState.initialized && !AppState.token && (
        <Redirect
          to={{
            pathname: Routes.LOGIN,
            state: {pathname: location.pathname !== Routes.MS_RETURN_TOKEN ? location.pathname : null},
          }}
        />
      )}
      {DashboardState.initialized && AppState.token && (
        <>
          <HelpNavigation />
          {!hasArticles && !hasFilters && !loading && (
            <NotificationViewerEmpty classes={classes} message={noArticleMessage} />
          )}
          {sectionArticles === null && loading && <LoadingSpinner />}
          {(hasArticles || hasFilters) && currentLoading && <LoadingSpinner overlay />}
          {(hasArticles || hasFilters || loading) && sectionArticles !== null && (
            <div className={classes.wrapper}>
              <Grid
                container
                spacing={2}
                alignItems={isOpen && !currentLoading ? 'flex-start' : 'center'}
                justify='space-between'
                className={classNames(classes.container)}
              >
                <Grid item sm={12} md={12}>
                  <div className={classes.headTitle}>
                    <span className={classes.title}>{formatMessage('How can we help you?')}</span>
                  </div>

                  <div className={classes.listContainerContent}>
                    <div className={classes.headTitle}>
                      <OutlinedInput
                        className={classes.input}
                        placeholder={formatMessage('Search')}
                        startAdornment={
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        }
                        defaultValue={articleFilters.query}
                              onChange={handleSearch}
                        inputProps={{
                          maxLength: 40,
                        }}
                      />
                    </div>
                    <Card
                      className={classNames(classes.listContainer, {
                        [classes.emptyListContainer]: !hasArticles || loading,
                      })}
                    >
                      <CardContent className={classes.cardContent}>
                        <>
                          {loading && (
                            <div className={classes.loadingContainer}>
                              <CircularProgress />
                            </div>
                          )}

                          {!loading &&
                            (sectionArticleArray || dataArray) &&
                            (hasArticles && (sectionArticleArray || dataArray) ? (
                              <div className={classes.articleSection}>
                                <InfiniteScroll
                                  style={{overflow: 'hidden'}}
                                  dataLength={sectionArticleArray ? sectionArticleArray?.length : dataArray?.length}
                                  next={fetchMoreData}
                                  hasMore={
                                    (sectionArticleArray ? sectionArticleArray?.length : dataArray?.length) <
                                    sectionTotalCount
                                  }
                                  loader={<h4>Loading...</h4>}
                                >
                                  <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container justify='center' spacing={2}>
                                        {(sectionArticleArray ? sectionArticleArray : dataArray).map((value, index) => (
                                          <Grid key={index} item xs={4}>
                                            <Paper className={classes.paper}>
                                              <div className={classes.item}>
                                                <div className={classes.cardTitle}>
                                                  <span title={formatMessage(value.section)}>{value.section}</span>
                                                </div>
                                              </div>
                                              <div className={classes.listWrap}>
                                                <List component='nav' aria-label='main mailbox folders'>
                                                  {value['article'].slice(0, 5).map((val, index) => (
                                                    <ListItem
                                                      key={index}
                                                      className={classes.listItem}
                                                      button
                                                      onClick={() => {
                                                        handleClick(val, false);
                                                      }}
                                                    >
                                                      <ListItemIcon>
                                                        <AssignmentOutlinedIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary={val.fullTitle} />
                                                    </ListItem>
                                                  ))}
                                                  <ListItem
                                                    key={index}
                                                    className={classes.listItem}
                                                    onClick={() => {
                                                      handleClick(value, true);
                                                    }}
                                                  >
                                                    {value['article'].length > 5 && (
                                                      <span>
                                                        <MoreHorizIcon />
                                                      </span>
                                                    )}
                                                  </ListItem>
                                                </List>
                                              </div>
                                              {value['article'].length > 5 && (
                                                <div
                                                  className={classes.loadMore}
                                                  onClick={() => {
                                                    handleClick(value, true);
                                                  }}
                                                >
                                                  <span>Show all</span>
                                                </div>
                                              )}
                                            </Paper>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </InfiniteScroll>
                              </div>
                            ) : (
                              noResultsMessage
                            ))}
                        </>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>

              <ArticleDetailsDialog
                open={isOpen}
                isOpen={isOpen}
                article={article}
                isLoadMore={isLoadMore}
                backButton={backButton}
                articleList={articleList}
                fromPinTo={fromPinTo}
              />

              <Footer />
            </div>
          )}
        </>
      )}
    </>
  );
};

HelpViewerContainer.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HelpViewerContainer);
