import React from 'react';
import ProptTypes from 'prop-types';
import classNames from 'classnames';
import formatMessage from 'format-message';
import CheckIcon from '../../../../assets/icons/icon_24_check_blue.svg';
import CloseIcon from '../../../../assets/icons/icon_24_close_blue.svg';
import styles from './notification-edit-header-styles';
import {withStyles, Grid, Link} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {NotificationEditDialog} from '../../create-dialog';
import {Field} from 'react-final-form';
import {NOTIFICATIONS_HELP_URL} from '../../../../assets/constants';

const NotificationEditHeader = ({classes, notification, isPublished, onClose}) => {
  return (
    <Field
      name='title'
      render={({input}) => (
        <>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <div className={classes.titleContainer}>
                <div>
                  <img src={CloseIcon} alt='Close' className={classes.closeIcon} onClick={onClose} />
                  <h1 className={classes.title}>{input.value}</h1>
                </div>
                <NotificationEditDialog value={input.value} onChange={input.onChange} />
              </div>
            </Grid>
            <Grid item>
              <div className={classNames(classes.statusTag, {[classes.statusTagWarning]: !isPublished})}>
                {isPublished ? (
                  <>
                    <img src={CheckIcon} alt='Close' />
                    {formatMessage('Published')}
                  </>
                ) : (
                  formatMessage('Unpublished')
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <p className={classes.subtitle}>
                {formatMessage('Notification Type: {type}', {type: notification.typeAdminLabel || notification.type})}
              </p>
              <p className={classes.subtitle}>
                {formatMessage('Author is identified as: {author}', {
                  author: notification.author.name || notification.author.email,
                })}
              </p>
            </Grid>
            <Grid item>
              <div className={classes.infoIcon}>
                <Link title={formatMessage('Notifications help')} target='_blank' href={NOTIFICATIONS_HELP_URL}>
                  <InfoIcon />
                </Link>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

NotificationEditHeader.proptTypes = {
  classes: ProptTypes.object.isRequired,
  notification: ProptTypes.object.isRequired,
};

export default withStyles(styles)(NotificationEditHeader);
