import React, {useRef, useLayoutEffect} from 'react';
import {Tabs, Tab, withStyles} from '@material-ui/core';

import theme from '../../../assets/theme';
import {isIE11} from '../utils';
import styles from './navigation-pages-styles';

const NavigationPages = props => {
  const {classes, tabs, active, onClick, tabsEnabled, sticky, isFullScreen} = props;

  const fixedLogoBackground = useRef();
  const pageLinks = useRef();

  // Effects
  useLayoutEffect(() => {
    if (sticky) {
      if (!isIE11) window.addEventListener('scroll', updateColor);

      return function removeScrollEventListener() {
        window.removeEventListener('scroll', updateColor);
      };
    }
  }, [sticky]);

  const renderTabs = () => {
    return (
      <Tabs textColor='primary' indicatorColor='primary' value={active}>
        {tabs.map((item, index) => (
          <Tab
            key={item.id}
            value={item.id}
            label={item.name}
            onClick={() => onClick(item.id)}
            disabled={!tabsEnabled}
          />
        ))}
      </Tabs>
    );
  };

  const updateColor = () => {
    if (pageLinks && fixedLogoBackground) {
      if (pageLinks.current && fixedLogoBackground.current) {
        window.requestAnimationFrame(function() {
          const scrollTop = pageLinks.current.getBoundingClientRect().top;

          if (!scrollTop || scrollTop > 0) {
            const opacity = 1 - scrollTop / pageLinks.current.offsetTop;
            if (opacity > 0.3) {
              [pageLinks.current, fixedLogoBackground.current].forEach(el => {
                el.style.background = `linear-gradient(rgba(255,255,255,${opacity}), 
                  rgba(255,255,255,${opacity})), linear-gradient(#F2F5F8, #F2F5F8)`;
              });
            } else {
              [pageLinks.current, fixedLogoBackground.current].forEach(el => {
                el.style.background = `linear-gradient(rgba(255,255,255,0), 
          rgba(255,255,255,0)), linear-gradient(${theme.palette.background.default}, 
            ${theme.palette.background.default})`;
              });
            }
          }
        });
      }
    }
  };

  return isFullScreen ? (
    renderTabs()
  ) : (
    <>
      <div ref={fixedLogoBackground} className={classes.fixedLogoBackground} />
      <div ref={pageLinks} className={classes.pagination}>
        {renderTabs()}
      </div>
    </>
  );
};

export default withStyles(styles)(NavigationPages);
