import React from 'react';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import texts from '../../assets/texts';
import {withStyles} from '@material-ui/core';
import styles from './loading-error-styles';

const LoadingError = ({action, classes}) => {
  return (
    <div className={classes.container}>
      <ErrorOutline className={classes.icon} />
      <p className={classes.text} dangerouslySetInnerHTML={{__html: texts.errors.loading}} />
      <Button variant='contained' color='primary' className={classes.button} onClick={action}>
        Retry
      </Button>
    </div>
  );
};

export default withStyles(styles)(LoadingError);
