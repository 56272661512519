import React, {useCallback, useMemo, useRef} from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import Dialog from './index';
import AlertIcon from '../../assets/icons/icon_80_alert_orange.svg';
import CheckIcon from '../../assets/icons/icon_24_check_blue.svg';
import {useStore} from 'outstated';
import {Button, withStyles, CircularProgress} from '@material-ui/core';
import DialogStore, {DialogType} from '../../state/dialog';
import styles from './dialog-simple-styles';

const DialogSimple = ({classes}) => {
  const containerRef = useRef();
  const {state, mutations} = useStore(DialogStore);

  const handleClose = useCallback(() => {
    if (state.onClose) state.onClose();
    mutations.close();
  }, []);

  const defaultIcon = state.type === DialogType.INFO ? CheckIcon : AlertIcon;
  const customOpts = useMemo(
    () =>
      state.type === DialogType.CONFIRM
        ? {
            button: state.buttonText || formatMessage('Yes'),
            action: () => {
              if (state.onActionConfirmed) state.onActionConfirmed();
            },
            cancelButtonComponent: (
              <Button
                className={classes.button}
                onClick={handleClose}
                variant='outlined'
                color='primary'
                disabled={state.loading}
              >
                {state.cancelText || formatMessage('No')}
              </Button>
            ),
          }
        : {
            button: null,
            action: () => {},
            cancelButtonComponent: (
              <div className={classes.buttonContainer}>
                <Button onClick={handleClose} variant='contained' color='primary' disabled={state.loading}>
                  {state.buttonText || formatMessage('Ok')}
                </Button>
              </div>
            ),
          },
    [state]
  );

  return (
    <Dialog open={state.open} title={state.title} onClose={handleClose} narrow disabled={state.loading} {...customOpts}>
      <div className={classes.content}>
        {state.loading ? (
          <div
            className={classes.loadingContainer}
            style={{height: containerRef.current ? containerRef.current.offsetHeight : 'min-height'}}
          >
            <CircularProgress color='primary' />
          </div>
        ) : (
          <div ref={containerRef}>
            <img className={classes.icon} src={state.icon || defaultIcon} alt={formatMessage(state.type)} />
            <p>{state.message}</p>
          </div>
        )}
      </div>
    </Dialog>
  );
};

DialogSimple.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(DialogSimple);
