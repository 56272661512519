import React, {useCallback, useState, useRef} from 'react';
import {withStyles, IconButton} from '@material-ui/core';
import {ReactComponent as NotificationsIcon} from '../../../../assets/icons/icon_24_wb_incandescent_midGrey.svg';
import classNames from 'classnames';
import {useStore} from 'outstated';
import formatMessage from 'format-message';
import styles from './notification-pin-styles';
import Menu from '../../../../components/menu';
import CardHeader from '../../../../components/card/header';
import CloseIcon from '../../../../assets/icons/icon_24_close_darkGrey.svg';
import RichTextEditorViewer from '../../../../components/rich-text-editor/viewer/rich-text-editor-viewer';
import DashboardStore from '../../../../state/dashboard';

const NotificationPinHeader = ({classes, onClose}) => {
  return (
    <CardHeader
      title={formatMessage('Chart insights')}
      rightButton={
        <div className={classes.closeIcon} onClick={onClose}>
          <img
            src={CloseIcon}
            alt={formatMessage('Close')}
            title={formatMessage('Close')}
            aria-label={formatMessage('Close')}
          />
        </div>
      }
    />
  );
};

const NotificationPin = ({classes, anchorEl, message, visualID}) => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);

  const [open, setOpen] = useState(false);
  const iconRef = useRef();

  const handleClose = useCallback(() => {
    DashboardMutations.setPopoverNotificationIndex('');
  }, []);

  const handleOpen = useCallback(e => {
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPopoverNotificationIndex(visualID);
  }, []);
  return (
    <>
      <IconButton ref={iconRef} className={classes.notificationIcon} onMouseEnter={event => handleOpen(event)}>
        <NotificationsIcon className={classNames({[classes.notificationIconActive]: open})} />
      </IconButton>

      <Menu
        className={classes.menu}
        open={DashboardState.popperNotificationIndex === visualID}
        placement='bottom-end'
        anchorEl={anchorEl}
        onClickAway={handleClose}
        onMouseLeave={handleClose}
      >
        <div className={classes.container}>
          <NotificationPinHeader classes={classes} onClose={handleClose} />
          <RichTextEditorViewer value={message} />
        </div>
      </Menu>
    </>
  );
};

export default withStyles(styles)(NotificationPin);
