import React, {useEffect, useCallback, useMemo} from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import _debounce from 'lodash/fp/debounce';
import {Card, CardContent, Grid, withStyles} from '@material-ui/core';
import {useStore} from 'outstated';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';

import LoadingSpinner from '../../../components/loading-spinner';
import DialogStore from '../../../state/dialog';
import HelpSectionTable from './help-section-table';
import HelpSectionTableColumns from './help-section-table-columns';
import {HelpSectionDialog} from '../create-dialog';
import {HelpSectionEdit} from '../create-dialog';
import Pagination from '../../../components/pagination';
import HelpAdminNavigation from '../navigation/admin';
import {SectionAdminStore} from '../../../state/section/store';
import styles from './help-section-list-styles';
import {getDeleteDialogContent} from '../help-section-dialog-content';

const showMessage = (classes, title, subtitle) => {
  return (
    <div className={classes.messageWrapper}>
      <p className={classes.message}>{title}</p>
      <p className={classes.message}>{subtitle}</p>
    </div>
  );
};

const HelpSectionList = ({classes}) => {
  const history = useHistory();
  const {state: SectionState, mutations: SectionMutations, effects: SectionEffects} = useStore(SectionAdminStore);
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {filters, pagination, queryPagination, loading, error, reload, sectionData, isEdit} = SectionState;

  const hasQuery = filters.query && filters.query.length;
  const currentPagination = hasQuery ? queryPagination : pagination;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    SectionEffects.getSections({isSearch: true});
  }, [filters, pagination.currentPage, queryPagination.currentPage, reload]);

  useEffect(() => {
    if (currentPagination.currentPage > currentPagination.totalPages) {
      SectionMutations.setPage(currentPagination.totalPages);
    }
  }, [currentPagination, SectionMutations]);

  const handlePagination = useCallback(
    page => {
      SectionMutations.setPage(page);
    },
    [SectionMutations]
  );

  const setQuery = useCallback(_debounce(300)(SectionMutations.setFiltersQuery), []);
  const handleSearch = useCallback(
    event => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const hasSections = sectionData && sectionData.length;
  const hasError = error && error.length;
  const errorMessage = showMessage(
    classes,
    null,
    formatMessage('Due to technical issues, section data is currently not available. Please try again later.')
  );
  const noResultsMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No results found.'),
        formatMessage('Modify your filters and search criteria.')
      );
  const noSectionMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No sections present yet.'),
        formatMessage("Click on the '+ New section' button to get started")
      );

  const handleOpenDeleteSectionConfirmation = useCallback(
    (id, status) => {
      const {title, message} = getDeleteDialogContent(status);
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (id && id.length) {
            const result = await SectionEffects.deleteSection(id);
            if (result) {
              SectionMutations.reload();
            }
          }
        },
        title,
        message,
      });
    },
    [DialogMutations, SectionMutations]
  );

  const handleEditClick = section => {
    SectionMutations.setSectionValue({
      isEdit: true,
      section: section,
    });
  };

  const handleRowClick = useCallback(
    row => {
      SectionMutations.setSectionValue({
        isEdit: true,
        section: row.original,
      });
    },
    []
  );

  const columns = useMemo(
    () =>
      HelpSectionTableColumns({
        onDeleteClick: handleOpenDeleteSectionConfirmation,
        onEditClick: handleEditClick,
      }),
    [filters, currentPagination.currentPage, handleOpenDeleteSectionConfirmation, handleEditClick]
  );

  return (
    <>
      <HelpAdminNavigation />
      <div className={classes.container}>
        <Grid container alignItems='center' justify='space-between' className={classes.header}>
          <Grid item className={classes.title}>
            {formatMessage('Help section area')}
          </Grid>
          <Grid item>
            <HelpSectionDialog isEdit={isEdit.isEdit} />
          </Grid>
        </Grid>

        {hasSections ? (
          <>
            <Card className={classNames(classes.card)}>
              <CardContent className={classes.cardContent}>
                {loading && <LoadingSpinner />}
                {!loading &&
                  (hasSections ? (
                    <>
                      <HelpSectionTable columns={columns} data={sectionData} onRowClick={handleRowClick}/>
                      <Pagination
                        pages={currentPagination.totalPages}
                        currentPage={currentPagination.currentPage}
                        onPageChanged={handlePagination}
                      />
                    </>
                  ) : (
                    noResultsMessage
                  ))}
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            {loading && <LoadingSpinner />}
            {!loading && noSectionMessage}
          </>
        )}
      </div>

      <HelpSectionEdit open={isEdit} isEdit={isEdit.isEdit} details={isEdit.section} />
    </>
  );
};

HelpSectionList.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HelpSectionList);
