import theme from '../../../assets/theme';

export default {
  row: {
    border: `1px solid ${theme.palette.grey.light}`,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
    cursor: 'pointer',
  },
  spacer: {
    height: theme.spacing(1),
    cursor: 'initial',
  },
  cell: {
    borderBottom: 'inherit',
    padding: [[theme.spacing(1.5), theme.spacing(2)]],
  },
  cellHead: {
    borderBottom: 'unset',
    fontSize: 18,
    lineHeight: '20px',
  },
  publishedCell: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  actionsColumn: {
    width: '36px',
    color: theme.palette.primary.main,
    padding: [[theme.spacing(1.5), 0]],
  },
  title: {
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
