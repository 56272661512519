import theme from '../../../../assets/theme';
import {container_height, container_top_separation} from '../notification-viewer-styles';

export default {
  card: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(9),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    borderColor: theme.palette.grey.light,
    height: container_height + container_top_separation,
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(2),
    },
    '&> div:last-child': {
      marginTop: theme.spacing(1),
    },
  },
  cardTitle: {
    fontSize: 28,
    lineHeight: '30px',
    fontWeight: 'bold',
    margin: 0,
    color: theme.palette.primary.main,
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'start',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemType: {
    display: 'flex',
    fontSize: 14,
    lineHeight: '18px',
    margin: 0,
    color: theme.palette.grey.mid,
    marginTop: theme.spacing(1.5),
  },
  editor: {
    '&>div': {
      borderRadius: '3px',
      marginTop: theme.spacing(3.5),
      maxHeight: `${container_height - theme.spacing(4.5)}px`,
      overflow: 'auto',
    },
  },
};
