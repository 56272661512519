import HelpArticleService from '../../services/help-service';
import logger from '../../utils/logger';
import formatMessage from 'format-message';
import {AXIOS_REQUEST_CANCELED} from '../../services/SeaburyAxios';

export default (state, mutations, dialog) => ({
  getArticles: async ({isSearch = false} = {}) => {
    mutations.setLoading(true);
    try {
      const params =
        state.filters.query && state.filters.query.length
          ? {
              ...state.filters,
              limit: state.queryPagination.limit,
              from: state.queryPagination.offset,
            }
          : {
              ...state.filters,
              limit: state.pagination.limit,
              from: state.pagination.offset,
            };
      const data = isSearch
        ? await HelpArticleService.searchArticles(params)
        : await HelpArticleService.getArticles(params);
      mutations.setArticleData(data);
      return data;
    } catch (err) {
      logger.warn(err);

      if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

      const errorMessage = formatMessage(
        'Due to a technical issue, the notification data is currently not available. Please try again later.'
      );
      mutations.setArticleError(errorMessage);
      dialog.showError({
        title: formatMessage('Data cannot be retrieved.'),
        message: errorMessage,
      });
      return null;
    }
  },

  getArticle: async id => {
    mutations.setCurrentLoading(true);
    try {
      if (id) {
        const data = await HelpArticleService.getArticleById(id);
        mutations.setArticlePinTo({
          pintTo: data.pintTo,
        });
        mutations.setCurrentArticle(data);
      }
      mutations.setCurrentLoading(false);
      return true;
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The Article data cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },
  
  getSections: async ({isSearch = false} = {}) => {
    mutations.setLoading(true);
    try {
      const params =
        state.filters.query && state.filters.query.length
          ? {
              ...state.filters,
              limit: state.queryPagination.limit,
              from: state.queryPagination.offset,
            }
          : {
              ...state.filters,
              limit: state.pagination.limit,
              from: state.pagination.offset,
            };
      const data = isSearch
        ? await HelpArticleService.getSections(params)
        : await HelpArticleService.getSections(params);

      mutations.setSectionData(data);
      return data;
    } catch (err) {
      logger.warn(err);

      if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

      const errorMessage = formatMessage(
        'Due to a technical issue, the section data is currently not available. Please try again later.'
      );
      mutations.setArticleError(errorMessage);
      dialog.showError({
        title: formatMessage('Data cannot be retrieved.'),
        message: errorMessage,
      });
      return null;
    }
  },

  getSectionArticles: async ({isSearch = false} = {}) => {
    mutations.setLoading(true);
    try {
      const params =
        state.articleFilters.query && state.articleFilters.query.length
          ? {
              ...state.articleFilters,
              limit: state.sectionArticleQueryPagination.limit,
              from: state.sectionArticleQueryPagination.offset,
            }
          : {
              ...state.articleFilters,
              limit: state.sectionArticlePagination.limit,
              from: state.sectionArticlePagination.offset,
            };
      const data = isSearch
        ? await HelpArticleService.searchSectionArticles(params)
        : await HelpArticleService.getSectionArticles(params);

      mutations.setSectionArticleData(data);
      return data;
    } catch (err) {
      logger.warn(err);
      if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

      const errorMessage = formatMessage(
        'Due to a technical issue, the article data is currently not available. Please try again later.'
      );
      mutations.setArticleError(errorMessage);
      dialog.showError({
        title: formatMessage('Data cannot be retrieved.'),
        message: errorMessage,
      });
      return null;
    }
  },

  getSectionDetails: async section => {
    mutations.setCurrentLoading(true);
    try {
      if (section) {
        const data = await HelpArticleService.getSectionDetails(section);
        data.section = section;
        mutations.setCurrentLoading(false);
        return data;
      }
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The Article data cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },

  getHelpStorageFiles: async () => {
    mutations.setCurrentLoading(true);
    try {
        const data = await HelpArticleService.getHelpStorageFiles();
        mutations.setHelpStorageFileList(data);
        return data;
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The file list cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },

  getArticleDetails: async id => {
    mutations.setCurrentLoading(true);
    try {
      if (id) {
        const data = await HelpArticleService.getArticleById(id);
        mutations.setCurrentLoading(false);
        return data;
      }
    } catch (err) {
      mutations.clearCurrent();
      if (err && err.response && err.response.status === 403) {
        dialog.showError({
          title: formatMessage('Not allowed.'),
          message: formatMessage('You cannot view this content.'),
        });
      } else {
        dialog.showError({
          title: formatMessage('Data cannot be retrieved.'),
          message: formatMessage(
            'The Article data cannot be retrieved at the moment. Please try again or try again later.'
          ),
        });
      }
      return null;
    }
  },
});
