import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditorViewer = ({className, value}) => {

  return <ReactQuill className={className} readOnly modules={{toolbar: false}} theme='snow' default={false} value={value} />;
};

export default RichTextEditorViewer;
