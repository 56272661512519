import React, {useCallback, useEffect, useMemo} from 'react';
import {Redirect, useParams, useHistory, useLocation} from 'react-router-dom';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import _debounce from 'lodash/fp/debounce';
import _isEmpty from 'lodash/fp/isEmpty';
import _isString from 'lodash/fp/isString';
import _isArray from 'lodash/fp/isArray';
import {Card, CardContent, Grid, withStyles, CircularProgress} from '@material-ui/core';
import {useStore} from 'outstated';
import {NotificationsUserStore, NotificationsMailboxStore} from '../../../state/notifications/store';
import classNames from 'classnames';
import LoadingSpinner from '../../../components/loading-spinner';
import Pagination from '../../../components/pagination';
import styles from './notification-viewer-styles';
import Footer from '../../../components/footer/footer-view';
import NotificationViewerHeader from './list/notification-viewer-header';
import NotificationViewerList from './list/notification-viewer-list-view';
import {Routes} from '../../../Routes';
import DashboardStore from '../../../state/dashboard';
import AppStore from '../../../state/app';
import {PinArticleStore} from '../../../state/help/store'
import NotificationViewerDetails from './details/notification-viewer-details-view';
import NotificationNavigation from '../navigation';
import {PINTO_TYPES} from '../../../state/notifications/constants';

const showMessage = (classes, title, subtitle) => {
  return (
    <div className={classes.messageWrapper}>
      <p className={classes.message}>{title}</p>
      {subtitle && <p className={classes.message}>{subtitle}</p>}
    </div>
  );
};

const NotificationViewerEmpty = ({classes, message}) => (
  <div className={classes.wrapper}>
    <span className={classNames(classes.title, classes.titleEmpty)}>{formatMessage('My notification mailbox')}</span>
    <Grid container spacing={2} alignItems={'center'} justify='space-between' className={classNames(classes.container)}>
      <Grid item sm={12}>
        {message}
      </Grid>
    </Grid>
  </div>
);

const NotificationViewerContainer = ({classes}) => {
  const {id} = useParams();
  const history = useHistory();
  const location = useLocation();
  const {state: DashboardState} = useStore(DashboardStore);
  const {state: AppState} = useStore(AppStore);
  const {state: NotificationsState, mutations: NotificationsMutations, effects: NotificationsEffects} = useStore(
    NotificationsUserStore
  );
  const {effects: PinArticleEffects} = useStore(PinArticleStore);

  const {effects: MailboxEffects} = useStore(NotificationsMailboxStore);
  const {
    filters,
    pagination,
    queryPagination,
    notifications,
    loading,
    error,
    current,
    currentLoading,
  } = NotificationsState;

  const hasQuery = filters.query && filters.query.length;
  const currentPagination = hasQuery ? queryPagination : pagination;

  useEffect(() => {
    MailboxEffects.getNotifications();
    NotificationsEffects.getNotifications({isSearch: true});

    PinArticleEffects.getVisualArticles(PINTO_TYPES[0]);
    PinArticleEffects.getArticles();
  }, [filters, pagination.currentPage, queryPagination.currentPage]);

  useEffect(() => {
    const getNotification = async id => {
      history.push(`${Routes.NOTIFICATIONS}/${id}`);
      const result = await NotificationsEffects.getNotification(id);
      if (!result) {
        history.push(Routes.NOTIFICATIONS);
      }
    };
    if (id) {
      getNotification(id);
    }
  }, [id]);

  useEffect(() => {
    return () => NotificationsMutations.clearCurrent();
  }, []);

  const handlePagination = useCallback(
    page => {
      NotificationsMutations.setPage(page);
    },
    [NotificationsMutations]
  );
  const onTypeClick = (event, filterType) => {
    if (filterType !== filters.selectedFilterType) {
      NotificationsMutations.selectedFilterType(filterType);
      NotificationsMutations.setFiltersType(filterType);
    }
  };

  const setQuery = useCallback(_debounce(300)(NotificationsMutations.setFiltersQuery), []);
  const handleSearch = useCallback(
    event => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const hasFilterType =
    filters.type && (_isString(filters.type) || (_isArray(filters.type) && filters.type.every(_isString)));
  const hasFilters = (filters.query && filters.query.length) || hasFilterType;
  const hasNotifications = notifications && notifications.length;
  const hasError = error && error.length;

  const errorMessage = showMessage(
    classes,
    null,
    formatMessage('Due to technical issues, notification data is currently not available. Please try again later.')
  );
  const noResultsMessage = hasError
    ? errorMessage
    : showMessage(
        {...classes, messageWrapper: classNames(classes.messageWrapper, classes.messageListWrapper)},
        formatMessage('No results found.'),
        formatMessage('Modify your filters and search criteria.')
      );
  const noNotificationsMessage = hasError
    ? errorMessage
    : showMessage(classes, formatMessage('You have no notifications yet.'), null);

  const details = useMemo(() => {
    return current && !_isEmpty(current) ? (
      <NotificationViewerDetails data={current} />
    ) : (
      showMessage(classes, formatMessage('No notification selected.'), null)
    );
  }, [current, classes]);

  return (
    <>
      {!DashboardState.initialized && <LoadingSpinner />}
      {DashboardState.initialized && !AppState.token && (
        <Redirect
          to={{
            pathname: Routes.LOGIN,
            state: {pathname: location.pathname !== Routes.MS_RETURN_TOKEN ? location.pathname : null},
          }}
        />
      )}
      {DashboardState.initialized && AppState.token && (
        <>
          <NotificationNavigation />
          {!hasNotifications && !hasFilters && !loading && (
            <NotificationViewerEmpty classes={classes} message={noNotificationsMessage} />
          )}
          {notifications === null && loading && <LoadingSpinner />}
          {(hasNotifications || hasFilters) && currentLoading && <LoadingSpinner overlay />}
          {(hasNotifications || hasFilters || loading) && notifications !== null && (
            <div className={classes.wrapper}>
              <Grid
                container
                spacing={2}
                alignItems={current && !_isEmpty(current) && !currentLoading ? 'flex-start' : 'center'}
                justify='space-between'
                className={classNames(classes.container)}
              >
                <Grid item sm={12} md={5}>
                  <span className={classes.title}>{formatMessage('My notification mailbox')}</span>
                  <div className={classes.listContainerContent}>
                    <NotificationViewerHeader
                      filters={filters}
                      onTypeClick={onTypeClick}
                      onSearchChange={handleSearch}
                      disabled={loading}
                      selectedOption={filters.selectedFilterType}
                    />
                    <Card
                      className={classNames(classes.listContainer, {
                        [classes.emptyListContainer]: !hasNotifications || loading,
                      })}
                    >
                      <CardContent className={classes.cardContent}>
                        {loading && (
                          <div className={classes.loadingContainer}>
                            <CircularProgress />
                          </div>
                        )}
                        {!loading &&
                          (hasNotifications ? (
                            <>
                              <NotificationViewerList items={notifications} />
                              <Pagination
                                className={classes.pagination}
                                pages={currentPagination.totalPages}
                                currentPage={currentPagination.currentPage}
                                onPageChanged={handlePagination}
                              />
                            </>
                          ) : (
                            noResultsMessage
                          ))}
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
                <Grid item sm={12} md={7}>
                  {!currentLoading && details}
                </Grid>
              </Grid>
              <Footer />
            </div>
          )}
        </>
      )}
    </>
  );
};

NotificationViewerContainer.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(NotificationViewerContainer);
