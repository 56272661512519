import theme from '../../../assets/theme';

export const container_height = 800;
export const container_top_separation = 84;

export default {
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '20px',
  },
  titleEmpty: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(2),
  },
  messageWrapper: {
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    height: container_height,
    marginTop: container_top_separation,
  },
  messageListWrapper: {
    height: container_height,
    margin: 0,
  },
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',

    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  listContainer: {
    height: container_height,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  emptyListContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContainerContent: {
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pagination: {
    marginTop: 0,
  },
};
