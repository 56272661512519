export const Routes = {
  ROOT: '/',
  LANDING: '/home',
  LOGIN: '/login',
  TERMS: '/terms',
  ANNOUNCEMENTS: '/announcements',
  MS_RETURN_TOKEN: '/auth/openid/return',
  ADMIN: {
    ROOT: '/admin',
    NOTIFICATIONS: '/admin/notifications',
    NOTIFICATIONS_EDIT: '/admin/notifications/:id',
    NOTIFICATIONS_NEW: '/admin/notifications/new',
    HELP: '/admin/help',
    HELP_EDIT: '/admin/help/:id',
    HELP_NEW: '/admin/help/new',
    SECTIONS: '/admin/sections',
  },
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_DETAILS: '/notifications/:id',
  HELP: '/help',
  HELP_DETAILS: '/help/:id',
};
