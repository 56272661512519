import theme from '../../assets/theme';

export default {
  wrapper: {
    marginTop: '9rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4.5rem',
    },
  },
  title: {
    width: '22.188rem',
    color: theme.palette.black,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -0.8,
    lineHeight: '36px',
  },
  email: {
    width: 461,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -0.8,
    lineHeight: '36px',
    textDecoration: 'none'
  },
  centerLine: {
    width: 1,
    height: '100%',
    backgroundColor: theme.palette.grey.line,
  },
  contactType: {
    color: theme.palette.black,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '20px',
  },
  contactAddress: {
    color: theme.palette.black,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '28px',
  },
  contactSection: {
    marginBottom: '3rem',
  },
  footerSection: {
    textAlign: 'end',
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
        textAlign: 'start',
        marginTop: '3rem',
    },
  },
  officeSection: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  contactSection: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  contactUs: {
    [theme.breakpoints.down('sm')]: {
        marginTop: '3rem',
    },
  },
  terms: {
    cursor: 'pointer'
  },
  contactLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  }
};
