import theme from '../../../assets/theme';

export default {
  header: {
    flex: '0 0 auto',
    display: 'flex',
  },
  titles: {
    flex: '1 1 auto',
    padding: [[theme.spacing(2), 0, theme.spacing(2), theme.spacing(2)]],
    wordBreak: 'break-word'
  },
  mainTitle: {
    fontSize: 18,
    margin: 0,
  },
  subTitles: {
    color: theme.palette.grey.mid,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    marginTop: 2,
    fontWeight: 300,
  },
  period: {
    fontSize: 14,
    margin: [[0, theme.spacing(2)]],
    marginTop: 2,
    fontWeight: 300,
  },
  actions: {
    margin: [['auto', 0, 'auto', theme.spacing(1)]],
    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    height: 32,
    padding: [[0, theme.spacing(2)]],
    borderLeft: [[0.5, theme.palette.grey.light, 'solid']],
  },
  tooltip: {
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#6B7279',
    borderColor: '#7A8188',
    borderRadius: '4px',
  },
  helpButton: {
    padding: [['6px']],
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
};
