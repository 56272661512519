import React, {useEffect, useMemo} from 'react';
import ReactGA from 'react-ga';

import AppBar from '../../../components/navigation/appbar';
import {useStore} from 'outstated';
import DashboardStore from '../../../state/dashboard';
import Icons from '../../../components/navigation/icons';
import NavigationIconAccount from '../../../components/navigation/icons/account';
import NavigationIconHelp from '../../../components/navigation/icons/help';
import NavigationIconMailbox from '../../../components/navigation/icons/mailbox';
import {isAdmin, getAuthenticadUserInfo, getAdminPermissions} from '../../../utils/access-token';
import NavigationDashboard from '../../../components/navigation/dashboards/navigation-dashboards-view';
import history from '../../../utils/history';
import {getDashboardDetail} from '../../Helper';
import {NotificationsMailboxStore} from '../../../state/notifications/store';
import AppStore from '../../../state/app';
import {HIDE_NOTIFICATION_SECTION} from '../../../assets/constants';
import {Divider} from '@material-ui/core';
import {
  LogoutMenuOption,
  AdminEnterOption,
  UserAccountOption,
  NotificationMailboxOption
} from '../../../components/navigation/menu/navigation-menu-options';

const HelpNavigation = () => {
  const {state: DashboardState, mutations: DashboardMutations} = useStore(DashboardStore);
  const {effects: MailboxEffects} = useStore(NotificationsMailboxStore);
  const {state: AppState} = useStore(AppStore);

  const adminPermissions = useMemo(() => getAdminPermissions(), []);
  let hasTabAccess = true;
  if (!adminPermissions.isNotifAdmin && !adminPermissions.isHelpAdmin && !adminPermissions.isDashAdmin) {
    hasTabAccess = false;
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (DashboardState.initialized) {
      const unlisten = history.listen((location, action) => {
        if (action === 'POP') {
          DashboardMutations.setActiveDashboard(location.state.activeDashboardTab);
          if (location.state.activePageTab) {
            DashboardMutations.setActivePage(location.state.activePageTab);
          }
        }
        if (AppState.dashboardRoutes.includes(location.pathname) && AppState.token && !HIDE_NOTIFICATION_SECTION) {
          MailboxEffects.getNotifications();
          MailboxEffects.getInsights();
        }
      });

      return function unlistenHistory() {
        unlisten();
      };
    }
  }, [DashboardState.initialized, DashboardMutations, AppState.dashboardRoutes, AppState.token, MailboxEffects]);

  useEffect(() => {
    const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');
    if (dashboard) {
      const page = dashboard.pages.find(p => p.id === DashboardState.current.activePage);
      if (page) {
        history.replace(`/${page.url}`, {
          activeDashboardTab: DashboardState.current.activeDashboard,
          activePageTab: DashboardState.current.activePage,
        });
      } else {
        DashboardMutations.setActiveDashboard(false);
        DashboardMutations.setActivePage(false);
      }
    }
  }, [
    DashboardState.dashboards,
    DashboardState.current.activeDashboard,
    DashboardState.current.activePage,
    DashboardState,
    DashboardMutations,
  ]);

  const showAdminLinks = useMemo(() => isAdmin(), []);
  const userInfo = useMemo(() => getAuthenticadUserInfo(), []);

  return (
    <>
      <AppBar>
        <NavigationDashboard
          tabs={DashboardState.dashboards.filter(item => item.resourceType === 'Dashboard')}
          active={DashboardState.current.activeDashboard}
          onClick={DashboardMutations.setActiveDashboard}
          newDashboardTabs={DashboardState.dashboards.filter(item => item.resourceType === 'Dataset')}
          sticky
        />
        <Icons>
          <NavigationIconHelp />
          <NavigationIconMailbox />
          <NavigationIconAccount>
            <UserAccountOption isAdmin={showAdminLinks} username={userInfo && userInfo.displayName} />
            <Divider />
            {showAdminLinks && hasTabAccess && (
              <>
                <AdminEnterOption />
                <Divider />
              </>
            )}
            <NotificationMailboxOption />
            <Divider />
            <LogoutMenuOption />
          </NavigationIconAccount>
        </Icons>
      </AppBar>
    </>
  );
};

export default HelpNavigation;
