import React from 'react';
import propTypes from 'prop-types';
import {Button, Grid, withStyles} from '@material-ui/core';
import Edit from '../../../assets/icons/icon_24_edit_blue.svg';
import Delete from '../../../assets/icons/icon_24_delete_blue.svg';
import texts from '../../../assets/texts';
import styles from './admin-dashboard-details-header-styles';

const Header = ({classes, dashboardID, title, url, editDashboard, deleteDashboard}) => (
  <Grid container className={classes.container} justify='space-between'>
    <Grid item>
      <h1 className={classes.title}>{title}</h1>
      <p className={classes.link}>{texts.admin.dashboardsURL + url}</p>
      <p className={classes.link}>ID  : {dashboardID}</p>
    </Grid>
    <Grid item className={classes.actions}>
      <Button color={'primary'} className={classes.button} onClick={editDashboard}>
        <img alt='' aria-hidden src={Edit} className={classes.icon} />
        <span className={classes.buttonText}>Edit</span>
      </Button>
      <Button color={'primary'} className={classes.button} onClick={deleteDashboard}>
        <img aria-hidden src={Delete} className={classes.icon} style={{width: '16px', height: '16px'}} alt='' />
      </Button>
    </Grid>
  </Grid>
);

Header.propTypes = {
  title: propTypes.string,
  url: propTypes.string,
  editDashboard: propTypes.func,
  deleteDashboard: propTypes.func,
};

export default withStyles(styles)(Header);
