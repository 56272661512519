import theme from '../../../assets/theme';

export default {
  container: {
    padding: theme.spacing(1),
    border: [[1, 'solid', theme.palette.grey.light]],
    boxShadow: 'none',
    margin: -theme.spacing(1),
    marginBottom: 0,
    textAlign: 'right',
  },
  field: {
    margin: [[0, 0, theme.spacing(4)]],
  },
  inputLabel: {
    '&:not($inputFocused):not($inputError)': {
      color: theme.palette.grey.mid,
    },
  },
  cssOutlinedInput: {
    '&:not($inputFocused):not($inputError) $notchedOutline': {
      borderColor: theme.palette.grey.mid,
    },
  },
  notchedOutline: {},
  inputFocused: {},
  inputError: {},
  button: {
    minWidth: '220px',
    textTransform: 'none',
    padding: [[theme.spacing(2), theme.spacing(1)]],
    fontSize: 16,
  },
  errorContainer: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    top: theme.spacing(-4),
    left: theme.spacing(2),
    margin: 0,
    color: theme.palette.grey.mid,
    fontSize: 12,
    lineHeight: '16px',
  },
  hidden: {
    display: 'none',
  },
};
