import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import Dialog from '../../../components/dialog';
import Category from '../../../components/category';
import Menu from '../../../components/menu';
import {apiURL} from '../../../assets/constants';
import {axiosHeader} from '../../../utils/axios';
import AdminStore from '../../../state/admin';
import WithLoader from '../../../components/with-loader';
import CustomCard from '../../../components/card';
import {useStore} from 'outstated';
import {TextField, withStyles, MenuList, MenuItem, Divider} from '@material-ui/core';
import classNames from 'classnames';
import texts from '../../../assets/texts';
import Account from '../../../assets/icons/icon_24_account_darkGrey.svg';
import styles from './admin-add-page-styles';

const CreateDialog = ({
  pageId,
  pageGroup,
  classes,
  open,
  onClose,
  name,
  pageUrl,
  prefix,
  error,
  filled,
  edit = {status: false, page: null},
  change,
  save,
  dashboards
}) => {

  const {state: AdminState, effects: AdminEffects} = useStore(AdminStore);

  const dashboard = useMemo(() => dashboards[AdminState.activeIndex], [
    dashboards,
    AdminState.activeIndex,
  ]);


  const [groups, setGroups] = useState(null);
  const [groupsMenuAnchorEl, setGroupsMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    (async function getGroups() {
      setLoading(true);
      try {
        const url = `${apiURL}/admin/securityGroups`;
        const {data} = await axios.get(url, axiosHeader(200));
        const sortedGroups = data.groups
          .map(g => g)
          .sort((a, b) => {
            if (b.name.toLowerCase() < a.name.toLowerCase()) {
              return 1;
            } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
        setGroups(sortedGroups);
      } catch (e) {}

      setLoading(false);
    })();
  }, []);

  // Actions
  const addGroup = async groupName => {
    setLoading(true);
    setGroupsMenuAnchorEl(null);

    try {
      const url = `${apiURL}/admin/page/${pageId}`;
      await axios.patch(url, {
        id: pageId,
        name: name,
        url: pageUrl,
        group: groupName}, axiosHeader(200));
    } catch (e) {}

    await AdminEffects.getDashboards();
    setLoading(false);
  };

  const removeGroup = async () => {
    setLoading(true);
    setGroupsMenuAnchorEl(null);

    try {
      const url = `${apiURL}/admin/page/${pageId}`;
      await axios.patch(url, {
        id: pageId,
        name: name,
        url: pageUrl,
        group: " "}, axiosHeader(200));
      } catch (e) {}

    await AdminEffects.getDashboards();
    setLoading(false);
  };

  return(
  <Dialog
    open={open}
    title={edit.status ? 'Edit page' : 'Create page'}
    onClose={onClose}
    button={edit.status ? texts.editing.updateButton : texts.editing.createButton}
    action={save}
    disabled={!filled}
  >
    <TextField
      id='name-input'
      label='Name'
      type='text'
      name='name'
      margin='normal'
      variant='outlined'
      autoFocus
      fullWidth
      value={name}
      onChange={e => change(e)}
      className={classes.field}
      InputLabelProps={{
        classes: {
          root: name.length > 0 ? classes.inputLabel : null,
          focused: classes.inputFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.inputFocused,
          notchedOutline: name.length > 0 ? classes.notchedOutline : null,
        },
      }}
    />
    <TextField
      id='url-input'
      label='Url path'
      type='url'
      name='url'
      margin='normal'
      variant='outlined'
      fullWidth
      className={classes.field}
      InputLabelProps={{
        classes: {
          root: pageUrl.length > 0 ? classes.inputLabel : null,
          focused: classes.inputFocused,
          error: classes.inputError,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.inputFocused,
          notchedOutline: name.length > 0 ? classes.notchedOutline : null,
          error: classes.inputError,
        },
      }}
      value={`${texts.admin.dashboardsURL}${prefix + '/'}${pageUrl}`}
      onChange={e => change(e)}
      error={error}
    />
    <Category
            icon={Account}
            title={'Restricted groups'}
            button={'Add user group'}
            action={e => setGroupsMenuAnchorEl(e.target)}
            loading={loading}
            disabled={Boolean(groupsMenuAnchorEl)}
          />
          {groups && (
            <Menu
              open={Boolean(groupsMenuAnchorEl)}
              anchorEl={groupsMenuAnchorEl}
              onClickAway={() => setGroupsMenuAnchorEl(null)}
              placement='bottom-end'
            >
              <MenuList className='menu-items'>
                {groups
                  .filter(group => !dashboard.groups.includes(group.name))
                  .map(group => (
                    <MenuItem className='menu-item' key={group.uuid} onClick={() => addGroup(group.name)}>
                      {group.name}
                      <Divider className='divider' />
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}
          <WithLoader loading={Boolean(loading && groups)}>
            {(`${pageGroup}`.length > 1) && (<CustomCard title={pageGroup} onButtonClick={() => removeGroup()} button='trash' />)}
          </WithLoader>
    <div className={classes.errorContainer}>
      <p className={classNames(classes.error, error ? null : classes.hidden)}>{texts.editing.duplicateURL}</p>
    </div>
  </Dialog>
)};

CreateDialog.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  name: propTypes.string,
  pageUrl: propTypes.string,
  prefix: propTypes.string,
  error: propTypes.bool,
  filled: propTypes.bool,
  edit: propTypes.object,
  change: propTypes.func,
  save: propTypes.func,
};

export default withStyles(styles)(CreateDialog);
