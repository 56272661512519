import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from './build-version-styles';
import logger from '../../utils/logger';

const buildVersion = process.env.REACT_APP_FRONTEND_BUILD;
const showBuildVersion = process.env.NODE_ENV !== 'production';

logger.info('Starting version: ', process.env.REACT_APP_FRONTEND_BUILD);

const BuildVersion = ({classes}) => {
  if (showBuildVersion && buildVersion) {
    return <p className={classes.buildVersion}>{`Build #${buildVersion}`}</p>;
  }
  return null;
};

export default withStyles(styles)(BuildVersion);
