import produce from 'immer';
import {PINTO_TYPES, VALID_STATUSES, OLD_NOTIFICATION_AGE} from './constant';

const getPublishedFromString = () => {
  const now = new Date();
  const publishedFromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - OLD_NOTIFICATION_AGE, 0, 0, 0);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
  const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(publishedFromDate);
  return `${year}-${month}-${day}`;
};

export const initialArticleState = {
  aricles: null,
  totalCount: 0,
  loading: true,
  currentLoading: false,
  filters: {
    query: null,
    type: null,
  },
  pinTofilters: {
    status: VALID_STATUSES[1], // PUBLISHED
    pintTo: PINTO_TYPES[3],
    publishedFrom: getPublishedFromString(),
    sortBy: 'publishedAt',
    withMessage: true,
  },
  pagination: {
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 1,
  },
  queryPagination: {
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 1,
  },
  reload: false,
  error: null,
  current: {},
  isUpdated: false,
  isEdit: false,
  isValidPinLocation: true,
  isOpen: false,
  isLoadMore: false,
  backButton: false,
  fromPinTo: false,
  articleList: null,
  sectionArticles: null,
  sectionTotalCount: 0,
  page: 1,
  articleFilters: {
    query: null,
  },
  sectionArticlePagination: {
    limit: 9,
    offset: 0,
    currentPage: 1,
    totalPages: 5,
  },
  sectionArticleQueryPagination: {
    limit: 9,
    offset: 0,
    currentPage: 1,
    totalPages: 5,
  },
  helpStorageFiles: null
};

export const resetPagination = (draft, initialPagination = {}, paginationKey = 'pagination') => {
  draft[paginationKey].limit = initialPagination.limit || 10;
  draft[paginationKey].offset = initialPagination.offset || 0;
  draft[paginationKey].totalPages = initialPagination.totalPages || 1;
  draft[paginationKey].currentPage = initialPagination.currentPage || 1;
};

export const setPage = (draft, page, paginationKey = 'pagination') => {
  if (page < 1) {
    draft[paginationKey].currentPage = 1;
    draft[paginationKey].offset = 0;
  } else if (page > draft[paginationKey].totalPages) {
    draft[paginationKey].currentPage = draft[paginationKey].totalPages;
    draft[paginationKey].offset = draft[paginationKey].limit * (draft[paginationKey].totalPages - 1);
  } else {
    draft[paginationKey].currentPage = page;
    draft[paginationKey].offset = draft[paginationKey].limit * (page - 1);
  }
};

export const updatePagination = (draft, data, paginationKey = 'pagination') => {
  if (data.limit !== draft[paginationKey].limit) {
    draft[paginationKey].limit = data.limit || 10;
  }
  if (data.offset !== draft[paginationKey].offset) {
    draft[paginationKey].offset = data.offset || 0;
  }
  if (data.page !== draft[paginationKey].currentPage) {
    draft[paginationKey].currentPage = data.page || 1;
  }
  if (data.totalPages !== draft[paginationKey].totalPages) {
    draft[paginationKey].totalPages = data.totalPages || 1;
  }
};

export const setInfiniteScroll = (draft, page, paginationKey = 'sectionArticlePagination') => {
  if (page < 1) {
    draft[paginationKey].currentPage = 1;
    draft[paginationKey].offset = 0;
  } else if (page > draft[paginationKey].totalPages) {
    draft[paginationKey].currentPage = draft[paginationKey].totalPages;
    draft[paginationKey].offset = draft[paginationKey].limit * (draft[paginationKey].totalPages - 1);
  } else {
    draft[paginationKey].currentPage = page;
    draft[paginationKey].offset = page;
  }
};

const hasQuery = state => state.filters.query && state.filters.query.length;
const hasArticleQuery = state => state.articleFilters.query && state.articleFilters.query.length;

export default (setState, initialUserPagination) => ({
  setArticleData: data =>
    setState(
      produce(draft => {
        draft.articles = data.docs;
        draft.totalCount = data.totalDocs;
        updatePagination(draft, data, hasQuery(draft) ? 'queryPagination' : 'pagination');
        draft.error = null;
        draft.loading = false;
      })
    ),
  setPage: page =>
    setState(
      produce(draft => {
        draft.loading = true;
        setPage(draft, page, hasQuery(draft) ? 'queryPagination' : 'pagination');
      })
    ),
  setFiltersQuery: query =>
    setState(
      produce(draft => {
        if (query === draft.filters.query || (query === 'ALL' && draft.filters.query === null)) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.filters.query = typeof query === 'string' ? query : null;
          resetPagination(draft, initialUserPagination, 'queryPagination');
        }
      })
    ),
  setFiltersType: pintTo =>
    setState(
      produce(draft => {
        if (pintTo === draft.filters.pintTo || (pintTo === 'ALL' && draft.filters.pintTo === null)) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.filters.pintTo = pintTo === 'ALL' ? null : pintTo;
          resetPagination(draft, initialUserPagination);
        }
      })
    ),
  setFiltersSection: section =>
    setState(
      produce(draft => {
        if (section === draft.filters.section || (section === 'ALL' && draft.filters.section === null)) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.filters.section = section === 'ALL' ? null : section;
          resetPagination(draft, initialUserPagination);
        }
      })
    ),
  setLoading: value =>
    setState(
      produce(draft => {
        draft.loading = value;
      })
    ),
  setCurrentLoading: value =>
    setState(
      produce(draft => {
        draft.currentLoading = value;
      })
    ),
  setArticleError: value =>
    setState(
      produce(draft => {
        draft.error = value;
        draft.aricles = [];
        draft.loading = false;
        resetPagination(draft, initialUserPagination);
        resetPagination(draft, initialUserPagination, 'queryPagination');
      })
    ),
  setCurrentArticle: values => {
    setState(
      produce(draft => {
        draft.current = values;
        draft.currentLoading = false;
      })
    );
  },
  setUpdateArticle: values => {
    setState(
      produce(draft => {
        draft.isUpdated = values.isUpdated;
        draft.currentLoading = false;
      })
    );
  },
  setArticlePinTo: values => {
    setState(
      produce(draft => {
        draft.pintTo = values;
        draft.currentLoading = false;
      })
    );
  },
  setValidPinTo: values => {
    setState(
      produce(draft => {
        draft.isValidPinLocation = values.isValidPinLocation;
        draft.currentLoading = false;
      })
    );
  },
  clearCurrent: () => {
    setState(
      produce(draft => {
        draft.current = {};
        draft.currentLoading = false;
      })
    );
  },
  clearCurrentSectionHelp: () => {
    setState(
      produce(draft => {
        draft.sectionArticles = null;
        draft.sectionTotalCount = 0;
      })
    );
  },
  reload: () => {
    setState(baseState =>
      produce(baseState, draft => {
        draft.reload = !baseState.reload;
      })
    );
  },
  setSectionData: data =>
    setState(
      produce(draft => {
        draft.sectionData = data.docs;
        draft.totalCount = data.totalDocs;
        updatePagination(draft, data, hasQuery(draft) ? 'queryPagination' : 'pagination');
        draft.error = null;
        draft.loading = false;
      })
    ),
  setSectionValue: values => {
    setState(
      produce(draft => {
        draft.isEdit = values;
        draft.currentLoading = false;
      })
    );
  },

  setArticleDialogValue: values => {
    setState(
      produce(draft => {
        draft.isOpen = values.isOpen;
        draft.article = values.article;
        draft.articleList = values.articleList;
        draft.isLoadMore = values.isLoadMore;
        draft.backButton = values.backButton;
        draft.fromPinTo = values.fromPinTo;
      })
    );
  },
  setSectionArticleData: data =>
    setState(
      produce(draft => {
        draft.sectionArticles = data.docs;
        draft.sectionTotalCount = data.totalDocs;
        updatePagination(draft, data, hasQuery(draft) ? 'queryPagination' : 'pagination');
        draft.error = null;
        draft.loading = false;
      })
    ),

  setSectionArticlePage: values => {
    setState(
      produce(draft => {
        draft.page = values.page;
        draft.currentLoading = false;
      })
    );
  },
  setArticleFiltersQuery: query =>
    setState(
      produce(draft => {
        if (query === draft.articleFilters.query || (query === 'ALL' && draft.articleFilters.query === null)) {
          draft.loading = false;
        } else {
          draft.loading = true;
          draft.articleFilters.query = typeof query === 'string' ? query : null;
          //setInfiniteScroll(draft, initialUserPagination, 'sectionArticleQueryPagination');
        }
      })
    ),
  setSectionArticlePage: page =>
    setState(
      produce(draft => {
        draft.loading = false;
        setInfiniteScroll(
          draft,
          page,
          hasArticleQuery(draft) ? 'sectionArticleQueryPagination' : 'sectionArticlePagination'
        );
      })
    ),

    setHelpStorageFileList: values => {
    setState(
      produce(draft => {
        draft.helpStorageFiles = values;
        draft.currentLoading = false;
      })
    );
  },
});
