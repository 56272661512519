import React, {useEffect, useState} from 'react';
import {withStyles, Drawer, MenuItem} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Link} from 'react-scroll';

import logoLight from '../../assets/images/seabury-logo-light.svg';
import menuIcon from '../../assets/icons/ic-menu.svg';
import closeIcon from '../../assets/icons/ic-close.svg';
import texts from '../../assets/texts';
import styles from './common-header-styles';
import history from '../../utils/history';
import {Routes} from '../../Routes';

const CommonHeader = props => {
  // From Styles
  const {classes, nonLanding} = props;
  const [navBackgroud, setNavBackground] = useState('appBarSolid');
  const [navBarText, setNavTextColor] = useState('appBarTextBlack');

  const navRef = React.useRef();
  navRef.current = navBackgroud;

  const navRefText = React.useRef();
  navRefText.current = navBarText;

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const {mobileView, drawerOpen} = state;

  const headersData = [
    {
      label: 'About',
      to: '/home#about',
    },
    {
      label: 'Product',
      to: '/home#products',
    },
    {
      label: 'Clients',
      to: '/home#clients',
    },
    {
      label: 'Contact',
      to: '/home#contact',
    },
  ];

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280 // For tablet and mobile view (material ui - md)
        ? setState(prevState => ({...prevState, mobileView: true}))
        : setState(prevState => ({...prevState, mobileView: false}));
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  const handleLoginClick = () => {
    history.push(Routes.LOGIN);
  };

  const handleHomeClick = () => {
    history.push(Routes.LANDING);
  };

  const displayDesktop = () => {
    return (
      <>
        {headersData.map(({label, to}) => {
          return (
            <Button color='inherit' className={classes[navRefText.current]}>
              <a className={classes[navRefText.current]} href={to}>
                {label}
              </a>
            </Button>
          );
        })}
        <Button variant='contained' className={classes.signinButton} onClick={handleLoginClick}>
          Sign in
        </Button>
      </>
    );
  };

  const displayMobile = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const handleDrawerOpen = () => {
      setState(prevState => ({...prevState, drawerOpen: true}));
    };
    const handleDrawerClose = () => {
      setState(prevState => ({...prevState, drawerOpen: false}));
    };

    const getDrawerChoices = () => {
      return headersData.map(({label, to}) => {
        return (
          <MenuItem className={classes.mobMenuList}>
            <a onClick={handleDrawerClose} className={classes.mobItem} href={to}>
              {label}
            </a>
          </MenuItem>
        );
      });
    };

    return (
      <>
        <div className={classes.mobileMenu}>
          <img src={menuIcon} alt={texts.company} className={classes.menuIcon} onClick={handleDrawerOpen} />
        </div>

        <SwipeableDrawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.closeMenuSection}>
              <div className={classes.closeMenu}>
                <img src={closeIcon} alt={texts.company} className={classes.closeIcon} onClick={handleDrawerClose} />
              </div>
            </div>

            {getDrawerChoices()}
            <Button variant='contained' className={classes.mobSigninButton} onClick={handleLoginClick}>
              Sign in
            </Button>
          </div>
        </SwipeableDrawer>
      </>
    );
  };

  return (
    <>
      {
        <AppBar position='fixed' className={classes[navRef.current]}>
          <Container maxWidth={'xl'}>
            <Toolbar className={classes.headerContent}>
              <div className={classes.headerLogo}>
                <img src={logoLight} alt={texts.company} className={classes.logo} onClick={handleHomeClick} />
              </div>
              {mobileView ? displayMobile() : displayDesktop()}
            </Toolbar>
          </Container>
        </AppBar>
      }
    </>
  );
};

export default withStyles(styles)(CommonHeader);
