import React from 'react';
import classNames from 'classnames';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useTable} from 'react-table';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import styles from './help-section-table-styles';
import {NOTIFICATION_STATUS} from '../../../assets/constants';

function HelpSectionTable({classes, columns, data, onRowClick}) {
  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  });
  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup,i) => (
          <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column,index) => (
              <TableCell key={index} className={classes.cellHead} {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);

          return (
            <>
              <TableRow
                key={i}
                className={classes.row}
                {...row.getRowProps()}
                onClick={() => {
                  onRowClick(row);
                }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell
                      key={index}
                      className={classNames(classes.cell, {
                        [classes.publishedCell]:
                          cell.column.id === 'status' && cell.value === NOTIFICATION_STATUS.PUBLISHED,
                        [classes.actionsColumn]: cell.column.id === 'actions',
                        [classes.title]: cell.column.id === 'section',
                      })}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
              <tr className={classes.spacer} />
            </>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

HelpSectionTable.propTypes = {
  classes: propTypes.object.isRequired,
  columns: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
};

export default withStyles(styles)(HelpSectionTable);
