import theme from '../../../assets/theme';

export default {
  container: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  item: {
    justifyContent: 'start',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  input: {
    maxHeight: theme.spacing(5),
    backgroundColor: '#ffffff',
  },
};
