import {useMemo, useState} from 'react';
import produce from 'immer';
import {useStore} from 'outstated';
import formatMessage from 'format-message';

import logger from '../../../utils/logger';
import HelpArticleService from '../../../services/help-service';
import DialogStore from '../../dialog';
import articlesMutations, {initialSectionState, resetPagination} from '../mutations';
import ArticleEffects from '../effets';
import {VALID_STATUSES} from '../constant';

const Help = sectionState => {
  const initialState = {
    ...initialSectionState,
    filters: {
      ...initialSectionState.filters,
      status: null,
    },
  };
  const [state, setState] = useState(sectionState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...articlesMutations(setState),
      setFiltersStatus: status =>
        setState(
          produce(draft => {
            if (
              status === draft.filters.status ||
              (status === 'ALL' && draft.filters.status === null) ||
              !VALID_STATUSES.includes(status)
            ) {
              draft.loading = false;
            } else {
              draft.loading = true;
              draft.filters.status = status === 'ALL' ? null : status;
              resetPagination(draft);
            }
          })
        ),
      setSection: sections =>
        setState(
          produce(draft => {
            draft.sections = sections;
          })
        ),
      setLoadingSection: value =>
        setState(
          produce(draft => {
            draft.isLoadingSection = value;
          })
        ),
    }),
    []
  );

  const effects = useMemo(
    () => ({
      ...ArticleEffects(state, mutations, DialogMutations),

      createSection: async section => {
        try {
          if (section) {
            mutations.setLoading(true);
            await HelpArticleService.createSection(section);

            DialogMutations.showInfo({
              title: formatMessage('Section created.'),
              message: formatMessage('The section has been successfully created.'),
            });
            mutations.reload();
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          if (err.response.status === 409) {
            DialogMutations.showError({
              title: formatMessage('Duplicate section'),
              message: formatMessage('Duplicate section cannot be added'),
            });
          } else {
            DialogMutations.showError({
              title: formatMessage('Creation error.'),
              message: formatMessage(
                'Due to an unknown error we could not create the section. Please try again later.'
              ),
            });
          }
          mutations.setLoading(false);
          return null;
        }
      },

      updateSection: async (section, id) => {
        try {
          if (section) {
            mutations.setLoading(true);
            await HelpArticleService.updateSection(section, id);

            DialogMutations.showInfo({
              title: formatMessage('Section updated.'),
              message: formatMessage('The section has been successfully updated.'),
            });
            mutations.reload();
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          if (err.response.status === 409) {
            DialogMutations.showError({
              title: formatMessage('Duplicate section'),
              message: formatMessage('Duplicate section cannot be added'),
            });
          } else {
            DialogMutations.showError({
              title: formatMessage('Creation error.'),
              message: formatMessage(
                'Due to an unknown error we could not update the section. Please try again later.'
              ),
            });
          }
          mutations.setLoading(false);
          return null;
        }
      },

      deleteSection: async id => {
        try {
          if (id) {
            mutations.setLoading(true);
            await HelpArticleService.deleteSection(id);
            if (state.current && state.current._id === id) {
            }
            DialogMutations.showInfo({
              title: formatMessage('Section deleted.'),
              message: formatMessage('The section has been successfully deleted.'),
            });
            return true;
          }
          return false;
        } catch (err) {
          logger.warn(err);
          DialogMutations.showError({
            title: formatMessage('Deletion error.'),
            message: formatMessage(err.response.data.message),
          });
          mutations.setLoading(false);
          return null;
        }
      },
    }),
    [mutations, DialogMutations, state]
  );

  return {state, mutations, effects};
};

export default Help;
