import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  getReports: async workspaceId => {
    try {
      const {data} = await SeaburyAxios.get(`/api/powerbi/${workspaceId}/reports`);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  getReportVisuals: async (workspaceId, reportId) => {
    try {
      const {data} = await SeaburyAxios.get(`/api/powerbi/${workspaceId}/report/${reportId}`);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  getReportConfig: async ({workspaceID, reportID, dashboardId}) => {
    try {
      const {data} = await SeaburyAxios.get(
        `/api/powerbi/${workspaceID}/report/${reportID}/dashboardId/${dashboardId}`
      );
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  getVisualConfig: async ({workspaceID, reportID, page, visual, dashboardId, pageId}) => {
    try {
      const {data} = await SeaburyAxios.get(
        `/api/powerbi/${workspaceID}/report/${reportID}/page/${page}/visual/${visual}/dashboardId/${dashboardId}/pageId/${pageId}`
      );
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
