import theme from '../../../assets/theme';

export default {
    wrapper: {
        display: 'block',
        fontFamily: theme.landingFontFamily.karla,
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh',
        position: 'relative'
    },
    termsContainer: {
        height: '100%',
        paddingTop: '9.5rem',
        marginBottom: 100,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '5.5rem',
        },
    },
    title: {
        color: theme.palette.black,
        fontSize: 48,
        fontWeight: 'bold',
        letterSpacing: -2,
        lineHeight: '60px',
    },
    content: {
        color: theme.palette.black,
        fontSize: 18,
        letterSpacing: 0,
        lineHeight: '32px',
        [theme.breakpoints.up('md')]: {
            width: 752,
        },
    }

};