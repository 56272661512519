import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button, Card, TextField, withStyles} from '@material-ui/core';

import ssoURI from '../../../utils/sso-uri';
import {apiURL} from '../../../assets/constants';
import texts from '../../../assets/texts';
import styles from './login-form-styles';

const LoginForm = props => {
  // From Styles
  const {classes, redirectUrl} = props;

  // From Login
  const {authenticate} = props;

  // State
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [filled, setFilled] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    if (username.length > 0 && password.length > 0) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [username, password]);

  // Actions
  const handleChange = e => {
    const field = e.target.name;

    setError(false);

    switch (field) {
      case 'username':
        setUsername(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const url = `${apiURL}/auth`;
      const {data} = await axios.post(
        url,
        {
          username: `${username}@seaburyexternal.com`,
          password,
        },
        {validateStatus: status => status === 200}
      );

      //if (isAccessTokenValid()) {
      authenticate(data.access_token, data.expires_in);
      //}
    } catch (e) {
      setPassword('');
      setLoading(false);
      setError(true);
    }
  };

  // Render
  return (
    <section className={classes.card}>
      <h2 className={classes.welcomeTitle}>Welcome back!</h2>
      <TextField
        label='Username'
        type='text'
        name='username'
        margin='normal'
        className={classes.field}
        value={username}
        onChange={e => handleChange(e)}
        InputProps={{
          classes: {
            underline: classes.underline,
            input: classes.input
          }
        }}
      />
      <TextField
        label='Password'
        type='password'
        name='password'
        margin='normal'
        className={classes.field}
        value={password}
        onChange={e => handleChange(e)}
        error={error}
        InputProps={{
          classes: {
            underline: classes.underline,
            input: classes.input
          }
        }}
      />
      {error && (
        <div className={classes.errorContainer}>
          <div className={classes.error}>Oops! This username / password combination is not valid.</div>
        </div>
      )}
      <div className={classes.buttonSection}>
        <Button
          variant='contained'
          color='primary'
          disabled={!filled}
          className={{root: classes.button, disabled: classes.disabled}}
          onClick={handleSubmit}
          style={{width: '170px'}}
        >
          {loading ? 'Loading...' : 'Sign in'}
        </Button>
        <a className={classes.alternative} href={ssoURI({redirectUrl})}>
          Sign in with SSO
        </a>
      </div>

      <p className={classes.help}>
        Forgot email or password?
        <br />
        Please contact your account manager
      </p>  
    </section>
  );
};

export default withStyles(styles)(LoginForm);
