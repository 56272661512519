import theme from '../../../assets/theme';

export const message_max_height = 180;

export default {
  card: {
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
    padding: [[theme.spacing(3), theme.spacing(4)]],
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    height: 79,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.landingFontFamily.lucida,
    fontSize: 18,
    letterSpacing: 0,
  },
  subTitle: {
    height: 24,
    width: 110,
    color: theme.palette.grey.midLight,
    fontSize: 13,
    letterSpacing: 0,
    [theme.breakpoints.up('xl')]: {
      height: 50,
      fontSize: 18
    },
  },
  readMore: {
    [theme.breakpoints.up('xl')]: {
      fontSize: 18
    },
  },
  bottomAction: {
    display: 'flex',
    '& > div': {
      cursor: 'pointer',
    },
    color: theme.palette.primary.contrastText,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    marginTop: theme.spacing(2),
  },
  message: {
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    '& .ql-editor': {
      maxHeight: message_max_height,
      overflow: 'hidden',
      'mask-image': 'linear-gradient(top, rgba(255, 255, 255, 1) 92%, rgba(0,0,0,0) 100%)',
    },
    '& img': {
      display: 'none',
    },
    '& video': {
      display: 'none',
    },
  },
  dialogContainer: {
    maxWidth: '50vw',
  },
  dialogSubtitle: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(4),
    maxWidth: 400,
  },
  dialogTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '30px',
    margin: 0,
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  clampMessage: {
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    '& > p': {
      margin: 0,
    },
  },
  forwardArrow: {
    paddingLeft : [theme.spacing(1)],
    cursor: 'pointer'
  }
};
