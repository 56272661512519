import {useMemo, useState} from 'react';
import produce from 'immer';

export const DialogType = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  CONFIRM: 'CONFIRM',
};

const initialDialogState = {
  open: false,
  title: null,
  loading: false,
  message: null,
  type: DialogType.ERROR,
  icon: null,
  onClose: undefined,
  onActionConfirmed: undefined,
  buttonText: null,
};

const openDialog = (draft, {type, title, message}) => {
  draft.open = true;
  draft.type = type;
  draft.title = title;
  draft.message = message;
  draft.loading = false;
};

const setDialogProps = (draft, {icon, onClose, onActionConfirmed, buttonText, cancelText}) => {
  draft.icon = icon;
  draft.onClose = onClose;
  draft.onActionConfirmed = onActionConfirmed;
  draft.buttonText = buttonText;
  draft.cancelText = cancelText;
};

const DialogStore = dialogState => {
  const [state, setState] = useState(dialogState || initialDialogState);

  const mutations = useMemo(
    () => ({
      showError: ({title, message, onClose, icon, buttonText}) =>
        setState(
          produce(draft => {
            setDialogProps(draft, {icon, onClose, buttonText});
            openDialog(draft, {type: DialogType.ERROR, title, message});
          })
        ),

      showInfo: ({title, message, onClose, icon, buttonText}) =>
        setState(
          produce(draft => {
            setDialogProps(draft, {icon, onClose, buttonText});
            openDialog(draft, {type: DialogType.INFO, title, message});
          })
        ),

      showConfirm: ({title, message, onClose, icon, onActionConfirmed, buttonText, cancelText}) =>
        setState(
          produce(draft => {
            setDialogProps(draft, {icon, onClose, onActionConfirmed, buttonText, cancelText});
            openDialog(draft, {type: DialogType.CONFIRM, title, message});
          })
        ),

      close: () =>
        setState(
          produce(draft => {
            draft.open = false;
            draft.title = null;
            draft.message = null;
          })
        ),
      loading: () =>
        setState(
          produce(draft => {
            draft.loading = true;
          })
        ),
    }),
    []
  );

  return {state, mutations};
};

export default DialogStore;
