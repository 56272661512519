import {useMemo, useState} from 'react';
import {useStore} from 'outstated';
import produce from 'immer';
import DialogStore from '../../dialog';
import ArticleMutations, {initialArticleState} from '../mutations';
import ArticleEffects from '../effets';
import {
  OLD_NOTIFICATION_AGE,
  VALID_STATUSES,
  MAX_NOTIFICATIONS_MAILBOX,
} from '../constant';
import HelpArticleService from '../../../services/help-service';

import logger from '../../../utils/logger';
import formatMessage from 'format-message';
import {AXIOS_REQUEST_CANCELED} from '../../../services/SeaburyAxios';

const getPublishedFromString = () => {
  const now = new Date();
  const publishedFromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - OLD_NOTIFICATION_AGE, 0, 0, 0);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
  const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(publishedFromDate);
  return `${year}-${month}-${day}`;
};

const getMailboxItems = notifications => {
  return [...notifications]
    .slice(0, MAX_NOTIFICATIONS_MAILBOX)
    .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());
};

const PinArticle = notificationState => {
  const initialState = {
    ...initialArticleState,
    filters: {
      status: VALID_STATUSES[1], // PUBLISHED
      publishedFrom: getPublishedFromString(),
      sortBy: 'publishedAt',
      withMessage: true,
    },
    insights: {},
    visuals: {},
    articleArray: {},
    announcement: null,
    priorityDataUpdates: [],
  };
  const [state, setState] = useState(notificationState || initialState);
  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      ...ArticleMutations(setState),
      setPinTo: values =>
        setState(
          produce(draft => {
            draft.articleArray = values.articleArray;
          })
        ),
        setSiteFooter: values =>
        setState(
          produce(draft => {
            draft.siteFooterArray = values.siteFooterArray;
          })
        ),


      setInsights: insights =>
        setState(
          produce(draft => {
            draft.insights = insights;
          })
        ),
    }),
    [state]
  );
  const effects = useMemo(
    () => ({
      ...ArticleEffects(state, mutations, DialogMutations),
      getVisualArticles: async pintoType => {
        try {
          const params = {
            ...state.filters,
            pintTo: pintoType,
            limit: 500, // FIXME: find a better way to handle pagination
            from: state.pagination.offset,
          };
          const data = await HelpArticleService.getArticles(params);
          let insights = {};

          if (data && data.docs && data.docs.length) {
            insights = data.docs.reduce((acc, article) => {
              acc[article.pinToInfo[0].location] = article;
              return acc;
            }, {});
          }
          mutations.setInsights(insights);
          return insights;
        } catch (err) {
          logger.warn(err);
          if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

          const errorMessage = formatMessage(
            'Due to a technical issue, the help article data is currently not available. Please try again later.'
          );
          mutations.setArticleError(errorMessage);
          DialogMutations.showError({
            title: formatMessage('Data cannot be retrieved.'),
            message: errorMessage,
          });
          return null;
        }
      },

      getArticles: async pintoType => {
        try {
          
          let newArray = [];
          let siteFooterArray = [];

          const params = {
            ...state.filters,
            limit: 500, // FIXME: find a better way to handle pagination
            from: state.pagination.offset,
          };
          const data = await HelpArticleService.getArticles(params);
          if (data && data.docs && data.docs.length) {
            data.docs.filter(res => res.pintTo !== 'VISUAL' && res.pintTo !== 'NOT_PINNED').map(article => {
              if (article.pinToInfo.length) {
                article.pinToInfo.map(articlePin => {
                  newArray.push({
                    details: article,
                    pinTo: articlePin,
                  });
                });
              } else {
                siteFooterArray.push({
                  details: article
                });
                
              }
              
            });
          }
          mutations.setPinTo({articleArray: newArray});
          mutations.setSiteFooter({siteFooterArray: siteFooterArray});
          return newArray;
        } catch (err) {
          logger.warn(err);
          if (err && err.key === AXIOS_REQUEST_CANCELED) return; // Return if request is canceled

          const errorMessage = formatMessage(
            'Due to a technical issue, the notification data is currently not available. Please try again later.'
          );
          mutations.setArticleError(errorMessage);
          DialogMutations.showError({
            title: formatMessage('Data cannot be retrieved.'),
            message: errorMessage,
          });
          return null;
        }
      },
    }),
    [mutations, DialogMutations, state]
  );
  return {state, mutations, effects};
};
export default PinArticle;
