import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  updateReportPages: async (reportId, body) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/report/${reportId}`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  deleteReportPage: async reportId => {
    try {
      await SeaburyAxios.delete(`/api/admin/report/${reportId}/pages`);
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
