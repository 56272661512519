import React from 'react';
import NotificationTableActions from './notifications-table-row-actions';
import formatMessage from 'format-message';
import {NOTIFICATION_TYPE_ADMIN_LABELS} from '../../../assets/constants';

export default ({onDeleteClick, onEditClick, onPublishClick, onUnpublishClick}) => [
  {
    id: 'dateCreated',
    Header: formatMessage('Date created'),
    accessor: 'dateCreated',
  },
  {
    id: 'type',
    Header: formatMessage('Type'),
    accessor: 'typeAdminLabel',
  },
  {
    id: 'title',
    Header: formatMessage('Title'),
    accessor: 'title',
  },
  {
    id: 'pinLocation',
    Header: formatMessage('Pin location'),
    accessor: 'insightInfo.location',
    Cell: cellInfo => {
      if (cellInfo && cellInfo.row && cellInfo.row.original) {
        const {type, insightInfo} = cellInfo.row.original;
        switch (type) {
          case NOTIFICATION_TYPE_ADMIN_LABELS.DATA_UPDATE:
          case NOTIFICATION_TYPE_ADMIN_LABELS.PRIORITY_DATA_UPDATE:
          case NOTIFICATION_TYPE_ADMIN_LABELS.NEWS:
            return formatMessage('Mailbox');
          case NOTIFICATION_TYPE_ADMIN_LABELS.ANNOUNCEMENT:
            return formatMessage('Login');
          case NOTIFICATION_TYPE_ADMIN_LABELS.INSIGHT:
            return insightInfo && insightInfo.location;
          default:
            return '';
        }
      }
      return '';
    },
  },
  {
    id: 'author',
    Header: formatMessage('Author'),
    accessor: 'author.name',
  },
  {
    id: 'status',
    Header: formatMessage('Status'),
    accessor: 'status',
  },
  {
    accessor: 'actions',
    Cell: cellInfo => {
      const notification = cellInfo.row.original;
      return (
        <NotificationTableActions
          notificationId={notification._id}
          status={notification.status}
          onPublishClick={() => onPublishClick(notification)}
          onUnpublishClick={() => onUnpublishClick(notification)}
          onEditClick={() => onEditClick(notification._id)}
          onDeleteClick={() => onDeleteClick(notification._id, notification.status)}
        />
      );
    },
  },
];
