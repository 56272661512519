import React from 'react';
import LoadingSpinner from '../loading-spinner';
import {withStyles} from '@material-ui/core';
import styles from './full-screen-loader-styles';

const FullScreenLoader = ({classes, loading, render}) => {
  if (loading)
    return (
      <div className={classes.fullScreenContainer}>
        <LoadingSpinner />
      </div>
    );

  return render();
};

export default withStyles(styles)(FullScreenLoader);
