import {createMuiTheme} from '@material-ui/core/styles';
import Karla from '../assets/fonts/Karla-Light.ttf';
import Lucida from '../assets/fonts/LucidaGrande.ttf';

const landingFontFamily = {
  karla: Karla,
  lucida: Lucida
};
const colors = {
  blue: '#015DAA',
  orange: '#F18031',
  darkOrange: '#F76D06',
  grey: {
    dark: '#3B4045',
    mid: '#6B7279',
    light: '#D6DADE',
    veryLight: '#F2F5F8',
    midLight: '#979EA6',
    line: '#D4D4D4',
    border: '#979797',
  },
  white: '#FFFFFF',
  black: '#000000',
  blackbg: '#1C1C1C',
};

const unit = 8;

export default createMuiTheme({
  landingFontFamily: {
    karla: landingFontFamily.karla,
    lucida: landingFontFamily.lucida
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: colors.blue,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.orange,
      contrastText: colors.grey.dark,
    },
    background: {
      paper: colors.white,
      default: colors.grey.veryLight,
      blackbg: colors.blackbg,
      signinButton: colors.darkOrange,
    },
    text: {
      primary: colors.grey.dark,
    },
    grey: colors.grey,
    black: colors.black,
    warning: {
      main: colors.orange,
    },
  },
  spacing: unit,
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0),0px 1px 1px 0px rgba(0,0,0,0.02),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 5px 0px rgba(0,0,0,0),0px 2px 2px 0px rgba(0,0,0,0.02),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0),0px 3px 4px 0px rgba(0,0,0,0.02),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0),0px 4px 5px 0px rgba(0,0,0,0.02),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0),0px 5px 8px 0px rgba(0,0,0,0.02),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0),0px 6px 10px 0px rgba(0,0,0,0.02),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0),0px 7px 10px 1px rgba(0,0,0,0.02),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0),0px 8px 10px 1px rgba(0,0,0,0.02),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0),0px 9px 12px 1px rgba(0,0,0,0.02),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0),0px 10px 14px 1px rgba(0,0,0,0.02),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0),0px 11px 15px 1px rgba(0,0,0,0.02),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0),0px 12px 17px 2px rgba(0,0,0,0.02),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0),0px 13px 19px 2px rgba(0,0,0,0.02),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0),0px 14px 21px 2px rgba(0,0,0,0.02),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0),0px 15px 22px 2px rgba(0,0,0,0.02),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0),0px 16px 24px 2px rgba(0,0,0,0.02),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0),0px 17px 26px 2px rgba(0,0,0,0.02),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0),0px 18px 28px 2px rgba(0,0,0,0.02),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0),0px 19px 29px 2px rgba(0,0,0,0.02),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0),0px 20px 31px 3px rgba(0,0,0,0.02),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0),0px 21px 33px 3px rgba(0,0,0,0.02),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0),0px 22px 35px 3px rgba(0,0,0,0.02),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0),0px 23px 36px 3px rgba(0,0,0,0.02),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0),0px 24px 38px 3px rgba(0,0,0,0.02),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth'
        },
        body: {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          letterSpacing: 'inherit',
          lineHeight: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      outlinedPrimary: {
        borderColor: colors.grey.light,
      },
    },
    MuiInput: {
      root: {
        '&$error': {
          border: [1, 'solid', '#F18031'],
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: colors.blue,
      },
      root: {
        minWidth: 'unset !important',
        textTransform: 'none',
        fontSize: '16px !important',
        lineHeight: '20px',
        letterSpacing: '0',
        fontWeight: '400px',
        padding: 0,
        '&$selected': {
          fontWeight: 'bold',
        },
        '& $wrapper': {
          width: 'unset',
          padding: [[0, unit * 3]],
        },
        '&$labelIcon': {
          minHeight: 'unset',
          padding: 'unset',
          marginBottom: 0,
        },
        '&$labelIcon $wrapper': {
          display: 'flex',
          flexFlow: 'row-reverse nowrap',
          '& > *:first-child': {
            marginBottom: 0,
          },
          '& > *:last-child': {
            marginRight: unit,
          },
        },
        '&$labelIcon $labelContainer': {
          marginRight: unit,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: [[unit * 2.5, unit * 2]],
        padding: 0,
        '& h4': {
          margin: 0,
          fontSize: 18,
          fontWeight: '700',
        },
        '& img': {
          display: 'block',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: [[unit * 2, unit * 2, unit * 3]],
      },
    },
    MuiDialogActions: {
      root: {
        margin: unit * 2,
        padding: 0,
      },
    },
  },
});
