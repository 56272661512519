import React, {useState, useEffect, useCallback} from 'react';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {FormDialog} from '../../../components/dialog';
import Button from '../../../components/button';
import {
  HelpFullTitleFormField,
  HelpShortTitleFormField,
  HelpHoverFormField,
  HelpSectionSelectFormField,
  SortSelectFormField
} from '../../../components/form/form-fields';
import {HelpAdminStore} from '../../../state/help/store';

const HelpEditDialog = ({value, article, onChange, formState}) => {
  const [open, setOpen] = useState(false);
  const {mutations: ArticlesMutations, effects: HelpEffects, state: ArticlesState} = useStore(HelpAdminStore);
  const [inputValue, setInputValue] = useState(value);
  const {totalCount} = ArticlesState;

  useEffect(() => {
    ArticlesMutations.setCurrentArticle({
      ...article,
      message: formState.values.message,
      audience: formState.values.audience,
      pinToInfo: formState.values.pinToInfo,
    });
    HelpEffects.getSection();
  }, [open]);

  const handleCancel = useCallback(() => {
    setInputValue(value);
    setOpen(false);
  }, [value]);

  const handleUpdate = useCallback(
    async values => {
      ArticlesMutations.setUpdateArticle({
        isUpdated: true,
      });
      values.fullTitle = values.fullTitle.trimEnd();
      values.shortTitle = values.shortTitle.trimEnd();
      values.hoverTitle = values.hoverTitle.trimEnd();

      ArticlesMutations.setCurrentArticle({
        ...values,
        message: formState.values.message,
        audience: formState.values.audience,
        pinToInfo: formState.values.pinToInfo,
      });
      setOpen(false);
    },
    [ArticlesMutations, onChange, inputValue]
  );

  return (
    <>
      <Button variant='outlined' icon={'edit'} onClick={() => setOpen(true)}>
        {formatMessage('Edit')}
      </Button>
      <FormDialog
        open={open}
        initialValues={article}
        title={formatMessage('Edit article')}
        onClose={handleCancel}
        button={formatMessage('Update')}
        onSubmit={handleUpdate}
        computeDisabled={({...formData}) => !formData.valid || formData.pristine}
      >
        {/* <TextField
          label={formatMessage('Full title')}
          value={inputValue}
          handleChange={e => setInputValue(e.currentTarget.value)}
          showError={!inputValue}
          error={formatMessage('Please fill in a Full title name')}
          isWarning
          maxLength={NOTIFICATION_TITLE_MAX_LENGTH}
        /> */}
        <HelpFullTitleFormField />
        <HelpShortTitleFormField />
        <HelpHoverFormField />
        <HelpSectionSelectFormField />
        <SortSelectFormField totalCount={totalCount}/>
      </FormDialog>
    </>
  );
};

export default HelpEditDialog;
