import React from 'react';
import Select from '@material-ui/core/Select';
import {FormControl, InputLabel, MenuItem, withStyles} from '@material-ui/core';
import styles from './select-field-styles';

const SelectField = ({classes, label, value, handleChange, options, disabled}) => {
  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        onChange={handleChange}
        value={value}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
      >
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SelectField);
