import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core';
import {Redirect} from 'react-router';
import {useStore} from 'outstated';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {Link} from 'react-scroll';
import Particles from 'react-particles-js';

import styles from './landing-styles';
import AppStore from '../../state/app';
import LoadingSpinner from '../../components/loading-spinner';
import {Routes} from '../../Routes';
import About from './about';
import Products from './products';
import ProductHighLights from './product-high-lights';
import Clients from './clients';
import LandingHeader from '../../components/landing-header';
import LandingFooter from '../../components/landing-footer';

const Landing = props => {
  // From Styles
  const {classes, location} = props;
  const redirectUrl = (location && location.state && location.state.pathname) || Routes.ROOT;
  const {state: AppState, mutations: AppMutations} = useStore(AppStore);

  const [bannerOpacity, setBannnerOpacity] = useState(1);
  const navRef = React.useRef();
  navRef.current = bannerOpacity;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) {
        setBannnerOpacity(bannerOpacity > 0.1 ? bannerOpacity - 0.1 : 0.1);
      } else {
        setBannnerOpacity(1);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        let scrollValue = window.innerWidth;
        if (scrollValue < 960) {
          elem.scrollIntoView(true);
        } else {
          elem.scrollIntoView({behavior: 'smooth', duration: 1000});
        }
      }
    } else {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth', duration: 1000});
    }
  }, [location]);

  return (
    <>
      {!AppState.initialized && <LoadingSpinner />}
      {AppState.initialized && AppState.token !== null && <Redirect to={redirectUrl} />}
      {AppState.initialized && AppState.token === null && (
        <div className={classes.wrapper} id="wrapper">
          <LandingHeader nonLanding='false' />
          <section className={classes.headerBanner} style={{opacity: navRef.current}}>
            <Particles
              className={classes.particles}
              params={{
                particles: {
                  number: {
                    value: 100,
                    density: {
                      enable: true,
                      value_area: 1000,
                    },
                  },
                  line_linked: {
                    enable: true,
                    distance: 120,
                    opacity: 0.4,
                    width: 1,
                  },
                  move: {
                    enable: true,
                    speed: 2,
                  },
                },
                interactivity: {
                  detect_on: 'canvas',
                  events: {
                    onhover: {
                      enable: true,
                      mode: 'grab',
                    },
                    onclick: {
                      enable: false,
                    },
                    resize: true,
                  },
                },
              }}
            />

            <Container maxWidth={'xl'}>
              <section className={classes.bannerSection}>
                <div className={classes.content}>
                  <h1 className={classes.title}>More insights, better decisions</h1>
                  <p className={classes.asTheGlobalCargo}>
                    As the global cargo marketplace is changing, so is the need to stay informed with fact-based
                    insights.
                  </p>
                  <Button variant='contained' color='primary' className={classes.findMore}>
                    <span className={classes.buttonText}>
                      <Link to={'about'} smooth={true} duration={1000}>
                        Find out more
                      </Link>
                    </span>
                  </Button>
                  <div className={classes.pageScroll}>
                    Scroll
                    <div className={classes.pageScrollLine} />
                  </div>
                </div>
              </section>
            </Container>
          </section>

          <About />
          <Products />
          <ProductHighLights />
          <Clients />
          <LandingFooter />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Landing);
