import React from 'react';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {TextField} from '../fields';
import {NOTIFICATION_TITLE_MAX_LENGTH} from '../../../assets/constants';

const HelpHoverFormField = () => {
  return (
    <>
      <Field
        name='hoverTitle'
        validate={value => (value ? !value.match(/^[^\s].{2,100}$/) : formatMessage('required'))}
        render={({input, meta}) => {
          return (
            <TextField
              label={formatMessage('Hover text')}
              value={input.value}
              handleChange={input.onChange}
              showError={(meta.modified && !input.value) || (meta.modified && !input.value.match(/^[^\s].{2,100}$/))}
              error={formatMessage('Please fill in a hover text name with a minimum 3 character')}
              isWarning
              maxLength={NOTIFICATION_TITLE_MAX_LENGTH}
            />
          );
        }}
      />
    </>
  );
};

export default HelpHoverFormField;
