import React, {useState, useRef} from 'react';
import {Tabs, Tab, RootRef, MenuItem, Divider, MenuList, Link, withStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {numberOfDashboardTabs, COGNOS_URL} from '../../../assets/constants';
import {useStore} from 'outstated';
import DashboardStore from '../../../state/dashboard';
import Menu from '../../menu/menu-view';
import LinkIcon from '../../../assets/icons/icon_24_link_blue.svg';
import ChevronDownIcon from '../../../assets/icons/icon_24_chevron_down_blue.svg';
import ChevronUpIcon from '../../../assets/icons/icon_24_chevron_up_blue.svg';
import styles from './navigation-styles';

const NavigationTabs = ({classes}) => {
  const [moreMenu, setMoreMenu] = useState(false);
  const history = useHistory();
  const numberOfTabs = numberOfDashboardTabs;

  const moreMenuRef = useRef();

  const {state: DashboardState} = useStore(DashboardStore);

  return (
    <>
      <Tabs
        classes={{
          root: classes.container,
          flexContainer: classes.flexContainer,
          fixed: classes.fixed,
          indicator: classes.hiddenIndicator,
        }}
        textColor='primary'
      >
        {DashboardState.dashboards &&
          DashboardState.dashboards.slice(0, numberOfTabs).map((item, index) => {
            if (!item.id) return null;
            let navigationUrl = `/${item.url}`;
            const navigationState = {dashboardID: item.id, pageID: false};
            if (item.pages.length > 0) {
              navigationUrl = `/${item.pages[0].url}`;
              navigationState.pageID = item.pages[0].id;
            }
            return (
              <Tab
                key={index}
                label={item.name}
                onClick={() => {
                  history.push(navigationUrl, navigationState);
                }}
              />
            );
          })}

        {DashboardState.dashboards && DashboardState.dashboards.length > numberOfTabs && (
          <RootRef rootRef={moreMenuRef}>
            <Tab
              selected={moreMenu}
              label={
                <span>
                  More
                  <img src={moreMenu ? ChevronUpIcon : ChevronDownIcon} alt='' />
                </span>
              }
              onClick={() => setMoreMenu(true)}
              disabled={Boolean(moreMenu)}
              className={classes.moreTab}
              classes={{
                selected: classes.moreTab,
                disabled: classes.disabled,
                textColorInherit: classes.textColorInherit,
              }}
            />
          </RootRef>
        )}

        <Tab
          className={classes.externalTab}
          disableRipple
          label={
            <span>
              <Link href={COGNOS_URL} target={'_blank'} underline={'none'}>
                Cognos
              </Link>
            </span>
          }
          icon={<img src={LinkIcon} alt={'Cognos'} />}
        />
      </Tabs>
      <Menu
        open={Boolean(moreMenu)}
        anchorEl={moreMenuRef.current}
        onClickAway={() => setMoreMenu(false)}
        className={classes.moreMenu}
      >
        <MenuList className='menu-items'>
          {DashboardState.dashboards &&
            DashboardState.dashboards.slice(numberOfTabs).map(item => {
              let navigationUrl = `/${item.url}`;
              const navigationState = {dashboardID: item.id, pageID: false};
              if (item.pages.length > 0) {
                navigationUrl = `/${item.pages[0].url}`;
                navigationState.pageID = item.pages[0].id;
              }
              return (
                <MenuItem
                  key={item.id}
                  className='menu-item'
                  onClick={() => {
                    history.push(navigationUrl, navigationState);
                  }}
                >
                  {item.name}
                  <Divider className='divider' />
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu>
    </>
  );
};

export default withStyles(styles)(NavigationTabs);
