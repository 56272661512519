import * as pbi from 'powerbi-client';
import {workspaceID} from '../assets/constants';
import PowerBiService from '../services/powerbi-service';
import {DATE_RANGE_FILTER_TYPE, DEFAULT_FILTER_TYPE, defaultFilterValueIdentifier} from '../assets/constants';
import logger from './logger';
import groupBy from 'lodash/groupBy';

const FILTER_PAGE = 'Filters';

const FILTER_NAME_REGEX = /filter-\d-[a-zA-Z0-9_ ']+-[a-zA-Z0-9_ ']+\[[a-zA-Z0-9_ ']+\](-[a-zA-Z0-9_ ']+)?/;

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

export const POWERBI_ERRORS = {
  TOKEN_EXPIRED: 'POWERBI_TOKEN_EXPIRED_ERROR',
  BROKEN_FILTERS: 'POWERBI_BROKEN_FILTERS_ERROR',
};

export const POWERBI_CUSTOM_ERRORS = {
  INVALID_FILTER_FORMAT: 'INVALID_FILTER_FORMAT',
  INVALID_FILTER_NAME: 'INVALID_FILTER_NAME',
};

const embed = ({element, config, callbacks: {onLoaded, onRendered, onError}}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const el = await powerbi.embed(element, {
        ...config,
      });

      el.on('loaded', () => {
        el.off('loaded');
        if (onLoaded && typeof onLoaded === 'function') onLoaded(el);
      });
      el.on('rendered', () => {
        el.off('rendered');
        if (onRendered && typeof onRendered === 'function') onRendered(el);
        resolve(el);
      });

      el.on('error', error => {
        el.off('error');
        logger.error('PowerBI on error event:', error.detail || error, ' on:', this || el);
        
        if (error && error.detail && error.detail.message === 'TokenExpired') {
          reject({...error, customKey: POWERBI_ERRORS.TOKEN_EXPIRED});
        } else if (error && error.detail && error.detail.message === 'Broken_Filters') {
          reject({...error, customKey: POWERBI_ERRORS.BROKEN_FILTERS});
        } else if (error && error.detail && error.detail.message === 'LoadReportFailed') {
          reject({...error});
        } else {
          if (onError && typeof onError === 'function') onError(error);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

const embedVisual = async ({element, visualConfig, callbacks = {}}) => {
  const el = await embed({element, config: visualConfig, callbacks});
  return el;
};

const embedReport = async ({element, reportConfig, callbacks = {}}) => {
  const el = await embed({element, config: reportConfig, callbacks});
  return el;
};

const checkIsFilterV2 = async report => {
  const pages = await report.getPages();
  const filterPage = pages.find(page => page.displayName === FILTER_PAGE);
  if (filterPage) {
    await report.page(filterPage.name).setActive();

    const visuals = await filterPage.getVisuals();
    return visuals.find(v => v.type === 'slicer') === undefined || visuals.find(v => v.type !== null) !== undefined;
  }
  return false;
};

const getFiltersData = async report => {
  let exportedList = [];
  const pages = await report.getPages();
  const filterPage = pages.find(page => page.displayName === FILTER_PAGE);
  await report.page(filterPage.name).setActive();

  const visuals = await filterPage.getVisuals();
  for (let i = 0; i < visuals.length; i++) {
    const visual = visuals[i];
    let tries = 0;
    while (tries < 4) {
      try {
        if (visual.title) {
          const exported = await visual.exportData(pbi.models.ExportDataType.Summarized);
          exportedList.push({...exported, filterName: visual.title});
          tries = 4;
        } else {
          exportedList = [];
          tries = 4;
        }
      } catch (error) {
        if (tries === 4) {
          throw error;
        }
      }
    }
  }
  return {
    pages,
    exportedList,
  };
};

const getVisualsInReport = async report => {
  const pagesToIgnore = [FILTER_PAGE];

  let visualList = [];

  const pages = await report.getPages();

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];

    if (!pagesToIgnore.includes(page.displayName)) {
      await report.page(page.name).setActive();

      const reportVisuals = await report.page(page.name).getVisuals();

      for (let j = 0; j < reportVisuals.length; j++) {
        const visual = reportVisuals[j];
        if (visual.type !== 'slicer') {
          visualList.push(visual);
        }
      }
    }
  }
  return visualList;
};

const parseFilterName = filterName => {
  if (filterName) {
    if (!FILTER_NAME_REGEX.test(filterName)) {
      const error = {
        key: POWERBI_CUSTOM_ERRORS.INVALID_FILTER_NAME,
      };
      throw error;
    }

    const arr = filterName.split('-');
    const tableColumn = arr[3].split('[');
    const table = tableColumn[0];
    const column = tableColumn[1].replace(/(])/gm, '');

    var regex = /\[([^\][]*)]/g;
    var results=[], m;
    while ( m = regex.exec(filterName) ) {
      results.push(m[1]);
    }
    let fullColumnArray = results;
    return {
      order: arr[1],
      label: arr[2],
      table,
      column,
      resetLabel: arr[4] || null,
      fullColumnArray,
    };
  }
  return {target: {table: 'not defined', column: 'not defined'}};
};

const parseExportedFilterData = exportedList => {
  const parsedFilters = exportedList.map(el => {
    const filterObj = {...parseFilterName(el.filterName), values: []};
    const rows = el.data.split('\n');
    const header = rows[0].replace(/(\r\n|\n|\r)/gm, '').split(',');

    if (!header.find(e => e.toLowerCase() === 'value')) {
      const error = {
        key: POWERBI_CUSTOM_ERRORS.INVALID_FILTER_FORMAT,
      };
      throw error;
    }

    const data = rows.slice(1, rows.length - 1); // First row is the header, last row is empty
    data.forEach(row => {
      const intObj = {};
      const item = row.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/); // Split on comma followed by non-quote characters

      item.forEach((e, index) => {
        const element = e.replace(/(\r\n|\n|\r|")/gm, '');

        const headerItem = header[index].toLowerCase();

        if (headerItem === 'userlist') {
          if (element) {
            intObj['allowedUsers'] = element.split(';');
          }
        } else if (headerItem === 'default') {
          if (element !== '') {
            intObj[headerItem] = element;
          }
        } else {
          intObj[headerItem] = element;
        }
      });
      filterObj.values.push(intObj);
    });
    return filterObj;
  });
  return parsedFilters;
};

const getAndParseFilterData = async report => {
  const filterData = await getFiltersData(report);
  const filters = await parseExportedFilterData(filterData.exportedList);

  filters.forEach(element => {
    // To filter all column values, assuming the level should always maximum of 3, if more than 3 need to change the logic to dynamic
    const first_type = element.fullColumnArray[0] ? element.fullColumnArray[0].toString().replace(/[\[\]']+/g, '') : '';
    const second_type = element.fullColumnArray[1] ? element.fullColumnArray[1].toString().replace(/[\[\]']+/g, ''): '';
    const third_type = element.fullColumnArray[2] ? element.fullColumnArray[2].toString().replace(/[\[\]']+/g, ''): '';
    const fourth_type = element.fullColumnArray[3] ? element.fullColumnArray[3].toString().replace(/[\[\]']+/g, ''): '';
    let filterValues = [];

    element.values.forEach((result, index) => { // Looping the "values" to restructure into a new object

      if (filterValues[filterValues.length - 1] && filterValues[filterValues.length - 1].value == result.value) { // If already present - merge else push
        const value2_lenngth = filterValues[filterValues.length - 1].value2?.length;

        if (filterValues[filterValues.length - 1].value2) {
          if (filterValues[filterValues.length - 1].value2[value2_lenngth - 1].value === result.value2) {
            if (filterValues[filterValues.length - 1].value2[value2_lenngth - 1].value3 && result.value3) {
              filterValues[filterValues.length - 1].value2[value2_lenngth - 1].value3.push({
                value: result.value3,
                type: third_type,
              });
              if(element.fullColumnArray.length === 3 && result.default) {
                filterValues[filterValues.length - 1].value2[value2_lenngth - 1].value3.default= result.default;
              }
            }
          } else {
            filterValues[filterValues.length - 1].value2.push({
              value: result.value2,
              type: second_type,
            });
            if(element.fullColumnArray.length === 2 && result.default) {
              filterValues[filterValues.length - 1].value2[value2_lenngth]['default'] = result.default;
            }
            if (result.value3) {
              filterValues[filterValues.length - 1].value2[value2_lenngth]['value3'] = [{
                value: result.value3,
                type: third_type
              }];
              if(element.fullColumnArray.length === 3 && result.default) {
                filterValues[filterValues.length - 1].value2[value2_lenngth]['value3']['default'] = result.default;
              }
            }
          }
        } else {
          filterValues[filterValues.length - 1].value2 && filterValues[filterValues.length - 1].value2.push({
            value: result.value2,
            type: second_type,
          });
          if(element.fullColumnArray.length === 2 && result.default) {
            filterValues[filterValues.length - 1].value2[value2_lenngth]['default'] = result.default;
          }
          if (result.value3) {
            filterValues[filterValues.length - 1].value2[value2_lenngth]['value3'] = [{
              value: result.value3,
              type: third_type
            }];
            if(element.fullColumnArray.length === 3 && result.default) {
              filterValues[filterValues.length - 1].value2[value2_lenngth]['value3']['default'] = result.default;
            }
          }
        }
      } else {
        const data = {
          value: result.value,
          type: first_type,
        };
        if(element.fullColumnArray.length === 1 && result.default) {
          data.default = result.default;
        }
        if (result.value2) {
          data['value2'] = [
            {
              value: result.value2,
              type: second_type,
            },
          ];
          if(element.fullColumnArray.length === 2 && result.default) {
            data.value2.default = result.default;
          }
        }
        if (result.value3) {

          data.value2[0].value3 = [
            {
              value: result.value3 ? result.value3 : '',
              type: third_type,
            },
          ];
          if(element.fullColumnArray.length === 3 && result.default) {
            const value3Length = data.value2[0].value3.length;
            data.value2[0].value3[value3Length - 1].default = result.default;
          }
        }
        if (result.value4) {
          data.value2[0].value3[0].value4 = [
            {
              value: result.value4 ? result.value4 : '',
              type: fourth_type,
            },
          ];
          if(element.fullColumnArray.length === 4 && result.default) {
            data.value2[0].value3[0].value4.default = result.default;
          }
        }
        filterValues.push(data);
      }
    });

    element.filterValues = filterValues;
  });
  return {
    reportPages: filterData.pages,
    filterList: filters,
  };
};

const addPbiMissingFilterData = filters => {
  return filters.map(filter => ({
    ...filter,
    pbi: {
      schemaUrl: 'http://powerbi.com/product/schema#basic',
      filterType: 1,
      operator: 'In',
    },
  }));
};

// TODO: Remove when all reports use new filters source
const getFiltersFromSlicers = async report => {
  let slicers = [];
  let tables = [];

  const pages = await report.getPages();

  for (let i = 0, n = pages.length; i < n; i++) {
    let page = pages[i];

    await report.page(page.name).setActive();

    const reportVisuals = await report.page(page.name).getVisuals();

    for (let i = 0, n = reportVisuals.length; i < n; i++) {
      const visual = reportVisuals[i];

      if (visual.type === 'slicer') {
        let slicer = await visual.getSlicerState().catch(e => null);
        if (slicer) {
          if (slicer.filters.length > 0) {
            const slicerIdentifier = `${slicer.filters[0].target.table}-${slicer.filters[0].target.column}`;
            if (!tables.includes(slicerIdentifier)) {
              const filter = slicer.filters[0];

              let isDateRangeFilter = '';
              if (Array.isArray(slicer.filters[0].target)) {
                slicer.filters[0].target.map((element, index) => {
                  isDateRangeFilter =
                    element.table.toLowerCase() === 'daterange' || element.column.toLowerCase() === 'period';
                });
              } else {
                isDateRangeFilter =
                  slicer.filters[0].target.table.toLowerCase() === 'daterange' ||
                  slicer.filters[0].target.column.toLowerCase() === 'period';
              }

              const newFilter = {
                ...filter,
                id: visual.name,
                active:
                  (filter.values &&
                    filter.values.find(v => {
                      try {
                        return v.startsWith(defaultFilterValueIdentifier);
                      } catch (e) {
                        return null;
                      }
                    })) ||
                  null,
                type: isDateRangeFilter ? DATE_RANGE_FILTER_TYPE : DEFAULT_FILTER_TYPE,
                hidden: false,
              };

              tables.push(slicerIdentifier);
              slicers.push(newFilter);
            }
          }
        }
      }
    }
  }
  return {slicers, pages};
};

const reset = element => {
  return powerbi.reset(element);
};

const getVisualsAndFilters = async ({element, reportID, dashboardId}) => {
  try {
    let filters = [];
    let isFilterV2 = false;
    const config = await PowerBiService.getReportConfig({workspaceID, reportID, dashboardId});
    if (!config) {
      return {visuals: [], filters: [], isFilterV2: [], reportPages: []};
    }
    const report = await embedReport({
      element,
      reportConfig: config,
    });
    let reportPages = [];
    const visuals = await getVisualsInReport(report);
    if (await checkIsFilterV2(report)) {
      const filtersData = await getAndParseFilterData(report);
      reportPages = filtersData.reportPages;
      isFilterV2 = true;
      filters = addPbiMissingFilterData(
        filtersData.filterList.sort((a, b) => {
          if (parseInt(a.order) > parseInt(b.order)) return 1;
          else return -1;
        })
      );
    } else {
      const filtersFromSlicers = await getFiltersFromSlicers(report);

      isFilterV2 = false;
      filters = filtersFromSlicers.slicers;
      reportPages = filtersFromSlicers.pages?.map(page => {
        return {
          displayName: page.displayName,
          name: page.name,
        };
      });
    }

    return {visuals, filters, isFilterV2, reportPages};
  } catch (error) {
    if (process.env.REACT_APP_IS_LOCAL === 'true') {
      logger.error('Error embeding hidden report', error);
    }
    throw error;
  }
};

const get = el => {
  return powerbi.get(el);
};

export default {
  embedVisual,
  embedReport,
  getFiltersData,
  parseExportedFilterData,
  addPbiMissingFilterData,
  reset,
  getVisualsAndFilters,
  get,
};
