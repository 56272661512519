import React, {useEffect} from 'react';
import Dialog from './admin-add-edit-chart-view';
import axios from 'axios';
import {useStore} from 'outstated';

import {apiURL} from '../../../assets/constants';
import {axiosHeader} from '../../../utils/axios';
import AdminStore from '../../../state/admin';
import AdminPageEditStore from '../../../state/admin-page-edit';

const AdminAddEditChart = props => {
  // From Page-edit
  const {open, edit, visuals, reportID, pageCharts} = props;

  const {effects: AdminEffects} = useStore(AdminStore);

  const {state: AdminPageEditState, mutations: AdminPageEditMutations} = useStore(AdminPageEditStore);

  const {
    chartEdit: {visual, title, subtitle, tooltips, layout, filled},
  } = AdminPageEditState;

  // Effects

  AdminPageEditMutations.setChartEditValues({});

  //// handleChanges
  useEffect(() => {
    if (visual && title && subtitle) {
      if (visual.name.length > 0 && title.length > 0 && subtitle.length > 0) {
        AdminPageEditMutations.setChartEditValues({filled: true});
        return;
      }
    }
    AdminPageEditMutations.setChartEditValues({filled: false});
  }, [visual, title, subtitle]);

  //// Edit Mode
  useEffect(() => {
    if (edit.status) {
      AdminPageEditMutations.setChartEditValues({
        title: edit.item.title,
        subtitle: edit.item.subtitle,
        tooltips: edit.item.description || '',
        layout: edit.item.two_columns,
      });
    }
  }, [edit]);

  useEffect(() => {
    if (visuals && edit.status) {
      AdminPageEditMutations.setChartEditValues({visual: visuals.find(visual => visual.name === edit.item.visual)});
    }
  }, [visuals, edit]);

  // Actions
  const resetFields = () => {
    setTimeout(() => {
      AdminPageEditMutations.setChartEditValues({
        visual: null,
        title: '',
        subtitle: '',
        tooltips: '',
        layout: false,
        filled: false,
      });
    }, 500);
  };

  const onClose = () => {
    resetFields();
    props.onClose();
  };

  const handleChange = e => {
    const field = e.target.name;

    switch (field) {
      case 'visual':
        AdminPageEditMutations.setChartEditValues({visual: visuals.find(item => item.name === e.target.value)});
        break;
      case 'title':
        AdminPageEditMutations.setChartEditValues({title: e.target.value});
        break;
      case 'subtitle':
        AdminPageEditMutations.setChartEditValues({subtitle: e.target.value});
        break;
      case 'extra info':
        AdminPageEditMutations.setChartEditValues({tooltips: e.target.value});
        break;
      case 'layout':
        AdminPageEditMutations.setChartEditValues({layout: e.target.value});
        break;
      default:
        break;
    }
  };

  const handleSave = async () => {
    try {
      let url;

      url = `${apiURL}/admin/chart`;
      const res = await axios.post(
        url,
        {
          title,
          subtitle,
          description: tooltips,
          report_id: visual.page.report.config.id,
          group_id: visual.page.report.config.groupId,
          page: visual.page.name,
          visual: visual.name,
          two_columns: layout,
        },
        axiosHeader(200)
      );
      const chartID = res.data.id;

      url = `${apiURL}/admin/report/${reportID}/charts`;
      await axios.patch(url, {charts: pageCharts.map(chart => chart.id).concat(chartID)}, axiosHeader(200));
    } catch (e) {}

    await AdminEffects.getDashboards();
    resetFields();
    onClose();
  };

  const handleEdit = async () => {
    try {
      const url = `${apiURL}/admin/chart/${edit.item.id}`;
      await axios.patch(
        url,
        {
          title,
          subtitle,
          description: tooltips,
          report_id: visual.page.report.config.id,
          group_id: visual.page.report.config.groupId,
          page: visual.page.name,
          visual: visual.name,
          two_columns: layout,
        },
        axiosHeader(200)
      );
    } catch (e) {}

    await AdminEffects.getDashboards();
    resetFields();
    onClose();
  };

  return (
    <Dialog
      open={open}
      edit={edit}
      onClose={onClose}
      onSave={edit.status ? handleEdit : handleSave}
      handleChange={handleChange}
      visuals={visuals}
      visual={visual}
      title={title}
      subtitle={subtitle}
      tooltips={tooltips}
      layout={layout}
      filled={filled}
    />
  );
};

export default AdminAddEditChart;
