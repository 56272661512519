import React from 'react';
import ProptTypes from 'prop-types';
import {useStore} from 'outstated';
import {HelpAdminStore} from '../../../state/help/store';
import {Redirect, Route} from 'react-router-dom';
import {Routes} from '../../../Routes';

const HelpRoute = ({exact, path, Component}) => {
  const {state: ArticlesState} = useStore(HelpAdminStore);

  const {current: notification} = ArticlesState;

  //  Redirect to notifications list if user doesnt follow the modal flow to create a new notification
  if (path === Routes.ADMIN.HELP_NEW && !notification.section) {
    return <Redirect to={Routes.ADMIN.HELP} />;
  }

  return <Route exact={exact} path={path} render={props => <Component {...props} />} />;
};

HelpRoute.proptTypes = {
  excat: ProptTypes.bool,
  path: ProptTypes.string.isRequired,
  Component: ProptTypes.node.isRequired,
};

HelpRoute.defaultProps = {
  exact: false,
};

export default HelpRoute;
