import theme from '../../../assets/theme';

export const container_height = 980;
export const container_top_separation = 84;

export default {
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: 'auto',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '25px',
    boxShadow: 'none',
    marginBottom: '30px',
    marginTop: '30px',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  articleSection: {
    marginTop: theme.spacing(2)
  },
  iconButton: {
    padding: 10,
    cursor: 'default',
  },

  newstyle: {
    height: '100%',
    border: '1px solid green',
    margin: 6,
    padding: 8,
  },
  root: {
    flexGrow: 1,
    '& .MuiGrid-justify-xs-center': {
      justifyContent: 'start',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '1px solid grey',
    minHeight: 364,
    borderColor: '#D6DADE',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '3px',
    marginBottom: '12px',
    '& .MuiListItem-root': {
      alignItems: 'flex-start',
    },
    '& .MuiListItemText-root': {
      marginTop: 0,
      wordBreak: 'break-word',
    },
  },
  listItemOld: {
    color: theme.palette.primary.main,
    lineHeight: '25px',
    cursor: 'pointer',
  },
  listItem: {
    cursor: 'pointer',
    paddingLeft: 0,
  },
  loadMore: {
    cursor: 'pointer',
    textAlign: 'end',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '10px',
    margin: '15px 0 0 0',
    color: theme.palette.primary.main,
    '& > span': {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  loadMoreIcon: {
    cursor: 'pointer',
    textAlign: 'start',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '10px',
    margin: 0,
    color: theme.palette.grey.mid,
    '& > span': {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  listWrap: {
    '& .MuiList-root': {
      overflowY: 'auto',
      height: 280,
    },
  },
  cardTitle: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
    flex: '1 1 auto',
    alignItems: 'start',
    whiteSpace: 'nowrap',
    maxWidth: '96%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headTitle: {
    textAlign: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '20px',
  },
  input: {
    maxHeight: theme.spacing(5),
    backgroundColor: '#ffffff',
    width: 400,
    margin: theme.spacing(2)
  },
  titleEmpty: {
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(2),
  },
  messageWrapper: {
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    marginTop: container_top_separation,
  },
  messageListWrapper: {
    margin: 0,
  },
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',

    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  emptyListContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContainerContent: {
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pagination: {
    marginTop: 0,
  },
};
