import React, {useState, useMemo} from 'react';
import {withStyles} from '@material-ui/core';
import {useStore} from 'outstated';

import FooterDialog from './dialog';
import styles from './footer-styles';
import AdminStore from '../../state/admin';
import DashboardStore from '../../state/dashboard';
import {PinArticleStore, HelpAdminStore} from '../../state/help/store';
import ArticleDetailsDialog from '../../sections/help/viewer/article-dialog/article-detail-view';
import {Routes} from '../../Routes';
import {getAuthenticadUserInfo} from '../../utils/access-token';
import history from '../../utils/history';

const Footer = ({classes, landingPages = false}) => {
  // State
  const [dialog, setDialog] = useState(null);
  const {state: PinArticlaState} = useStore(PinArticleStore);
  const {state: AdminState} = useStore(AdminStore);
  const {state: ArticleState, mutations: ArticlesMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const {isOpen, article, articleList, isLoadMore, backButton, fromPinTo} = ArticleState;
  const {mutations: DashboardMutations} = useStore(DashboardStore);
  const userInfo = useMemo(() => getAuthenticadUserInfo(), []);

  const [isClicked, setClickState] = useState(false);
  const handleClick = async value => {
    const result = await ArticleEffects.getArticleDetails(value._id);
    if (result) {
      ArticlesMutations.setArticleDialogValue({
        isOpen: true,
        article: result,
        isLoadMore: false,
        backButton: false,
        fromPinTo: true,
      });
    }

    setClickState(true);
  };
  const handleMouseEnter = event => {
    if (window.location.pathname !== Routes.LOGIN && userInfo) {
      DashboardMutations.setPopoverIndex('');
      DashboardMutations.setPopoverNotificationIndex('');
      DashboardMutations.setReportTooltip('');
      DashboardMutations.setPintoOpenStatus({
        pageHeader: false,
        pageFooter: false,
      });
    }
  };

  const handleTermsClick = () => {
    history.push(Routes.TERMS);
  };

  // Render
  return (
    <>
      {!landingPages && (
        <footer className={classes.footer}>
          <p className={classes.items} onMouseEnter={handleMouseEnter}>
            <span className={classes.item} onClick={() => setDialog('SourceAttribution')}>
              Accenture {new Date().getFullYear()}
            </span>
            <FooterDialog
              open={dialog === 'SourceAttribution'}
              onClose={() => setDialog(null)}
              title={'Source attributions for Accenture Cargo trade products'}
              text={SourceAttribution}
            />

            <span className={classes.item} onClick={() => setDialog('TC')}>
              Terms & conditions
            </span>

            <FooterDialog
              open={dialog === 'TC'}
              onClose={() => setDialog(null)}
              title={'Terms and conditions'}
              text={TC}
              maxWidth={'sm'}
            />
            {userInfo && AdminState && AdminState?.siteFooterArray?.length
              ? AdminState &&
                AdminState?.siteFooterArray?.length >= 1 &&
                AdminState?.siteFooterArray.map((val, index) => (
                  <span
                    key={index}
                    className={classes.item}
                    onClick={() => {
                      handleClick(val.details, false);
                    }}
                    title={val.details.hoverTitle}
                  >
                    {val.details.shortTitle}
                  </span>
                ))
              : userInfo &&
                PinArticlaState &&
                PinArticlaState?.siteFooterArray?.length >= 1 &&
                PinArticlaState?.siteFooterArray.map((val, index) => (
                  <span
                    key={index}
                    className={classes.item}
                    onClick={() => {
                      handleClick(val.details, false);
                    }}
                    title={val.details.hoverTitle}
                  >
                    {val.details.shortTitle}
                  </span>
                ))}
          </p>
          {isClicked && (
            <ArticleDetailsDialog
              open={isOpen}
              isOpen={isOpen}
              article={article}
              isLoadMore={isLoadMore}
              backButton={backButton}
              articleList={articleList}
              fromPinTo={fromPinTo}
            />
          )}
        </footer>
      )}

      {landingPages && (
        <footer className={classes.landingFooter}>
          <p className={classes.landingitems}>
            <span className={classes.landingitem}>Accenture {new Date().getFullYear()}</span>
            <span className={classes.landingitem} onClick={handleTermsClick}>
              Terms & conditions
            </span>
          </p>
        </footer>
      )}
    </>
  );
};

export default withStyles(styles)(Footer);

var TC = (
  <>
    <p>
      Access to and use of this Database is provided solely under the Terms and Conditions as agreed between Accenture
      and Licensee. Accenture reserves the right to pursue legal recourse for any unauthorised access and use.
    </p>
    <p>
      Accenture will perform patch management for software used in delivery of the Databases, evaluating and deploying
      patches released by vendors on a monthly basis according to a planned patch schedule (updates, hotfixes and
      security) during defined scheduled maintenance windows, details of which are available on request.
    </p>
    <p>Usage of this website is logged for analytics purposes.</p>
  </>
);

const SourceAttribution = (
  <table>
    <tr>
      <th>Source data (reporting country)</th>
      <th>Notes</th>
    </tr>
    <tr>
      <td>Australia</td>
      <td>
        <p>
          Source: Australian Bureau of Statistics, licensed under the Creative Commons Attribution 4.0 International
          license.
        </p>
        <p>Note: the content of the source data has been edited, to reflect Accenture Cargo’s opinion on Australian trade</p>
      </td>
    </tr>
    <tr>
      <td>Brazil</td>
      <td>
        <p>Source: Brasil Ministry of Industry, Foreign Trade and Services</p>
      </td>
    </tr>
    <tr>
      <td>Canada</td>
      <td>
        <p>
          Adapted from: Statistics Canada Disclaimer: does not constitute an endorsement by Statistics Canada of this
          product.
        </p>
      </td>
    </tr>
    <tr>
      <td>Chile</td>
      <td>
        <p>
          Contains information from the{' '}
          <a href='http://datos.gob.cl/dataset/registros-de-exportacion-2017' target='_blank' rel='noopener noreferrer'>
            Export
          </a>{' '}
          and{' '}
          <a href='http://datos.gob.cl/dataset/registros-de-importacion-2017' target='_blank' rel='noopener noreferrer'>
            Import
          </a>{' '}
          databases, which is made available under the Open Database License (
          <a href='http://opendefinition.org/licenses/odc-by' target='_blank' rel='noopener noreferrer'>
            ODbL
          </a>
          )
        </p>
      </td>
    </tr>
    <tr>
      <td>China</td>
      <td>
        <p>Source: General Administration of China Customs</p>
      </td>
    </tr>
    <tr>
      <td>EU countries</td>
      <td>
        <p>
          Source: Eurostat Disclaimer: the content of the source data has been edited, to reflect Accenture Cargo’s opinion on
          EU trade, for which Eurostat cannot be held responsible
        </p>
      </td>
    </tr>
    <tr>
      <td>Hong Kong</td>
      <td>
        <p>
          Source: Hong Kong Census and Statistics Department Note: IP rights source data owned by the Hong Kong Special
          Administrative Region
        </p>
        <p>
          Disclaimer: This website / product / service contains information that is copied or extracted from data made
          available by the Government of Hong Kong Special Administrative Region (the ""Government"") at{' '}
          <a href='https://DATA.GOV.HK' target='_blank' rel='noopener noreferrer'>
            https://DATA.GOV.HK
          </a>{' '}
          (""DATA.GOV.HK""). The provision of information copied or extracted from or a link to DATA.GOV.HK at this
          website or in relation to the product or service shall not constitute any form of co-operation or affiliation
          by the Government with any person in relation to this website, the product or the service or any contents
          herein. Nothing in this website, the product or the service shall give rise to any representation, warranty or
          implication that the Government agrees with, approves of, recommends or endorses any contents of this website
          or the product or the service. The Government does not have any liability, obligation or responsibility
          whatsoever for any loss, destruction or damage (including without limitation consequential loss, destruction
          or damage) howsoever arising from or in respect of your use or misuse of or reliance on or inability to use
          any contents herein.
        </p>
      </td>
    </tr>
    <tr>
      <td>India</td>
      <td>
        <p>Source: Director General of Commercial Intelligence and Statistics, India</p>
      </td>
    </tr>
    <tr>
      <td>Indonesia</td>
      <td>
        <p>Source: Statistics Indonesia</p>
      </td>
    </tr>
    <tr>
      <td>Japan</td>
      <td>
        <p>
          Source: Japan Ministry of Finance Note: the content of the source data has been edited, to reflect Accenture Cargo’s
          opinion on Japanese trade{' '}
          <a href='http://www.mof.go.jp/english/' target='_blank' rel='noopener noreferrer'>
            http://www.mof.go.jp/english/
          </a>
        </p>
      </td>
    </tr>
    <tr>
      <td>Korea</td>
      <td>
        <p>Source: Korean Statistical Information Service</p>
      </td>
    </tr>
    <tr>
      <td>Malaysia</td>
      <td>
        <p>Source: Department of Statistics, Malaysia</p>
      </td>
    </tr>
    <tr>
      <td>New Zealand</td>
      <td>
        <p>
          Note: this work is based on/includes Statistics New Zealand's data which are licensed by Statistics New
          Zealand for re-use under the Creative Commons Attribution 4.0 International license.
        </p>
      </td>
    </tr>
    <tr>
      <td>Norway</td>
      <td>
        <p>
          Source: Statistics Norway Contains data under the Norwegian license for Open Government data (NLOD),
          distributed by Statistics Norway.{' '}
          <a href='http://data.norge.no/nlod/en/1.0' target='_blank' rel='noopener noreferrer'>
            http://data.norge.no/nlod/en/1.0
          </a>
          ,{' '}
          <a href='http://www.ssb.no' target='_blank' rel='noopener noreferrer'>
            http://www.ssb.no
          </a>{' '}
        </p>
      </td>
    </tr>
    <tr>
      <td>Philippines</td>
      <td>
        <p>Source: Philippine Statistics Authority</p>
      </td>
    </tr>
    <tr>
      <td>Singapore</td>
      <td>
        <p>Source: IE Singapore</p>
      </td>
    </tr>
    <tr>
      <td>Switzerland</td>
      <td>
        <p>
          Source: Swiss Federal Customs Administration Disclaimer: the content of the source data has been edited, to
          reflect Accenture Cargo’s opinion on Swiss trade
        </p>
      </td>
    </tr>
    <tr>
      <td>Taiwan</td>
      <td>
        <p>Source: Customs Administrations, Ministry of Finance, Taiwan</p>
      </td>
    </tr>
    <tr>
      <td>United Kingdom</td>
      <td>
        <p>
          Source: HM Revenue and Customs department{' '}
          <a
            href='http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/'
            target='_blank'
            rel='noopener noreferrer'
          >
            http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/
          </a>
        </p>
      </td>
    </tr>
    <tr>
      <td>United States</td>
      <td>
        <p>Source: U.S. Census Bureau</p>
      </td>
    </tr>
    <tr>
      <td>All other countries</td>
      <td>
        <p>Source: UN Comtrade</p>
      </td>
    </tr>
  </table>
);
