import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import styles from './loading-spinner-styles';

const LoadingSpinner = ({classes, overlay}) => {
  return (
    <>
      {overlay && <div className={classes.overlay} />}
      <div className={classNames(classes.spinner, {[classes.spinnerOverlay]: overlay})}>
        <CircularProgress color='primary' />
      </div>
    </>
  );
};

export default withStyles(styles)(LoadingSpinner);
