import theme from '../../../assets/theme';

export default {
  wrapper: {
    '-ms-grid-row': 1,
    '-ms-grid-column': 3,
    gridArea: 'extra',
  },
  container: {
    top: '0',
    right: '0',
    zIndex: '9999',
    display: 'flex',
    padding: [['6px', theme.spacing(10), '6px', '0']],
  },
  iconContainer: {
    borderRadius: '100px',
    boxSizing: 'content-box',
    height: '24px',
    width: '24px',
    margin: '0 6px',
    padding: '6px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
  icons: {
    '& img': {
      height: '24px',
      width: '24px',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    margin: theme.spacing(1) / 2,
    fontWeight: 'bold',
    display: 'flex',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
};
