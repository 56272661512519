import React, {useState, useCallback, useEffect} from 'react';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {useHistory} from 'react-router-dom';
import {FormDialog} from '../../../components/dialog';
import Button from '../../../components/button';
import {
  HelpFullTitleFormField,
  HelpShortTitleFormField,
  HelpHoverFormField,
  HelpSectionSelectFormField,
  SortSelectFormField
} from '../../../components/form/form-fields';
import {HelpAdminStore} from '../../../state/help/store';
import {Routes} from '../../../Routes';
import {getAuthenticadUserInfo} from '../../../utils/access-token';
import {HELP_ARTICLE_PINTO} from '../../../assets/constants';

const HelpCreate = ({initialValues}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const {state: ArticlesState, mutations: ArticlesMutations, effects: HelpEffects} = useStore(HelpAdminStore);
  const {totalCount} = ArticlesState;

  useEffect(() => {
    HelpEffects.getSection();
  }, [open]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async values => {
      const userInfo = getAuthenticadUserInfo();
      values.fullTitle = values.fullTitle.trimEnd();
      values.shortTitle = values.shortTitle.trimEnd();
      values.hoverTitle = values.hoverTitle.trimEnd();
      ArticlesMutations.setCurrentArticle({
        ...values,
        author: {id: userInfo.id, email: userInfo.userPrincipalName, name: userInfo.displayName},
      });
      ArticlesMutations.setArticlePinTo({
        pintTo: HELP_ARTICLE_PINTO,
      });
      history.push(Routes.ADMIN.HELP_NEW);
    },
    [ArticlesMutations, history]
  );

  return (
    <>
      <Button variant='outlined' icon={'add'} onClick={() => setOpen(true)}>
        {formatMessage('New article')}
      </Button>
      <FormDialog
        initialValues={initialValues}
        open={open}
        title={formatMessage('New help article')}
        onClose={handleOnClose}
        button={formatMessage('Create')}
        onSubmit={handleSubmit}
        computeDisabled={({...formData}) => !formData.valid || formData.pristine}
      >
        <HelpFullTitleFormField />
        <HelpShortTitleFormField />
        <HelpHoverFormField />
        <HelpSectionSelectFormField />
        <SortSelectFormField totalCount={totalCount}/>
      </FormDialog>
    </>
  );
};

export default HelpCreate;
