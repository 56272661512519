import theme from '../../assets/theme';

export default {
  popper: {
    border: `1px solid ${theme.palette.grey.light}`,
    boxShadow: `0 3px 12px ${theme.palette.grey.light}`,
    borderRadius: 4,
    width: 350,
    zIndex: 1,
    backgroundColor: theme.palette.grey.veryLight,
  },
  inputBase: {
    padding: theme.spacing(1),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey.light}`,
      fontSize: 14,
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey.light}`,
    color: theme.palette.grey.mid,
    margin: 0,
    fontSize: 14,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: [[theme.spacing(1), theme.spacing(1)]],
    paddingLeft: `${theme.spacing(1)}px !important`,
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  groupLabel: {
    color: theme.palette.grey.dark,
    borderBottom: `1px solid ${theme.palette.grey.mid}`,
  },
};
