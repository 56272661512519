import React from 'react';
import ProptTypes from 'prop-types';
import {useStore} from 'outstated';
import {NotificationsAdminStore} from '../../../state/notifications/store';
import {Redirect, Route} from 'react-router-dom';
import {Routes} from '../../../Routes';

const NotificationRoute = ({exact, path, Component}) => {
  const {state: NotificationsState} = useStore(NotificationsAdminStore);

  const {current: notification} = NotificationsState;

  //  Redirect to notifications list if user doesnt follow the modal flow to create a new notification
  if (path === Routes.ADMIN.NOTIFICATIONS_NEW && !notification.type) {
    return <Redirect to={Routes.ADMIN.NOTIFICATIONS} />;
  }

  return <Route exact={exact} path={path} render={props => <Component {...props} />} />;
};

NotificationRoute.proptTypes = {
  excat: ProptTypes.bool,
  path: ProptTypes.string.isRequired,
  Component: ProptTypes.node.isRequired,
};

NotificationRoute.defaultProps = {
  exact: false,
};

export default NotificationRoute;
