import theme from '../../assets/theme';

export default {
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  container: {
    textAlign: 'center',
    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  button: {
    height: theme.spacing(7),
    textTransform: 'none',
    color: 'white',
    fontSize: 16,
  },
  icon: {
    width: '80px',
    height: '80px',
  },
  description: {
    margin: [[theme.spacing(2), 0]],
  },
};
