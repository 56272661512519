import theme from '../../../assets/theme';

export default {
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  container: {
    maxWidth: '100%',
    margin: 0,
    padding: [[0, theme.spacing(9), theme.spacing(10)]],
    flex: '1 1 auto',
  },
  messageWrapper: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
  },
  message: {
    display: 'flex',
    padding: '5px',
    margin: '10px',
    textAlign: 'center',
  },
  card: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(9),
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
    '&:hover': {
      '& $menu': {
        visibility: 'visible',
      },
    },
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(2),
    },
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
  },
  cardLink: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: 0,
  },
};
