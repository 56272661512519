import {Quill} from 'react-quill';
const BlockEmbed = Quill.import('blots/block/embed');

const ATTRIBUTES = ['alt', 'height', 'width', 'style'];

class CustomImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.className = 'ql-custom-image';
    node.setAttribute('src', value);
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

CustomImageBlot.blotName = 'customImage';
CustomImageBlot.tagName = 'img';

export default CustomImageBlot;
