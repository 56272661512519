import React from 'react';
import {AppBar, withStyles} from '@material-ui/core';
import styles from './appbar-styles';
import logo from '../../../assets/images/seabury-logo.png';

const AppbarView = ({children, classes}) => {
  return (
    <AppBar position='sticky' color='default' className={classes.appBar}>
      <img src={logo} alt={'Seabury'} className={classes.relative} />
      {children}
    </AppBar>
  );
};

export default withStyles(styles)(AppbarView);
