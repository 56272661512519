import React from 'react';
import {ClickAwayListener, Grow, Paper, Popper, withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import styles from './menu-styles';
const Menu = ({classes, open, anchorEl,type, onClickAway = null, children, style = null, placement, ...props}) => {  
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      style={{...style, zIndex: '9999'}}
      placement={placement ? placement : 'bottom-start'}
      {...props}
    >
      {({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'center left'}}>
          {(onClickAway && (
            <ClickAwayListener onClickAway={onClickAway}>
              <Paper className={type==='filter' ? classes.filterMenu : classes.menu}>{children}</Paper>
            </ClickAwayListener>
          )) || (
            <Paper elevation={2} className={type==='filter' ? classes.filterMenu : classes.menu}>
              {children}
            </Paper>
          )}
        </Grow>
      )}
    </Popper>
  );
};

Menu.propTypes = {
  classes: propTypes.object,
  open: propTypes.bool.isRequired,
  anchorEl: propTypes.instanceOf(Element),
  onClickAway: propTypes.func,
  children: propTypes.element.isRequired,
  style: propTypes.object,
};

export default withStyles(styles)(Menu);
