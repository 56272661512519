import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  authenticate: async token => {
    try {
      const {data} = await SeaburyAxios.post('/api/auth', null, {
        headers: {
          Authorization: token,
        },
        timeout: 10000,
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  getConfiguration: async () => {
    try {
      const {data} = await SeaburyAxios.get(`/api/configuration`, {
      });
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
