import {useState, useMemo} from 'react';
import formatMessage from 'format-message';
import produce from 'immer';
import {useStore} from 'outstated';

import AdminDashboardsService from '../services/admin-dashboards-service';
import AdminNotificationsService from '../services/admin-notifications-service';
import HelpArticleService from '../services/help-service';
import DialogStore from './dialog';
import {ROW_LEVEL_SECURITY_LIST, TARGET_WINDOW_LIST,PINTO_TYPES,OLD_NOTIFICATION_AGE,VALID_STATUSES} from '../assets/constants';

export const ADMIN_ACTIONS = {
  ADD_DASHBOARD: 'ADD_DASHBOARD',
  EDIT_DASHBOARD: 'EDIT_DASHBOARD',
  DELETE_DASHBOARD: 'DELETE_DASHBOARD',
  ADD_PAGE: 'ADD_PAGE',
  EDIT_PAGE: 'EDIT_PAGE',
  DELETE_PAGE: 'DELETE_PAGE',
};

const getPublishedFromString = () => {
  const now = new Date();
  const publishedFromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - OLD_NOTIFICATION_AGE, 0, 0, 0);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
  const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(publishedFromDate);
  return `${year}-${month}-${day}`;
};

const initialAdminState = {
  activeIndex: 0,
  dashboards: [],
  tabTitle: 'Dashboards',
  activeAction: false,
  editPageId: null,
  currentDashboard: {
    name: '',
    url: '',
    weightage: null,
    error: false,
    filled: false,
  },
  audiences: [],
  isLoadingAudiences: false,
};

const AdminState = adminState => {
  const [state, setState] = useState(adminState || initialAdminState);

  const {mutations: DialogMutations} = useStore(DialogStore);

  const mutations = useMemo(
    () => ({
      setActiveIndex: index =>
        setState(
          produce(draft => {
            draft.activeIndex = index;
          })
        ),
      storeDashboards: dashboards =>
        setState(
          produce(draft => {
            draft.dashboards = dashboards;
          })
        ),
      setActiveAction: action =>
        setState(
          produce(draft => {
            draft.activeAction = action;
          })
        ),
      setTabTitle: tabTitle =>
        setState(
          produce(draft => {
            draft.tabTitle = tabTitle;
          })
        ),
      setEditPage: pageId =>
        setState(
          produce(draft => {
            draft.editPageId = pageId;
          })
        ),
      setCurrentDashboard: ({...values}) =>
        setState(
          produce(draft => {
            Object.keys(values).forEach(key => {
              draft.currentDashboard[key] = values[key];
            });
          })
        ),
      setAudiences: audiences =>
        setState(
          produce(draft => {
            draft.audiences = audiences;
          })
        ),
      setLoadingAudiences: value =>
        setState(
          produce(draft => {
            draft.isLoadingAudiences = value;
          })
        ),
      setSiteFooter: values =>
        setState(
          produce(draft => {
            draft.siteFooterArray = values.siteFooterArray;
          })
        ),
    }),
    []
  );

  const effects = useMemo(
    () => ({
      getDashboards: async () => {
        const dashboards = await AdminDashboardsService.getDashboards();
        if (dashboards && dashboards.length) {
          mutations.storeDashboards(dashboards);
        }
      },
      createDashboard: async ({name, url, weightage, children, selfChild, dropDownName, resourceType}) => {
        await AdminDashboardsService.createDashboard({
          name: name,
          url: url,
          weightage: weightage,
          published: true,
          rowLevelSecurity: ROW_LEVEL_SECURITY_LIST[0].value,
          targetWindow: TARGET_WINDOW_LIST[0].value,
          children,
          selfChild,
          dropDownName,
          resourceType: resourceType
        });
      },
      editDashboard: async ({activeDashboard, name, url, weightage, children, selfChild, dropDownName}) => {
        await AdminDashboardsService.updateDashboard(
          {
            name: name,
            url: url,
            weightage: weightage,
            published: true,
            security_groups: activeDashboard.groups,
            pages: activeDashboard.pages.map(page => page.id),
            rowLevelSecurity: activeDashboard.rowLevelSecurity,
            targetWindow: activeDashboard.targetWindow,
            children,
            selfChild,
            dropDownName,
          },
          activeDashboard.id
        );
      },
      getDashboardsTilesInformation: async () => {
        try {
          await effects.getDashboards();
        } catch (error) {
          DialogMutations.showError({
            title: formatMessage('Data can not be retrieved'),
            message: formatMessage(
              'The Dashboard/tile name lists cannot be retrieved from the Seabury backend. Please try again or try again later.'
            ),
          });
        }
      },
      getAudiences: async () => {
        mutations.setLoadingAudiences(true);
        try {
          const audiences = await AdminNotificationsService.getAudiences();
          mutations.setAudiences(audiences);
        } catch (error) {
          DialogMutations.showError({
            title: formatMessage('Data can not be retrieved'),
            message: formatMessage(
              'The Audience data cannot be retrieved from the Seabury backend. Please try again or try again later.'
            ),
          });
        } finally {
          mutations.setLoadingAudiences(false);
        }
      },
      updateRowLevelSecurity: async (dashboardId, body) => {
        return await AdminDashboardsService.updateRowLevelSecurity(dashboardId, body);
      },
      updateTargetWindow: async (dashboardId, body) => {
        return await AdminDashboardsService.updateTargetWindow(dashboardId, body);
      },
      updateLayoutOption: async (pageId, body) => {
        return await AdminDashboardsService.updateLayoutOption(pageId, body);
      },

      getSiteFooterArticles: async () => {
        const params = {
          status: VALID_STATUSES[1], // PUBLISHED
          pintTo: PINTO_TYPES[3],
          publishedFrom: getPublishedFromString(),
          sortBy: 'publishedAt',
          withMessage: true,
          limit: 500, // FIXME: find a better way to handle pagination
        };

        const data = await HelpArticleService.getArticles(params);
        let siteFooterArray = [];
        if (data && data.docs && data.docs.length) {
          data.docs.map(article => {
            siteFooterArray.push({
              details: article,
            });
          });
        }
        mutations.setSiteFooter({siteFooterArray: siteFooterArray});
      },
    }),
    [mutations, DialogMutations]
  );

  return {state, mutations, effects};
};

export default AdminState;
