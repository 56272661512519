import theme from '../../../assets/theme';

export default {
  accountMenuItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  icon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  accountTitle: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
    width: theme.spacing(15),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accountSubtitle: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
    width: theme.spacing(15),
  },
  accountUser: {
    display: 'flex',
    alignItems: 'center',
  },
  accountMenuItemContainer: {
    padding: [[theme.spacing(1), theme.spacing(1.5)]],
    opacity: '1 !important',
  },
};
