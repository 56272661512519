import React, {useEffect, useState} from 'react';
import {withStyles, Card} from '@material-ui/core';
import styles from './login-pin-notification-styles';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {NotificationsMailboxStore} from '../../../state/notifications/store';
import RichTextEditorViewer from '../../../components/rich-text-editor/viewer';
import Dialog from '../../../components/dialog';
import forwardArrow from '../../../assets/icons/ic-arrow-forward.svg';
import {Routes} from '../../../Routes';
import history from '../../../utils/history';

const LoginPinNotification = ({classes}) => {
  const [showFull, setShowFull] = useState(false);

  const {state: MailboxState, effects: MailboxEffects} = useStore(NotificationsMailboxStore);

  const {announcement} = MailboxState;

  useEffect(() => {
    MailboxEffects.getAnnouncement();
  }, []);

  const handleReadMore = () => {
    history.push(Routes.ANNOUNCEMENTS);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth', duration: 1000});
  }
  if (!announcement) return null;

  return (
    <>
       <div className={classes.content}>
          <div className={classes.title}>{announcement.title}</div>
          <div className={classes.subTitle}>{announcement.datePublished}</div>
        </div>
        <div className={classes.bottomAction} onClick={handleReadMore}>
          <div className={classes.readMore}>{formatMessage('READ MORE')}</div>
          <img src={forwardArrow} alt={'Read more'} className={classes.forwardArrow} />
        </div>
      <Dialog
        className={classes.dialogContainer}
        open={showFull}
        title={
          <p title={announcement.title} className={classes.dialogTitle}>
            {announcement.title}
          </p>
        }
        onClose={() => setShowFull(false)}
        hideActions
      >
        <div className={classes.dialogSubtitle}>{announcement.datePublished}</div>
        <div className={classes.cancelEditModalContent}>
          <RichTextEditorViewer value={announcement.message} />
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(LoginPinNotification);
