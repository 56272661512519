
const allowedPowerBiSchemas = [
  'http://powerbi.com/product/schema#basic',
  'http://powerbi.com/product/schema#advanced',
  'http://powerbi.com/product/schema#relativeDate',
  'http://powerbi.com/product/schema#topN',
];

// TODO: Remove isFilterV2
export const prepareFilters = (filters, defaultFilters, isFilterV2,filterLevels) => {

  if (filters && filters.length > 0) {
    const filtersPrep = filters
      .filter(f => {
        if (isFilterV2) {
          if (f.pbi.active === '') return null;
          return f.pbi.active || f.defaultValues.length;
        }
        return f.active !== null;
      })
      .map(f => {

        let filter = isFilterV2 ? {...f.pbi, active: f.pbi.active || (f.defaultFilterValues?.length ?  f.defaultFilterValues[0] : f.defaultValues[0])} : f;

        let defaultValueData = {};

        filter.filterValues && filter.filterValues.map((filterValues) => {
          let endValue2 = {};
          let endValue3 = {};
    
          filterValues.value2.length && filterValues.value2.map((v2) => {
            if (v2.default) {
              endValue2 = {
                value2 : v2,
                value1 : filterValues
              };
            }
          });
          filterValues.value2.length &&
          filterValues.value2.forEach((v2) => {
            v2.value3 &&
              v2.value3.forEach((v3) => {
                if (v3.default) {
                  endValue3 = {
                    value3 : v3,
                    value2 : filterValues.value2
                  };
                }
              });
          });

          if (filterValues.default || f.defaultValues) {
            defaultValueData = filterValues;

          } else if (endValue2?.value2?.default) {
            defaultValueData = endValue2?.value2;

          } else if (endValue3?.value3?.default) {
            defaultValueData = endValue3?.value3;
          }
        });

        if (!filterLevels && filter.filterValues) {
          filter.target = {
            ...f.pbi.target,
            column: defaultValueData.type
          }
        }
        
        if (defaultFilters) {
          const index = defaultFilters.findIndex(
            e =>
              e.target.table === filter.target.table &&
              e.target.column === filter.target.column &&
              e.target.measure === filter.target.measure
          );

          if (index !== -1) {
            defaultFilters.splice(index, 1);
          }
        }

        return {...filter, values: [filter.active]};
      });

    return [filtersPrep, defaultFilters];
  }

  return [filters, defaultFilters];
};

export const enableFilter = (filters, filterID, valueID, isFilterV2,type, value,label,selection) =>

  filters.map((filter, id) => {
    if (id === filterID) {

      if (isFilterV2) {
        const active = valueID !== null ? filter.values[valueID] : {value: valueID};
        // valueID is equal to '' when using ResetFilter option

        if (type) {
          return {...filter, pbi: {...filter.pbi, target: {...filter.pbi.target, column: type}, active: valueID === '' ? '' : value.value || null,
          filters: filter.label === label && Object.keys(selection).length ? selection : {}}};
        } else {
          return {...filter, pbi: {...filter.pbi, active: valueID === '' ? '' : active.value || null,
          filters: filter.label === label && Object.keys(selection).length ? selection : {}},};
        }

      }
      const active = valueID !== null ? filter.values[valueID] : valueID;
      return {...filter, active: active ? active : null};
    }
    return {...filter};

  });

export const mergeFilters = (defaultFilters, newFilters, isFilterV2,filterLevels) => {
  let defaultFiltersFiltered = defaultFilters.filter(f => allowedPowerBiSchemas.indexOf(f['$schema']) !== -1);

  const [filtersPrepared, defaultFiltersPrepared] = prepareFilters(newFilters, defaultFiltersFiltered, isFilterV2,filterLevels);

  return defaultFiltersPrepared.concat(filtersPrepared);
};
