import React from 'react';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import styles from './clients-styles';
import shippingLines from '../../../assets/icons/clients/shipping-lines.svg';
import airLines from '../../../assets/icons/clients/airlines.svg';
import airPort from '../../../assets/icons/clients/airport.svg';
import forwarders from '../../../assets/icons/clients/forwarders.svg';
import governments from '../../../assets/icons/clients/governments.svg';
import integrators from '../../../assets/icons/clients/integrators.svg';
import seaports from '../../../assets/icons/clients/seaports.svg';
import shippers from '../../../assets/icons/clients/shippers.svg';

const Clients = props => {
  const {classes} = props;

  const clients = [
    {
      label: 'Airlines',
      link: airLines,
    },
    {
      label: 'Airports',
      link: airPort,
    },
    {
      label: 'Integrators',
      link: integrators,
    },
    {
      label: 'Governments',
      link: governments,
    },
    {
      label: 'Forwarders',
      link: forwarders,
    },
    {
      label: 'Shippers',
      link: shippers,
    },
    {
      label: 'Shipping lines',
      link: shippingLines,
    },
    {
      label: 'Seaports',
      link: seaports,
    },
  ];

  return (
    <section className={classes.wrapper} id='clients'>
      <Container maxWidth={'xl'}>
        <Grid container direction='row' spacing={0}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h1 className={classes.title}>Our clients</h1>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.textContent}>
            <Box display='flex' className={classes.firstColumn}>
              <Box>
                <h3 className={classes.subTitle}>— Trusted source by the industry</h3>
                <p className={classes.description}>
                  Serving leading companies throughout the supply chain.
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Container maxWidth={'xl'}>
          <Grid container direction='row' spacing={0} className={classes.iconSection}>
            {clients.map(({label, link}) => {
              return (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.iconItem}>
                  <img src={link} alt={'Icon'} />
                  <p className={classes.iconName}>{label}</p>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Container>
    </section>
  );
};

export default withStyles(styles)(Clients);
