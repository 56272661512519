import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {HelpRoute} from './navigation';
import HelpEdit from './edit';
import HelpList from './list';
import {Routes} from '../../Routes';
import {withStyles} from '@material-ui/core';
import propTypes from 'prop-types';
import styles from './help-styles';
import Footer from '../../components/footer/footer-view';

const HelpContainer = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Switch>
        <HelpRoute exact path={Routes.ADMIN.HELP} Component={HelpList} />
        <HelpRoute exact path={Routes.ADMIN.HELP_NEW} Component={HelpEdit} />
        <HelpRoute exact path={Routes.ADMIN.HELP_EDIT} Component={HelpEdit} />
        <Redirect to={Routes.ADMIN.HELP} />
      </Switch>
      <Footer />
    </div>
  );
};

HelpContainer.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HelpContainer);
