import theme from '../../../assets/theme';

export default {
  aboutWrapper: {
    paddingTop: '9.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4.5rem',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '6rem',
    },
  },
  aboutSection: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  firstColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '34%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '34%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  thirdColumn: {
    [theme.breakpoints.up('lg')]: {
      width: '32%',
      height: '30%',
      position: 'relative'
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      height: '30%',
      position: 'relative'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mobileDescriptionView: {
    display : 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
  },
  
  menuTitle: {
    color: theme.palette.black,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  menuDetails: {
    width: 220,
    color: theme.palette.grey.midLight,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '28px'
  },
  contentTitle: {
    color: theme.palette.black,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '36px',
    marginTop: 0,
    [theme.breakpoints.up('lg')]: {
      width: 370,
    },
    [theme.breakpoints.down('md')]: {
      width: 456,
    },
    [theme.breakpoints.down('sm')]: {
      width: 343,
    },
  },
  contentDetails: {
    color: theme.palette.black,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '32px',
    display : 'block',
    [theme.breakpoints.up('lg')]: {
      width: 370,
    },
    [theme.breakpoints.down('md')]: {
      width: 456,
    },
    [theme.breakpoints.down('sm')]: {
      width: 343,
      display: 'none'
    },
  },
  mobContentDetails: {
    color: theme.palette.black,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '32px',
    width: '100%'
  },
  airCargoImg: {
    width: '100%'
  },
  aboutImage: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 0
    },
    [theme.breakpoints.only('md')]: {
      position: 'absolute',
      right: 0
    },
  }
};
