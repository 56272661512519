import theme from '../../assets/theme';

export default {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    opacity: 0.4,
    zIndex: 100,
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  spinnerOverlay: {
    position: 'fixed',
    zIndex: 101,
  },
};
