  import React, {useEffect, useMemo} from 'react';
import {useHistory, Redirect} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useStore} from 'outstated';

import LoadingSpinner from '../../components/loading-spinner';
import ForbiddenError from '../../components/403';
import Report from './report';
import Filters from './filters';
import Footer from '../../components/footer';
import DashboardStore from '../../state/dashboard';
import styles from './dashboard-styles';
import AppStore from '../../state/app';
import NavigationDashboard from './navigation';
import {Routes} from '../../Routes';
import classNames from 'classnames';
import {LAYOUT_OPTIONS} from '../../assets/constants';
import {getDashboardDetail} from '../Helper';
import PintoArticleBox from '../../components/pinto-article-box';
import {PinArticleStore} from '../../state/help/store';

let previousPage = null;
let numVisualsLoaded = 0;
let loadedVisualsExternal = 0;

const Dashboard = ({classes}) => {

  const history = useHistory();

  const {state: DashboardState, mutations: DashboardMutations, effects: DashboardEffects} = useStore(DashboardStore);
  const {state: AppState} = useStore(AppStore);
  const {state: PinArticlaState} = useStore(PinArticleStore);

  const handleClose = () => {
    DashboardMutations.setPopoverIndex('');
    DashboardMutations.setPopoverNotificationIndex('');
    DashboardMutations.setReportTooltip('');
    DashboardMutations.setPintoOpenStatus({
      pageHeader: false,
      pageFooter: false,
    });
  }
  const handleIncreaseLoadingVisuals = () => {
    DashboardMutations.increaseLoadingVisuals();
  };

  const handleDecreaseLoadingVisuals = () => {
    loadedVisualsExternal++;
    numVisualsLoaded++;
    DashboardMutations.decreaseLoadingVisuals();
  };

  useEffect(() => {
    previousPage = DashboardState.current.activePage;
  }, [DashboardState, DashboardState.current.activePage]);

  useMemo(() => {
    loadedVisualsExternal = 0;
    numVisualsLoaded = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DashboardState.current.activePage]);

  useEffect(() => {
    if (DashboardState.current.hasAccess === null && DashboardState.current.activeDashboard === false) {
      DashboardEffects.initDashboards(history.location);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DashboardState.current.activeDashboard, DashboardState.current.hasAccess, history.location]);

  useEffect(() => {
    DashboardEffects.checkAccessToDashboard(AppState.token);
  }, [DashboardState.dashboards, DashboardState.current.activeDashboard, AppState.token, DashboardEffects]);

  useEffect(() => {
    DashboardEffects.computePeriodLabel();
  }, [DashboardEffects]);

  const [
    visuals,
    numVisuals,
    dashboardName,
    pageName,
    isChartLayout,
    hasHeaderArticle,
    hasFooterArticle,
  ] = useMemo(() => {
    let visuals = [];
    let numVisuals = 0;
    let reportID;
    let dashboardName = '';
    let pageName = '';
    let isChartLayout = false;
    let workspaceID;
    let hasFooterArticle = [];
    let hasHeaderArticle = [];
    const dashboard = getDashboardDetail(DashboardState.dashboards, DashboardState.current.activeDashboard, 'id');

    if (dashboard) {
      dashboardName = dashboard.name;
      const page = dashboard.pages.find(p => p.id === DashboardState.current.activePage);
      isChartLayout = page?.layout === +LAYOUT_OPTIONS[0].value;

      if (page && page.reports && page.reports.length > 0) {
        pageName = page.name;
        if (isChartLayout) {
          visuals = page.reports[0].charts;
          reportID = visuals[0] ? visuals[0].reportID : '';
        } else {
          const reportPages = page.reports[0].reportPages;
          visuals = reportPages.length > 0 ? reportPages : [];
          reportID = page.reports[0].reportID;
        }
        workspaceID = page.reports[0].workspaceID;
        numVisuals = visuals.length;
      }
      hasHeaderArticle =
        PinArticlaState &&
        PinArticlaState?.articleArray.length &&
        PinArticlaState?.articleArray.filter(
          res => res.details.pintTo === 'PAGE_HEADER' && res.pinTo.location.split('/').slice(0, -1).join('/') === `${dashboardName}/${pageName}`
        );
      hasFooterArticle =
        PinArticlaState &&
        PinArticlaState?.articleArray.length &&
        PinArticlaState?.articleArray.filter(
          res => res.details.pintTo === 'PAGE_FOOTER' && res.pinTo.location.split('/').slice(0, -1).join('/') === `${dashboardName}/${pageName}`
        );
    }

    const emptyVisual = {
      reportID,
      title: '',
      subtitle: '',
      description: '',
      workspaceID,
      page: '',
      visual: 'bootstraping',
      bootstraping: true,
    };
    let finalVisuals = [];
    if (isChartLayout) {
      visuals = visuals?.slice(0, loadedVisualsExternal + 2);
    }

    const maxIframesRecycled = 10;
    if (!isChartLayout && visuals.length) {
      emptyVisual.page = visuals[0].name;
      emptyVisual.id = visuals[0]._id;
      emptyVisual['two_columns'] = true;
      finalVisuals = [emptyVisual];
    } else if (visuals.length >= maxIframesRecycled) {
      finalVisuals = visuals;
    } else {
      finalVisuals = visuals
        .concat(Array(maxIframesRecycled - visuals.length))
        .fill(emptyVisual, visuals.length, maxIframesRecycled);
    }

    return [finalVisuals, numVisuals, dashboardName, pageName, isChartLayout, hasHeaderArticle, hasFooterArticle];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DashboardState.dashboards,
    DashboardState.current.activeDashboard,
    DashboardState.current.activePage,
    loadedVisualsExternal,
  ]);

  return (
    <>
      {!DashboardState.initialized && <LoadingSpinner />}
      {DashboardState.initialized && !DashboardState.current.hasAccess && !AppState.token && (
        <Redirect to={Routes.LOGIN} />
      )}
      {DashboardState.initialized &&
        DashboardState.current.hasAccess === false &&
        DashboardState.current.activeDashboard === false && <ForbiddenError />}

      {DashboardState.initialized &&
        DashboardState.current.activeDashboard &&
        (DashboardState.current.hasAccess || DashboardState.dashboards.length === 0) && (
          <>
            <NavigationDashboard tabsEnabled={numVisualsLoaded >= Math.ceil(numVisuals / 2)} sticky />

            {!isChartLayout && !DashboardState.current.filters.values?.length && hasHeaderArticle?.length ? (
              <div className={classes.visualPinTo}  onMouseLeave={handleClose}>
                <PintoArticleBox type={'pageHeader'} />
              </div>
            ) : null}

            {DashboardState.current.filters.values?.length ? (
              <Filters filtersEnabled isFullScreenView={DashboardState.fullScreenView} isChartLayout={isChartLayout} hasHeaderArticle={hasHeaderArticle}/>
            ) : null}
            <main className={classes.main} onMouseLeave={handleClose}>
              <Grid
                onMouseLeave={handleClose}
                spacing={2}
                container
                className={classNames(classes.grid, DashboardState.fullScreenView && classes.gridSpace)}
              >
                {visuals.map((visual, index) => (
                  <Report
                  onMouseLeave={handleClose}
                    key={`report-${index}`}
                    visualData = {visual}
                    title={visual.title}
                    subtitle={visual.subtitle}
                    period={DashboardState.current.period}
                    visualID={index}
                    description={visual.description}
                    size={visual.two_columns ? 12 : 6}
                    workspaceID={visual.workspaceID}
                    reportID={visual.reportID}
                    page={visual.page}
                    visual={visual.visual}
                    visualMongoID={visual.id}
                    bootstraping={isChartLayout ? !!visual.bootstraping : false}
                    onIncreaseLoadingVisuals={handleIncreaseLoadingVisuals}
                    onDecreaseLoadingVisuals={handleDecreaseLoadingVisuals}
                    isChangingPage={previousPage !== DashboardState.current.activePage}
                    dashboardName={dashboardName}
                    pageName={pageName}
                    isChartLayout={isChartLayout}
                  />
                ))}
              </Grid>
            </main>
            {!isChartLayout && hasFooterArticle?.length ? (
              <div className={classes.visualPinTo} onMouseLeave={handleClose}>
                <PintoArticleBox type={'pageFooter'} />
              </div>
            ) : null}
            <Footer />
          </>
        )}
    </>
  );
};

export default withStyles(styles)(Dashboard);
