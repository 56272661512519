import React from 'react';
import classNames from 'classnames';
import {withStyles, Badge as MuiBadge} from '@material-ui/core';
import styles from './badge-styles';

export const BADGE_TYPES = {
  INFO: 'info',
};

const Badge = ({className, classes, value, type, children}) => {
  return (
    <MuiBadge
      classes={{badge: classNames(classes.badge, {[classes.info]: type === BADGE_TYPES.INFO}, className)}}
      badgeContent={value || undefined}
    >
      {children}
    </MuiBadge>
  );
};

export default withStyles(styles)(Badge);
