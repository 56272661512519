import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  getDashboards: async () => {
    try {
      const {data} = await SeaburyAxios.get(`/api/dashboards`);
      return data;
    } catch (err) {
      if (err === 'Error: Network Error') {
        return err;
      }
      logger.warn(err);
      return null;
    }
  },
};
