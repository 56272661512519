import React, {useRef} from 'react';

import {Dialog, DialogContent, DialogTitle, RootRef, withStyles} from '@material-ui/core';

import Close from '@material-ui/icons/Close';
import styles from './footer-dialog-styles';

const FooterDialog = props => {
  // From Styles
  const {classes} = props;

  //Fom Footer
  const {open, onClose, maxWidth = 'md', title, text} = props;

  // State
  const content = useRef();

  // Actions
  function addListener() {
    if (content && content.current) content.current.addEventListener('scroll', handleScroll);
  }

  function removeListener() {
    if (content && content.current) content.current.removeEventListener('scroll', handleScroll);
  }

  function handleScroll(e) {
    window.requestAnimationFrame(function() {
      if (e.target.scrollTop > 2) {
        e.target.classList.add('scrolled');
      } else {
        e.target.classList.remove('scrolled');
      }
    });
  }

  // Render
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onEntered={addListener}
      onExit={removeListener}
      maxWidth={maxWidth}
      classes={{
        paper: classes.dialog,
        paperWidthSm: classes.sm,
        paperWidthMd: classes.md,
      }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <h1 className={classes.h1}>{title}</h1>
        <Close className={classes.close} onClick={onClose} />
      </DialogTitle>
      <RootRef rootRef={content}>
        <DialogContent className={classes.content}>
          <div className={classes.text}>{text}</div>
        </DialogContent>
      </RootRef>
    </Dialog>
  );
};

export default withStyles(styles)(FooterDialog);
