import theme from '../../assets/theme';

export default {
  container: {
    margin: [[theme.spacing(4), 0, theme.spacing(2)]],
  },
  icon: {
    padding: 0,
    marginRight: theme.spacing(1) / 2,
  },
  iconImg: {
    display: 'block',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
};
