import theme from '../../../../assets/theme';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  item: {
    justifyContent: 'start',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  input: {
    maxHeight: theme.spacing(5),
    backgroundColor: '#ffffff',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
      borderColor: 'transparent',
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      outlineColor: theme.palette.primary.main,
      outlineWidth: '0.5px',
      outlineStyle: 'solid',
    },
    color: theme.palette.primary.main,
    borderColor: theme.palette.grey,
    borderRadius: 0,
  },
  outline: {
    outlineColor: theme.palette.grey,
    outlineWidth: '0.5px',
    outlineStyle: 'solid',
  },
};
