import React, {useCallback} from 'react';
import formatMessage from 'format-message';
import {useHistory} from 'react-router-dom';
import NotificationEditHeader from './header';
import Button from '../../../components/button';
import InfoIcon from '../../../assets/icons/icon_24_error_blue.svg';
import {withStyles} from '@material-ui/core';
import styles from './notification-edit-styles';
import {useStore} from 'outstated';
import DialogStore from '../../../state/dialog';
import {Routes} from '../../../Routes';
import {NotificationsAdminStore}  from '../../../state/notifications/store';

const NotificationEditContainer = ({classes, notification, isPublished, formState, onTogglePublish, children}) => {
  const history = useHistory();
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {state: NotificationsState} = useStore(NotificationsAdminStore);
  const {isUpdated} = NotificationsState;

  const handleCancel = useCallback(
    isPristine => {
      if (isPristine) return history.push(Routes.ADMIN.NOTIFICATIONS);
      DialogMutations.showConfirm({
        onActionConfirmed: () => {
          DialogMutations.close();
          history.push(Routes.ADMIN.NOTIFICATIONS);
        },
        icon: InfoIcon,
        title: formatMessage('Cancel editing'),
        message: formatMessage(
          'Are you sure you want to cancel editing? If you continue, your adjustments will not be saved.'
        ),
      });
    },
    [history]
  );

  return (
    <>
      <NotificationEditHeader
        notification={notification}
        isPublished={isPublished}
        onClose={() => handleCancel(formState.pristine)}
      />
      {children}
      <div className={classes.buttonContainer}>
        <Button variant='outlined' onClick={() => handleCancel(formState.pristine)}>
          {formatMessage('Cancel')}
        </Button>
        <Button
          disabled={isUpdated ? !formState.valid : formState.pristine || !formState.valid}
          variant={isPublished ? 'contained' : 'outlined'}
          type='submit'
        >
          {isPublished ? formatMessage('Update') : formatMessage('Save')}
        </Button>
        <Button
          disabled={!formState.valid}
          variant={isPublished ? 'outlined' : 'contained'}
          onClick={() => onTogglePublish(formState.form.submit)}
        >
          {isPublished ? formatMessage('Unpublish') : formatMessage('Publish')}
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(NotificationEditContainer);
