import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import {Card, CardContent, Grid, withStyles} from '@material-ui/core';
import classNames from 'classnames';
import Button from '../../../components/button';
import texts from '../../../assets/texts';
import {useStore} from 'outstated';
import AdminStore from '../../../state/admin';
import styles from './admin-dashboards-list-styles';

const List = ({classes, addDashboard, dashboards}) => {
  const {state: AdminState, mutations: AdminMutations} = useStore(AdminStore);

  const [title, setTitle] = useState('');
  const [addText, setAddText] = useState('');

useEffect(() => {
  if (AdminState.tabTitle === 'Dashboards') {
    setTitle(texts.admin.title);
    setAddText(texts.admin.addDashboard);
  } else {
    setTitle(texts.admin.datasetTitle);
    setAddText(texts.admin.addDataset);
  }
}, [AdminState.tabTitle, AdminState.dashboards])

  return (
    <>
      <Grid container alignItems='center' justify='space-between' className={classes.header}>
        <Grid item className={classes.title}>
        {title}
        </Grid>
        <Grid item>
          <Button variant='outlined' onClick={addDashboard} icon='add'>
          {addText}
          </Button>
        </Grid>
      </Grid>
      {dashboards.map((dashboard, id) => (
        <Card
          key={id}
          className={classNames(classes.card, id === AdminState.activeIndex ? classes.activeCard : null)}
          onClick={() => {
            AdminMutations.setActiveIndex(id);
            window.scrollTo(0, 0)
          }}
        >
          <CardContent className={classes.cardContent}>
            <h1 className={classes.cardTitle}>{dashboard.name}</h1>
            <p className={classes.cardLink}>{texts.admin.dashboardsURL + dashboard.url}</p>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

List.propTypes = {
  addDashboard: propTypes.func,
};

export default withStyles(styles)(List);
