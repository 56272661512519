import {NOTIFICATION_STATUS} from '../../assets/constants';
import EyeOff from '../../assets/icons/icon_24_eye_off_grey.svg';
import formatMessage from 'format-message';
import EyeOn from '../../assets/icons/icon_24_eye_on_blue.svg';
import {AdminTypeOptions} from '../../state/notifications/constants';

export const getPublishDialogContent = type => ({
  newStatus: NOTIFICATION_STATUS.PUBLISHED,
  icon: EyeOn,
  title: formatMessage('Publish notification?'),
  // Announcement and Insights
  message: [AdminTypeOptions[4].label, AdminTypeOptions[5].label].includes(type)
    ? formatMessage(
        'Do you want to publish this notification? This will replace any notification currently pinned to the same location.'
      )
    : formatMessage('Do you want to publish this notification?'),
});

export const getUnpublishDialogContent = type => ({
  newStatus: NOTIFICATION_STATUS.UNPUBLISHED,
  icon: EyeOff,
  title: formatMessage('Unpublish notification?'),
  message: formatMessage('Do you want to unpublish this notification?'),
});

export const getDeleteDialogContent = status => ({
  title: formatMessage('Delete notification?'),
  message:
    status === NOTIFICATION_STATUS.PUBLISHED
      ? formatMessage(
          'Are you sure you want to delete this notification? Any published notifications will be unpublished and deleted.'
        )
      : formatMessage('Are you sure you want to delete this notification?'),
});
