import theme from '../../assets/theme';

export default {
  footer: {
    flex: '0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'auto',
    minHeight: theme.spacing(8),
    backgroundColor: theme.palette.background.paper,
    borderTop: [[0.5, 'solid', theme.palette.grey.light]],
    zIndex: 2,
  },
  items: {
    textAlign: 'center',
    margin: 'auto 0',
    '& .MuiSnackbar-anchorOriginBottomRight': {
      right: 175,
      bottom: 5
    }
  },
  item: {
    textDecoration: 'none',
    fontSize: 14,
    color: theme.palette.grey.mid,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
      '&:before': {
        content: '"-"',
        marginRight: theme.spacing(1),
      },
    },
    '&:not($noClick):hover': {
      cursor: 'pointer',
    },
  },
  noClick: {},


  landingFooter: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingRight: 24,
    paddingBottom: 24,
  },
  landingitems: {
    color: theme.palette.grey.border,
    fontFamily: theme.landingFontFamily.lucida,
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  landingitem: {
    textDecoration: 'none',
    color: theme.palette.grey.border,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
      '&:before': {
        content: '"-"',
        marginRight: theme.spacing(1),
      },
    }
  },
};
