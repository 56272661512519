import theme from '../../../assets/theme';

export default {
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  card: {
    height: '100%',
    boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.1)',
  },
  cardContent: {
    padding: '0 !important',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  break: {
    margin: 0,
    border: 'none',
    borderTop: [[1, theme.palette.grey.light, 'solid']],
  },
  content: {
    flex: [[1, 1, 'auto']],
    padding: [[0, 0, 0]],
    height: '100%',
    maxHeight: 'calc(100vh - 241px)',
    alignItems: 'center',
    '& .report': {
      width: '100%',
      height: '100%',
      flex: '0 0 auto',
    },
  },
  placeHolder: {
    backgroundColor: theme.palette.grey.veryLight,
    paddingTop: '40%',
    flex: [[1, 1, 'auto']],
    position: 'absolute',
  },
  loadingContainer: {
    backgroundColor: theme.palette.grey.veryLight,
    position: 'absolute',
    flex: '0 0 auto',
    width: '100%',
    height: '100%',
  },
  report: {
    flex: '1 1 100%',
    height: '100%',
    '& iframe': {
      border: 'none',
    },
  },
  oneColumn: {
    height: '550px !important',
    '& iframe': {
      height: '550px !important',
    },
    '@media screen and (max-height: 768px)': {
      height: '450px !important',
      '& iframe': {
        height: '450px !important',
      },
    },
    '@media screen and (min-height: 769px)': {
      height: '500px !important',
      '& iframe': {
        height: '500px !important',
      },
    },
  },
  twoColumn: {
    height: '550px',
    '& iframe': {
      height: '550px',
    },
    '@media screen and (max-height: 768px)': {
      height: '450px !important',
      '& iframe': {
        height: '450px !important',
      },
    },
    '@media screen and (min-height: 769px)': {
      height: '550px !important',
      '& iframe': {
        height: '550px !important',
      },
    },
  },
  twoColumnFullHeight: {
    height: 'calc(100vh - 241px)',
    '& iframe': {
      height: '550px',
    },
    '@media screen and (max-height: 768px)': {
      height: 'calc(100vh - 241px) !important',
      '& iframe': {
        height: 'calc(100vh - 241px) !important',
      },
    },
    '@media screen and (min-height: 769px)': {
      height: 'calc(100vh - 241px) !important',
      '& iframe': {
        height: 'calc(100vh - 241px) !important',
      },
    },
  },
};
