import theme from '../../../assets/theme';

export default {
  container: {
    maxWidth: 360,
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    lineHeight: '20px',
    margin: theme.spacing(1),
  },
  cross: {
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: '16px',
    width: 32,
    height: 32,
    '&:hover': {
      backgroundColor: theme.palette.grey.veryLight,
      cursor: 'pointer',
    },
  },
  content: {
    margin: [[0, theme.spacing(1)]],
    textAlign: 'center',
  },
  icon: {
    display: 'block',
    margin: [[theme.spacing(4), 'auto']],
    height: theme.spacing(6),
    width: 'auto',
    color: '#F18031',
  },
  text: {
    margin: 0,
    fontSize: 16,
    lineHeight: '22px',
  },
  actions: {
    margin: [[theme.spacing(4), theme.spacing(1), theme.spacing(1)]],
  },
  button: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
    textAlign: 'center',
    padding: [[theme.spacing(1), theme.spacing(1) / 2]],
    borderRadius: theme.spacing(1),
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 16,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
};
