import theme from '../../assets/theme';

export default {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
  },
  icon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: [[0, 0, theme.spacing(2)]],
    color: theme.palette.grey.mid,
  },
  text: {
    margin: [[0, 0, theme.spacing(2)]],
    fontSize: '16px',
    lineHeight: '1.5em',
  },
  button: {
    padding: [[theme.spacing(1.25), theme.spacing(3.5)]],
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 'bold',
  },
};
