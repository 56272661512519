import React from 'react';
import formatMessage from 'format-message';

const CustomToolbar = ({disableLinks, disableVideo, disableImages}) => (
  <div id='toolbar'>
    <span className='ql-formats' title={formatMessage('Font')}>
      <select className='ql-font' defaultValue='roboto'>
        <option value='arial'>Arial</option>
        <option value='comic-sans'>Comic Sans</option>
        <option value='courier-new'>Courier New</option>
        <option value='georgia'>Georgia</option>
        <option value='helvetica'>Helvetica</option>
        <option value='lucida'>Lucida</option>
        <option value='roboto'>Roboto</option>
      </select>
      <select className='ql-size' defaultValue='normal' title={formatMessage('Font Size')}>
        <option value='extra-small'>{formatMessage('Extra small')}</option>
        <option value='small'>{formatMessage('Small')}</option>
        <option value='normal'>{formatMessage('Normal')}</option>
        <option value='large'>{formatMessage('Large')}</option>
        <option value='huge'>{formatMessage('Huge')}</option>
      </select>
    </span>

    <span className='ql-formats'>
      <select className='ql-align' title={formatMessage('Alignment')} />
      <button className='ql-indent' value='-1' title={formatMessage('Decrease Indent')} />
      <button className='ql-indent' value='+1' title={formatMessage('Increase Indent')} />
    </span>

    <span className='ql-formats'>
      <button className='ql-bold' title={formatMessage('Bold')} />
      <button className='ql-italic' title={formatMessage('Italic')} />
      <button className='ql-underline' title={formatMessage('Underline')} />
      <button className='ql-strike' title={formatMessage('Strikethrough')} />
    </span>

    <span className='ql-formats'>
      <button className='ql-list' value='ordered' title={formatMessage('Numbering')} />
      <button className='ql-list' value='bullet' title={formatMessage('Bullets')} />
    </span>

    <span className='ql-formats'>
      <select className='ql-color' title={formatMessage('Font Color')} />
      <select className='ql-background' title={formatMessage('Highlight')} />
      <button className='ql-clean' title={formatMessage('Remove Formatting')} />
    </span>

    <span className='ql-formats'>
      {!disableLinks && (
        <>
          <button className='ql-link' title={formatMessage('Add Link')} />
          {!disableImages && <button className='ql-image' title={formatMessage('Add Image')} />}
          {!disableVideo && <button className='ql-video' title={formatMessage('Add Video')} />}
        </>
      )}
    </span>
  </div>
);

export default CustomToolbar;
