import React, {useState, useCallback} from 'react';
import formatMessage from 'format-message';
import {useStore} from 'outstated';
import {useHistory} from 'react-router-dom';
import {FormDialog} from '../../../components/dialog';
import Button from '../../../components/button';
import {NotificationTitleFormField, NotificationTypeSelectFormField} from '../../../components/form/form-fields';
import {NotificationsAdminStore} from '../../../state/notifications/store';
import {Routes} from '../../../Routes';
import {getAuthenticadUserInfo} from '../../../utils/access-token';

const NotificationCreate = ({initialValues}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const {mutations: NotificationsMutations} = useStore(NotificationsAdminStore);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async values => {
      const userInfo = getAuthenticadUserInfo();
      NotificationsMutations.setCurrentNotification({
        ...values,
        author: {id: userInfo.id, email: userInfo.userPrincipalName, name: userInfo.displayName},
      });
      history.push(Routes.ADMIN.NOTIFICATIONS_NEW);
    },
    [NotificationsMutations, history]
  );

  return (
    <>
      <Button variant='outlined' icon={'add'} onClick={() => setOpen(true)}>
        {formatMessage('New notification')}
      </Button>
      <FormDialog
        initialValues={initialValues}
        open={open}
        title={formatMessage('New notification')}
        onClose={handleOnClose}
        button={formatMessage('Create')}
        onSubmit={handleSubmit}
        computeDisabled={({...formData}) => !formData.valid || formData.pristine}
      >
        <NotificationTypeSelectFormField />
        <NotificationTitleFormField />
      </FormDialog>
    </>
  );
};

export default NotificationCreate;
