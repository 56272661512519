import theme from '../../assets/theme';

export default {
  container: {
    border: [[1, 'solid', theme.palette.grey.light]],
    boxShadow: 'none',
    margin: [[theme.spacing(2), 0]],
  },
  containerHover: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey.veryLight,
    },
    '&:hover $icon': {
      visibility: 'visible',
    },
  },
  content: {
    padding: theme.spacing(2),
    '&:last-child': {
      padding: theme.spacing(2),
    },
  },
  text: {
    flex: 1,
  },
  croptext: {
    maxWidth: '95%',
    whiteSpace: 'nowrap',
    '& > h3': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: 0,
  },
  link: {
    fontSize: 14,
    color: theme.palette.grey.mid,
    lineHeight: '18px',
    margin: 0,
  },
  icon: {
    visibility: 'hidden',
    padding: [[0, theme.spacing(1)]],
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
