import theme from '../../assets/theme';
import loginBackground from '../../assets/images/login-side-background.png';

export default {
  wrapper: {
    display: 'block',
    fontFamily: theme.landingFontFamily.karla,
    backgroundColor: theme.palette.background.paper,
  },
  headerBanner: {
    backgroundImage: `url(${loginBackground})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    height: 840,
    [theme.breakpoints.down('sm')]: {
      height: 667
    },
  },
  particles: {
    height: 840,
    paddingTop: '30rem',
    [theme.breakpoints.down('sm')]: {
      height: 667
    },
  },  
  bannerSection: {
    position: 'absolute',
    top: 0,
  },
  content: {
    marginTop: theme.spacing(21),
    position: 'absolute',
  },
  title: {
    width: 652,
    color: theme.palette.primary.contrastText,
    fontSize: 84,
    fontWeight: 'bold',
    letterSpacing: -2,
    marginBottom: 0,
    lineHeight: '80px',
    [theme.breakpoints.down('sm')]: {
      width: 343,
      fontSize: 40,
      letterSpacing: -0.95,
      lineHeight: '48px'
    },
  },
  asTheGlobalCargo: {
    width: 556,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '32px',
    [theme.breakpoints.down('sm')]: {
      width: 343,
      letterSpacing: 0,
      lineHeight: '32px'
    },
  },
  findMore: {
    height: 40,
    width: 165,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 163
    },
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
    fontFamily: theme.landingFontFamily.karla,
  },

  scrollText: {
    width: 36,
    transform: "rotate(92deg)",
    color: theme.palette.primary.contrastText,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '15px',
    textAlign: 'center',
    marginTop: 165,
    [theme.breakpoints.down('sm')]: {
      marginTop: '4rem',
    },
  },
  scrollLine: {
    height: 82,
    width: 2,
    backgroundColor: theme.palette.primary.contrastText,
    marginLeft: 16,
  },


  pageScroll: {
    position: 'relative',
    width: 36,
    transform: 'rotate(90deg)',
    color: theme.palette.primary.contrastText,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '15px',
    textAlign: 'center',
    marginTop: 165,
    [theme.breakpoints.down('sm')]: {
      marginTop: '4rem',
    },
  },
  
  pageScrollLine: {
    position: 'absolute',
    top: '50%',
    left: '100%',
    width: 186,
    height: 1,
    backgroundColor: theme.palette.primary.contrastText,
    transform: 'translateY(-50%)',
    animation: '$elasticus 2.5s cubic-bezier(1,0,0,1) infinite',
  },

  "@keyframes elasticus": {
    "0%": {
      transformOrigin: '0% 0%',
      transform: 'scale(0, 1)',
    },
    
    "50%": {
      transformOrigin: '0% 0%',
      transform: 'scale(1, 1)',
      
    },
    
    "50.1%": {
      transformOrigin: '100% 0%',
      transform: 'scale(1, 1)',
    },
    
    "100%": {
      transformOrigin: '100% 0%',
      transform: 'scale(0, 1)',
    }
  },

};    
