import SeaburyAxios from './SeaburyAxios';
import logger from '../utils/logger';

export default {
  /*
   * Dashboard CRUD
   */
  getDashboards: async () => {
    try {
      const {data} = await SeaburyAxios.get(`/api/admin/dashboards`, {
        timeout: 10000,
      });
      return data;
    } catch (err) {
      logger.warn(err);
      throw err;
    }
  },

  createDashboard: async body => {
    try {
      const {data} = await SeaburyAxios.post(`/api/admin/dashboard`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  updateDashboard: async (body, dashboardId) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/dashboard/${dashboardId}`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  deleteDashboard: async dashboard => {
    try {
      await SeaburyAxios.delete(`/api/admin/dashboard/${dashboard.id}`);
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  /*
   * Dashboard Security groups
   */

  updateDashboardSecurityGroups: async (dashboard, groupName) => {
    try {
      const groups = [...dashboard.groups, groupName].sort();
      await SeaburyAxios.patch(`/api/admin/dashboard/${dashboard.id}/securitygroups`, {
        security_groups: groups,
      });
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  removeDashboardSecurityGroups: async dashboard => {
    try {
      await SeaburyAxios.delete(`/api/admin/dashboard/${dashboard.id}/securitygroups`);
      return true;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  /*
   * Dashboard pages
   */

  addDashboardPage: async (dashboard, pageId) => {
    try {
      const pagesIds = dashboard.pages.map(page => page.id);
      return await SeaburyAxios.patch(`/api/admin/dashboard/${dashboard.id}/pages`, {
        pages: [...pagesIds, pageId],
      });
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },

  /**
   * Dashboard Row Secutity
   */
  updateRowLevelSecurity: async (dashboardId, body) => {
    try {
      return await SeaburyAxios.patch(`api/admin/dashboard/${dashboardId}`, body);
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
  /**
   * Dashboard Target Window
   */
  updateTargetWindow: async (dashboardId, body) => {
    try {
      return await SeaburyAxios.patch(`api/admin/dashboard/${dashboardId}`, body);
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
  /**
   * Dashboard Layout option
   */
  updateLayoutOption: async (pageId, body) => {
    try {
      const {data} = await SeaburyAxios.patch(`/api/admin/page/${pageId}`, body);
      return data;
    } catch (err) {
      logger.warn(err);
      return null;
    }
  },
};
