import React from 'react';
import {withStyles, Card, Grid} from '@material-ui/core';
import styles from './section-card-styles';

const SectionCard = ({classes, title, subtitle, button, children}) => {
  return (
    <Card className={classes.card}>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <p className={classes.heading}>{title}</p>
          <p className={classes.title}>{subtitle}</p>
        </Grid>
        {button && <Grid item>{button}</Grid>}
      </Grid>
      {children && <div className={classes.contentContainer}>{children}</div>}
    </Card>
  );
};

export default withStyles(styles)(SectionCard);
