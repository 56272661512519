import React from 'react';
import propTypes from 'prop-types';
import formatMessage from 'format-message';
import {Grid, withStyles, OutlinedInput, InputAdornment} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styles from './help-table-header-styles';
import Dropdown from '../../../components/dropdown';
import {StatusOptions, PinToTypeOptions} from '../../../state/help/constant';

const HelpTableHeader = ({classes, filters, sections, onStatusClick, onSectionClick, onTypeClick, disabled, onSearchChange}) => (
  <Grid container className={classes.container} justify='space-between'>
    <Grid item className={classes.item}>
      <span className={classes.title}>{formatMessage('Filters')}</span>

      <Dropdown
        value={filters.status}
        options={StatusOptions}
        name='StatusFilter'
        onItemClick={onStatusClick}
        label={formatMessage('Status')}
        disabled={disabled}
      />
      {sections && <Dropdown
        value={filters.section}
        options={sections}
        name='TypeFilter'
        onItemClick={onSectionClick}
        label={formatMessage('Section')}
        disabled={disabled}
      />}
      <Dropdown
        value={filters.pintTo}
        options={PinToTypeOptions}
        name='TypeFilter'
        onItemClick={onTypeClick}
        label={formatMessage('Type')}
        disabled={disabled}
      />
    </Grid>
    <Grid item className={classes.item}>
      <span className={classes.title}>{formatMessage('Search')}</span>
      <OutlinedInput
        defaultValue={filters.query}
        className={classes.input}
        onChange={onSearchChange}
        placeholder={formatMessage('Type here')}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        inputProps={{
          maxLength: 40,
        }}
      />
    </Grid>
  </Grid>
);

HelpTableHeader.propTypes = {
  classes: propTypes.object.isRequired,
  onStatusClick: propTypes.func.isRequired,
  onTypeClick: propTypes.func.isRequired,
  onSearchChange: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

HelpTableHeader.defaultProps = {
  disabled: false,
};
export default withStyles(styles)(HelpTableHeader);
