import React, {useCallback, useEffect, useMemo} from 'react';
import formatMessage from 'format-message';
import propTypes from 'prop-types';
import _debounce from 'lodash/fp/debounce';
import {Card, CardContent, Grid, withStyles} from '@material-ui/core';
import {useStore} from 'outstated';
import ReactGA from 'react-ga';

import {NotificationsAdminStore} from '../../../state/notifications/store';
import DialogStore from '../../../state/dialog';
import NotificationsTable from './notifications-table';
import NotificationsTableColumns from './notifications-table-columns';
import NotificationsTableHeader from './notifications-table-header-view';
import classNames from 'classnames';
import LoadingSpinner from '../../../components/loading-spinner';
import {NotificationCreateDialog} from '../create-dialog';
import Pagination from '../../../components/pagination';
import NotificationAdminNavigation from '../navigation/admin';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../../Routes';
import styles from './notification-list-styles';
import {NOTIFICATION_STATUS} from '../../../assets/constants';
import {
  getDeleteDialogContent,
  getPublishDialogContent,
  getUnpublishDialogContent,
} from '../notification-dialog-content';

const showMessage = (classes, title, subtitle) => {
  return (
    <div className={classes.messageWrapper}>
      <p className={classes.message}>{title}</p>
      <p className={classes.message}>{subtitle}</p>
    </div>
  );
};

const NotificationList = ({classes}) => {
  const history = useHistory();
  const {state: NotificationsState, mutations: NotificationsMutations, effects: NotificationsEffects} = useStore(
    NotificationsAdminStore
  );
  const {mutations: DialogMutations} = useStore(DialogStore);
  const {filters, pagination, queryPagination, notifications, loading, error, reload} = NotificationsState;

  const hasQuery = filters.query && filters.query.length;
  const currentPagination = hasQuery ? queryPagination : pagination;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({page: window.location.pathname});
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    NotificationsEffects.getNotifications({isSearch: true});
  }, [filters, pagination.currentPage, queryPagination.currentPage, reload]);

  useEffect(() => {
    if (currentPagination.currentPage > currentPagination.totalPages) {
      NotificationsMutations.setPage(currentPagination.totalPages);
    }
  }, [currentPagination, NotificationsMutations]);

  const handlePagination = useCallback(
    page => {
      NotificationsMutations.setPage(page);
    },
    [NotificationsMutations]
  );
  const handleStatusChange = useCallback(
    status => {
      NotificationsMutations.setFiltersStatus(status.value);
    },
    [NotificationsMutations]
  );
  const handleTypeChange = useCallback(
    type => {
      NotificationsMutations.setFiltersType(type.value);
    },
    [NotificationsMutations]
  );
  const setQuery = useCallback(_debounce(300)(NotificationsMutations.setFiltersQuery), []);
  const handleSearch = useCallback(
    event => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const hasFilters =
    (filters.query && filters.query.length) ||
    (filters.status && filters.status.length) ||
    (filters.type && filters.type.length);
  const hasNotifications = notifications && notifications.length;
  const hasError = error && error.length;

  const errorMessage = showMessage(
    classes,
    null,
    formatMessage('Due to technical issues, notification data is currently not available. Please try again later.')
  );
  const noResultsMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No results found.'),
        formatMessage('Modify your filters and search criteria.')
      );
  const noNotificationsMessage = hasError
    ? errorMessage
    : showMessage(
        classes,
        formatMessage('No notifications present yet.'),
        formatMessage("Click on the '+ New notification' button to get started")
      );

  const handleRowClick = useCallback(
    row => {
      history.push(`${Routes.ADMIN.NOTIFICATIONS}/${row.original._id}`);
    },
    [history]
  );

  const handleOpenDeleteNotificationConfirmation = useCallback(
    (id, status) => {
      const {title, message} = getDeleteDialogContent(status);
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (id && id.length) {
            const result = await NotificationsEffects.deleteNotification(id);
            if (result) {
              NotificationsMutations.reload();
            }
          }
        },
        title,
        message,
      });
    },
    [DialogMutations, NotificationsMutations]
  );

  const handleEditClick = id => {
    history.push(`${Routes.ADMIN.NOTIFICATIONS}/${id}`);
  };

  const handlePublishClick = useCallback(
    notification => {
      const {icon, title, message} = getPublishDialogContent(notification.type);
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (notification && notification.status !== NOTIFICATION_STATUS.PUBLISHED) {
            const result = await NotificationsEffects.createOrUpdateNotificationPublication(
              {
                ...notification,
                status: NOTIFICATION_STATUS.PUBLISHED,
              },
              true
            );
            if (result) {
              NotificationsMutations.reload();
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [DialogMutations, NotificationsMutations]
  );

  const handleUnpublishClick = useCallback(
    notification => {
      const {icon, title, message} = getUnpublishDialogContent();
      DialogMutations.showConfirm({
        onActionConfirmed: async () => {
          DialogMutations.loading();
          if (notification && notification.status !== NOTIFICATION_STATUS.UNPUBLISHED) {
            const result = await NotificationsEffects.createOrUpdateNotificationPublication(
              {
                ...notification,
                status: NOTIFICATION_STATUS.UNPUBLISHED,
              },
              true
            );
            if (result) {
              NotificationsMutations.reload();
            }
          }
        },
        icon,
        title,
        message,
      });
    },
    [DialogMutations, NotificationsMutations]
  );

  const columns = useMemo(
    () =>
      NotificationsTableColumns({
        onDeleteClick: handleOpenDeleteNotificationConfirmation,
        onEditClick: handleEditClick,
        onPublishClick: handlePublishClick,
        onUnpublishClick: handleUnpublishClick,
      }),
    [
      filters,
      currentPagination.currentPage,
      handleOpenDeleteNotificationConfirmation,
      handleEditClick,
      handlePublishClick,
      handleUnpublishClick,
    ]
  );

  return (
    <>
      <NotificationAdminNavigation />
      <div className={classes.container}>
        <Grid container alignItems='center' justify='space-between' className={classes.header}>
          <Grid item className={classes.title}>
            {formatMessage('Notifications admin area')}
          </Grid>
          <Grid item>
            <NotificationCreateDialog />
          </Grid>
        </Grid>
        {hasNotifications || hasFilters ? (
          <>
            <NotificationsTableHeader
              filters={filters}
              disabled={loading}
              onStatusClick={handleStatusChange}
              onTypeClick={handleTypeChange}
              onSearchChange={handleSearch}
            />
            <Card className={classNames(classes.card)}>
              <CardContent className={classes.cardContent}>
                {loading && <LoadingSpinner />}
                {!loading &&
                  (hasNotifications ? (
                    <>
                      <NotificationsTable columns={columns} data={notifications} onRowClick={handleRowClick} />
                      <Pagination
                        pages={currentPagination.totalPages}
                        currentPage={currentPagination.currentPage}
                        onPageChanged={handlePagination}
                      />
                    </>
                  ) : (
                    noResultsMessage
                  ))}
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            {loading && <LoadingSpinner />}
            {!loading && noNotificationsMessage}
          </>
        )}
      </div>
    </>
  );
};

NotificationList.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(NotificationList);
