import React, {useCallback} from 'react';
import {useStore} from 'outstated';
import {withStyles, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {useHistory} from 'react-router-dom';

import {ArticleDialog} from '../../../../components/dialog';
import {HelpAdminStore} from '../../../../state/help/store';
import styles from './article-detail-view-styles';
import RichTextEditorViewer from '../../../../components/rich-text-editor/viewer/rich-text-editor-viewer';
import {Routes} from '../../../../Routes';

const ArticleDetailsDialog = ({classes, open, isOpen, article, isLoadMore, backButton, articleList,fromPinTo}) => {
  const {mutations: ArticlesMutations, effects: ArticleEffects} = useStore(HelpAdminStore);
  const history = useHistory();

  const handleOnClose = useCallback(() => {
    ArticlesMutations.setArticleDialogValue({
      isOpen: false,
      fromPinTo: false,
    });
    if (!fromPinTo) {
      history.push(`${Routes.HELP}`);
    }
  }, []);

  const handleOnBack = useCallback(() => {
    ArticlesMutations.setArticleDialogValue({
      isOpen: true,
      isLoadMore: true,
      fromPinTo: false,
      articleList: articleList,
    });
    history.push(`${Routes.HELP}`);
  });

  const handleClick = async (value, isLoad) => {
    const result = await ArticleEffects.getArticleDetails(value._id);
      if (result) {
        ArticlesMutations.setArticleDialogValue({
          isOpen: true,
          article: result,
          articleList: articleList,
          isLoadMore: false,
          backButton: true,
          fromPinTo: false
        });
        history.push(`${Routes.HELP}/${value._id}`);
      } else {
        history.push(`${Routes.HELP}`);
      }

   
  };
  return (
    <>
      {!isLoadMore && isOpen ? (
        <ArticleDialog
          open={isOpen}
          title={article.fullTitle}
          subTitle={article.section}
          isLoadMore={isLoadMore}
          backButton={backButton}
          fromPinTo={fromPinTo}
          onClose={handleOnClose}
          onBack={handleOnBack}

        >
          <RichTextEditorViewer className={classes.editor} value={article.message} />

        </ArticleDialog>
      ) : (
        <ArticleDialog
          open={isOpen}
          title={articleList ? articleList.section : ''}
          isLoadMore={isLoadMore}
          subTitle={articleList ? articleList.section : ''}
          fromPinTo={fromPinTo}
          onClose={handleOnClose}
          onBack={handleOnBack}
        >
          <List component='nav' aria-label='main mailbox folders'>
            {articleList && articleList.map((value, index) => (
              <ListItem
                key={index}
                className={classes.listItem}
                button
                onClick={() => {
                  handleClick(value, false);
                }}
              >
                <ListItemIcon>
                  <AssignmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={value.fullTitle} />
              </ListItem>
            ))}
          </List>
        </ArticleDialog>
      )}
    </>
  );
};

export default withStyles(styles)(ArticleDetailsDialog);
