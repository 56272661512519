export default {
  buildVersion: {
    position: 'fixed',
    zIndex: '9999',
    bottom: '0',
    right: '0',
    padding: '10px 20px',
    margin: '0',
    fontSize: '10px',
    opacity: '0.2',
    '&:hover': {
      opacity: '1',
      background: 'white',
    },
  },
};
