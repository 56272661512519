import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import classNames from 'classnames';
import {MenuList, withStyles} from '@material-ui/core';
import NavigationIcon from '../navigation-icons-view';
import Account from '../../../../assets/icons/icon_24_account_blue.svg';
import styles from './navigation-icon-styles';

const NavigationIconAccount = ({classes, children}) => {
  return (
    <NavigationIcon
      IconComponent={
        <img
          src={Account}
          alt={formatMessage('Account')}
          title={formatMessage('Account')}
          aria-label={formatMessage('Account')}
        />
      }
      render={() => <MenuList className={classNames(classes.menuItems, 'menu-items')}>{children}</MenuList>}
    />
  );
};

NavigationIconAccount.defaultProps = {
  children: PropTypes.node,
};

export default withStyles(styles)(NavigationIconAccount);
