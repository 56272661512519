import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {withStyles} from '@material-ui/core';
import propTypes from 'prop-types';

import {HelpSectionRoute} from './navigation';
import HelpSectionList from './list';
import {Routes} from '../../Routes';
import styles from './help-section-styles';
import Footer from '../../components/footer/footer-view';

const HelpSectionContainer = ({classes}) => {
  return (
    <div className={classes.wrapper}>
      <Switch>
        <HelpSectionRoute exact path={Routes.ADMIN.SECTIONS} Component={HelpSectionList} />   
        <Redirect to={Routes.ADMIN.SECTIONS} />
      </Switch>
      <Footer />
    </div>
  );
};

HelpSectionContainer.propTypes = {
  classes: propTypes.object.isRequired,
};

export default withStyles(styles)(HelpSectionContainer);
