import theme from '../../../assets/theme';

export default {
  productWrapper: {
    paddingTop: '9rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '4.5rem',
    },
  },
  menuTitle: {
    color: theme.palette.black,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  menuDetails: {
    width: 170,
    color: theme.palette.grey.midLight,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '28px',
    marginBottom: 0,
    '&:last-child': {
      marginBottom: 15,
    },
  },
  firstColumn: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  secondColumn: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentTitle: {
    width: 752,
    color: theme.palette.black,
    fontSize: 48,
    fontWeight: 'bold',
    letterspacing: -2,
    lineHeight: '60px',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      width: 809,
    },
    [theme.breakpoints.down('sm')]: {
      width: 343,
      fontSize: 24,
      letterspacing: -1,
      lineHeight: '32px',
    },
  },

  tabSection: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileTabTitle: {
    display: 'none',
    color: theme.palette.background.blackbg,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: -1.07,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  productTabSection: {
    marginTop: 83,
  },
  productTabs: {
    width: '100vw',
  },
  tabArea: {
    '&:hover': {
      "& $defaultTab": {
        color: theme.palette.primary.main,
        opacity: 'unset',
      },
      "& $tabNumberDefault": {
        color: theme.palette.primary.main,
        opacity: 'unset',
      }
    },
  },
  defaultTab: {
    opacity: 0.32,
    color: theme.palette.black,
    fontSize: 36,
    fontWeight: 'bold',
    letterSpacing: -1.6,
    lineHeight: '48px',
    marginRight: 50,
    cursor: 'pointer',
    position: 'relative',
    marginTop: '0.7rem',
  },
  activeTab: {
    fontSize: 36,
    fontWeight: 'bold',
    letterSpacing: -1.6,
    lineHeight: '48px',
    color: theme.palette.primary.main,
    marginRight: 50,
    cursor: 'pointer',
    position: 'relative',
    marginTop: '0.7rem',
  },
  tabNumberDefault: {
    opacity: 0.32,
    color: theme.palette.black,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '20px',
    position: 'absolute',
  },
  tabNumberActive: {
    color: theme.palette.primary.main,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
    lineHeight: '20px',
    position: 'absolute',
  },

  tabContainer: {
    justifyContent: 'center',
    minHeight: '13rem'
  },
  showTabContent: {
    width: '100%',
    marginTop: '2.938rem',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  hideTabContent: {
    width: '100%',
    marginTop: '2.938rem',
    display: 'none',
  },
  tabTitleSection: {
    display: 'flex',
  },
  title: {
    color: theme.palette.black,
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '24px',
    paddingLeft: [theme.spacing(2)],
  },
  tabDetails: {
    width: 233,
    color: theme.palette.black,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '28px',
    marginLeft: '2rem',
  },

  tabletViewSection: {
    display: 'block',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey.line}`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  tabletView: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '3rem',
    display: 'flex',
    paddingBottom: '0.375rem',
  },
  tabViewCount: {
    color: theme.palette.black,
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
  arrowIconleft: {
    paddingRight: '1.875rem',
    cursor: 'pointer',
  },
  arrowIconright: {
    cursor: 'pointer',
  },

  testDiv: {
    width: '100vw',
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    transform: 'rotate(-90deg)',
    transformOrigin: 'right top',
    transform: 'rotate(-90deg) translateY(-100px)',
  },
  itemSection: {
    position: 'relative',
    width: 500,
    height: 300,
    display: 'inline-block',
    cursor: 'grabbing',
    transform: 'rotate(90deg)',
    transformOrigin: 'right top',
  },
  item: {
    fontSize: 100,
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundColor: '#ddd',
    cursor: 'grabbing',
  },

  test: {
    display: 'flex',
  },
  items: {
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    cursor: 'pointer',
    width: '100%',
  },
  item: {
    width: 500,
    height: 300,
    backgroundColor: '#aeaeae',
    border: '1px solid black',
    display: 'inline-flex',
  },

  mainSection: {
    width: `100%`,
    height: 230,
    display: 'flex',
  },
  childSection: {
    width: 345,
  },

  selectorPage: {
    width: `100%`,
    height: 230,
    display: 'flex',
  },
  selectorPageUl: {
    fontSize: 0,
    listStyle: 'none',
    padding: '25px 0',
    display: 'flex',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  selectorPageLi: {
    textAlign: 'center',
    width: 500,
    padding: '30px 20px',
    borderRadius: 4,
    whiteSpace: 'normal',
  },

  gridListRoot: {
    '&::-webkit-scrollbar': {
      height: 2,
    },
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)', 
	    backgroundColor: theme.palette.grey.veryLight 
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    }
    
  },
  gridList: {

    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      marginTop: '2.938rem',
    },

   
  },
  gridItem: {
    marginRight: '8.5rem',
    '&:last-child': {
      paddingRight: '8.5rem',
    },
  },

  productLine: {
    height: 1,
    width: '85%',
    backgroundColor: theme.palette.grey.line,
    marginTop: 120,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  pageScroll: {
    position: 'relative',
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '15px',
    textAlign: 'center',
    marginTop: 114,
    display: 'block',
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  
  pageScrollLine: {
    position: 'absolute',
    top: '50%',
    left: '8%',
    height: 1,
    width: '85%',
    backgroundColor: theme.palette.grey.line,
    display: 'block',
    transform: 'translateY(-50%)',
    animation: '$elasticus 2.5s cubic-bezier(1,0,0,1) infinite',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  "@keyframes elasticus": {
    "0%": {
      transformOrigin: '0% 0%',
      transform: 'scale(0, 1)',
    },
    
    "50%": {
      transformOrigin: '0% 0%',
      transform: 'scale(1, 1)',
      
    },
    
    "50.1%": {
      transformOrigin: '100% 0%',
      transform: 'scale(1, 1)',
    },
    
    "100%": {
      transformOrigin: '100% 0%',
      transform: 'scale(0, 1)',
    }
  },
};
