import React from 'react';
import classNames from 'classnames';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useTable} from 'react-table';
import propTypes from 'prop-types';
import {withStyles, Tooltip} from '@material-ui/core';
import styles from './help-table-styles';
import {NOTIFICATION_STATUS} from '../../../assets/constants';

function HelpTable({classes, columns, data, onRowClick}) {
  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, headerGroups, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      fontSize: 12,
      color: '#3B4045',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid',
      borderColor: theme.palette.grey.light,
      maxWidth: 220
    }
  }))(Tooltip);

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup, i) => (
          <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <TableCell key={index} className={classes.cellHead} {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);

          return (
            <>
              <TableRow
                key={i}
                className={classes.row}
                {...row.getRowProps()}
                onClick={() => {
                  onRowClick(row);
                }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <HtmlTooltip
                      key={index}
                      placement='left'
                      classes={{tooltip: classes.tooltip}}
                      title={
                        cell.column.id === 'pinLocation' &&
                        cell.row.original.pinToInfo.length > 1 &&
                        (cell.row.original.pintTo === 'PAGE_HEADER' || cell.row.original.pintTo === 'PAGE_FOOTER') ? (
                          <React.Fragment>
                            <ul>
                              {cell.row.original.pinToInfo.map((val,i) => (
                                <li key={i}>{val.location}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : (
                          ''
                        )
                      }
                    >
                      <TableCell
                        className={classNames(classes.cell, {
                          [classes.publishedCell]:
                            cell.column.id === 'status' && cell.value === NOTIFICATION_STATUS.PUBLISHED,
                          [classes.actionsColumn]: cell.column.id === 'actions',
                          [classes.fulltitle]: cell.column.id === 'fullTitle',
                          [classes.title]: cell.column.id === 'section',
                        })}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    </HtmlTooltip>
                  );
                })}
              </TableRow>
              <tr className={classes.spacer} />
            </>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

HelpTable.propTypes = {
  classes: propTypes.object.isRequired,
  columns: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
};

export default withStyles(styles)(HelpTable);
