import React, {useRef, useEffect, useCallback, useState} from 'react';
import {withStyles} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import HorizontalScroll from 'react-scroll-horizontal';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import styles from './products-styles';
import globalTradeIcon from '../../../assets/icons/ic-global-trade.svg';
import oceanTradeIcon from '../../../assets/icons/ic-ocean-trade.svg';
import forecastIcon from '../../../assets/icons/ic-forecast.svg';
import expressIcon from '../../../assets/icons/ic-express.svg';
import capacityTrackingIcon from '../../../assets/icons/ic-capacity-tracking.svg';
import scheduledCapacityIcon from '../../../assets/icons/ic-scheduled-capacity.svg';
import cargoModelIcon from '../../../assets/icons/ic-cargo-model.svg';
import trafficIcon from '../../../assets/icons/ic-traffic.svg';
import ArrowBack from '../../../assets/icons/ic-arrow-back.svg';
import ArrowForward from '../../../assets/icons/ic-arrow-forward-black.svg';
import AirCargo from '../../../assets/images/air-cargo.png';

const Products = ({classes}) => {
  const [tabValue, setValue] = React.useState(1);
  const navRef = React.useRef();
  navRef.current = tabValue;
  const [state, setState] = useState({
    mobileView: false,
  });
  const {mobileView} = state;

  const handleChange = newValue => {
    // sideScroll(tabValue, newValue);

    setValue(newValue);
  };

  const onTabArrowChange = value => {
    if (value && navRef.current <= 2) {
      setValue(navRef.current + 1);
    } else if (!value && navRef.current <= 3 && navRef.current > 1) {
      setValue(navRef.current - 1);
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960 // For mobile view (material ui - sm)
        ? setState(prevState => ({...prevState, mobileView: true}))
        : setState(prevState => ({...prevState, mobileView: false}));
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  return (
    <section className={classes.productWrapper} id='products'>
      <Container maxWidth={'xl'}>
        <Grid container direction='row' spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box display='flex' className={classes.firstColumn}>
              <p className={classes.menuTitle}>— Products</p>
              <p className={classes.menuDetails}>Market intelligence databases</p>
              <p className={classes.menuDetails}>Fleet & network tools</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box display='flex' className={classes.secondColumn}>
              <h1 className={classes.contentTitle}>
              Accenture Cargo offers an unparalleled and comprehensive set of trade and capacity databases to the
                industry.
              </h1>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.pageScroll}>
              Click
              <div className={classes.pageScrollLine} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} className={classes.tabSection}>
            <Box display='flex' className={classes.productTabSection}>
              <div className={classes.tabArea}>
                <div className={classes[navRef.current === 1 ? 'tabNumberActive' : 'tabNumberDefault']}>01</div>
                <div
                  id='demandTab'
                  onClick={() => handleChange(1)}
                  className={classes[navRef.current === 1 ? 'activeTab' : 'defaultTab']}
                >
                  Demand
                </div>
              </div>
              <div className={classes.tabArea}>
                <div className={classes[navRef.current === 2 ? 'tabNumberActive' : 'tabNumberDefault']}>02</div>
                <div
                  id='supplyTab'
                  onClick={() => handleChange(2)}
                  className={classes[navRef.current === 2 ? 'activeTab' : 'defaultTab']}
                >
                  Supply
                </div>
              </div>
              <div className={classes.tabArea}>
                <div className={classes[navRef.current === 3 ? 'tabNumberActive' : 'tabNumberDefault']}>03</div>
                <div
                  id='fleetTab'
                  onClick={() => handleChange(3)}
                  className={classes[navRef.current === 3 ? 'activeTab' : 'defaultTab']}
                >
                  Fleet & network
                </div>
              </div>
            </Box>
          </Grid>

          <div className={mobileView || navRef.current === 1 ? classes.showTabContent : classes.hideTabContent}>
            <Grid container direction='row' spacing={0} className={classes.tabContainer}>
              <Grid item xs={12} sm={12} md={3}>
                <h2 className={classes.mobileTabTitle}>Demand</h2>
                <div className={classes.tabTitleSection}>
                  <img src={globalTradeIcon} alt={'Icon'} />
                  <p className={classes.title}>Air trade</p>
                </div>
                <div class={classes.tabDetails}>
                  Freight carried by air and surface, global coverage for 2000 commodities per trade lane.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.tabTitleSection}>
                  <img src={oceanTradeIcon} alt={'Icon'} />
                  <p className={classes.title}>Ocean trade</p>
                </div>
                <div class={classes.tabDetails}>
                  Global ocean trade demand, split by mode with visibility on trade lane level per commodity.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.tabTitleSection}>
                  <img src={expressIcon} alt={'Icon'} />
                  <p className={classes.title}>Express</p>
                </div>
                <div class={classes.tabDetails}>
                  Air express trade flows by trade lane and industry in shipments and weight.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.tabTitleSection}>
                  <img src={forecastIcon} alt={'Icon'} />
                  <p className={classes.title}>Forecast</p>
                </div>
                <div class={classes.tabDetails}>
                  5-year forecast for air, express, and containerized ocean cargo demand, based on economic drivers and expert input.
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={mobileView || navRef.current === 2 ? classes.showTabContent : classes.hideTabContent}>
            <Grid container direction='row' spacing={0} className={classes.tabContainer}>
              <Grid item xs={12} sm={12} md={3}>
                <h2 className={classes.mobileTabTitle}>Supply</h2>
                <div className={classes.tabTitleSection}>
                  <img src={scheduledCapacityIcon} alt={'Icon'} />
                  <p className={classes.title}>Scheduled capacity</p>
                </div>
                <div class={classes.tabDetails}>
                  Available scheduled air cargo capacity, per airline, by freighter and passenger aircraft types on
                  route and flight level.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.tabTitleSection}>
                  <img src={capacityTrackingIcon} alt={'Icon'} />
                  <p className={classes.title}>Capacity tracking</p>
                </div>
                <div class={classes.tabDetails}>
                  Air cargo capacity of actual flown flights, per airline, by freighter and passenger aircraft types on
                  route and flight level; weekly data updates.
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={mobileView || navRef.current === 3 ? classes.showTabContent : classes.hideTabContent}>
            <Grid container direction='row' spacing={0} className={classes.tabContainer}>
              <Grid item xs={12} sm={12} md={3}>
                <h2 className={classes.mobileTabTitle}>Fleet & network</h2>
                <div className={classes.tabTitleSection}>
                  <img src={cargoModelIcon} alt={'Icon'} />
                  <p className={classes.title}>Cargo Model</p>
                </div>
                <div class={classes.tabDetails}>
                  The Accenture Cargo Model is a state-of-the-art tool enabling our airline clients to autonomously
                  optimize their network & fleet strategy.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.tabTitleSection}>
                  <img src={trafficIcon} alt={'Icon'} />
                  <p className={classes.title}>Traffic Model</p>
                </div>
                <div class={classes.tabDetails}>
                  Air freight volumes carried between airports modeled per airline for over 200 airlines and 1000+
                  airports.
                </div>
              </Grid>
            </Grid>
          </div>

          <Box display='flex' className={classes.tabletViewSection}>
            <div className={classes.tabletView}>
              <div className={classes.tabViewCount}>
                {navRef.current === 1 ? '01' : navRef.current === 2 ? '02' : '03'}/03
              </div>
              <div>
                <img
                  src={ArrowBack}
                  alt={'Icon'}
                  className={classes.arrowIconleft}
                  onClick={() => onTabArrowChange(false)}
                />
                <img
                  src={ArrowForward}
                  alt={'Icon'}
                  className={classes.arrowIconright}
                  onClick={() => onTabArrowChange(true)}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(Products);
