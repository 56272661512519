import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import {Field} from 'react-final-form';
import {useStore} from 'outstated';
import {SelectField} from '../fields';
import {HelpAdminStore} from '../../../state/help/store';


const HelpSectionSelectFormField = ({disabled}) => {

const {state: HelpState} = useStore(HelpAdminStore);
const {sections} = HelpState;

  return (
    <Field
      validate={value => (value ? undefined : formatMessage('required'))}
      name='section'
      render={({input, meta}) => (
        <SelectField
          label={formatMessage('Section')}
          value={input.value}
          handleChange={input.onChange}
          extra={meta.error}
          options={sections}
          disabled={disabled}
        />
      )}
    />
  );
};

HelpSectionSelectFormField.propTypes = {
  disabled: PropTypes.bool,
};

HelpSectionSelectFormField.defaultProps = {
  disabled: false,
};

export default HelpSectionSelectFormField;
