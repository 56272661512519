import React from 'react';
import propTypes from 'prop-types';
import {Button as MuiButton, withStyles} from '@material-ui/core';
import Add from '../../assets/icons/icon_24_plus_blue.svg';
import Edit from '../../assets/icons/icon_24_edit_blue.svg';
import Back from '../../assets/icons/icon_24_chevron_left_blue.svg';
import styles from './button-styles';

const Button = props => {
  // Fron Styles
  const {classes} = props;
  // From Parent
  const {className, children, type, onClick = null, loading = false, disabled = false, variant} = props;

  // Variables
  let icon;
  switch (props.icon) {
    case 'add':
      icon = Add;
      break;
    case 'edit':
      icon = Edit;
      break;
    case 'back':
      icon = Back;
      break;
    default:
      icon = 'none';
  }

  // Render
  return (
    <MuiButton
      className={className}
      variant={variant}
      color='primary'
      onClick={onClick}
      disabled={loading || disabled}
      type={type || 'button'}
      classes={{
        root: classes.button,
        outlinedPrimary: classes.buttonOutlined,
        disabled: classes.buttonDisabled,
        label: classes.label,
      }}
    >
      {loading && 'Loading...'}

      {!loading && icon !== 'none' && <img alt='' aria-hidden src={icon} className={classes.icon} />}
      {!loading && children}
    </MuiButton>
  );
};

Button.propTypes = {
  children: propTypes.any.isRequired,
  onClick: propTypes.func,
  icon: propTypes.string,
  loading: propTypes.bool,
  variant: propTypes.string,
};

export default withStyles(styles)(Button);
